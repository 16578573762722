import { uniqBy } from 'lodash-es'
import { FC } from 'react'
import { HiOutlineLink } from 'react-icons/hi'
import { isPresent } from 'ts-extras'
import { Actions } from '~/redux'
import { UIResource, UIResourceTagSet } from '~/types'
import { useDispatch, useSelector } from '~/utils'
import { Tag } from './Tag'
import { TagSet } from './TagSet'

export interface ResourceTagSetProps {
	resource: UIResource
	set: UIResourceTagSet
	row: number
}

export const ResourceTagSet: FC<ResourceTagSetProps> = ({ resource, set, row }) => {
	const dispatch = useDispatch()
	const config = useSelector(state => state.config)
	const resourceTags = useSelector(state => state.resources.tags)
	const segmentTags = useSelector(state => state.segmentTags)
	const resourceSegments = useSelector(state => state.resourceSegments).filter(as => as.resource_id === resource.id)
	const activeTags = uniqBy(
		[
			resourceTags.filter(tag => tag.resource_id === resource.id),
			segmentTags.filter(tag => resourceSegments.some(s => tag.segment_id === s.segment_id))
		].flat(),
		tag => tag.tag_id
	)
	const tagSet = set.tag_set
	const tags = resource.editing
		? config.tags.filter(tag => tag.tag_set_id === tagSet.id)
		: activeTags
				.map(activeTag => ({
					...activeTag,
					label: config.tags.find(t => t.id === activeTag.tag_id && t.tag_set_id === tagSet.id)?.label
				}))
				.filter(tag => isPresent(tag.label))
	return (
		<TagSet label={tagSet.label} row={row}>
			{tags.map(tag => {
				const activeTag = activeTags.find(t => t.tag_id === tag.id)
				const isSegmentTag = 'segment_id' in tag || (activeTag && 'segment_id' in activeTag)
				return (
					<Tag
						key={tag.id}
						type={tagSet.tag_type_id}
						inactive={!activeTag}
						onClick={
							resource.editing && !isSegmentTag
								? () =>
										activeTag
											? dispatch(Actions.removeResourceTag(activeTag.id))
											: dispatch(
													Actions.addResourceTag({
														resource_id: resource.id,
														tag_id: 'tag_id' in tag ? tag.tag_id : tag.id
													})
												)
								: undefined
						}
					>
						{isSegmentTag && <HiOutlineLink />}
						{tag.label}
					</Tag>
				)
			})}
		</TagSet>
	)
}
