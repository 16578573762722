import { SetOptional } from 'type-fest'
import {
	Resource,
	ResourceCategory,
	ResourceSegment,
	ResourceTag,
	TablesInsert,
	UIEnrichedResource,
	UIResource,
	UIResourceTagSet
} from '~/types'
import { makeActionCreator, makeMetaActionCreator } from './actionCreator'

export default {
	getResources: makeMetaActionCreator<Resource['project_id']>()('GET_RESOURCES'),
	getResourcesOk: makeActionCreator<Array<SetOptional<UIResource, 'editing'>>>()('GET_RESOURCES_OK'),
	getResourceTagsOk: makeActionCreator<ResourceTag[]>()('GET_RESOURCE_TAGS_OK'),

	getResourceSegmentsOk: makeActionCreator<ResourceSegment[]>()('GET_RESOURCE_SEGMENTS_OK'),

	createResourceSet: makeActionCreator<SetOptional<UIResourceTagSet, 'id'>>()('CREATE_RESOURCE_SET'),
	createResourceSets: makeActionCreator<Array<SetOptional<UIResourceTagSet, 'id'>>>()('CREATE_RESOURCE_SETS'),

	getResourceCategories: makeActionCreator()('GET_RESOURCE_CATEGORIES'),
	getResourceCategoriesOk: makeActionCreator<ResourceCategory[]>()('GET_RESOURCE_CATEGORIES_OK'),

	duplicateResource: makeActionCreator<UIEnrichedResource>()('DUPLICATE_RESOURCE'),

	editResource: makeActionCreator<boolean, Resource['id']>()('EDIT_RESOURCE'),
	updateResource: makeActionCreator<Partial<UIResource>, Resource['id']>()('UPDATE_RESOURCE'),

	addResourceTag: makeActionCreator<TablesInsert<'resource_tag'>>()('ADD_RESOURCE_TAG'),
	removeResourceTag: makeMetaActionCreator<ResourceTag['id']>()('REMOVE_RESOURCE_TAG')
}
