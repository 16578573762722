import * as Sentry from '@sentry/react'
import { Loader2Icon } from 'lucide-react'
import { FC, StrictMode, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigation } from 'react-router-dom'
import { Auth } from '~/components/Auth'
import { Toaster } from '~/components/ui'
import { getItem, useSelector } from '~/utils'
import { ThemeProvider } from '../ui/theme-provider'
import { ErrorBoundary } from './ErrorFallback'
import { Navbar } from './navbar'

const themeKey = 'vite-ui-theme'
const defaultTheme = 'light'

if (import.meta.env.VITE_VERCEL_ENV !== 'development') {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_VERCEL_ENV || 'development',
		tracePropagationTargets: ['localhost', location.origin, import.meta.env.VITE_SUPABASE_API_URL],
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false
			}),
			Sentry.feedbackIntegration({
				colorScheme: getItem('themeKey') || defaultTheme,
				formTitle: 'Send feedback',
				buttonLabel: 'Send feedback'
			})
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

export const App: FC = () => {
	const user = useSelector(s => s.user)
	const resources = useSelector(s => s.resources)
	const navigation = useNavigation()
	const [isLoading, setIsLoading] = useState(false)
	const { pathname } = useLocation()

	useEffect(() => {
		const loading = navigation.state === 'loading' || resources.loading
		const timeout = setTimeout(() => setIsLoading(loading), 150)
		if (!loading) setIsLoading(false)
		return () => clearTimeout(timeout)
	}, [navigation.state, resources.loading])

	return (
		<StrictMode>
			<ThemeProvider defaultTheme={defaultTheme} storageKey={themeKey}>
				<ErrorBoundary>
					{user ? (
						<div className="grid h-full max-h-screen grid-rows-[auto,1fr]">
							<Navbar className="mx-auto max-w-screen-xl px-4" />
							<div className="overflow-y-auto">
								<main
									className={
										['canvas', 'template', 'prospecting'].some(p => pathname.includes(p))
											? 'h-full w-full'
											: 'mx-auto h-full max-w-screen-xl px-4 py-4'
									}
								>
									<ErrorBoundary>
										<Outlet />
									</ErrorBoundary>
								</main>
								{isLoading && (
									<div className="absolute inset-x-0 bottom-0 top-16 z-10 flex items-center justify-center bg-white/60 dark:bg-white/10">
										<Loader2Icon className="h-12 w-12 animate-spin opacity-70" />
									</div>
								)}
							</div>
						</div>
					) : (
						<div className="flex h-full w-full items-center">
							<Auth />
						</div>
					)}
					<Toaster posistion="left" />
				</ErrorBoundary>
			</ThemeProvider>
		</StrictMode>
	)
}
