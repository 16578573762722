import type { ProspectingList } from '~/types'
import { Action } from '../actions'

export function prospectingLists(state: ProspectingList[] = [], action: Action): ProspectingList[] {
	switch (action.type) {
		case 'GET_PROSPECTING_LISTS_OK':
			return action.payload

		case 'INSERT_PROSPECTING_LIST_OK':
			return [...state, action.payload]

		case 'UPDATE_PROSPECTING_LIST_OK':
			return state.map(pl => (pl.id === action.payload.id ? action.payload : pl))

		case 'DELETE_PROSPECTING_LIST_OK':
			return state.filter(pl => pl.id !== action.meta)
	}
	return state
}
