import { isPresent } from 'ts-extras'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { Project } from '~/types'
import { State } from '~/types/state'
import { getItem, supabase, toToastError } from '~/utils'
import { Action, ActionTypes, Actions } from '../actions'

export function* tagSetSagas() {
	yield* takeLatest<ActionTypes, any>('ORGANIZATIONS_LOADED', getTagConfig)
	yield* takeLatest<ActionTypes, any>('PROJECT_SELECT', getTagConfig)
	yield* takeLatest<ActionTypes, any>('UPDATE_TAG_SET', updateTagSet)
}

function* updateTagSet(action: Action<'UPDATE_TAG_SET'>) {
	const res = yield* call(async () => supabase.from('tag_set').update(action.payload).eq('id', action.meta))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	}
}

function* getTagConfig(action: Action<'ORGANIZATIONS_LOADED'> | Action<'PROJECT_SELECT'>) {
	const projects = yield* select((state: State) => state.user?.projects ?? [])
	const companies = yield* select((state: State) => state.user?.companies)
	let selectedProject: Project | undefined
	if (action.type === 'ORGANIZATIONS_LOADED') {
		selectedProject = projects?.find(p => p.id === getItem('selectedProjectId')) ?? projects[0]
	} else if (action.type === 'PROJECT_SELECT') {
		if (!projects.length) return
		selectedProject = projects.find(p => p.id === action.meta)
	}

	const currentCompanyID = selectedProject?.org_id ?? companies?.[0]?.id
	if (!currentCompanyID) return

	const queries = yield* call(async () =>
		Promise.all([
			supabase.from('tag_set').select('*').eq('org_id', currentCompanyID),
			supabase.from('tag_type').select('*').order('order'),
			supabase
				.from('tag')
				.select('*, tag_set!inner(org_id)')
				.eq('tag_set.org_id', currentCompanyID)
				.order('label')
		])
	)
	const errors = queries.map(q => q.error).filter(isPresent)

	// eslint-disable-next-line github/array-foreach
	if (errors.length) errors.forEach(console.warn)
	else {
		yield* put(
			Actions.getTagConfigOk({
				tagSets: queries[0].data ?? [],
				tags:
					queries[2].data?.map(tag => ({
						...tag,
						type: queries[0].data?.find(tg => tg.id === tag.tag_set_id)?.tag_type_id
					})) ?? [],
				tagSetTypes: queries[1].data ?? []
			})
		)
	}
}
