import type { UITagSet } from '~/types'
import { TagConfigState } from '~/types/ui'
import { Action, MetaAction } from '../actions'

export const initialState: TagConfigState = {
	sets: [],
	types: [],
	tags: []
}

function set(state: UITagSet, action: MetaAction): UITagSet {
	if (state.id !== action.meta) return state
	switch (action.type) {
		case 'DISABLE_TAG_SET':
			return {
				...state,
				disabled: action.payload
			}

		case 'EDIT_TAG_SET':
			return {
				...state,
				editing: action.payload
			}

		case 'UPDATE_TAG_SET_OK':
		case 'UPDATE_TAG_SET':
			return {
				...state,
				...action.payload
			}
	}
	return state
}

export function config(state: TagConfigState = initialState, action: Action): TagConfigState {
	switch (action.type) {
		case 'GET_TAG_CONFIG_OK':
			return {
				...state,
				sets: action.payload.tagSets.map(g => ({ ...g, editing: false })),
				types: action.payload.tagSetTypes,
				tags: action.payload.tags
			}

		case 'CREATE_TAG_SET':
			return {
				...state,
				sets: [...state.sets, { ...action.payload, editing: action.payload.editing ?? false }]
			}

		case 'EDIT_TAG_SET':
		case 'UPDATE_TAG_SET_OK':
		case 'UPDATE_TAG_SET':
		case 'DISABLE_TAG_SET':
			return {
				...state,
				sets: state.sets.map(g => set(g, action))
			}

		case 'DELETE_TAG_SET_OK':
		case 'DELETE_TAG_SET':
			return {
				...state,
				sets: state.sets.filter(g => g.id !== action.meta)
			}

		case 'INSERT_TAG_OK':
			return {
				...state,
				tags: [...state.tags, action.payload]
			}

		case 'DELETE_TAG_OK':
		case 'DELETE_TAG':
			return {
				...state,
				tags: state.tags.filter(t => t.id !== action.meta)
			}
	}
	return state
}
