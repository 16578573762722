/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { startCase } from 'lodash-es'
import { Download, Upload } from 'lucide-react'
import { FC, useEffect, useMemo, useState } from 'react'
import { LiaSpinnerSolid } from 'react-icons/lia'
import { useParams } from 'react-router-dom'
import {
	Card,
	CardHeader,
	DialogTrigger,
	Menubar,
	MenubarContent,
	MenubarItem,
	MenubarMenu,
	MenubarTrigger
} from '~/components/ui'
import { ProspectingContact, ProspectingList, ProspectingListCompany, ProspectingType } from '~/types'
import {
	PROFF_COMPANY_ENRICHMENT_ID,
	compareRows,
	pluralize,
	useCurrentProject,
	useMemoSelector,
	useSearch,
	useSelector
} from '~/utils'
import { isRunActive } from '~/utils/dagster'
import { CompanyTable } from './CompanyTable'
import { ContactTable } from './ContactTable'
import { EnrichmentDialog } from './EnrichmentDialog'
import { ExportDialog } from './ExportDialog'
import { ListUpload } from './ListUpload'
import { enrichmentName, useCompaniesWithEnrichments, useProspectingContacts } from './listUtils'

export interface ProspectingSearchParams {
	tab: string
	type?: ProspectingType
}

export const Prospecting: FC = () => {
	const project = useCurrentProject()
	const _lists = useSelector(state => state.prospecting.lists)
	const lists = useMemo(() => _lists.filter(pl => pl.project_id === project?.id), [_lists, project?.id])
	const { listId } = useParams<{ 'listId': ProspectingList['id'] }>()
	const currentList = lists.find(l => l.id === listId)
	const [{ tab = 'all', type: listType = 'company' }, setParams] = useSearch<ProspectingSearchParams>({ tab: 'all' })
	const [menuOpen, setMenuOpen] = useState(false)
	const [selected, setSelected] = useState<Array<ProspectingListCompany | ProspectingContact>>([])

	const enrichments = useMemoSelector(
		state => state.enrichments.items,
		e =>
			e
				.filter(enr => enr.list_id === listId && enr.type_id !== PROFF_COMPANY_ENRICHMENT_ID)
				.sort(compareRows({ order: 'desc', type: 'updated_at' })),
		[listId]
	)

	const runs = useSelector(state => state.enrichments.runs)
	const types = useMemoSelector(
		state => state.enrichments.types,
		t => t.filter(et => et.type === listType).filter(et => !['proff', 'manual'].includes(et.name)),
		[listType]
	)

	const companies = useCompaniesWithEnrichments(listId, enrichments)
	const contacts = useProspectingContacts(companies, enrichments)

	const typeHasEnrichment = useMemo(
		() =>
			Object.fromEntries(
				types.map(et => [et.name, enrichments.some(e => e.type_id === et.id && e.list_id === listId)])
			),
		[enrichments, listId, types]
	)

	useEffect(() => {
		setSelected([])
	}, [listType])

	if (!project || !currentList) return null
	return (
		<>
			<Card className="col-start-1 w-full">
				<CardHeader className="flex-row justify-between space-y-0 p-4">
					<ListUpload project={project} list={currentList} type={listType}>
						<Upload />
						<div className="whitespace-normal text-center">Import {startCase(pluralize(listType))}</div>
					</ListUpload>
					<ExportDialog
						data={listType === 'company' ? companies : contacts}
						filename={`${pluralize(listType)}_${currentList.id}`}
						selected={selected}
					>
						<Download />
						<div className="whitespace-normal text-center">Export {startCase(pluralize(listType))}</div>
					</ExportDialog>
				</CardHeader>
			</Card>
			<div
				className="col-start-2 col-end-4 row-start-1 row-end-3 flex h-full min-h-0 w-full flex-col gap-4"
				onClick={_ => setMenuOpen(false)}
			>
				<div className="flex justify-between gap-4">
					<Menubar className="justify-self-end" value={listType}>
						<MenubarMenu value="company">
							<MenubarTrigger onClick={_ => setParams({ type: 'company' })}>
								Companies ({companies.length})
							</MenubarTrigger>
						</MenubarMenu>
						<MenubarMenu value="contact">
							<MenubarTrigger onClick={_ => setParams({ type: 'contact' })}>
								Contacts ({contacts.length})
							</MenubarTrigger>
						</MenubarMenu>
					</Menubar>
					<Menubar className="justify-self-end" value={menuOpen ? 'new' : tab}>
						<MenubarMenu value="all">
							<MenubarTrigger onClick={_ => setParams({ tab: 'all' })}>All</MenubarTrigger>
						</MenubarMenu>
						<MenubarMenu value="custom">
							<MenubarTrigger onClick={_ => setParams({ tab: 'custom' })}>Custom</MenubarTrigger>
						</MenubarMenu>
						{types.map(et => {
							const runIsProbablyLaunching = [
								companies.length > 0,
								!typeHasEnrichment[et.name],
								et.name !== 'gpt',
								listType === 'contact' ? et.name !== 'csv' : true
							].every(Boolean)
							const hasActiveRun = runs.some(r => r.pipeline.name.includes(et.name) && isRunActive(r))
							return (
								<MenubarMenu key={et.id} value={et.name}>
									<MenubarTrigger onClick={_ => setParams({ tab: et.name })}>
										{enrichmentName(et)}
										{(runIsProbablyLaunching || hasActiveRun) && (
											<LiaSpinnerSolid className="ml-1 h-4 w-4 animate-spin" />
										)}
									</MenubarTrigger>
								</MenubarMenu>
							)
						})}
						<MenubarMenu value="new">
							<MenubarTrigger
								onClick={e => {
									e.stopPropagation()
									setMenuOpen(true)
								}}
							>
								+
							</MenubarTrigger>
							<MenubarContent onClick={e => e.stopPropagation()}>
								<MenubarItem disabled>New View (coming soon)</MenubarItem>
								<EnrichmentDialog
									listId={currentList.id}
									onOpenChange={_ => setMenuOpen(false)}
									type={listType}
									selected={selected.map(c => c.id)}
								>
									<DialogTrigger asChild>
										<MenubarItem>New Enrichment</MenubarItem>
									</DialogTrigger>
								</EnrichmentDialog>
							</MenubarContent>
						</MenubarMenu>
					</Menubar>
				</div>

				{listType === 'company' ? (
					<CompanyTable
						tab={tab}
						list={currentList}
						companies={companies}
						enrichments={enrichments}
						selected={selected}
						onRowSelected={e => {
							if (!e.node.isSelected()) setSelected(sel => sel.filter(r => r.id !== e.node.id))
							else setSelected(sel => sel.concat(e.node.data))
						}}
					/>
				) : (
					<ContactTable
						tab={tab}
						list={currentList}
						contacts={contacts}
						enrichments={enrichments}
						selected={selected}
						onRowSelected={e => {
							if (!e.node.isSelected()) setSelected(sel => sel.filter(r => r.id !== e.node.id))
							else setSelected(sel => sel.concat(e.node.data))
						}}
					/>
				)}
			</div>
		</>
	)
}
