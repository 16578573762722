import { snakeCase } from 'lodash-es'
import { objectEntries } from 'ts-extras'

// Calculate Levenshtein distance for tie-breaking
export function levenshteinDistance(a: string, b: string): number {
	const costs = []
	for (let i = 0; i <= a.length; i++) {
		let lastValue = i
		for (let j = 0; j <= b.length; j++) {
			if (i == 0) costs[j] = j
			else if (j > 0) {
				let newValue = costs[j - 1] ?? 0
				if (a.charAt(i - 1) !== b.charAt(j - 1))
					newValue = Math.min(Math.min(newValue, lastValue), costs[j] ?? 0) + 1
				costs[j - 1] = lastValue
				lastValue = newValue
			}
		}
		if (i > 0) costs[b.length] = lastValue
	}
	return costs[b.length] as number
}

export function wordSimilarityScore(a: string, b: string) {
	const wordsA = a.split(/_+/)
	const wordsB = b.split(/_+/)
	let score = -5

	// Extra reward for matching partial words
	if (a.includes(b) || b.includes(a)) score += 20

	// Extra reward for matching whole words
	for (const workA of wordsA) if (wordsB.includes(workA)) score += 20

	score -= levenshteinDistance(a, b) // Penalty based on word distance

	return score
}

export function fuzzyGetEntry<T extends Record<string, any>>(obj: T, ...keys: string[]) {
	if (keys.length === 0) return null
	// Normalize key function
	const normalizeKey = (k: string) => snakeCase(k)

	const matches = objectEntries(obj).map(([k, v]) => ({
		key: k,
		value: v,
		score:
			keys
				.map(key => wordSimilarityScore(normalizeKey(key), normalizeKey(k)))
				.sort((a, b) => b - a)
				.shift() ?? -1
	}))

	// Sort matches based on score (higher is better)
	matches.sort((a, b) => b.score - a.score)

	// Return the closest match or undefined if no matches
	return matches.find(m => m.score > 0) ?? null
}
