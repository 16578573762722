import { Button } from '@/components/ui/button'
import { zodResolver } from '@hookform/resolvers/zod'
import { DialogProps } from '@radix-ui/react-dialog'
import { mapValues, startCase } from 'lodash-es'
import { Globe } from 'lucide-react'
import { FC, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '~/components/ui'
import { Textarea } from '~/components/ui/textarea'
import { Actions } from '~/redux'
import { ProspectingCompany, ProspectingContact, ProspectingListCompany, ProspectingType, Template } from '~/types'
import { GLOBAL_COMPANY, pluralize, useDispatch, useSelector } from '~/utils'

export interface EnrichmentDialogProps extends DialogProps {
	listId: string
	type: ProspectingType
	selected: Array<ProspectingCompany['id'] | ProspectingListCompany['id'] | ProspectingContact['id']>
}

export const EnrichmentDialog: FC<EnrichmentDialogProps> = ({ listId, children, type, selected, ...props }) => {
	const templates = useSelector(state => state.templates)
	const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null)
	const [open, setOpen] = useState(false)
	const dispatch = useDispatch()

	const FormSchema = useMemo(
		() =>
			z.object({
				templateId: z.string({ required_error: 'Please select a template' }),
				parameters: currentTemplate?.parameters
					? z.object(
							mapValues(currentTemplate.parameters as Record<string, string[]>, (_, key) =>
								z.string({ required_error: `${startCase(key)} is required` })
							)
						)
					: z.object({}).optional()
			}),
		[currentTemplate?.parameters]
	)
	const form = useForm<z.infer<typeof FormSchema>>({ resolver: zodResolver(FormSchema) })

	const currentTemplateId = form.watch('templateId')
	useEffect(() => {
		const template = templates?.find(t => t.id === currentTemplateId)
		setCurrentTemplate(template ?? null)
	}, [templates, currentTemplateId])

	const ids = useMemo(
		() =>
			selected.filter(id => {
				if (type === 'company') return id.startsWith('pco_') || id.startsWith('plc_')
				if (type === 'contact') return id.startsWith('pct_')
			}),
		[selected, type]
	)

	async function onSubmit(formData: z.infer<typeof FormSchema>) {
		dispatch(
			Actions.runEnrichment({
				listId,
				type,
				...formData,
				selected
			})
		)
		setOpen(false)
	}

	return (
		<Form {...form}>
			<Dialog {...props} open={open} onOpenChange={setOpen}>
				{children}
				<DialogContent>
					<form onSubmit={form.handleSubmit(onSubmit, console.error)} className="w-full space-y-4">
						<DialogHeader>
							<DialogTitle>AI enrichment</DialogTitle>
							<DialogDescription>
								Enrich your data with AI to get more insights and improve your targeting
							</DialogDescription>
						</DialogHeader>
						<FormField
							control={form.control}
							name="templateId"
							render={({ field }) => (
								<FormItem>
									<FormLabel>{startCase(field.name)}</FormLabel>
									<Select onValueChange={field.onChange} defaultValue={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue placeholder="Select an enrichment template" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											{templates?.map(template => (
												<SelectItem key={template.id} value={template.id}>
													<div className="flex items-center gap-1">
														{template.org_id === GLOBAL_COMPANY && (
															<Globe className="mr-1 h-4 w-4" />
														)}
														{template.label ?? template.id}
													</div>
												</SelectItem>
											))}
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>
						{currentTemplate &&
							Object.entries(currentTemplate.parameters ?? {}).map(([key, value]) => (
								<FormField
									key={key}
									control={form.control}
									name={`parameters.${key}`}
									render={({ field }) => (
										<FormItem>
											<FormLabel>{startCase(key)}</FormLabel>
											<Select onValueChange={field.onChange} defaultValue={field.value}>
												<FormControl>
													{Array.isArray(value) && value.length > 1 ? (
														<>
															<SelectTrigger>
																<SelectValue placeholder="Select an option" />
															</SelectTrigger>
															<SelectContent>
																{value.map(option => (
																	<SelectItem key={option} value={option}>
																		{option}
																	</SelectItem>
																))}
															</SelectContent>
														</>
													) : (
														<Textarea
															{...field}
															className="text-left"
															placeholder={Array.isArray(value) ? value[0] : value}
														/>
													)}
												</FormControl>
											</Select>
											<FormMessage />
										</FormItem>
									)}
								/>
							))}
						<DialogFooter>
							<Button type="submit">
								Run enrichment for {ids.length || 'all'} {pluralize(type)}
							</Button>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</Form>
	)
}
