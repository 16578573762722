import { pick, uniq } from 'lodash-es'
import { isPresent } from 'ts-extras'
import { call, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga'
import { State } from '~/types/state'
import { supabase, toToastError } from '~/utils'
import { Action, ActionTypes, Actions } from '../actions'
import { getCurrentProject } from './sagaUtils'

export function* resourceSagas() {
	yield* takeLatest<ActionTypes, any>('ORGANIZATIONS_LOADED', onCompaniesLoaded)
	yield* takeLatest<ActionTypes, any>('GET_RESOURCES', getResources)
	yield* takeLatest<ActionTypes, any>('GET_RESOURCES', getResourcesTags)
	yield* takeLatest<ActionTypes, any>('GET_RESOURCES_OK', getResourceTags)
	yield* takeLatest<ActionTypes, any>('GET_RESOURCE_CATEGORIES', getresourceCategories)
	yield* takeLeading<ActionTypes, any>('USER_LOGGED_IN', getresourceCategories)
	yield* takeLatest<ActionTypes, any>('DUPLICATE_RESOURCE', duplicateResource)

	yield* takeLatest<ActionTypes, any>('ADD_RESOURCE_TAG', addResourceTag)
	yield* takeLatest<ActionTypes, any>('REMOVE_RESOURCE_TAG', removeResourceTag)
}

function* onCompaniesLoaded(action: Action<'ORGANIZATIONS_LOADED'>) {
	const selectedProject = yield* call(getCurrentProject)
	if (selectedProject && action.payload.map(c => c.id).includes(selectedProject?.org_id)) {
		yield* put(Actions.getResources(selectedProject.id))
	} else {
		yield* put(Actions.getResourcesOk([]))
	}
}

function* getResources(action: Action<'GET_RESOURCES'>) {
	const res = yield* call(async () =>
		supabase
			.from('resource')
			.select('*, resource_file(*), resource_text(*)')
			.order('created_at', { ascending: false })
			.eq('project_id', action.meta)
	)
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getResourceFilesOk(res.data.map(rs => rs.resource_file).filter(isPresent)))
		yield* put(Actions.getResourceTextsOk(res.data.map(rs => rs.resource_text).filter(isPresent)))
		yield* put(Actions.getResourcesOk(res.data))
	}
}

function* getResourcesTags(_action: Action<'GET_RESOURCES'>) {
	const res = yield* call(async () =>
		supabase.from('resource_segment').select('*').order('created_at', { ascending: false })
	)
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getResourceSegmentsOk(res.data))
	}
}

function* getResourceTags(action: Action<'GET_RESOURCES_OK'>) {
	const res = yield* call(async () =>
		supabase
			.from('resource_tag')
			.select('*')
			.in(
				'resource_id',
				action.payload.map(a => a.id)
			)
	)
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getResourceTagsOk(res.data))
		let allTags = yield* select((state: State) => state.config.tags)
		let sets = yield* select((state: State) => state.config.sets)
		if (!allTags.length || !sets.length) {
			yield* take('GET_TAG_CONFIG_OK')
			allTags = yield* select((state: State) => state.config.tags)
			sets = yield* select((state: State) => state.config.sets)
		}

		for (const set of sets) {
			// Create UI resource set if that set has resource tags
			const setTags = allTags.filter(tag => tag.tag_set_id === set.id)
			// Find any resourceTag that links to the tags in the current set

			const resourceIds = res.data.filter(tag => setTags.some(t => t.id === tag.tag_id)).map(t => t.resource_id)

			yield* put(
				Actions.createResourceSets(
					uniq(resourceIds)
						.filter(isPresent)
						.map(resource_id => ({ resource_id, tag_set: set }))
				)
			)
		}
	}
}

function* getresourceCategories() {
	const res = yield* call(async () => supabase.from('resource_category').select('*').order('order'))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getResourceCategoriesOk(res.data))
	}
}

function* duplicateResource(action: Action<'DUPLICATE_RESOURCE'>) {
	if (action.payload.file_id) {
		yield* put(Actions.addToast({ title: 'Cannot duplicate resource with file', variant: 'default' }))
		return
	} else if (!action.payload.text) {
		yield* put(Actions.addToast({ title: 'Cannot duplicate resource without text', variant: 'destructive' }))
		return
	}
	const resourceText = yield* call(async () =>
		supabase
			.from('resource_text')
			.insert(pick(action.payload.text!, 'value', 'project_id'))
			.select()
			.single()
	)
	if (resourceText.error) {
		const toastError = yield* call(toToastError, resourceText.error)
		yield* put(Actions.addToast(toastError))
	}
	const { data: resource, error } = yield* call(async () =>
		supabase
			.from('resource')
			.insert({
				text_id: resourceText.data!.id,
				category_id: action.payload.category_id,
				project_id: action.payload.project_id
			})
			.select()
			.single()
	)
	if (error) {
		const toastError = yield* call(toToastError, error)
		yield* put(Actions.addToast(toastError))
	} else if (resource) {
		const tags = yield* select((state: State) =>
			state.resources.tags.filter(t => t.resource_id === action.payload.id)
		)

		const tagSets = yield* select((state: State) =>
			state.resources.sets.filter(g => g.resource_id === action.payload.id)
		)

		yield* put(Actions.createResourceSets(tagSets.map(set => ({ resource_id: resource.id, tag_set: set.tag_set }))))

		const res = yield* call(async () =>
			supabase
				.from('resource_tag')
				.insert(tags.map(tag => ({ tag_id: tag.tag_id, resource_id: resource.id })))
				.select()
		)

		if (res.error) {
			const toastError = yield* call(toToastError, res.error)
			yield* put(Actions.addToast(toastError))
			yield call(async () => supabase.from('resource').delete().eq('id', resource.id))
		}
	}
}

function* addResourceTag(action: Action<'ADD_RESOURCE_TAG'>) {
	const res = yield* call(async () => supabase.from('resource_tag').insert(action.payload).select().single())
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	}
}

function* removeResourceTag(action: Action<'REMOVE_RESOURCE_TAG'>) {
	const res = yield* call(async () => supabase.from('resource_tag').delete().eq('id', action.meta))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	}
}
