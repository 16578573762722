import { Except } from 'type-fest'
import { Toast } from '~/types/ui'
import { makeActionCreator, makeMetaActionCreator } from './actionCreator'

export default {
	addToast: makeActionCreator<Except<Toast, 'id'>>()('ADD_TOAST'),
	updateToast: makeActionCreator<Partial<Toast>>()('UPDATE_TOAST'),
	dismissToast: makeMetaActionCreator<Toast['id']>()('DISMISS_TOAST'),
	removeToast: makeMetaActionCreator<Toast['id']>()('REMOVE_TOAST')
}
