import { FC, FormEventHandler, PropsWithChildren, ReactNode, useState } from 'react'
import { HiTrash } from 'react-icons/hi2'
import { Button, Dialog, DialogContent, DialogTrigger, Input, Label } from '~/components/ui'

export interface DeleteConfirmProps extends PropsWithChildren {
	className?: string
	/** Disables button that triggers opening */
	disabled?: boolean
	password?: string
	onSubmit: FormEventHandler<HTMLFormElement>
	icon?: ReactNode
	trigger?: ReactNode
}

export const DeleteConfirm: FC<DeleteConfirmProps> = ({ disabled, password, onSubmit, children, icon, trigger }) => {
	const [open, setOpen] = useState(false)
	const [error, setError] = useState<string | null>(null)
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				{trigger ?? (
					<Button variant="ghost" disabled={disabled}>
						{icon ?? (
							<>
								<HiTrash className="mr-2 h-4 w-4" /> Delete
							</>
						)}
					</Button>
				)}
			</DialogTrigger>
			<DialogContent>
				<form
					className="space-y-6 text-left"
					onSubmit={e => {
						e.preventDefault()
						if (error || disabled) return
						onSubmit?.(e)
						setOpen(false)
						return false
					}}
				>
					{children ?? <p>Are you sure you want to delete {password || 'this'}?</p>}
					{password && (
						<div className="space-y-3">
							<Label className="block" htmlFor="confirmText">
								Type <strong>{password}</strong> to confirm
							</Label>
							<Input
								className={error ? 'ring-2 !ring-red-500 ring-offset-2' : ''}
								id="confirmText"
								type="text"
								name="confirmText"
								required
								onChange={_ => setError(null)}
								onInvalid={_ => setError(`Your input does not match "${password}"`)}
								placeholder={password}
								pattern={`${password
									.split('') // Make password case insensitive
									.map(s => `[${s.toLocaleLowerCase()}${s.toLocaleUpperCase()}]`)
									.join('')}`}
							/>
							{error && (
								<p className="text-sm font-semibold text-red-500" role="tooltip">
									{error}
								</p>
							)}
						</div>
					)}
					<div className="flex justify-end space-x-2">
						<Button type="button" variant="outline" onClick={_ => setOpen(false)}>
							Cancel
						</Button>
						<Button type="submit" variant="destructive">
							Confirm
						</Button>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	)
}
