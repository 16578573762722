/* eslint-disable github/array-foreach */
import { ColDef } from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react' // React Data Grid Component
import { mapValues, startCase } from 'lodash-es'
import { useMemo } from 'react'
import { HiEllipsisVertical } from 'react-icons/hi2'
import { RxMixerHorizontal } from 'react-icons/rx'
import { isPresent } from 'ts-extras'
import { Obj } from '~/types'
import { displayValue, getColumnKeys, useSelector } from '~/utils'
import {
	Button,
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger
} from '../ui'
import { EditableCell } from './EditableCell'

import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the grid
import '../../ag-grid-theme-builder.css'

export interface AutoTableProps<TData = unknown> extends Partial<AgGridReactProps<TData>> {
	data?: TData[]
	searchColumn?: keyof TData
	excludedColumns?: ReadonlyArray<keyof TData>
	editableColumns?: Array<keyof TData> | ((key: string) => boolean)
	showToggleColumns?: boolean
	extraColumns?: Array<ColDef<TData>>
	actions?: Record<string, (selectedRows: TData[]) => void>
	selected?: TData[]
	columnVisibility?: Record<string, boolean>
	onColumnVisibilityChange?: (columnVisibility: Record<string, boolean>) => void
}

export function AutoTable<TData extends Obj>(props: AutoTableProps<TData>) {
	const templates = useSelector(state => state.templates)
	const {
		editableColumns,
		actions,
		data = [],
		excludedColumns,
		extraColumns,
		columnVisibility,
		selected = [],
		onColumnVisibilityChange,
		...rest
	} = props
	const columnKeys = useMemo(() => getColumnKeys(data ?? [], excludedColumns ?? []), [data, excludedColumns])
	const columns = useMemo(() => {
		const columnDefs: Array<ColDef<TData>> = [
			{
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				maxWidth: 47,
				checkboxSelection: true,
				resizable: false,
				pinned: 'left',
				suppressSizeToFit: true
			},
			...columnKeys.map<ColDef<TData>>(key => {
				const isEditable =
					typeof editableColumns === 'function' ? editableColumns(key) : editableColumns?.includes(key)

				const columnVisible = columnVisibility?.[key]

				return {
					colId: key,
					headerName: (key.startsWith('te_') && templates?.find(t => t.id === key)?.label) || startCase(key),
					tooltipField: key.startsWith('te_') ? (key as any) : undefined, // Add tooltip that displays long gpt enrichments
					editable: isEditable,
					field: key as any,
					valueFormatter: row => displayValue(key, row.data!) as string,
					cellRenderer: isEditable ? EditableCell : undefined,
					filter: true,
					maxWidth: 600,
					initialHide: ['id', 'created_at', 'updated_at', 'company_id', 'created_by', 'org_id'].includes(key)
						? true
						: undefined,
					hide: isPresent(columnVisible) ? !columnVisible : false
				}
			}),
			...(extraColumns ?? [])
		]
		return columnDefs
	}, [columnKeys, extraColumns, editableColumns, columnVisibility, templates])

	const isAllVisible = useMemo(() => Object.values(columnVisibility ?? {}).every(v => v === true), [columnVisibility])

	return (
		<>
			<div className="flex">
				{actions && Object.keys(actions).length > 0 && (
					<DropdownMenu>
						<DropdownMenuTrigger asChild disabled={selected.length === 0}>
							<Button variant="outline" size="sm" className="h-8 space-x-1">
								<HiEllipsisVertical className="h-4 w-4" />
								<span>Actions</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="start" className="left-4 min-w-[150px]">
							{Object.entries(actions).map(([label, action]) => (
								<DropdownMenuItem key={label} onClick={() => action(selected)}>
									{startCase(label)}
								</DropdownMenuItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				)}
				{columnVisibility && (
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="outline" size="sm" className="ml-auto h-8">
								<RxMixerHorizontal className="mr-2 h-4 w-4" />
								Columns
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end" className="w-[150px]">
							<DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
							<DropdownMenuSeparator />
							<DropdownMenuCheckboxItem
								checked={isAllVisible}
								onCheckedChange={value =>
									columnVisibility &&
									onColumnVisibilityChange?.(mapValues(columnVisibility, () => value))
								}
							>
								{isAllVisible ? 'Uns' : 'S'}elect All
							</DropdownMenuCheckboxItem>
							<DropdownMenuSeparator />
							{columnKeys.map(key => (
								<DropdownMenuCheckboxItem
									key={key}
									className="capitalize"
									checked={columnVisibility[key]}
									onCheckedChange={value =>
										onColumnVisibilityChange?.({ ...columnVisibility, [key]: value })
									}
								>
									{startCase(key)}
								</DropdownMenuCheckboxItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				)}
			</div>
			<div className="ag-theme-custom h-full">
				<AgGridReact
					rowData={data}
					columnDefs={columns}
					rowSelection="multiple"
					getRowId={c => c.data.id as string}
					suppressMenuHide
					tooltipInteraction
					tooltipShowDelay={1000}
					suppressRowClickSelection
					{...rest}
				/>
			</div>
		</>
	)
}
