import { Session, User } from '@supabase/supabase-js'
import { Project, UIOrganization, UIOrganizationUser } from '~/types'
import { makeActionCreator, makeMetaActionCreator } from './actionCreator'

export default {
	loadUser: makeActionCreator()('USER_LOAD'),
	userLoggedIn: makeActionCreator<Session, User | null>()('USER_LOGGED_IN'),
	userUpdated: makeActionCreator<Partial<User>>()('USER_UPDATED'),
	getCompanies: makeActionCreator()('ORGANIZATION_GET'),
	companiesLoaded: makeActionCreator<Array<UIOrganization & { projects: Project[] }>>()('ORGANIZATIONS_LOADED'),
	companyCreated: makeActionCreator<UIOrganization>()('ORG_CREATED'),
	companyDeleted: makeMetaActionCreator<UIOrganization['id']>()('ORG_DELETED'),

	userInvited: makeActionCreator<UIOrganizationUser, UIOrganization['id']>()('USER_INVITE_OK'),

	selectProject: makeMetaActionCreator<Project['id'] | null>()('PROJECT_SELECT')
}
