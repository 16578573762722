import { FC, useEffect, useState } from 'react'
import { HiMiniPlusCircle } from 'react-icons/hi2'
import { Button } from '~/components/ui'
import { Actions } from '~/redux'
import { UIResource } from '~/types'
import { useDispatch, useResourceTagSets, useSelector } from '~/utils'
import { ResourceTagSet } from './ResourceTagSet'
import { Tag } from './Tag'
import { TagSet } from './TagSet'

export interface ResourceTagSetsProps {
	resource: UIResource
	row: number
}

export const ResourceTagSets: FC<ResourceTagSetsProps> = ({ resource, row }) => {
	const dispatch = useDispatch()
	const config = useSelector(state => state.config)
	const sets = useResourceTagSets(resource)
	const [creatingSet, setCreatingSet] = useState(false)

	useEffect(() => {
		if (!resource.editing) setCreatingSet(false)
	}, [resource.editing])

	return (
		<>
			{sets.map((set, i) => (
				<ResourceTagSet
					key={set.tag_set.id}
					set={set}
					resource={resource}
					row={row + i + (resource.editing ? 0 : -1)}
				/>
			))}
			{!resource.editing ? null : creatingSet ? (
				<TagSet label="Pick One" row={sets.length + row}>
					{config.sets.map(tagSet => {
						const exists = sets.some(set => set.tag_set.id === tagSet.id)
						return (
							<Tag
								key={tagSet.id}
								type={exists ? null : tagSet.tag_type_id}
								disabled={exists}
								onClick={_ => {
									dispatch(Actions.createResourceSet({ resource_id: resource.id, tag_set: tagSet }))
									setCreatingSet(false)
								}}
							>
								{tagSet.label}
							</Tag>
						)
					})}
				</TagSet>
			) : (
				<Button
					className="col-span-full h-full min-h-[3rem] rounded bg-white text-[#7B7B7B] dark:bg-opacity-30"
					variant="ghost"
					onClick={_ => setCreatingSet(true)}
					style={{ gridRow: sets.length + row }}
				>
					<HiMiniPlusCircle className="mr-2 h-6 w-6" />
					<span>Link new tag set</span>
				</Button>
			)}
		</>
	)
}
