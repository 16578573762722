import { FC, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, redirect } from 'react-router-dom'
import { Grid } from '~/components/Grid'
import { Actions } from '~/redux'
import { supabase, toToastError, useCurrentProject, useSelector } from '~/utils'
import { ListDialog } from './ListDialog'

export const Lists: FC = () => {
	const [open, setOpen] = useState(false)
	const project = useCurrentProject()
	const _lists = useSelector(state => state.prospecting.lists)
	const lists = useMemo(() => _lists.filter(pl => pl.project_id === project?.id), [_lists, project?.id])
	const dispatch = useDispatch()

	return (
		<Grid.Container className="grid-rows-[1fr,auto] lg:grid-cols-[18rem,1fr,18rem]">
			<Grid.SideMenu
				className="h-full"
				title="Lists"
				onSubmit={e => {
					e.preventDefault()
					setOpen(true)
				}}
			>
				{lists?.map(list => (
					<Grid.SideMenuLink
						key={list.id}
						to={list.id}
						onDeleteClick={async _ => {
							const res = await supabase.from('prospecting_list').delete().eq('id', list.id)
							if (res.error) {
								const error = await toToastError(res.error)
								dispatch(Actions.addToast(error))
							}
						}}
					>
						{list.label ?? list.id}
					</Grid.SideMenuLink>
				))}
			</Grid.SideMenu>
			<ListDialog
				open={open}
				currentProject={project}
				onOpenChange={setOpen}
				onCreate={list => redirect(list.id)}
			/>
			<Outlet />
		</Grid.Container>
	)
}
