import type { ProspectingCompany, ProspectingListCompany } from '~/types'
import { Action } from '../actions'

export function prospectingListCompanies(
	state: ProspectingListCompany[] = [],
	action: Action
): ProspectingListCompany[] {
	switch (action.type) {
		case 'GET_PROSPECTING_LIST_COMPANIES_OK':
			return action.payload

		case 'INSERT_PROSPECTING_LIST_COMPANY_OK':
			return [...state, action.payload]

		case 'UPDATE_PROSPECTING_LIST_COMPANY_OK':
			return state.map(pl => (pl.id === action.meta ? action.payload : pl))

		case 'DELETE_PROSPECTING_LIST_COMPANY_OK':
			return state.filter(pl => pl.id !== action.meta)
	}
	return state
}

export function prospectingCompanies(state: ProspectingCompany[] = [], action: Action): ProspectingCompany[] {
	switch (action.type) {
		case 'GET_PROSPECTING_COMPANY_OK':
			return action.payload

		case 'INSERT_PROSPECTING_COMPANY_OK':
			return [...state, action.payload]

		case 'UPDATE_PROSPECTING_COMPANY_OK':
			return state.map(pl => (pl.id === action.meta ? action.payload : pl))

		case 'DELETE_PROSPECTING_COMPANY_OK':
			return state.filter(pl => pl.id !== action.meta)
	}
	return state
}
