import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import {
	Button,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Input,
	Label,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '~/components/ui'
import { Actions } from '~/redux'
import { Nullable, Project, TypeID } from '~/types'
import { supabase, toToastError, useDispatch, useSelector } from '~/utils'

export interface ProjectCreatorProps {
	currentProject: Nullable<Project>
	onClose: () => void
}
export const ProjectCreator: FC<ProjectCreatorProps> = ({ currentProject, onClose }) => {
	const dispatch = useDispatch()
	const companies = useSelector(state => state.user?.companies)
	const currentCompany =
		companies?.find(company => company.id === currentProject?.org_id) ??
		(companies?.length === 1 ? companies[0] : undefined)
	const [selectedCompany, setSelectedCompany] = useState<string | undefined>(currentCompany?.id ?? undefined)
	const [name, setName] = useState('')

	return (
		<DialogContent>
			<form
				onSubmit={e => {
					e.preventDefault()
					if (!name || !selectedCompany) return false
					supabase
						.from('project')
						.insert({ name, org_id: selectedCompany as TypeID<'co'> })
						.select()
						.single()
						.then(async project => {
							if (project.error) {
								const toastError = await toToastError(project.error)
								dispatch(Actions.addToast(toastError))
							} else {
								onClose()
							}
						})
					return false
				}}
			>
				<DialogHeader>
					<DialogTitle>Create project</DialogTitle>
					<DialogDescription>Create a new project to manage new value propositions.</DialogDescription>
				</DialogHeader>
				<div>
					<div className="space-y-4 py-2 pb-4">
						<div className="space-y-2">
							<Label htmlFor="name">Project Name</Label>
							<Input
								id="name"
								placeholder="Acme Inc."
								required
								value={name}
								onChange={e => setName(e.target.value)}
							/>
						</div>
						{companies?.length ? (
							<div className="space-y-2">
								<Label htmlFor="plan">Company</Label>
								<Select value={selectedCompany} onValueChange={setSelectedCompany} required>
									<SelectTrigger>
										<SelectValue placeholder="Select a company" />
									</SelectTrigger>
									<SelectContent>
										{companies.map(company => (
											<SelectItem key={company.id} value={company.id}>
												{company.name && (
													<>
														<span className="font-medium">{company.name}</span> -{' '}
													</>
												)}
												<span className="text-muted-foreground">{company.id}</span>
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
						) : (
							<p className="text-sm text-muted-foreground">
								You need to{' '}
								<Link className="text-blue-700 underline " to="/settings/companies" onClick={onClose}>
									create a company
								</Link>{' '}
								before you can create a project.
							</p>
						)}
					</div>
				</div>
				<DialogFooter>
					<Button variant="outline" onClick={() => onClose()}>
						Cancel
					</Button>
					<Button>Continue</Button>
				</DialogFooter>
			</form>
		</DialogContent>
	)
}
