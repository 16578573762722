import { PopoverClose } from '@radix-ui/react-popover'
import clsx from 'clsx'
import { PlusCircleIcon, Trash2 } from 'lucide-react'
import { FC, MouseEventHandler, PropsWithChildren, ReactNode, useState } from 'react'
import { Form, NavLink, NavLinkProps } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	CardTitle,
	Popover,
	PopoverContent,
	PopoverTrigger,
	buttonVariants
} from '~/components/ui'
import { HTMLElementProps } from '~/types'
import { cn } from '~/utils'

export const Container: FC<HTMLElementProps<'div'>> = ({ className, ...props }) => (
	<div
		className={cn(
			'grid h-full grid-cols-1 items-start gap-6 p-6 sm:grid-cols-[18rem,1fr] xl:grid-cols-[18rem,1fr,18rem]',
			className
		)}
		{...props}
	/>
)

export interface SideMenuLinkProps extends NavLinkProps {
	onDeleteClick?: MouseEventHandler<HTMLButtonElement>
	children?: ReactNode
}

export const SideMenuLink: FC<SideMenuLinkProps> = ({ children, className, onDeleteClick, ...props }) => {
	const [open, setOpen] = useState(false)
	return (
		<NavLink
			className={cn(
				buttonVariants({ variant: 'ghost' }),
				'group justify-between active:bg-muted active:hover:bg-muted',
				className
			)}
			{...props}
		>
			<span className="truncate">{children}</span>
			{onDeleteClick && (
				<Popover open={open} onOpenChange={setOpen}>
					<PopoverTrigger asChild>
						<Button
							className={clsx('-mr-4', { 'hidden hover:opacity-60 group-hover:flex': !open })}
							type="button"
							variant="ghost"
							size="sm"
						>
							<Trash2 className="h-4 w-4" />
						</Button>
					</PopoverTrigger>
					<PopoverContent>
						Are you sure you want to delete <strong>{children}</strong>?
						<div className="flex justify-end gap-2">
							<PopoverClose asChild>
								<Button variant="outline">Cancel</Button>
							</PopoverClose>
							<Button variant="destructive" onClick={onDeleteClick}>
								Delete
							</Button>
						</div>
					</PopoverContent>
				</Popover>
			)}
		</NavLink>
	)
}

export interface SideMenuProps extends PropsWithChildren {
	className?: string
	contentClassName?: string
	title?: ReactNode
	onSubmit?: React.FormEventHandler<HTMLFormElement> | false
}

const SideMenu: FC<SideMenuProps> = ({ className, contentClassName, title, onSubmit, children }) => (
	<Card className={twMerge('flex min-h-0 flex-col', className)}>
		<CardHeader className="flex-row items-center justify-between pb-0 pt-4">
			<CardTitle className="text-lg">{title}</CardTitle>
			{onSubmit !== false && (
				<Form method="post" onSubmit={onSubmit}>
					<button type="submit" className="transition-opacity hover:opacity-50">
						<PlusCircleIcon />
					</button>
				</Form>
			)}
		</CardHeader>

		<CardContent className={cn('flex flex-col space-y-1 overflow-y-auto p-3', contentClassName)}>
			{children}
		</CardContent>
	</Card>
)

export const Grid = {
	Container,
	SideMenu,
	SideMenuLink
}
