import { Button } from '@/components/ui/button'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { startCase } from 'lodash-es'
import { FC, Fragment, PropsWithChildren, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { isPresent } from 'ts-extras'
import { z } from 'zod'
import {
	Checkbox,
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	RadioGroup,
	RadioGroupItem,
	ScrollArea,
	Separator
} from '~/components/ui'
import { Nullable } from '~/types'
import { downloadCSV, getColumnKeys } from '~/utils'

function toCurrentColumnsArray(currentColumns: Nullable<Record<string, boolean>>) {
	return Object.entries(currentColumns ?? {})
		.map(([key, value]) => (value ? key : null))
		.filter(isPresent)
}

const FormSchema = z.object({
	type: z.enum(['all', 'current', 'custom'], {
		required_error: 'You need to select a set of columns.'
	}),
	columns: z.array(z.string()).optional(),
	selectAll: z.boolean().optional(),
	rows: z.enum(['all', 'selected'])
})

export interface ExportDialogProps extends PropsWithChildren {
	data: Array<Record<string, any>>
	filename: string
	currentColumns?: Record<string, boolean>
	selected?: Array<Record<string, any>>
}

export const ExportDialog: FC<ExportDialogProps> = ({ data, filename, currentColumns, selected, children }) => {
	const keys = useMemo(() => getColumnKeys(data, []), [data])
	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(
			FormSchema.refine(value => (value.type === 'custom' ? !!value.columns?.length : true), {
				message: 'You need to select at least one column.',
				path: ['columns']
			}).refine(value => (value.rows === 'selected' ? !!selected?.length : true), {
				message: 'No rows selected in table.',
				path: ['rows']
			})
		),
		defaultValues: {
			type: 'all',
			rows: 'all',
			columns: keys
		}
	})
	const type = form.watch('type')
	const rows = form.watch('rows')

	useEffect(() => {
		const includedColumns = toCurrentColumnsArray(currentColumns)
		form.resetField('columns', { defaultValue: includedColumns })
	}, [keys, type, form, currentColumns])

	function onSubmit(formData: z.infer<typeof FormSchema>) {
		const columns =
			formData.type === 'all'
				? keys
				: formData.type === 'current'
					? toCurrentColumnsArray(currentColumns)
					: formData.columns

		downloadCSV(
			data
				.filter(d => (d && 'disabled' in d ? !d.disabled : true))
				.filter(d => (rows === 'selected' && selected ? selected.some(s => s === d) : true)),
			`${filename}_${formData.type}`,
			columns
		)
	}

	return (
		<Form {...form}>
			<Dialog>
				<DialogTrigger asChild>
					<Button className="h-auto flex-col gap-1 px-4 py-0.5" variant="ghost" size="lg">
						{children}
					</Button>
				</DialogTrigger>
				<DialogContent>
					<form onSubmit={form.handleSubmit(onSubmit, console.error)} className="w-full space-y-4">
						<DialogHeader>
							<DialogTitle>Export CSV</DialogTitle>
							<DialogDescription>
								Export your data to a CSV file. You can open this file in any spreadsheet program, or
								import it to Clay.
							</DialogDescription>
						</DialogHeader>
						<FormField
							control={form.control}
							name="type"
							render={({ field }) => (
								<FormItem className="space-y-3">
									<FormLabel>Select Columns...</FormLabel>
									<FormControl>
										<RadioGroup
											onValueChange={field.onChange}
											defaultValue={field.value}
											className="flex flex-col space-y-1"
										>
											{FormSchema.shape.type.options
												.filter(option =>
													Object.keys(currentColumns ?? {}).length === 0
														? option !== 'current'
														: true
												)
												.map(option => (
													<FormItem
														key={option}
														className="flex items-center space-x-3 space-y-0"
													>
														<FormControl>
															<RadioGroupItem value={option} />
														</FormControl>
														<FormLabel className="cursor-pointer font-normal">
															{option === 'all'
																? 'All columns'
																: option === 'current'
																	? 'Current view'
																	: 'Custom'}
														</FormLabel>
													</FormItem>
												))}
										</RadioGroup>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="columns"
							render={() => (
								<FormItem>
									<ScrollArea
										className={clsx('relative h-72 w-full rounded-md border px-3', {
											hidden: type !== 'custom'
										})}
									>
										<FormField
											control={form.control}
											rules={{ value: false }}
											name="selectAll"
											render={({ field }) => (
												<FormItem className="sticky top-0  w-full bg-background pt-1.5">
													<div className="flex flex-row items-center">
														<FormControl>
															<Checkbox
																{...field}
																value={undefined}
																checked={
																	form.getValues('columns')?.length === keys.length
																}
																onCheckedChange={checked =>
																	form.setValue('columns', checked ? keys : [])
																}
															/>
														</FormControl>
														<FormLabel className="w-full cursor-pointer py-0.5 pl-3 font-normal">
															<span>
																Select{' '}
																{form.getValues('columns')?.length === keys.length
																	? 'none'
																	: 'all'}
															</span>
														</FormLabel>
													</div>
													<Separator />
												</FormItem>
											)}
										/>
										{keys.map(key => (
											<Fragment key={key}>
												<FormField
													key={key}
													control={form.control}
													name="columns"
													render={({ field }) => (
														<FormItem
															key={key}
															className="flex flex-row items-center space-y-0"
														>
															<FormControl>
																<Checkbox
																	{...field}
																	checked={field.value?.includes(key)}
																	onCheckedChange={checked =>
																		checked
																			? field.onChange([
																					...(field.value ?? []),
																					key
																				])
																			: field.onChange(
																					field.value?.filter(
																						value => value !== key
																					)
																				)
																	}
																/>
															</FormControl>
															<FormLabel className="w-full cursor-pointer py-2 pl-3 font-normal">
																<span>{startCase(key).replace('Id', 'ID')}</span>
															</FormLabel>
														</FormItem>
													)}
												/>
												<Separator className="my-0.5 last:hidden" />
											</Fragment>
										))}
									</ScrollArea>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="rows"
							render={({ field }) => (
								<FormItem className="space-y-3">
									<FormLabel>Rows</FormLabel>
									<FormControl>
										<RadioGroup
											onValueChange={field.onChange}
											defaultValue={field.value}
											className="flex flex-col space-y-1"
										>
											{FormSchema.shape.rows.options.map(option => (
												<FormItem
													key={option}
													className="flex items-center space-x-3 space-y-0"
												>
													<FormControl>
														<RadioGroupItem value={option} />
													</FormControl>
													<FormLabel className="cursor-pointer font-normal">
														{startCase(option)}{' '}
														{option === 'selected' && `(${selected?.length})`}
													</FormLabel>
												</FormItem>
											))}
										</RadioGroup>
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<DialogFooter>
							<Button type="submit">Export</Button>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</Form>
	)
}
