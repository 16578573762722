import { Dagster, EnrichmentType, Nullable } from '~/types'

export function isRunActive(run: Dagster.RunFragment) {
	switch (run.status) {
		case Dagster.RunStatus.Queued:
		case Dagster.RunStatus.Started:
		case Dagster.RunStatus.Starting:
		case Dagster.RunStatus.NotStarted:
			return true

		default:
			return false
	}
}

export interface FindEnrichmentRunOptions {
	runs: Dagster.RunFragment[]
	enrichmentType: Nullable<EnrichmentType>
	listId?: string
	contactId?: string
	templateId?: string
}

export function findEnrichmentRun(options: FindEnrichmentRunOptions) {
	const { runs, listId, contactId, enrichmentType, templateId } = options
	if (!enrichmentType) return null
	const pipelineName = `enrichment_${enrichmentType.name}`

	const run = runs
		.filter(r => r.pipeline.name.startsWith(pipelineName))
		.filter(r => (listId ? r.tags.some(t => t.key === 'dagster/partition' && t.value === listId) : true))
		.filter(r =>
			contactId
				? r.runConfig?.ops?.[pipelineName]?.ops?.get_workflow_items?.config?.selected?.includes(contactId)
				: true
		)
		.filter(r =>
			templateId
				? r.runConfig?.ops?.[pipelineName]?.ops?.get_workflow_items?.config?.templateId === templateId
				: true
		)
		.shift()

	return run ?? null
}
