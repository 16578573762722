import type { CompanySegment } from '~/types'
import { isSegmentEqual } from '~/utils'
import { Action, MetaAction } from '../actions'

export const initialState: CompanySegment[] = []

function items(state: CompanySegment, action: MetaAction): CompanySegment {
	if (typeof action.meta === 'string' || !('segment_id' in action.meta)) return state
	if (!isSegmentEqual(state, action.meta)) return state
	switch (action.type) {
		case 'UPDATE_PROSPECTING_COMPANY_SEGMENT_OK':
			return { ...state, ...action.payload }
	}
	return state
}

export function companySegments(state: CompanySegment[] = initialState, action: Action): CompanySegment[] {
	switch (action.type) {
		case 'GET_COMPANY_SEGMENTS_OK':
			return action.payload

		case 'INSERT_PROSPECTING_COMPANY_SEGMENT_OK':
			return [...state, action.payload]

		case 'UPDATE_PROSPECTING_COMPANY_SEGMENT_OK':
			return state.map(as => items(as, action))

		case 'DELETE_PROSPECTING_COMPANY_SEGMENT_OK':
			return state.filter(as => !isSegmentEqual(as, action.meta))
	}
	return state
}
