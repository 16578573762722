import { SetOptional } from 'type-fest'
import { ResourceFile, ResourceText, UIFile } from '~/types'
import { makeActionCreator, makeMetaActionCreator } from './actionCreator'

export default {
	getFilesOk: makeActionCreator<UIFile[]>()('GET_FILES_OK'),
	getResourceTextsOk: makeActionCreator<ResourceText[]>()('GET_RESOURCE_TEXTS_OK'),
	getResourceFilesOk: makeActionCreator<ResourceFile[]>()('GET_RESOURCE_FILES_OK'),

	fileAdded: makeActionCreator<SetOptional<UIFile, 'owner' | 'buckets' | 'metadata'>>()('FILE_ADDED'),
	fileUpdated: makeActionCreator<Partial<UIFile>, UIFile['id']>()('FILE_UPDATED'),
	fileDeleted: makeMetaActionCreator<UIFile['id']>()('FILE_DELETED')
}
