import { DBEvent } from '~/types/realtime'
import { makeActionCreator } from './actionCreator'
import CanvasActions from './canvas'
import ProspectingActions from './prospecting'
import ResourceFilesActions from './resourceFiles'
import ResourceActions from './resources'
import SegmentActions from './segments'
import TagSetActions from './tagSets'
import TemplateActions from './templates'
import ToastActions from './toast'
import UserActions from './user'

export const Actions = {
	__init: makeActionCreator()('@@INIT'),
	...CanvasActions,
	...ProspectingActions,
	...ResourceFilesActions,
	...ResourceActions,
	...TagSetActions,
	...UserActions,
	...TemplateActions,
	...SegmentActions,
	...ToastActions
}

export type ActionCreator = (typeof Actions)[keyof typeof Actions]
type A = Readonly<DBEvent> | Readonly<ReturnType<ActionCreator>>

export type Action<TKey extends Action['type'] = any, TAction extends A = A> = TAction extends { type: TKey }
	? TAction
	: never

export type ErrorAction = FilterActions<Action, 'ERROR'>
export type SuccessAction = FilterActions<Action, 'SUCCESS'>

export type MetaAction = GetMetaActions<Action>
export type ActionTypes<TSearch = null> = TSearch extends string
	? FilterActions<Action, TSearch>['type']
	: Action['type']

export interface AnyAction {
	[extraProps: string]: any
	type: ActionTypes
}

export type GetMetaActions<T> = T extends { meta: NonNullable<unknown> } ? T : never

export type FilterActions<TAction extends { type: string }, TKey extends string> = TAction extends {
	type: `${string}${TKey}${string}`
}
	? TAction
	: never

export function isActionGroup<K extends string>(action: Action, type: K): action is FilterActions<Action, K> {
	return action.type.includes(type)
}
