import { zodResolver } from '@hookform/resolvers/zod'
import { DialogProps } from '@radix-ui/react-dialog'
import { startCase } from 'lodash-es'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '~/components/ui'
import { ProspectingCompany, TypeID } from '~/types'
import { supabase, useSelector } from '~/utils'

export interface ProjectCreatorProps extends Pick<DialogProps, 'onOpenChange'> {
	selectedCompanies: Array<ProspectingCompany['id']>
}
export const SegmentDialog: FC<ProjectCreatorProps> = ({ selectedCompanies, ...props }) => {
	const segments = useSelector(state => state.segments)

	const FormSchema = z.object({
		segment: z.string({ required_error: 'Please select a segment' }),
		action: z.enum(['add', 'remove'])
	})

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: { segment: '', action: 'add' },
		mode: 'onBlur'
	})

	return (
		<Dialog open={selectedCompanies.length > 0} {...props}>
			<DialogContent>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(async data => {
							const res =
								data.action === 'add'
									? await supabase.from('prospecting_company_segment').upsert(
											selectedCompanies.map(company_id => ({
												company_id,
												segment_id: data.segment as TypeID<'tg'>
											}))
										)
									: await supabase
											.from('prospecting_company_segment')
											.delete()
											.eq('segment_id', data.segment)
											.in('company_id', selectedCompanies)
							if (res.error) {
								form.setError('root', res.error)
							} else {
								props.onOpenChange?.(false)
							}
						}, console.error)}
						className="space-y-4"
					>
						<DialogHeader>
							<DialogTitle>Edit segments</DialogTitle>
							<DialogDescription>Add or remove segments from companies.</DialogDescription>
						</DialogHeader>
						<FormField
							control={form.control}
							name="segment"
							render={({ field }) => (
								<FormItem>
									<FormLabel>{startCase(field.name)}</FormLabel>
									<Select onValueChange={field.onChange} defaultValue={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue placeholder="Select a segment" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											{segments.map(segment => (
												<SelectItem key={segment.id} value={segment.id}>
													{segment.label || segment.id}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="action"
							render={({ field }) => (
								<FormItem>
									<FormLabel>{startCase(field.name)}</FormLabel>
									<Select onValueChange={field.onChange} defaultValue={field.value}>
										<FormControl>
											<SelectTrigger>
												<SelectValue placeholder="Select a Role" />
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											{FormSchema.shape[field.name].options.map(t => (
												<SelectItem key={t} value={t}>
													{startCase(t)}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>

						<DialogFooter>
							<DialogClose asChild>
								<Button type="button" variant="outline">
									Cancel
								</Button>
							</DialogClose>
							<Button type="submit">Continue</Button>
							{/* <FormMessage /> TODO: Add this when https://github.com/shadcn-ui/ui/pull/3475 is merged */}
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	)
}
