/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionsResponse } from '@supabase/functions-js'
import { FunctionsError, PostgrestError, PostgrestResponse, PostgrestSingleResponse } from '@supabase/supabase-js'
import { Actions } from '~/redux'
import { reduxStore } from '~/redux/configureStore'
import { toToastError } from './supabase'

export async function handleFulfilled<
	W extends PostgrestResponse<T> | PostgrestSingleResponse<T> | FunctionsResponse<T>,
	T
>(res: W) {
	if ('error' in res && res.error) {
		const toastError = await toToastError(res.error)
		reduxStore.dispatch(Actions.addToast(toastError))
	}
	return res
}
export async function handleRejected(err: PostgrestError | FunctionsError) {
	const toastError = await toToastError(err)
	reduxStore.dispatch(Actions.addToast(toastError))
	return Promise.reject(err)
}
