import { Dagster } from '~/types'
import { Action } from '../actions'

const initialState: Dagster.RunFragment[] = []

export function runs(state = initialState, action: Action): Dagster.RunFragment[] {
	switch (action.type) {
		case 'RUN_LAUNCHED_OK':
			return [action.payload, ...state]

		case 'GET_RUNS_OK':
			return action.payload

		default:
			return state
	}
}
