import { FC, useEffect, useState } from 'react'
import { HiMiniPlusCircle } from 'react-icons/hi2'
import { Button } from '~/components/ui'
import { Actions } from '~/redux'
import { UIResource } from '~/types'
import { supabase, toToastError, useDispatch, useSelector } from '~/utils'
import { Tag } from './Tag'
import { TagSet } from './TagSet'

export interface TagGroupsProps {
	resource: UIResource
	row: number
}

export const TagGroups: FC<TagGroupsProps> = ({ resource, row }) => {
	const dispatch = useDispatch()
	const resourceSegments = useSelector(state => state.resourceSegments).filter(as => as.resource_id === resource.id)
	const [creatingGroup, setCreatingGroup] = useState(false)
	const segments = useSelector(state => state.segments)

	useEffect(() => {
		if (!resource.editing) setCreatingGroup(false)
	}, [resource.editing])

	return (
		<>
			{!resource.editing ? null : creatingGroup ? (
				<TagSet label="Pick One" row={row}>
					{segments.map(segment => {
						const exists = resourceSegments.some(s => s.segment_id === segment.id)
						return (
							<Tag
								key={segment.id}
								type={exists ? null : 'orange'}
								disabled={exists}
								onClick={async _ => {
									const res = await supabase
										.from('resource_segment')
										.insert({ resource_id: resource.id, segment_id: segment.id })
									if (res.error) {
										const error = await toToastError(res.error)
										dispatch(Actions.addToast(error))
									} else {
										setCreatingGroup(false)
									}
								}}
							>
								{segment.label}
							</Tag>
						)
					})}
				</TagSet>
			) : (
				<Button
					className="col-span-full h-full min-h-[3rem] rounded bg-white text-[#7B7B7B] dark:bg-opacity-30"
					variant="ghost"
					onClick={_ => setCreatingGroup(true)}
				>
					<HiMiniPlusCircle className="mr-2 h-6 w-6" />
					<span>Link new segment</span>
				</Button>
			)}
			{resourceSegments.length > 0 && (
				<TagSet label="Segment" row={row + (resource.editing ? 1 : 0)}>
					{segments
						.filter(segment => resourceSegments.some(s => s.segment_id === segment.id))
						.map(segment => (
							<Tag
								key={segment.id}
								type="orange"
								onClick={
									resource.editing
										? async _ => {
												const res = await supabase
													.from('resource_segment')
													.delete()
													.match({ resource_id: resource.id, segment_id: segment.id })
												if (res.error) {
													const error = await toToastError(res.error)
													dispatch(Actions.addToast(error))
												} else {
													setCreatingGroup(false)
												}
											}
										: undefined
								}
							>
								{segment.label}
							</Tag>
						))}
				</TagSet>
			)}
		</>
	)
}
