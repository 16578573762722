import { FC } from 'react'
import { HiCheckCircle, HiTrash } from 'react-icons/hi2'
import { LiaEditSolid, LiaPlusCircleSolid } from 'react-icons/lia'
import { IterableElement } from 'type-fest'
import { Tag, TagSet } from '~/components/resource'
import { Button } from '~/components/ui'
import { Actions } from '~/redux'
import { getSegmentName, supabase, useCurrentProject, useDispatch, useSelector } from '~/utils'

export const Segments: FC = () => {
	const dispatch = useDispatch()
	const resourceCategories = useSelector(state => state.resourceCategories)
	const segments = useSelector(state => state.segments)
	const segmentTags = useSelector(state => state.segmentTags)
	const config = useSelector(state => state.config)
	const project = useCurrentProject()

	if (!resourceCategories.length || !project) return null
	return (
		<>
			<Button
				className="mb-4 rounded bg-green text-black hover:bg-green-300"
				onClick={() => dispatch(Actions.createSegment({ project_id: project.id }))}
			>
				<LiaPlusCircleSolid className="mr-1 h-6 w-6" /> Create Segment
			</Button>
			<div className="grid grid-cols-[repeat(auto-fit,100%)] items-start justify-start gap-4 xs:grid-cols-[repeat(auto-fit,minmax(22rem,1fr))]">
				{segments.map(segment => {
					const sets = config.sets
						.sort((a, b) => {
							const setType = (set: IterableElement<typeof config.sets>) =>
								config.types.find(type => type.id === set.tag_type_id)?.order ?? 0
							const order = setType(a) - setType(b)
							return order !== 0 ? order : a.order - b.order
						})
						.map(set => {
							const tags = config.tags.filter(tag => tag.tag_set_id === set.id)
							return {
								...set,
								tags,
								activeTags: segmentTags.filter(
									st => segment.id === st.segment_id && tags.some(tag => tag.id === st.tag_id)
								)
							}
						})

					return (
						<div
							key={segment.id}
							className="h-min w-full space-y-2.5 rounded bg-gray-200 p-4 dark:bg-zinc-800"
							data-testid="segment-card"
						>
							<h2 className="text-center text-lg">
								{segment.editing ? (
									<input
										className="h-full w-full text-center"
										onChange={e =>
											dispatch(Actions.updateSegment({ label: e.target.value }, segment.id))
										}
										onBlur={async e =>
											supabase
												.from('segment')
												.update({ label: e.target.value })
												.eq('id', segment.id)
										}
										value={segment.label ?? ''}
										placeholder={getSegmentName(segment, segmentTags, config) || 'Segment'}
									/>
								) : (
									getSegmentName(segment, segmentTags, config) || 'Segment'
								)}
							</h2>
							<div className="grid grid-flow-row grid-cols-[auto,1fr] gap-2.5">
								{sets
									.filter(set => (segment.editing ? set.tags.length > 0 : set.activeTags.length > 0))
									.map((set, i) => (
										<TagSet key={i} label={set.label} row={i + 1}>
											{set.tags.map(tag => {
												const activeTag = set.activeTags.find(
													t => t.segment_id === segment.id && t.tag_id === tag.id
												)
												if (!segment.editing && !activeTag) return null
												return (
													<Tag
														key={tag.id}
														type={set.tag_type_id}
														inactive={!activeTag}
														onClick={
															!segment.editing
																? undefined
																: async () =>
																		activeTag
																			? supabase
																					.from('segment_tag')
																					.delete()
																					.eq('tag_id', tag.id)
																					.eq('segment_id', segment.id)
																			: supabase.from('segment_tag').insert({
																					tag_id: tag.id,
																					segment_id: segment.id
																				})
														}
													>
														{tag.label}
													</Tag>
												)
											})}
										</TagSet>
									))}
							</div>
							<div className="flex justify-end space-x-2">
								{segment.editing && (
									<Button
										className="mr-auto"
										variant={segment.editing ? 'secondary' : 'ghost'}
										onClick={_ => dispatch(Actions.deleteSegment(segment.id))}
									>
										<HiTrash className="mr-2 h-4 w-4" /> Delete
									</Button>
								)}

								{segment.editing ? (
									<Button
										variant="secondary"
										onClick={_ => dispatch(Actions.editSegment(false, segment.id))}
									>
										<HiCheckCircle className="mr-2 h-4 w-4" /> Done
									</Button>
								) : (
									<Button
										variant="ghost"
										onClick={_ => dispatch(Actions.editSegment(true, segment.id))}
									>
										<LiaEditSolid className="mr-2 h-4 w-4" /> Edit
									</Button>
								)}
							</div>
						</div>
					)
				})}
			</div>
		</>
	)
}
