import { PostgrestError } from '@supabase/supabase-js'
import { ColumnDef } from '@tanstack/react-table'
import { isObject, mapKeys, mapValues, snakeCase, startCase } from 'lodash-es'
import { useMemo } from 'react'
import { isPresent, objectEntries } from 'ts-extras'
import { IterableElement } from 'type-fest'
import { Nullable } from 'vitest'
import { Tag } from '~/components/resource'
import { DataTableColumnHeader } from '~/components/table/data-table-column-header'
import { Actions } from '~/redux'
import {
	Enrichment,
	EnrichmentType,
	ProspectingContact,
	ProspectingList,
	ProspectingListCompany,
	Template
} from '~/types'
import {
	combine,
	displayAddress,
	fuzzyGetEntry,
	supabase,
	toFullCountryName,
	toToastError,
	useCurrentProject,
	useDispatch,
	useMemoSelector,
	useSelector
} from '~/utils'

export function normalizeCompanyName(name: Nullable<string>) {
	if (!name) return ''
	return name.trim().replace(/\s+/g, '-').toLocaleLowerCase()
}

export async function parseCSV(file: File): Promise<Array<Record<string, string>>> {
	const reader = file.stream().getReader()
	const decoder = new TextDecoder('utf-8')
	const { parse } = await import('csv-parse/browser/esm')

	let text = ''
	do {
		// eslint-disable-next-line no-var -- Allow while condition to access `done`
		var { done, value } = await reader.read()
		const chunk = decoder.decode(value)
		text += chunk
	} while (!done)

	const sep = text.split(',').length > text.split(';').length ? ',' : ';'
	return new Promise((resolve, reject) =>
		parse(
			text,
			{
				delimiter: sep,
				columns: true,
				relax_quotes: true,
				trim: true,
				skip_empty_lines: true,
				cast: true
			},
			(err, data) => {
				if (err) reject(err)
				else resolve(data)
			}
		)
	)
}

export function getCountryCode(country: Nullable<string>) {
	if (!country) return undefined
	if (country.length === 2) return country.toUpperCase()
	else country = country.toLocaleLowerCase()
	const countryMap: Record<string, string> = {
		'afghanistan': 'AF',
		'albania': 'AL',
		'algeria': 'DZ',
		'american samoa': 'AS',
		'andorra': 'AD',
		'angola': 'AO',
		'anguilla': 'AI',
		'antarctica': 'AQ',
		'antigua and barbuda': 'AG',
		'argentina': 'AR',
		'armenia': 'AM',
		'aruba': 'AW',
		'australia': 'AU',
		'austria': 'AT',
		'azerbaijan': 'AZ',
		'bahamas (the)': 'BS',
		'bahrain': 'BH',
		'bangladesh': 'BD',
		'barbados': 'BB',
		'belarus': 'BY',
		'belgium': 'BE',
		'belize': 'BZ',
		'benin': 'BJ',
		'bermuda': 'BM',
		'bhutan': 'BT',
		'bolivia (plurinational state of)': 'BO',
		'bonaire, sint eustatius and saba': 'BQ',
		'bosnia and herzegovina': 'BA',
		'botswana': 'BW',
		'bouvet island': 'BV',
		'brazil': 'BR',
		'british indian ocean territory (the)': 'IO',
		'brunei darussalam': 'BN',
		'bulgaria': 'BG',
		'burkina faso': 'BF',
		'burundi': 'BI',
		'cabo verde': 'CV',
		'cambodia': 'KH',
		'cameroon': 'CM',
		'canada': 'CA',
		'cayman islands (the)': 'KY',
		'central african republic (the)': 'CF',
		'chad': 'TD',
		'chile': 'CL',
		'china': 'CN',
		'christmas island': 'CX',
		'cocos (keeling) islands (the)': 'CC',
		'colombia': 'CO',
		'comoros (the)': 'KM',
		'congo (the democratic republic of the)': 'CD',
		'congo (the)': 'CG',
		'cook islands (the)': 'CK',
		'costa rica': 'CR',
		'croatia': 'HR',
		'cuba': 'CU',
		'curaçao': 'CW',
		'cyprus': 'CY',
		'czechia': 'CZ',
		"côte d'ivoire": 'CI',
		'denmark': 'DK',
		'djibouti': 'DJ',
		'dominica': 'DM',
		'dominican republic (the)': 'DO',
		'ecuador': 'EC',
		'egypt': 'EG',
		'el salvador': 'SV',
		'equatorial guinea': 'GQ',
		'eritrea': 'ER',
		'estonia': 'EE',
		'eswatini': 'SZ',
		'ethiopia': 'ET',
		'falkland islands (the) [malvinas]': 'FK',
		'faroe islands (the)': 'FO',
		'fiji': 'FJ',
		'finland': 'FI',
		'france': 'FR',
		'french guiana': 'GF',
		'french polynesia': 'PF',
		'french southern territories (the)': 'TF',
		'gabon': 'GA',
		'gambia (the)': 'GM',
		'georgia': 'GE',
		'germany': 'DE',
		'ghana': 'GH',
		'gibraltar': 'GI',
		'greece': 'GR',
		'greenland': 'GL',
		'grenada': 'GD',
		'guadeloupe': 'GP',
		'guam': 'GU',
		'guatemala': 'GT',
		'guernsey': 'GG',
		'guinea': 'GN',
		'guinea-bissau': 'GW',
		'guyana': 'GY',
		'haiti': 'HT',
		'heard island and mcdonald islands': 'HM',
		'holy see (the)': 'VA',
		'honduras': 'HN',
		'hong kong': 'HK',
		'hungary': 'HU',
		'iceland': 'IS',
		'india': 'IN',
		'indonesia': 'ID',
		'iran (islamic republic of)': 'IR',
		'iraq': 'IQ',
		'ireland': 'IE',
		'isle of man': 'IM',
		'israel': 'IL',
		'italy': 'IT',
		'jamaica': 'JM',
		'japan': 'JP',
		'jersey': 'JE',
		'jordan': 'JO',
		'kazakhstan': 'KZ',
		'kenya': 'KE',
		'kiribati': 'KI',
		"korea (the democratic people's republic of)": 'KP',
		'korea (the republic of)': 'KR',
		'kuwait': 'KW',
		'kyrgyzstan': 'KG',
		"lao people's democratic republic (the)": 'LA',
		'latvia': 'LV',
		'lebanon': 'LB',
		'lesotho': 'LS',
		'liberia': 'LR',
		'libya': 'LY',
		'liechtenstein': 'LI',
		'lithuania': 'LT',
		'luxembourg': 'LU',
		'macao': 'MO',
		'madagascar': 'MG',
		'malawi': 'MW',
		'malaysia': 'MY',
		'maldives': 'MV',
		'mali': 'ML',
		'malta': 'MT',
		'marshall islands (the)': 'MH',
		'martinique': 'MQ',
		'mauritania': 'MR',
		'mauritius': 'MU',
		'mayotte': 'YT',
		'mexico': 'MX',
		'micronesia (federated states of)': 'FM',
		'moldova (the republic of)': 'MD',
		'monaco': 'MC',
		'mongolia': 'MN',
		'montenegro': 'ME',
		'montserrat': 'MS',
		'morocco': 'MA',
		'mozambique': 'MZ',
		'myanmar': 'MM',
		'namibia': 'NA',
		'nauru': 'NR',
		'nepal': 'NP',
		'netherlands (the)': 'NL',
		'new caledonia': 'NC',
		'new zealand': 'NZ',
		'nicaragua': 'NI',
		'niger (the)': 'NE',
		'nigeria': 'NG',
		'niue': 'NU',
		'norfolk island': 'NF',
		'northern mariana islands (the)': 'MP',
		'norway': 'NO',
		'oman': 'OM',
		'pakistan': 'PK',
		'palau': 'PW',
		'palestine, state of': 'PS',
		'panama': 'PA',
		'papua new guinea': 'PG',
		'paraguay': 'PY',
		'peru': 'PE',
		'philippines (the)': 'PH',
		'pitcairn': 'PN',
		'poland': 'PL',
		'portugal': 'PT',
		'puerto rico': 'PR',
		'qatar': 'QA',
		'republic of north macedonia': 'MK',
		'romania': 'RO',
		'russian federation (the)': 'RU',
		'rwanda': 'RW',
		'réunion': 'RE',
		'saint barthélemy': 'BL',
		'saint helena, ascension and tristan da cunha': 'SH',
		'saint kitts and nevis': 'KN',
		'saint lucia': 'LC',
		'saint martin (french part)': 'MF',
		'saint pierre and miquelon': 'PM',
		'saint vincent and the grenadines': 'VC',
		'samoa': 'WS',
		'san marino': 'SM',
		'sao tome and principe': 'ST',
		'saudi arabia': 'SA',
		'senegal': 'SN',
		'serbia': 'RS',
		'seychelles': 'SC',
		'sierra leone': 'SL',
		'singapore': 'SG',
		'sint maarten (dutch part)': 'SX',
		'slovakia': 'SK',
		'slovenia': 'SI',
		'solomon islands': 'SB',
		'somalia': 'SO',
		'south africa': 'ZA',
		'south georgia and the south sandwich islands': 'GS',
		'south sudan': 'SS',
		'spain': 'ES',
		'sri lanka': 'LK',
		'sudan (the)': 'SD',
		'suriname': 'SR',
		'svalbard and jan mayen': 'SJ',
		'sweden': 'SE',
		'switzerland': 'CH',
		'syrian arab republic': 'SY',
		'taiwan (province of china)': 'TW',
		'tajikistan': 'TJ',
		'tanzania, united republic of': 'TZ',
		'thailand': 'TH',
		'timor-leste': 'TL',
		'togo': 'TG',
		'tokelau': 'TK',
		'tonga': 'TO',
		'trinidad and tobago': 'TT',
		'tunisia': 'TN',
		'turkey': 'TR',
		'turkmenistan': 'TM',
		'turks and caicos islands (the)': 'TC',
		'tuvalu': 'TV',
		'uganda': 'UG',
		'ukraine': 'UA',
		'united arab emirates (the)': 'AE',
		'united kingdom of great britain and northern ireland (the)': 'GB',
		'united states minor outlying islands (the)': 'UM',
		'united states of america (the)': 'US',
		'uruguay': 'UY',
		'uzbekistan': 'UZ',
		'vanuatu': 'VU',
		'venezuela (bolivarian republic of)': 'VE',
		'viet nam': 'VN',
		'virgin islands (british)': 'VG',
		'virgin islands (u.s.)': 'VI',
		'wallis and futuna': 'WF',
		'western sahara': 'EH',
		'yemen': 'YE',
		'zambia': 'ZM',
		'zimbabwe': 'ZW',
		'åland islands': 'AX'
	}
	return countryMap[country]
}

export function useSegmentColumn<T>() {
	const companySegments = useSelector(state => state.prospecting.segments)
	const segments = useSelector(state => state.segments)

	return useMemo(
		() => [
			{
				id: 'segments',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				accessorFn: row =>
					companySegments
						?.filter(cs => cs.company_id === (row as unknown as ProspectingListCompany).company_id)
						.map(cs => segments.find(s => s.id === cs.segment_id)?.label)
						.filter(isPresent),
				filterFn: (row, id, filterValues) => row.getValue<string[]>(id).some(v => v.includes(filterValues)),
				cell: ({ row }) => (
					<div className="flex gap-1">
						{companySegments
							?.filter(
								cs => cs.company_id === (row.original as unknown as ProspectingListCompany).company_id
							)
							.map(cs => segments.find(s => s.id === cs.segment_id)?.label)
							.filter(isPresent)
							.map(label => (
								<Tag key={label} type="orange">
									{label}
								</Tag>
							))}
					</div>
				),
				enableColumnFilter: segments.length > 1
			} satisfies ColumnDef<IterableElement<T>>
		],
		[companySegments, segments]
	)
}

export function useCompaniesWithEnrichments(
	listId: Nullable<ProspectingList['id']>,
	enrichments: Enrichment[]
): Array<ProspectingListCompany & Record<string, any>> {
	const types = useMemoSelector(
		state => state.enrichments.types,
		t => t.filter(et => et.type === 'company')
	)
	const allCompanies = useSelector(state => state.prospecting.companies)
	const listCompanies = useSelector(state => state.prospecting.listCompanies)
	const project = useCurrentProject()
	return useMemo(() => {
		const currentCompanies = allCompanies.filter(pco => pco.org_id === project?.org_id)
		return listCompanies
			.filter(lc => (listId ? lc.list_id === listId : true))
			.map(lc => {
				const values = enrichments
					.filter(e => e.company_id === lc.company_id && types.some(t => t.id === e.type_id))
					.flatMap(mapEnrichment)

				const prospectingCompany = currentCompanies.find(pco => pco.id === lc.company_id)
				const company = combine(lc, prospectingCompany!, ...(values as Array<Record<string, unknown>>))

				return mapValues(company, (value, key) => {
					if (key.toLowerCase() === 'country') return toFullCountryName(value as string)
					if (value && key.toLowerCase().includes('address')) return displayAddress(value as any)
					return value
				})
			}) as Array<ProspectingListCompany & Record<string, any>>
	}, [allCompanies, listCompanies, project?.org_id, listId, enrichments, types])
}

export function useProspectingContacts(companies: ProspectingListCompany[], enrichments: Enrichment[]) {
	const roles = useSelector(state => state.prospecting.roles)
	const contacts = useSelector(state => state.prospecting.contacts)

	return useMemo(
		() =>
			companies
				.flatMap(company => {
					const rolesInCompany = roles.filter(r => r.company_id === company.company_id)
					return contacts
						.filter(c => rolesInCompany.some(r => r.contact_id === c.id))
						.map(c =>
							combine(
								c,
								{
									company: fuzzyGetEntry(company, 'name')?.value,
									roles: rolesInCompany.filter(r => r.contact_id === c.id).map(r => r.role)
								},
								...(enrichments
									.filter(e => e.contact_id === c.id)
									.flatMap(mapEnrichment)
									.filter(isPresent) as Array<Record<string, string>>)
							)
						)
				})
				.filter(c => c.roles),
		[companies, contacts, enrichments, roles]
	)
}

export async function toggleRows(
	selected: ProspectingListCompany[],
	dispatch: ReturnType<typeof useDispatch>,
	enabled: boolean
) {
	const disabled = !enabled
	const toggled = selected.filter(row => row?.disabled !== disabled)
	const res = await supabase
		.from('prospecting_list_company')
		.update({ disabled })
		.in(
			'id',
			toggled.map(row => row.id)
		)
	if (res.error) {
		const error = await toToastError(res.error)
		dispatch(Actions.addToast(error))
	} else {
		for (const company of toggled) {
			dispatch({
				type: 'UPDATE_PROSPECTING_LIST_COMPANY_OK',
				payload: { ...company, disabled },
				meta: company.id
			})
		}
	}
}

export async function deleteRows(
	selected: Array<ProspectingListCompany | ProspectingContact>
): Promise<PostgrestError[]> {
	const companies = selected.filter(row => row.id.startsWith('plc_')).map(row => row.id)
	const contacts = selected.filter(row => row.id.startsWith('pct_')).map(row => row.id)

	const res = await Promise.all([
		supabase.from('prospecting_list_company').delete().in('id', companies),
		supabase.from('prospecting_contact').delete().in('id', contacts)
	])
	return res.map(r => r.error).filter(isPresent)
}

export function mapEnrichment(enrichment: Enrichment): Enrichment['data'] | Array<Enrichment['data']> {
	if (isObject(enrichment.data) && Object.keys(enrichment.data).length === 1) {
		const [templateID, value] = objectEntries(
			enrichment.data as Record<Template['id'], string | Record<string, string | number>>
		)[0]!
		if (templateID.startsWith('te_')) {
			return { [templateID]: value }
		}
	}
	return isObject(enrichment.data) ? { ...mapKeys(enrichment.data, (_, key) => snakeCase(key)) } : ({} as any)
}

export function enrichmentName(et: Pick<EnrichmentType, 'id' | 'name'>) {
	switch (et.name) {
		case 'csv':
			return 'Uploaded'
		case 'gpt':
			return 'GPT'
		default:
			return startCase(et.name)
	}
}
