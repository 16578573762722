import { isObject } from 'lodash-es'
import { FC, PropsWithChildren, useCallback } from 'react'
import { objectEntries } from 'ts-extras'
import { Button } from '~/components/ui'
import { Actions } from '~/redux'
import { CompanyDimension, ContactDimension, Project, ProspectingList, ProspectingType, TablesInsert } from '~/types'
import {
	CSV_COMPANY_ENRIGHTMENT_ID,
	CSV_COMPANY_WEIGHT_ID,
	CSV_CONTACT_ENRIGHTMENT_ID,
	CSV_CONTACT_WEIGHT_ID,
	fuzzyGetEntry,
	getDimensionEntry,
	supabase,
	toToastError,
	useDispatch
} from '~/utils'
import { getCountryCode, normalizeCompanyName, parseCSV } from './listUtils'

export interface ListUploadProps extends PropsWithChildren {
	project: Project
	list: ProspectingList
	type: ProspectingType
}

export const ListUpload: FC<ListUploadProps> = ({ project, type, list, children }) => {
	const dispatch = useDispatch()

	const processCSV = useCallback(
		async (file: File) => {
			const data = await parseCSV(file).catch(e => {
				dispatch(Actions.addToast({ variant: 'destructive', title: 'Invalid CSV', description: e.message }))
				return null
			})
			if (!data || !data[0]) {
				const err = { title: 'Invalid CSV', description: 'No data found in the CSV file.' }
				return dispatch(Actions.addToast({ variant: 'destructive', ...err }))
			}
			const error =
				type === 'company'
					? await processCompanies(data, list, project)
					: await processContacts(data, list, project)
			if (error) dispatch(Actions.addToast({ variant: 'destructive', ...error }))
		},
		[dispatch, list, project, type]
	)

	return (
		<Button className="h-auto flex-col gap-1 px-4 py-0.5" variant="ghost" size="lg" asChild>
			<label className="cursor-pointer">
				{children}
				<form className="pointer-events-none absolute h-0 w-0 opacity-0">
					<input
						type="file"
						accept="text/csv"
						onChange={async e => {
							const file = e.currentTarget.files?.[0]
							if (!file) return
							processCSV(file)
							e.currentTarget.value = ''
						}}
					/>
				</form>
			</label>
		</Button>
	)
}

async function processCompanies(
	rows: Array<Record<string, string>>,
	list: ProspectingList,
	project: Project
): Promise<null | ReturnType<typeof toToastError>> {
	const headers = rows[0]!

	const nameCol = fuzzyGetEntry(headers, 'name', 'company')
	const countryCol = fuzzyGetEntry(headers, 'country code', 'country')
	const orgNumberCol = fuzzyGetEntry(headers, 'org number', 'organization number')

	if (!nameCol || !countryCol) {
		return { title: 'Invalid CSV', description: `All the following columns are required: Name, Country Code` }
	}

	const companies = rows
		.map(row => ({
			name: normalizeCompanyName(row[nameCol.key]),
			org_number: orgNumberCol ? row[orgNumberCol.key] : undefined,
			country: getCountryCode(row[countryCol.key])!,
			org_id: project?.org_id
		}))
		.filter(c => c.name && c.country)

	if (!companies.length)
		return { title: 'Invalid CSV', description: 'List has no valid country names / country codes.' }

	const inserted = await Promise.allSettled(
		companies.map(c =>
			supabase
				.from('prospecting_company')
				.upsert(c, { onConflict: 'name, country, org_id' })
				.select('id')
				.single()
		)
	)
	const listEntries: Array<TablesInsert<'prospecting_list_company'>> = []
	const enrichments: Array<TablesInsert<'enrichment'>> = []
	for (const result of inserted) {
		if (result.status === 'rejected') return toToastError(result.reason)
		if (result.value.error) return toToastError(result.value.error)

		listEntries.push({ list_id: list.id, company_id: result.value.data.id })
		enrichments.push({
			type_id: CSV_COMPANY_ENRIGHTMENT_ID,
			list_id: list.id,
			company_id: result.value.data.id,
			data: rows[inserted.indexOf(result)] || {}
		})
	}

	const plc = await supabase
		.from('prospecting_list_company')
		.upsert(listEntries, { onConflict: 'list_id, company_id' })
	if (plc.error) return toToastError(plc.error)

	const res = await supabase.from('enrichment').insert(enrichments).select('id, data, company_id')
	if (res.error) return toToastError(res.error)

	const dimensions: { [K in CompanyDimension]: Array<TablesInsert<`dimension_company_${K}`>> } = {
		name: [],
		url: [],
		headcount: [],
		revenue: [],
		profit: [],
		total_assets: [],
		about: [],
		phone: []
	}
	const weight_id = CSV_COMPANY_WEIGHT_ID
	for (const enrichment of res.data || []) {
		if (!isObject(enrichment.data)) continue
		const enrichment_id = enrichment.id
		const eData = enrichment.data as Record<string, string>
		if (!enrichment.data || Object.keys(enrichment.data).length === 0) continue

		const makeEntry = getDimensionEntry(enrichment_id, weight_id)

		if (eData[nameCol.key]) dimensions.name.push(makeEntry(eData[nameCol.key]!))

		const urlEntry = fuzzyGetEntry(eData, 'url', 'website', 'domain')
		if (urlEntry?.value) dimensions.url.push(makeEntry(urlEntry.value))

		const headcountEntry = fuzzyGetEntry(eData, 'headcount', 'number of employees')
		if (headcountEntry?.value) dimensions.headcount.push(makeEntry(Number.parseFloat(headcountEntry.value)))

		const revenueEntry = fuzzyGetEntry(eData, 'revenue')
		if (revenueEntry?.value) dimensions.revenue.push(makeEntry(revenueEntry.value, 'OOO'))

		const profitEntry = fuzzyGetEntry(eData, 'profit')
		if (profitEntry?.value) dimensions.profit.push(makeEntry(profitEntry.value, 'OOO'))

		const assetsEntry = fuzzyGetEntry(eData, 'total_assets')
		if (assetsEntry?.value) dimensions.total_assets.push(makeEntry(assetsEntry.value, 'OOO'))

		const aboutEntry = fuzzyGetEntry(eData, 'about', 'purpose')
		if (aboutEntry?.value) dimensions.about.push(makeEntry(aboutEntry.value))

		const phoneEntry = fuzzyGetEntry(eData, 'phone', 'company_phone', 'tel', 'mobile')
		if (phoneEntry?.value) dimensions.phone.push(makeEntry(phoneEntry.value))
	}
	for (const [dimension, values] of objectEntries(dimensions)) {
		if (values.length === 0) continue
		const dim = await supabase
			.from(`dimension_company_${dimension}`)
			.upsert(values as any, { onConflict: 'enrichment_id' })
		if (dim.error) return toToastError(dim.error)
	}
	return null
}

async function processContacts(
	rows: Array<Record<string, string>>,
	list: ProspectingList,
	project: Project
): Promise<null | ReturnType<typeof toToastError>> {
	const headers = rows[0]!

	const firstNameCol = fuzzyGetEntry(headers, 'first name', 'first name')
	const lastNameCol = fuzzyGetEntry(headers, 'last name', 'last name')
	const companyCol = fuzzyGetEntry(headers, 'company', 'company name')
	const roleCol = fuzzyGetEntry(headers, 'role', 'position')

	if (!firstNameCol || !lastNameCol || !companyCol || !roleCol) {
		return {
			title: 'Invalid CSV',
			description: `All the following columns are required: First Name, Last Name, Company Name, Role`
		}
	}
	const contacts = rows
		.map(row => ({
			first_name: row[firstNameCol.key]!,
			last_name: row[lastNameCol.key]!,
			org_id: project?.org_id
		}))
		.filter(c => c.first_name && c.last_name)

	if (!contacts.length)
		return { title: 'Invalid CSV', description: 'List has no valid country names / country codes.' }

	const inserted = await Promise.allSettled(
		contacts.map(c => supabase.from('prospecting_contact').insert(c).select('id').single())
	)
	const companies = await supabase.from('prospecting_company').select()
	if (companies.error) return toToastError(companies.error)

	const enrichments: Array<TablesInsert<'enrichment'>> = []
	for (const result of inserted) {
		if (result.status === 'rejected') return toToastError(result.reason)
		if (result.value.error) return toToastError(result.value.error)
		const contact = rows[inserted.indexOf(result)]
		if (!contact) return { title: 'Error', description: 'Internal error during CSV processing.' }

		const role = contact[roleCol.key]!
		const company = companies.data.find(c => c.name === normalizeCompanyName(contact[companyCol.key]!))
		if (!company)
			return { title: 'Error', description: `Company ${contact[companyCol.key]} not found in database.` }

		enrichments.push({
			type_id: CSV_CONTACT_ENRIGHTMENT_ID,
			list_id: list.id,
			contact_id: result.value.data.id,
			data: contact
		})

		await supabase
			.from('prospecting_role')
			.insert({ contact_id: result.value.data.id, role, company_id: company.id })
	}

	const res = await supabase.from('enrichment').insert(enrichments).select('id, data, company_id')
	if (res.error) return toToastError(res.error)

	const dimensions: { [K in ContactDimension]: Array<TablesInsert<`dimension_contact_${K}`>> } = {
		email: [],
		phone: []
	}
	const weight_id = CSV_CONTACT_WEIGHT_ID
	for (const enrichment of res.data || []) {
		if (!isObject(enrichment.data)) continue
		const enrichment_id = enrichment.id
		const eData = enrichment.data as Record<string, string>
		if (!enrichment.data || Object.keys(enrichment.data).length === 0) continue

		const makeEntry = getDimensionEntry(enrichment_id, weight_id)

		const emailEntry = fuzzyGetEntry(eData, 'email', 'contact_email', 'email address')
		if (emailEntry?.value) dimensions.email.push({ enrichment_id, weight_id, value: emailEntry.value })

		const phoneEntry = fuzzyGetEntry(eData, 'phone', 'contact_phone', 'tel', 'mobile')
		if (phoneEntry?.value) dimensions.phone.push(makeEntry(phoneEntry.value))
	}
	for (const [dimension, values] of objectEntries(dimensions)) {
		if (values.length === 0) continue
		const dim = await supabase
			.from(`dimension_contact_${dimension}`)
			.upsert(values, { onConflict: 'enrichment_id' })
		if (dim.error) return toToastError(dim.error)
	}
	return null
}
