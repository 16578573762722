import { startCase } from 'lodash-es'
import { PlusSquareIcon } from 'lucide-react'
import { FC, useState } from 'react'
import { HiCheckCircle, HiPencil, HiUserRemove } from 'react-icons/hi'
import { HiOutlineUserPlus } from 'react-icons/hi2'
import {
	Button,
	Dialog,
	DialogTrigger,
	Separator,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
	buttonVariants
} from '~/components/ui'
import { Actions } from '~/redux'
import { UIOrganization } from '~/types'
import { supabase, toToastError, useDispatch, useSelector } from '~/utils'
import { CreateCompanyDialog } from './CreateCompanyDialog'
import { DeleteConfirm } from './DeleteConfirm'
import { InviteDialog } from './InviteDialog'

export const Organizations: FC = () => {
	const dispatch = useDispatch()
	const [dialogOpen, setDialogOpen] = useState<'invite' | 'create' | null>(null)
	const companies = useSelector(state => state.user?.companies)
	const currentUser = useSelector(state => state.user)
	const [selectedCompany, setSelectedCompany] = useState<UIOrganization | null>(null)
	const [editing, setEditing] = useState<UIOrganization['id'] | null>(null)

	if (!currentUser) return null

	return (
		<div className="space-y-6">
			<Dialog open={dialogOpen === 'create'} onOpenChange={open => setDialogOpen(open ? 'create' : null)}>
				<div className="space-y-2">
					<h2 className="text-lg font-medium">Organizations</h2>
					<p className="text-sm text-muted-foreground">
						Edit and create organizations, and assign invite users.
					</p>
					<Button variant="secondary" onClick={_ => setDialogOpen('create')}>
						<PlusSquareIcon className="mr-2" /> Create Organization
					</Button>
				</div>
				<CreateCompanyDialog onClose={() => setDialogOpen(null)} />
			</Dialog>
			<Separator />
			<Dialog open={dialogOpen === 'invite'} onOpenChange={open => setDialogOpen(open ? 'invite' : null)}>
				{companies?.map(company => (
					<div key={company.id} className="space-y-2" data-testid="organization">
						<header className="flex items-center justify-between">
							<div className="flex flex-auto flex-wrap items-baseline gap-x-3">
								<h3 className="font-medium" data-testid="organization-name">
									{editing === company.id ? (
										<input
											name="name"
											className="rounded border border-black"
											defaultValue={company.name ?? undefined}
											onBlur={async e => {
												const res = await supabase
													.from('organization')
													.update({ name: e.target.value.trim() })
													.eq('id', company.id)
												if (res.error) {
													const error = await toToastError(res.error)
													dispatch(Actions.addToast(error))
												}
											}}
										/>
									) : (
										company.name
									)}
								</h3>
								<h4 className="whitespace-nowrap text-xs opacity-30">ID: {company.id}</h4>
							</div>
							<Button
								variant="ghost"
								onClick={_ => setEditing(editing === company.id ? null : company.id)}
							>
								{editing === company.id ? (
									<>
										<HiCheckCircle className="mr-2 h-4 w-4" /> Done
									</>
								) : (
									<>
										<HiPencil className="mr-2 h-4 w-4" /> Edit
									</>
								)}
							</Button>
							<DeleteConfirm
								password={company.name ?? company.id}
								onSubmit={async () => {
									const res = await supabase.from('organization').delete().eq('id', company.id)
									if (res.error) {
										const toastError = await toToastError(res.error)
										dispatch(Actions.addToast(toastError))
									} else if (res.count) {
										dispatch(Actions.companyDeleted(company.id))
									}
								}}
								disabled={
									company.org_users.find(u => u.user_id === currentUser.info?.id)?.role !== 'admin'
								}
							>
								<p className="Ztext-sm">Are you sure? All projects and resources will be deleted.</p>
							</DeleteConfirm>
						</header>
						<div className="rounded-md border">
							<Table>
								<TableHeader>
									<TableRow key="header">
										<TableHead key="email">Email</TableHead>
										<TableHead key="role">Role</TableHead>
										<TableHead key="status">Status</TableHead>
										<TableHead key="actions" />
									</TableRow>
								</TableHeader>
								<TableBody>
									{company.org_users.map(user => (
										<TableRow key={user.user_id}>
											<TableCell key="email">
												{user.email} {user.user_id === currentUser.info?.id && '(You)'}
											</TableCell>
											<TableCell key="role">{startCase(user.role)}</TableCell>
											<TableCell key="status">{user.status}</TableCell>
											<TableCell key="actions" className="text-right">
												<DeleteConfirm
													onSubmit={async () => {
														const res = await supabase
															.from('organization_user')
															.delete()
															.eq('user_id', user.user_id)
															.eq('org_id', company.id)
														if (res.error) {
															const toastError = await toToastError(res.error)
															dispatch(Actions.addToast(toastError))
														}
													}}
													icon={<HiUserRemove />}
													disabled={
														user.user_id === currentUser.info?.id ||
														company.org_users.find(u => u.user_id === currentUser.info?.id)
															?.role !== 'admin'
													}
												/>
											</TableCell>
										</TableRow>
									))}
									<TableRow className="hover:bg-transparent" key="invite">
										<TableCell className="py-2 pl-0" colSpan={3}>
											<DialogTrigger
												className={buttonVariants({
													className: 'ml-2 flex items-center gap-2 px-2',
													variant: 'ghost'
												})}
												onClick={_ => setSelectedCompany(company)}
												disabled={
													company.org_users.find(u => u.user_id === currentUser.info?.id)
														?.role !== 'admin'
												}
											>
												<HiOutlineUserPlus /> Invite
											</DialogTrigger>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</div>
					</div>
				))}
				<InviteDialog
					company={selectedCompany}
					onClose={() => setDialogOpen(null)}
					onInvited={user => {
						dispatch(Actions.userInvited(user, user.org_id))
						setDialogOpen(null)
					}}
				/>
			</Dialog>
		</div>
	)
}
