// src/Tiptap.tsx
import { BubbleMenu as BubbleMenuPrimitive, EditorProviderProps, useCurrentEditor } from '@tiptap/react'
import { FC } from 'react'
import { HTMLElementProps } from '~/types'
import { cn } from '~/utils'

export const BubbleMenu: FC<EditorProviderProps> = ({ ...props }) => {
	const { editor } = useCurrentEditor()
	if (!editor) return null
	return (
		<BubbleMenuPrimitive editor={editor} {...props}>
			<div className="flex h-10 items-center space-x-1 rounded-md border bg-background p-1">
				<BubbleMenuButton
					onClick={() => editor.chain().focus().toggleBold().run()}
					aria-selected={editor.isActive('bold')}
				>
					Bold
				</BubbleMenuButton>
				<BubbleMenuButton
					onClick={() => editor.chain().focus().toggleItalic().run()}
					aria-selected={editor.isActive('italic')}
				>
					Italic
				</BubbleMenuButton>
				<BubbleMenuButton
					onClick={() => editor.chain().focus().toggleCode().run()}
					aria-selected={editor.isActive('code')}
				>
					Code
				</BubbleMenuButton>
			</div>
		</BubbleMenuPrimitive>
	)
}

const BubbleMenuButton: FC<HTMLElementProps<'button'>> = ({ className, children, ...props }) => (
	<button
		className={cn(
			'flex cursor-default select-none items-center rounded-sm px-3 py-1.5 text-sm font-medium outline-none',
			'hover:bg-accent/60 focus:text-accent-foreground aria-selected:bg-accent aria-selected:font-bold aria-selected:text-accent-foreground',
			className
		)}
		{...props}
	>
		{children}
	</button>
)
