import { call, put, select, takeLatest } from 'typed-redux-saga'
import { UIOrganizationUser } from '~/types'
import { State } from '~/types/state'
import { AUTH_SESSION_KEY, setItem, supabase, toToastError } from '~/utils'
import { Action, ActionTypes, Actions } from '../actions'
import { getCurrentProject } from './sagaUtils'

export function* userSagas() {
	yield* takeLatest<ActionTypes, any>('USER_LOAD', loadUser)
	yield* takeLatest<ActionTypes, any>('USER_LOGGED_IN', getOrganizations)
	yield* takeLatest<ActionTypes, any>('ORGANIZATION_GET', getOrganizations)
	yield* takeLatest<ActionTypes, any>('PROJECT_SELECT', saveProject)
	yield* takeLatest<ActionTypes, any>('DELETE_PROJECT_OK', onProjectDeleted)
}

function* loadUser() {
	const { data, error } = yield* call(() => supabase.auth.getSession())
	if (data.session) {
		supabase.functions.setAuth(data.session.access_token)
		yield* put(Actions.userLoggedIn(data.session, data.session?.user ?? null))
		// Fetch updated user data
		const user = yield* call(() => supabase.auth.getUser())
		if (user.error) {
			if (user.error.status === 404) {
				yield* call(() => supabase.auth.signOut())
				setItem('selectedProjectId', null)
				location.reload()
			} else if (
				user.error.status === 403 &&
				user.error.message.includes('Session from session_id claim in JWT does not exist')
			) {
				// This sometimes happens when a session exists from an old database instance
				setItem(AUTH_SESSION_KEY, null)
				location.reload()
			}
			const toastError = yield* call(toToastError, user.error)
			yield* put(Actions.addToast(toastError))
		} else if (user) {
			yield* put(Actions.userUpdated(user.data.user))
		}
	} else if (error) {
		const toastError = yield* call(toToastError, error)
		yield* put(Actions.addToast(toastError))
	} else {
		setItem('selectedProjectId', null)
		setItem(AUTH_SESSION_KEY, null)
	}
}

function* getOrganizations() {
	const [organizations, users] = yield* call(async () =>
		Promise.all([
			supabase.from('organization').select('*, projects:project(*)'),
			supabase.functions.invoke<{ data: UIOrganizationUser[] }>('users')
		])
	)

	if (organizations.error || users.error) {
		const toastError = yield* call(toToastError, organizations.error || users.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(
			Actions.companiesLoaded(
				organizations.data.map(org => ({
					...org,
					org_users: users.data?.data.filter(u => u.org_id === org.id) ?? []
				}))
			)
		)
	}
}

function* saveProject(action: Action<'PROJECT_SELECT'>) {
	if (action.meta === null) yield* call(() => setItem('selectedProjectId', null))
	const project = yield* select((state: State) => state.user?.projects?.find(p => p.id === action.meta))
	if (project) yield* call(() => setItem('selectedProjectId', project.id))
}

function* onProjectDeleted(action: Action<'DELETE_PROJECT_OK'>) {
	const project = yield* call(getCurrentProject)
	if (project?.id !== action.meta) return
	yield* put(Actions.selectProject(null))
}
