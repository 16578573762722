import { FC, PropsWithChildren, useState } from 'react'
import { HiDocumentDownload } from 'react-icons/hi'
import { Button, ButtonProps } from '~/components/ui'
import { Actions } from '~/redux'
import { ResourceFile } from '~/types'
import {
	SUPABASE_BUCKET_NAME,
	cn,
	supabase,
	toFilePath,
	toToastError,
	useCurrentProject,
	useDispatch,
	useMemoSelector
} from '~/utils'

export interface DownloadButtonProps extends ButtonProps, DownloadLinkProps {}

export const DownloadButton: FC<DownloadButtonProps> = ({ resourceFile, children, ...props }) => {
	const file = useMemoSelector(
		state => state.files,
		files => files.find(f => f.id === resourceFile.id || f.name === resourceFile.filename),
		[resourceFile.id, resourceFile.filename]
	)
	if (!file) return null
	return (
		<Button asChild variant={file.editing ? 'secondary' : 'ghost'} {...props}>
			<DownloadLink resourceFile={resourceFile}>{children}</DownloadLink>
		</Button>
	)
}

export interface DownloadLinkProps extends PropsWithChildren {
	id?: string
	className?: string
	resourceFile: ResourceFile
}

export const DownloadLink: FC<DownloadLinkProps> = ({ className, resourceFile, children, ...props }) => {
	const file = useMemoSelector(
		state => state.files,
		files => files.find(f => f.id === resourceFile.id || f.name === resourceFile.filename),
		[resourceFile.id, resourceFile.filename]
	)
	const dispatch = useDispatch()
	const project = useCurrentProject()
	const [downloadUrl, setDownloadUrl] = useState<string>()
	if (!file) return null
	const fullPath = toFilePath(project, file.name)

	return (
		<a
			className={cn('inline-flex items-center', className)}
			href={downloadUrl ?? ''}
			download={file.name}
			onMouseEnter={async () => {
				if (downloadUrl) return
				const res = await supabase.storage
					.from(SUPABASE_BUCKET_NAME)
					.createSignedUrl(fullPath, 60, { download: file.name })
				if (res.error) {
					const error = await toToastError(res.error)
					dispatch(Actions.addToast(error))
				} else {
					setDownloadUrl(res.data.signedUrl)
				}
			}}
			{...props}
		>
			{children ?? (
				<>
					<HiDocumentDownload className="mr-2 h-4 w-4" /> Download
				</>
			)}
		</a>
	)
}
