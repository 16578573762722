import type { ResourceCategory } from '~/types'
import { Action } from '../actions'

export function resourceCategories(state: ResourceCategory[] = [], action: Action): ResourceCategory[] {
	switch (action.type) {
		case 'GET_RESOURCE_CATEGORIES_OK':
			return action.payload

		case 'INSERT_RESOURCE_CATEGORY_OK':
			return [...state, action.payload]

		case 'UPDATE_RESOURCE_CATEGORY_OK':
			return state.map(rc => (rc.id === action.payload.id ? action.payload : rc))

		case 'DELETE_RESOURCE_CATEGORY_OK':
			return state.filter(resourceCategory => resourceCategory.id !== action.payload)
	}
	return state
}
