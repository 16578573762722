import * as Sentry from '@sentry/react'
import { ErrorBoundaryProps, FallbackRender } from '@sentry/react'
import { AlertOctagon } from 'lucide-react'
import { FC, useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import { isPresent } from 'ts-extras'
import { useSelector } from '~/utils'
import { Button } from '../ui'

export const ErrorFallback: FallbackRender = ({ error }) => (
	<div className="flex h-full items-center justify-center">
		<div className="flex flex-col items-center gap-2 text-center">
			<AlertOctagon className="h-8 w-full text-destructive" />
			<h3 className="text-2xl font-bold tracking-tight text-destructive">Something went wrong.</h3>
			<p className="text-sm text-muted-foreground">We are sorry for the inconvenience. Please try again.</p>
			<Button onClick={_ => location.reload()} className="mt-4">
				Reload
			</Button>

			<pre className="mt-4 whitespace-break-spaces rounded bg-muted p-4 text-left text-xs">
				{error?.message}
				{error?.stack}
			</pre>
		</div>
	</div>
)

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ showDialog = true, ...props }) => {
	const user = useSelector(s => s.user)
	const location = useLocation()
	const [fallbackKey, update] = useReducer(n => n + 1, 0)
	useEffect(() => {
		// Force rerender of error boundary on route change
		update()
	}, [location.pathname])

	return (
		<Sentry.ErrorBoundary
			fallback={ErrorFallback}
			data-key={fallbackKey}
			showDialog={showDialog}
			dialogOptions={{
				user: user?.info
					? {
							email: user.info.email,
							name: [user.info.user_metadata?.firstName, user.info.user_metadata?.lastName]
								.filter(isPresent)
								.join(' ')
						}
					: undefined
			}}
			{...props}
		/>
	)
}
