import { zodResolver } from '@hookform/resolvers/zod'
import { startCase } from 'lodash-es'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { objectKeys } from 'ts-extras'
import * as z from 'zod'
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '~/components/ui'
import { Actions } from '~/redux'
import { supabase, toToastError, useSelector } from '~/utils'

const profileFormSchema = z.object({
	firstName: z.string().optional(),
	lastName: z.string().optional(),
	email: z
		.string({
			required_error: 'Please enter your email address.'
		})
		.email()
})

type ProfileFormValues = z.infer<typeof profileFormSchema>

export function ProfileForm() {
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)
	const form = useForm<ProfileFormValues>({
		resolver: zodResolver(profileFormSchema),
		defaultValues: {
			firstName: user?.info?.user_metadata?.firstName,
			lastName: user?.info?.user_metadata?.lastName,
			email: user?.info?.email
		},
		mode: 'onChange'
	})

	async function onSubmit({ email, ...data }: ProfileFormValues) {
		const res = await supabase.auth.updateUser({ email, data })
		if (res.error) dispatch(Actions.addToast(await toToastError(res.error)))
		else {
			dispatch(Actions.userUpdated({ user_metadata: data }))
			dispatch(
				Actions.addToast({
					title: 'User info updated',
					description:
						email !== user?.info?.email
							? 'Please check your email to verify your new email address.'
							: undefined
				})
			)
		}
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit, console.error)} className="space-y-4">
				{objectKeys(profileFormSchema.shape).map(key => (
					<FormField
						key={key}
						control={form.control}
						name={key}
						render={({ field }) => (
							<FormItem>
								<FormLabel>{startCase(field.name)}</FormLabel>
								<FormControl>
									<Input {...field} {...getFormProps(field.name)} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				))}

				<Button type="submit" className="!mt-8">
					Update profile
				</Button>
			</form>
		</Form>
	)
}

function getFormProps(key: keyof ProfileFormValues): { placeholder: string; autoComplete: AutoFill } {
	switch (key) {
		case 'firstName':
			return { placeholder: 'John', autoComplete: 'given-name' }
		case 'lastName':
			return { placeholder: 'Doe', autoComplete: 'family-name' }
		case 'email':
			return { placeholder: 'mail@example.com', autoComplete: 'email' }
	}
}
