import { FC, useState } from 'react'
import { HiDocumentDownload } from 'react-icons/hi'
import { HiCheckCircle, HiTrash } from 'react-icons/hi2'
import { LiaEditSolid } from 'react-icons/lia'
import { twJoin } from 'tailwind-merge'
import { TextArea } from '~/components/form'
import { Button } from '~/components/ui'
import { Actions } from '~/redux'
import { UIFile } from '~/types'
import {
	SUPABASE_BUCKET_NAME,
	supabase,
	toFilePath,
	toToastError,
	useCurrentProject,
	useDispatch,
	useSelector
} from '~/utils'

export interface FileCardProps {
	file: UIFile
}

export const FileCard: FC<FileCardProps> = ({ file }) => {
	const dispatch = useDispatch()
	const project = useCurrentProject()
	const [filename, setFilename] = useState(file.name)
	const [downloadUrl, setDownloadUrl] = useState<string>()
	const fullPath = toFilePath(project, file.name)
	const resource = useSelector(state => state.resources.items).find(
		r => r.file_id === file.id || r.label === file.name
	)

	return (
		<div
			className={twJoin(
				'h-min w-full space-y-2.5 rounded bg-gray-200 p-4 dark:bg-zinc-800',
				resource ? '' : 'opacity-50'
			)}
		>
			<h2 className="text-center text-lg">{file.metadata.mimetype}</h2>
			<TextArea
				className="bg-white dark:bg-white/30"
				name="name"
				disabled={!file.editing}
				value={filename}
				onChange={e => setFilename(e.target.value)}
				onBlur={async _ => {
					if (filename === file.name) return
					const res = await supabase.storage
						.from(SUPABASE_BUCKET_NAME)
						.move(fullPath, toFilePath(project, filename))
					if (res.error) {
						const error = await toToastError(res.error)
						dispatch(Actions.addToast(error))
					} else {
						dispatch(Actions.fileUpdated({ name: filename }, file.name))
					}
				}}
				placeholder="Enter filename here"
			/>

			<div className="flex justify-end space-x-2">
				{file.editing ? (
					<Button
						className="mr-auto"
						variant={file.editing ? 'secondary' : 'ghost'}
						onClick={async _ => {
							const res = await supabase.storage.from(SUPABASE_BUCKET_NAME).remove([fullPath])
							if (res.error) {
								const error = await toToastError(res.error)
								dispatch(Actions.addToast(error))
							} else {
								dispatch(Actions.fileDeleted(file.name))
							}
						}}
					>
						<HiTrash className="mr-2 h-4 w-4" /> Delete
					</Button>
				) : (
					<Button
						asChild
						className="mr-auto"
						onMouseEnter={async _ => {
							if (downloadUrl) return
							const res = await supabase.storage
								.from(SUPABASE_BUCKET_NAME)
								.createSignedUrl(fullPath, 60, { download: file.name })
							if (res.error) {
								const error = await toToastError(res.error)
								dispatch(Actions.addToast(error))
							} else {
								setDownloadUrl(res.data.signedUrl)
							}
						}}
						variant={file.editing ? 'secondary' : 'ghost'}
					>
						<a href={downloadUrl ?? ''} download={file.name}>
							<HiDocumentDownload className="mr-2 h-4 w-4" /> Download
						</a>
					</Button>
				)}
				{file.editing ? (
					<Button
						variant="secondary"
						onClick={_ => dispatch(Actions.fileUpdated({ editing: false }, file.name))}
					>
						<HiCheckCircle className="mr-2 h-4 w-4" /> Done
					</Button>
				) : (
					<Button variant="ghost" onClick={_ => dispatch(Actions.fileUpdated({ editing: true }, file.name))}>
						<LiaEditSolid className="mr-2 h-4 w-4" /> Edit
					</Button>
				)}
			</div>
		</div>
	)
}
