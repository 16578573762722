export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Cursor: { input: any; output: any; }
  GenericScalar: { input: any; output: any; }
  RunConfigData: { input: any; output: any; }
};

export enum ActiveAgentCategory {
  Container = 'CONTAINER',
  Local = 'LOCAL',
  Mixed = 'MIXED',
  None = 'NONE',
  Unknown = 'UNKNOWN'
}

export type AddDynamicPartitionResult = AddDynamicPartitionSuccess | DuplicateDynamicPartitionError | PythonError | UnauthorizedError;

export type AddDynamicPartitionSuccess = {
  partitionKey: Scalars['String']['output'];
  partitionsDefName: Scalars['String']['output'];
};

export type AddLocationFromDocumentMutationResult = CodeLocationLimitError | InvalidLocationError | PythonError | UnauthorizedError | WorkspaceEntry;

export type AddLocationMutationResult = InvalidLocationError | PythonError | UnauthorizedError | WorkspaceEntry;

export type AddMemberToTeamMutationResult = AddMemberToTeamSuccess | PythonError | UnauthorizedError | UserLimitError;

export type AddMemberToTeamSuccess = {
  team: DagsterCloudTeam;
};

export type AddOrUpdateLocationFromDocumentMutationResult = InvalidLocationError | PythonError | UnauthorizedError | WorkspaceEntry;

export type AddOrUpdateLocationMutationResult = InvalidLocationError | PythonError | UnauthorizedError | WorkspaceEntry;

export type AddUserToOrganizationMutationResult = AddUserToOrganizationSuccess | PythonError | UnauthorizedError | UserLimitError;

export type AddUserToOrganizationSuccess = {
  email: Scalars['String']['output'];
  userWithGrants: DagsterCloudUserWithScopedPermissionGrants;
};

export type Agent = {
  agentLabel?: Maybe<Scalars['String']['output']>;
  codeServerStates: Array<CloudServerState>;
  errors: Array<TimestampedError>;
  id: Scalars['String']['output'];
  lastHeartbeatTime: Scalars['Float']['output'];
  metadata: Array<AgentMetadata>;
  runWorkerStates: Array<RunWorkerState>;
  status: AgentStatus;
};

export type AgentMetadata = {
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum AgentStatus {
  NotRunning = 'NOT_RUNNING',
  Running = 'RUNNING'
}

export type AgentTokenOrError = DagsterCloudAgentToken | PythonError | UnauthorizedError;

export type AgentTokensOrError = DagsterCloudAgentTokens | PythonError | UnauthorizedError;

export type AlertConfiguration = {
  emailAddresses: Array<Maybe<Scalars['String']['output']>>;
  pipelineName: Scalars['String']['output'];
};

export type AlertFailureEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type AlertPoliciesAsDocument = {
  document?: Maybe<Scalars['GenericScalar']['output']>;
};

export type AlertPoliciesSchema = {
  /** Fetch all the named config types that are in the alert policies config document schema. */
  allConfigTypes: Array<ConfigType>;
  /** Fetch the root config type for the alert policies config document. */
  rootConfigType: ConfigType;
};

export type AlertPolicy = {
  alertTargets: Array<AlertTarget>;
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  eventTypes: Array<AlertPolicyEventType>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  notificationService: AlertPolicyNotification;
  tags?: Maybe<Array<AlertPolicyTag>>;
};

export enum AlertPolicyEventType {
  AgentUnavailable = 'AGENT_UNAVAILABLE',
  AssetCheckExecutionFailure = 'ASSET_CHECK_EXECUTION_FAILURE',
  AssetCheckPassed = 'ASSET_CHECK_PASSED',
  AssetCheckSeverityError = 'ASSET_CHECK_SEVERITY_ERROR',
  AssetCheckSeverityWarn = 'ASSET_CHECK_SEVERITY_WARN',
  AssetMaterializationFailure = 'ASSET_MATERIALIZATION_FAILURE',
  AssetMaterializationSuccess = 'ASSET_MATERIALIZATION_SUCCESS',
  AssetOverdue = 'ASSET_OVERDUE',
  CodeLocationError = 'CODE_LOCATION_ERROR',
  InsightsConsumptionExceeded = 'INSIGHTS_CONSUMPTION_EXCEEDED',
  JobFailure = 'JOB_FAILURE',
  JobLongRunning = 'JOB_LONG_RUNNING',
  JobSuccess = 'JOB_SUCCESS',
  TickFailure = 'TICK_FAILURE'
}

export type AlertPolicyNotification = EmailAlertPolicyNotification | EmailOwnersAlertPolicyNotification | MicrosoftTeamsAlertPolicyNotification | PagerdutyAlertPolicyNotification | SlackAlertPolicyNotification;

export type AlertPolicyTag = {
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type AlertStartEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type AlertSuccessEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type AlertTarget = AssetGroupTarget | AssetKeyTarget | CreditLimitTarget | InsightsAssetGroupThresholdTarget | InsightsAssetThresholdTarget | InsightsDeploymentThresholdTarget | InsightsJobThresholdTarget | LongRunningJobThresholdTarget;

export type AnomalyDetectionFailure = {
  message: Scalars['String']['output'];
};

export type AnomalyDetectionResult = AnomalyDetectionFailure | AnomalyDetectionSuccess | PythonError;

export type AnomalyDetectionSuccess = {
  response: Scalars['GenericScalar']['output'];
};

export type ApiTokensOrError = DagsterCloudApiTokens | PythonError | UnauthorizedError;

export type ArrayConfigType = ConfigType & WrappingConfigType & {
  description?: Maybe<Scalars['String']['output']>;
  isSelector: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  ofType: ConfigType;
  /**
   *
   * This is an odd and problematic field. It recursively goes down to
   * get all the types contained within a type. The case where it is horrible
   * are dictionaries and it recurses all the way down to the leaves. This means
   * that in a case where one is fetching all the types and then all the inner
   * types keys for those types, we are returning O(N^2) type keys, which
   * can cause awful performance for large schemas. When you have access
   * to *all* the types, you should instead only use the type_param_keys
   * field for closed generic types and manually navigate down the to
   * field types client-side.
   *
   * Where it is useful is when you are fetching types independently and
   * want to be able to render them, but without fetching the entire schema.
   *
   * We use this capability when rendering the sidebar.
   *
   */
  recursiveConfigTypes: Array<ConfigType>;
  /**
   *
   * This returns the keys for type parameters of any closed generic type,
   * (e.g. List, Optional). This should be used for reconstructing and
   * navigating the full schema client-side and not innerTypes.
   *
   */
  typeParamKeys: Array<Scalars['String']['output']>;
};

export type Asset = {
  assetMaterializations: Array<MaterializationEvent>;
  assetObservations: Array<ObservationEvent>;
  definition?: Maybe<AssetNode>;
  id: Scalars['String']['output'];
  key: AssetKey;
};


export type AssetAssetMaterializationsArgs = {
  afterTimestampMillis?: InputMaybe<Scalars['String']['input']>;
  beforeTimestampMillis?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  partitionInLast?: InputMaybe<Scalars['Int']['input']>;
  partitions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type AssetAssetObservationsArgs = {
  afterTimestampMillis?: InputMaybe<Scalars['String']['input']>;
  beforeTimestampMillis?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  partitionInLast?: InputMaybe<Scalars['Int']['input']>;
  partitions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssetBackfillData = {
  assetBackfillStatuses: Array<AssetBackfillStatus>;
  rootTargetedPartitions?: Maybe<AssetBackfillTargetPartitions>;
};

export type AssetBackfillPreviewParams = {
  assetSelection: Array<AssetKeyInput>;
  partitionNames: Array<Scalars['String']['input']>;
};

export type AssetBackfillStatus = AssetPartitionsStatusCounts | UnpartitionedAssetStatus;

export type AssetBackfillTargetPartitions = {
  partitionKeys?: Maybe<Array<Scalars['String']['output']>>;
  ranges?: Maybe<Array<PartitionKeyRange>>;
};

export type AssetCheck = {
  additionalAssetKeys: Array<AssetKey>;
  assetKey: AssetKey;
  blocking: Scalars['Boolean']['output'];
  canExecuteIndividually: AssetCheckCanExecuteIndividually;
  description?: Maybe<Scalars['String']['output']>;
  executionForLatestMaterialization?: Maybe<AssetCheckExecution>;
  jobNames: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum AssetCheckCanExecuteIndividually {
  CanExecute = 'CAN_EXECUTE',
  NeedsUserCodeUpgrade = 'NEEDS_USER_CODE_UPGRADE',
  RequiresMaterialization = 'REQUIRES_MATERIALIZATION'
}

export type AssetCheckEvaluation = {
  assetKey: AssetKey;
  checkName: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  metadataEntries: Array<MetadataEntry>;
  severity: AssetCheckSeverity;
  success: Scalars['Boolean']['output'];
  targetMaterialization?: Maybe<AssetCheckEvaluationTargetMaterializationData>;
  /** When the check evaluation was stored */
  timestamp: Scalars['Float']['output'];
};

export type AssetCheckEvaluationEvent = MessageEvent & StepEvent & {
  evaluation: AssetCheckEvaluation;
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type AssetCheckEvaluationPlannedEvent = MessageEvent & StepEvent & {
  assetKey: AssetKey;
  checkName: Scalars['String']['output'];
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type AssetCheckEvaluationTargetMaterializationData = {
  runId: Scalars['String']['output'];
  storageId: Scalars['Int']['output'];
  timestamp: Scalars['Float']['output'];
};

export type AssetCheckExecution = {
  evaluation?: Maybe<AssetCheckEvaluation>;
  id: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  status: AssetCheckExecutionResolvedStatus;
  stepKey?: Maybe<Scalars['String']['output']>;
  /** When the check run started */
  timestamp: Scalars['Float']['output'];
};

/** An enumeration. */
export enum AssetCheckExecutionResolvedStatus {
  ExecutionFailed = 'EXECUTION_FAILED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Skipped = 'SKIPPED',
  Succeeded = 'SUCCEEDED'
}

export type AssetCheckHandleInput = {
  assetKey: AssetKeyInput;
  name: Scalars['String']['input'];
};

export type AssetCheckNeedsAgentUpgradeError = Error & {
  message: Scalars['String']['output'];
};

export type AssetCheckNeedsMigrationError = Error & {
  message: Scalars['String']['output'];
};

export type AssetCheckNeedsUserCodeUpgrade = Error & {
  message: Scalars['String']['output'];
};

/**
 * Severity level for an AssetCheckResult.
 *
 *     - WARN: a potential issue with the asset
 *     - ERROR: a definite issue with the asset
 *
 *     Severity does not impact execution of the asset or downstream assets.
 *
 */
export enum AssetCheckSeverity {
  Error = 'ERROR',
  Warn = 'WARN'
}

export type AssetCheckhandle = {
  assetKey: AssetKey;
  name: Scalars['String']['output'];
};

export type AssetChecks = {
  checks: Array<AssetCheck>;
};

export type AssetChecksOrError = AssetCheckNeedsAgentUpgradeError | AssetCheckNeedsMigrationError | AssetCheckNeedsUserCodeUpgrade | AssetChecks;

export type AssetConditionEvaluation = {
  evaluationNodes: Array<AssetConditionEvaluationNode>;
  rootUniqueId: Scalars['String']['output'];
};

export type AssetConditionEvaluationNode = PartitionedAssetConditionEvaluationNode | SpecificPartitionAssetConditionEvaluationNode | UnpartitionedAssetConditionEvaluationNode;

export type AssetConditionEvaluationRecord = {
  assetKey: AssetKey;
  endTimestamp?: Maybe<Scalars['Float']['output']>;
  evaluation: AssetConditionEvaluation;
  evaluationId: Scalars['Int']['output'];
  evaluationNodes: Array<AutomationConditionEvaluationNode>;
  id: Scalars['ID']['output'];
  isLegacy: Scalars['Boolean']['output'];
  numRequested: Scalars['Int']['output'];
  rootUniqueId: Scalars['String']['output'];
  runIds: Array<Scalars['String']['output']>;
  startTimestamp?: Maybe<Scalars['Float']['output']>;
  timestamp: Scalars['Float']['output'];
};

export type AssetConditionEvaluationRecords = {
  records: Array<AssetConditionEvaluationRecord>;
};

export type AssetConditionEvaluationRecordsOrError = AssetConditionEvaluationRecords | AutoMaterializeAssetEvaluationNeedsMigrationError;

/** An enumeration. */
export enum AssetConditionEvaluationStatus {
  False = 'FALSE',
  Skipped = 'SKIPPED',
  True = 'TRUE'
}

export type AssetConnection = {
  cursor?: Maybe<Scalars['String']['output']>;
  nodes: Array<Asset>;
};

/**
 * What change an asset has undergone between two deployments. Used
 *     in distinguishing asset definition changes in branch deployment and
 *     in subsequent other deployments.
 *
 */
export enum AssetDefinitionChangeType {
  CodeVersion = 'CODE_VERSION',
  Dependencies = 'DEPENDENCIES',
  Metadata = 'METADATA',
  New = 'NEW',
  PartitionsDefinition = 'PARTITIONS_DEFINITION',
  Removed = 'REMOVED',
  Tags = 'TAGS'
}

export type AssetDependency = {
  asset: AssetNode;
  inputName: Scalars['String']['output'];
  partitionMapping?: Maybe<PartitionMapping>;
};

export type AssetDiff = {
  changeTypes: Array<AssetDefinitionChangeType>;
};

export type AssetDiffHistoryEntry = {
  assetKey: AssetKey;
  codeLocationDataUploadTimestamp: Scalars['Float']['output'];
  diffSinceLastLoad?: Maybe<AssetDiff>;
  gitCommitHash?: Maybe<Scalars['String']['output']>;
  lastLoadGitCommitHash?: Maybe<Scalars['String']['output']>;
  locationName: Scalars['String']['output'];
};

/** The event type of an asset event. */
export enum AssetEventType {
  AssetMaterialization = 'ASSET_MATERIALIZATION',
  AssetObservation = 'ASSET_OBSERVATION'
}

export type AssetFreshnessInfo = {
  currentLagMinutes?: Maybe<Scalars['Float']['output']>;
  currentMinutesLate?: Maybe<Scalars['Float']['output']>;
  latestMaterializationMinutesLate?: Maybe<Scalars['Float']['output']>;
};

export type AssetGroup = {
  assetKeys: Array<AssetKey>;
  groupName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type AssetGroupReportingMetricsFilter = {
  assetGroups?: InputMaybe<Array<InputMaybe<QualifiedAssetGroup>>>;
  codeLocations?: InputMaybe<Array<InputMaybe<RepositoryCodeLocation>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * This type represents the fields necessary to identify
 *         an asset group.
 */
export type AssetGroupSelector = {
  groupName: Scalars['String']['input'];
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
};

export type AssetGroupTarget = {
  assetGroup: Scalars['String']['output'];
  locationName: Scalars['String']['output'];
  repoName?: Maybe<Scalars['String']['output']>;
};

export type AssetKey = {
  path: Array<Scalars['String']['output']>;
};

export type AssetKeyInput = {
  path: Array<Scalars['String']['input']>;
};

export type AssetKeyTarget = {
  assetKey: AssetKey;
};

export type AssetLatestInfo = {
  assetKey: AssetKey;
  id: Scalars['ID']['output'];
  inProgressRunIds: Array<Scalars['String']['output']>;
  latestMaterialization?: Maybe<MaterializationEvent>;
  latestRun?: Maybe<Run>;
  unstartedRunIds: Array<Scalars['String']['output']>;
};

export type AssetLineageInfo = {
  assetKey: AssetKey;
  partitions: Array<Scalars['String']['output']>;
};

export type AssetMaterializationPlannedEvent = MessageEvent & RunEvent & {
  assetKey?: Maybe<AssetKey>;
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  runOrError: RunOrError;
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type AssetMetadataEntry = MetadataEntry & {
  assetKey: AssetKey;
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type AssetMetricInputs = {
  assetGroup?: InputMaybe<Scalars['String']['input']>;
  assetKey: Scalars['String']['input'];
  metricValues?: InputMaybe<Array<InputMaybe<MetricEntryInput>>>;
  partition?: InputMaybe<Scalars['String']['input']>;
};

export type AssetNode = {
  assetChecksOrError: AssetChecksOrError;
  assetKey: AssetKey;
  assetMaterializationUsedData: Array<MaterializationUpstreamDataVersion>;
  assetMaterializations: Array<MaterializationEvent>;
  assetObservations: Array<ObservationEvent>;
  assetPartitionStatuses: AssetPartitionStatuses;
  autoMaterializePolicy?: Maybe<AutoMaterializePolicy>;
  backfillPolicy?: Maybe<BackfillPolicy>;
  changedReasons: Array<ChangeReason>;
  computeKind?: Maybe<Scalars['String']['output']>;
  configField?: Maybe<ConfigTypeField>;
  currentAutoMaterializeEvaluationId?: Maybe<Scalars['Int']['output']>;
  dataVersion?: Maybe<Scalars['String']['output']>;
  dataVersionByPartition: Array<Maybe<Scalars['String']['output']>>;
  dependedBy: Array<AssetDependency>;
  dependedByKeys: Array<AssetKey>;
  dependencies: Array<AssetDependency>;
  dependencyKeys: Array<AssetKey>;
  description?: Maybe<Scalars['String']['output']>;
  freshnessInfo?: Maybe<AssetFreshnessInfo>;
  freshnessPolicy?: Maybe<FreshnessPolicy>;
  graphName?: Maybe<Scalars['String']['output']>;
  groupName: Scalars['String']['output'];
  hasAssetChecks: Scalars['Boolean']['output'];
  hasMaterializePermission: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isExecutable: Scalars['Boolean']['output'];
  isMaterializable: Scalars['Boolean']['output'];
  isObservable: Scalars['Boolean']['output'];
  isPartitioned: Scalars['Boolean']['output'];
  jobNames: Array<Scalars['String']['output']>;
  jobs: Array<Pipeline>;
  latestMaterializationByPartition: Array<Maybe<MaterializationEvent>>;
  latestRunForPartition?: Maybe<Run>;
  metadataEntries: Array<MetadataEntry>;
  op?: Maybe<SolidDefinition>;
  opName?: Maybe<Scalars['String']['output']>;
  opNames: Array<Scalars['String']['output']>;
  opVersion?: Maybe<Scalars['String']['output']>;
  owners: Array<AssetOwner>;
  partitionDefinition?: Maybe<PartitionDefinition>;
  partitionKeys: Array<Scalars['String']['output']>;
  partitionKeysByDimension: Array<DimensionPartitionKeys>;
  partitionStats?: Maybe<PartitionStats>;
  repository: Repository;
  requiredResources: Array<ResourceRequirement>;
  staleCauses: Array<StaleCause>;
  staleCausesByPartition?: Maybe<Array<Array<StaleCause>>>;
  staleStatus?: Maybe<StaleStatus>;
  staleStatusByPartition: Array<StaleStatus>;
  tags: Array<DefinitionTag>;
  targetingInstigators: Array<Instigator>;
  type?: Maybe<DagsterType>;
};


export type AssetNodeAssetChecksOrErrorArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  pipeline?: InputMaybe<PipelineSelector>;
};


export type AssetNodeAssetMaterializationUsedDataArgs = {
  timestampMillis: Scalars['String']['input'];
};


export type AssetNodeAssetMaterializationsArgs = {
  beforeTimestampMillis?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  partitions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type AssetNodeAssetObservationsArgs = {
  beforeTimestampMillis?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  partitions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type AssetNodeDataVersionArgs = {
  partition?: InputMaybe<Scalars['String']['input']>;
};


export type AssetNodeDataVersionByPartitionArgs = {
  partitions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type AssetNodeLatestMaterializationByPartitionArgs = {
  partitions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type AssetNodeLatestRunForPartitionArgs = {
  partition: Scalars['String']['input'];
};


export type AssetNodePartitionKeysByDimensionArgs = {
  endIdx?: InputMaybe<Scalars['Int']['input']>;
  startIdx?: InputMaybe<Scalars['Int']['input']>;
};


export type AssetNodeStaleCausesArgs = {
  partition?: InputMaybe<Scalars['String']['input']>;
};


export type AssetNodeStaleCausesByPartitionArgs = {
  partitions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type AssetNodeStaleStatusArgs = {
  partition?: InputMaybe<Scalars['String']['input']>;
};


export type AssetNodeStaleStatusByPartitionArgs = {
  partitions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssetNodeDefinitionCollision = {
  assetKey: AssetKey;
  repositories: Array<Repository>;
};

export type AssetNodeOrError = AssetNode | AssetNotFoundError;

export type AssetNotFoundError = Error & {
  message: Scalars['String']['output'];
};

export type AssetOrError = Asset | AssetNotFoundError;

export type AssetOwner = TeamAssetOwner | UserAssetOwner;

export type AssetPartitionRange = {
  assetKey: AssetKey;
  partitionRange?: Maybe<PartitionRange>;
};

export type AssetPartitionStatuses = DefaultPartitionStatuses | MultiPartitionStatuses | TimePartitionStatuses;

export type AssetPartitions = {
  assetKey: AssetKey;
  partitions?: Maybe<AssetBackfillTargetPartitions>;
};

export type AssetPartitionsStatusCounts = {
  assetKey: AssetKey;
  numPartitionsFailed: Scalars['Int']['output'];
  numPartitionsInProgress: Scalars['Int']['output'];
  numPartitionsMaterialized: Scalars['Int']['output'];
  numPartitionsTargeted: Scalars['Int']['output'];
};

export type AssetReportingMetricsFilter = {
  assetGroups?: InputMaybe<Array<InputMaybe<QualifiedAssetGroup>>>;
  assets?: InputMaybe<Array<InputMaybe<QualifiedAssetKey>>>;
  codeLocations?: InputMaybe<Array<InputMaybe<RepositoryCodeLocation>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetSelection = {
  assetKeys: Array<AssetKey>;
  assetSelectionString?: Maybe<Scalars['String']['output']>;
  assets: Array<Asset>;
  assetsOrError: AssetsOrError;
};

/** The output from deleting asset history. */
export type AssetWipeMutationResult = AssetNotFoundError | AssetWipeSuccess | PythonError | UnauthorizedError | UnsupportedOperationError;

/** Output indicating that asset history was deleted. */
export type AssetWipeSuccess = {
  assetPartitionRanges: Array<AssetPartitionRange>;
};

export type AssetsOrError = AssetConnection | PythonError;

export type AuditLogEntry = {
  authorAgentTokenId?: Maybe<Scalars['String']['output']>;
  authorUserEmail?: Maybe<Scalars['String']['output']>;
  branchDeploymentName?: Maybe<Scalars['String']['output']>;
  deploymentName?: Maybe<Scalars['String']['output']>;
  eventMetadata?: Maybe<Scalars['GenericScalar']['output']>;
  eventType: AuditLogEventType;
  id: Scalars['String']['output'];
  timestamp: Scalars['Float']['output'];
};

/** An enumeration. */
export enum AuditLogEventType {
  ChangeUserPermissions = 'CHANGE_USER_PERMISSIONS',
  CreateAgentToken = 'CREATE_AGENT_TOKEN',
  CreateDeployment = 'CREATE_DEPLOYMENT',
  CreateSecret = 'CREATE_SECRET',
  CreateUserToken = 'CREATE_USER_TOKEN',
  DeleteDeployment = 'DELETE_DEPLOYMENT',
  DeleteSecret = 'DELETE_SECRET',
  LogIn = 'LOG_IN',
  ModifyAlertPolicies = 'MODIFY_ALERT_POLICIES',
  RevokeAgentToken = 'REVOKE_AGENT_TOKEN',
  RevokeUserToken = 'REVOKE_USER_TOKEN',
  UpdateAgentTokenPermissions = 'UPDATE_AGENT_TOKEN_PERMISSIONS',
  UpdateSchedule = 'UPDATE_SCHEDULE',
  UpdateSecret = 'UPDATE_SECRET',
  UpdateSensor = 'UPDATE_SENSOR',
  UpdateSubscriptionPlan = 'UPDATE_SUBSCRIPTION_PLAN'
}

export type AuditLogFilters = {
  afterDatetime?: InputMaybe<Scalars['Float']['input']>;
  beforeDatetime?: InputMaybe<Scalars['Float']['input']>;
  deploymentNames?: InputMaybe<Array<Scalars['String']['input']>>;
  eventTypes?: InputMaybe<Array<AuditLogEventType>>;
  isBranchDeployment?: InputMaybe<Scalars['Boolean']['input']>;
  userEmails?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AuditLogQuery = {
  auditLogEntries?: Maybe<Array<AuditLogEntry>>;
  enabled: Scalars['Boolean']['output'];
};


export type AuditLogQueryAuditLogEntriesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AuditLogFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AutoMaterializeAssetEvaluationNeedsMigrationError = Error & {
  message: Scalars['String']['output'];
};

export type AutoMaterializeAssetEvaluationRecord = {
  assetKey: AssetKey;
  evaluationId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  numDiscarded: Scalars['Int']['output'];
  numRequested: Scalars['Int']['output'];
  numSkipped: Scalars['Int']['output'];
  rules?: Maybe<Array<AutoMaterializeRule>>;
  rulesWithRuleEvaluations: Array<AutoMaterializeRuleWithRuleEvaluations>;
  runIds: Array<Scalars['String']['output']>;
  timestamp: Scalars['Float']['output'];
};

export type AutoMaterializeAssetEvaluationRecords = {
  records: Array<AutoMaterializeAssetEvaluationRecord>;
};

export type AutoMaterializeAssetEvaluationRecordsOrError = AutoMaterializeAssetEvaluationNeedsMigrationError | AutoMaterializeAssetEvaluationRecords;

/**
 * Represents the set of results of the auto-materialize logic.
 *
 *     MATERIALIZE: The asset should be materialized by a run kicked off on this tick
 *     SKIP: The asset should not be materialized by a run kicked off on this tick, because future
 *         ticks are expected to materialize it.
 *     DISCARD: The asset should not be materialized by a run kicked off on this tick, but future
 *         ticks are not expected to materialize it.
 *
 */
export enum AutoMaterializeDecisionType {
  Discard = 'DISCARD',
  Materialize = 'MATERIALIZE',
  Skip = 'SKIP'
}

export type AutoMaterializePolicy = {
  maxMaterializationsPerMinute?: Maybe<Scalars['Int']['output']>;
  policyType: AutoMaterializePolicyType;
  rules: Array<AutoMaterializeRule>;
};

/** An enumeration. */
export enum AutoMaterializePolicyType {
  Eager = 'EAGER',
  Lazy = 'LAZY'
}

export type AutoMaterializeRule = {
  className: Scalars['String']['output'];
  decisionType: AutoMaterializeDecisionType;
  description: Scalars['String']['output'];
};

export type AutoMaterializeRuleEvaluation = {
  evaluationData?: Maybe<AutoMaterializeRuleEvaluationData>;
  partitionKeysOrError?: Maybe<PartitionKeysOrError>;
};

export type AutoMaterializeRuleEvaluationData = ParentMaterializedRuleEvaluationData | TextRuleEvaluationData | WaitingOnKeysRuleEvaluationData;

export type AutoMaterializeRuleWithRuleEvaluations = {
  rule: AutoMaterializeRule;
  ruleEvaluations: Array<AutoMaterializeRuleEvaluation>;
};

export type AutomationConditionEvaluationNode = {
  childUniqueIds: Array<Scalars['String']['output']>;
  endTimestamp?: Maybe<Scalars['Float']['output']>;
  expandedLabel: Array<Scalars['String']['output']>;
  isPartitioned: Scalars['Boolean']['output'];
  numCandidates?: Maybe<Scalars['Int']['output']>;
  numTrue: Scalars['Int']['output'];
  startTimestamp?: Maybe<Scalars['Float']['output']>;
  uniqueId: Scalars['String']['output'];
  userLabel?: Maybe<Scalars['String']['output']>;
};

export type AvailableGitHubInstallation = {
  installationId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  settingsUrl: Scalars['String']['output'];
};

export type BackfillNotFoundError = Error & {
  backfillId: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type BackfillPolicy = {
  description: Scalars['String']['output'];
  maxPartitionsPerRun?: Maybe<Scalars['Int']['output']>;
  policyType: BackfillPolicyType;
};

/** An enumeration. */
export enum BackfillPolicyType {
  MultiRun = 'MULTI_RUN',
  SingleRun = 'SINGLE_RUN'
}

export type BoolMetadataEntry = MetadataEntry & {
  boolValue?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type BranchAgainstRepo = {
  branchDeploymentName?: Maybe<Scalars['String']['output']>;
  branchDeploymentStatus?: Maybe<DeployingCode>;
  githubUrl?: Maybe<Scalars['String']['output']>;
  locationEntries: Array<WorkspaceLocationEntry>;
  locationName?: Maybe<Scalars['String']['output']>;
  pullRequestData?: Maybe<PullRequestData>;
  repoName?: Maybe<Scalars['String']['output']>;
  repoUrl?: Maybe<Scalars['String']['output']>;
  state: GitHubStepState;
  step: BranchAgainstRepoStep;
};

export enum BranchAgainstRepoStep {
  MergingPr = 'MERGING_PR',
  PrCreated = 'PR_CREATED',
  PrMerged = 'PR_MERGED',
  ScaffoldingPr = 'SCAFFOLDING_PR'
}

export type BranchDeploymentGitMetadata = {
  branchName: Scalars['String']['output'];
  branchUrl?: Maybe<Scalars['String']['output']>;
  pullRequestNumber?: Maybe<Scalars['String']['output']>;
  pullRequestStatus?: Maybe<PullRequestStatus>;
  pullRequestUrl?: Maybe<Scalars['String']['output']>;
  repoName: Scalars['String']['output'];
};

export type BranchDeploymentsConnection = {
  nodes: Array<DagsterCloudDeployment>;
};

export enum BulkActionStatus {
  Canceled = 'CANCELED',
  Canceling = 'CANCELING',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Requested = 'REQUESTED'
}

export type CancelBackfillResult = CancelBackfillSuccess | PythonError | UnauthorizedError;

export type CancelBackfillSuccess = {
  backfillId: Scalars['String']['output'];
};

/**
 * This error should only be raised if a user attempts to remove all organization
 * admins from their org.
 */
export type CantRemoveAllAdminsError = Error & {
  message: Scalars['String']['output'];
};

export type CapturedLogs = {
  cursor?: Maybe<Scalars['String']['output']>;
  logKey: Array<Scalars['String']['output']>;
  stderr?: Maybe<Scalars['String']['output']>;
  stdout?: Maybe<Scalars['String']['output']>;
};

export type CapturedLogsMetadata = {
  stderrDownloadUrl?: Maybe<Scalars['String']['output']>;
  stderrLocation?: Maybe<Scalars['String']['output']>;
  stdoutDownloadUrl?: Maybe<Scalars['String']['output']>;
  stdoutLocation?: Maybe<Scalars['String']['output']>;
};

export type CatalogView = {
  creatorId: Scalars['BigInt']['output'];
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPrivate: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  selection: CatalogViewSelection;
};

export type CatalogViewSelection = {
  codeLocations: Array<CodeLocation>;
  computeKindTags: Array<Scalars['String']['output']>;
  groups: Array<DefinitionGroup>;
  owners: Array<AssetOwner>;
  storageKindTags: Array<DefinitionTag>;
  tags: Array<DefinitionTag>;
};

export type CatalogViewSelectionInput = {
  codeLocations: Array<CodeLocationAssetSelectionInput>;
  computeKindTags: Array<Scalars['String']['input']>;
  groups: Array<GroupAssetSelectionInput>;
  owners: Array<OwnerAssetSelectionInput>;
  storageKindTags: Array<TagAssetSelectionInput>;
  tags: Array<TagAssetSelectionInput>;
};

/**
 * What change an asset has undergone between two deployments. Used
 *     in distinguishing asset definition changes in branch deployment and
 *     in subsequent other deployments.
 *
 */
export enum ChangeReason {
  CodeVersion = 'CODE_VERSION',
  Dependencies = 'DEPENDENCIES',
  Metadata = 'METADATA',
  New = 'NEW',
  PartitionsDefinition = 'PARTITIONS_DEFINITION',
  Removed = 'REMOVED',
  Tags = 'TAGS'
}

export type ClaimedConcurrencySlot = {
  runId: Scalars['String']['output'];
  stepKey: Scalars['String']['output'];
};

/** An enumeration. */
export enum CliEventType {
  Build = 'BUILD',
  Deploy = 'DEPLOY',
  Upload = 'UPLOAD'
}

export type CloneDbtRepoResult = CloneDbtRepoSuccess | GitHubError | PythonError | SetupRepoError | UnauthorizedError;

export type CloneDbtRepoSuccess = {
  repoUrl: Scalars['String']['output'];
};

/** An enumeration. */
export enum CloudCodeServerStatus {
  Failed = 'FAILED',
  Running = 'RUNNING',
  Starting = 'STARTING'
}

export type CloudMutation = {
  /** Adds a partition to a dynamic partition set. */
  addDynamicPartition: AddDynamicPartitionResult;
  addLocation: AddLocationMutationResult;
  addLocationFromDocument: AddLocationFromDocumentMutationResult;
  addMemberToTeam: AddMemberToTeamMutationResult;
  addOrUpdateLocation: AddOrUpdateLocationMutationResult;
  addOrUpdateLocationFromDocument: AddOrUpdateLocationFromDocumentMutationResult;
  addUserToOrganization: AddUserToOrganizationMutationResult;
  anomalyDetectionInference: AnomalyDetectionResult;
  /** Cancels a set of partition backfill runs. */
  cancelPartitionBackfill: CancelBackfillResult;
  cancelStripeSubscription: UpdateStripeSubscriptionMutationResult;
  cloneDBTRepo: CloneDbtRepoResult;
  createAgentToken: CreateAgentTokenResult;
  createApiToken: CreateApiTokenResult;
  createDeployment: CreateDeploymentResult;
  createOrUpdateAgentPermissions: ModifyAgentTokenResult;
  createOrUpdateAlertPolicyFromDocument: CreateOrUpdateAlertPolicyFromDocumentMutationResult;
  createOrUpdateBranchDeployment: CreateDeploymentResult;
  createOrUpdateCatalogView: CreateOrUpdateCatalogViewMutationResult;
  createOrUpdateCostMultiplier: UpdateMetricCustomizationsResult;
  createOrUpdateMetrics: CreateOrUpdateMetricsResult;
  createOrUpdateTeam: CreateOrUpdateTeamMutationResult;
  createOrUpdateTeamPermission: CreateOrUpdateTeamPermissionMutationResult;
  createOrUpdateUserPermissions: DagsterCloudUserWithScopedPermissionGrantsOrError;
  createSSHKey: CreateSshKeyResult;
  createSecret: CreateOrUpdateSecretResult;
  createTemplateRepo: CreateTemplateRepoResult;
  createUserToken: CreateUserTokenResult;
  deleteAlertPolicy: DeleteAlertPolicyMutationResult;
  deleteCatalogView: CreateOrUpdateCatalogViewMutationResult;
  /** Sets the concurrency limit for a given concurrency key. */
  deleteConcurrencyLimit: Scalars['Boolean']['output'];
  deleteDeployment: DeleteDeploymentResult;
  /** Deletes partitions from a dynamic partition set. */
  deleteDynamicPartitions: DeleteDynamicPartitionsResult;
  deleteLocation: DeleteLocationMutationResult;
  /** Deletes a run from storage. */
  deletePipelineRun: DeletePipelineRunResult;
  /** Deletes a run from storage. */
  deleteRun: DeletePipelineRunResult;
  deleteSecret: DeleteSecretResult;
  deleteTeam: DeleteTeamMutationResult;
  deselectInstallation: DeselectInstallationResult;
  dismissUserNuxChecklistEntry: UserNuxChecklistEntry;
  editAgentTokenDescription: EditDescAgentTokenResult;
  editApiTokenDescription: EditApiTokenDescriptionResult;
  editUserTokenDescription: EditDescUserTokenResult;
  /** Frees concurrency slots. */
  freeConcurrencySlots: Scalars['Boolean']['output'];
  /** Frees the concurrency slots occupied by a specific run. */
  freeConcurrencySlotsForRun: Scalars['Boolean']['output'];
  generateServerlessPexUrl: Array<ServerlessPexUploadUrl>;
  /** [Experimental] Launches an express job run. */
  launchNonIsolatedRun: LaunchRunResult;
  /** Launches a set of partition backfill runs. */
  launchPartitionBackfill: LaunchBackfillResult;
  /** Launches a job run. */
  launchPipelineExecution: LaunchRunResult;
  /** Re-executes a job run. */
  launchPipelineReexecution: LaunchRunReexecutionResult;
  /** Launches a job run. */
  launchRun: LaunchRunResult;
  /** Re-executes a job run. */
  launchRunReexecution: LaunchRunReexecutionResult;
  /** Log telemetry about the Dagster instance. */
  logTelemetry: LogTelemetryMutationResult;
  markCliEvent?: Maybe<Scalars['String']['output']>;
  mergePullRequest: MergePullRequestResult;
  pingLocation: PingLocationMutationResult;
  reconcileAlertPoliciesFromDocument: ReconcileAlertPoliciesResult;
  reconcileLocations: ReconcileLocationsMutationResult;
  reconcileLocationsFromDocument: ReconcileLocationsFromDocumentMutationResult;
  /** Reloads a code location server. */
  reloadRepositoryLocation: ReloadRepositoryLocationMutationResult;
  /** Reloads the workspace and its code location servers. */
  reloadWorkspace: ReloadWorkspaceMutationResult;
  removeAgentPermissions: ModifyAgentTokenResult;
  removeMemberFromTeam: RemoveMemberFromTeamMutationResult;
  removeStripePaymentMethod: RemoveStripePaymentMethodMutationResult;
  removeTeamPermission: RemoveTeamPermissionMutationResult;
  removeUserFromOrganization: RemoveUserFromOrganizationMutationResult;
  removeUserPermissions: DagsterCloudUserWithScopedPermissionGrantsOrError;
  renameTeam: RenameTeamResult;
  /** Reports runless events for an asset or a subset of its partitions. */
  reportRunlessAssetEvents: ReportRunlessAssetEventsResult;
  resetLoadedCodeSetupStage: ResetLoadedCodeSetupStageSuccess;
  resetSchedule: ScheduleMutationResult;
  resetSensor: SensorOrError;
  restartGitCI: RestartGitHubActionsRunMutationResult;
  /** Retries a set of partition backfill runs. */
  resumePartitionBackfill: ResumeBackfillResult;
  revokeAgentToken: RevokeAgentTokenResult;
  revokeApiToken: RevokeApiTokenResult;
  revokeSSHKey: RevokeSshKeyResult;
  revokeUserToken: RevokeUserTokenResult;
  scaffoldDagsterInDbtRepo: ScaffoldDagsterInPullRequestResult;
  /** Enable a schedule to launch runs for a job based on external state change. */
  scheduleDryRun: ScheduleDryRunResult;
  selectInstallation: SelectInstallationResult;
  sendSampleNotification: SendSampleNotificationMutationResult;
  /** Enable a sensor to launch runs for a job based on external state change. */
  sensorDryRun: SensorDryRunResult;
  /** Toggle asset auto materializing on or off. */
  setAutoMaterializePaused: Scalars['Boolean']['output'];
  /** Sets the concurrency limit for a given concurrency key. */
  setConcurrencyLimit: Scalars['Boolean']['output'];
  /** Replace all existing settings with the new settings. */
  setDeploymentSettings: SetDeploymentSettingsResult;
  /** Store whether we've shown the nux to any user and they've dismissed or submitted it. */
  setNuxSeen: Scalars['Boolean']['output'];
  /** Replace all org existing settings with new settings. */
  setOrganizationSettings: SetOrganizationSettingsResult;
  setScimSyncEnabled: SetScimSyncEnabledResult;
  setSensorCursor: SensorOrError;
  shareFeedback: ShareFeedbackMutationResult;
  /** Shuts down a code location server. */
  shutdownRepositoryLocation: ShutdownRepositoryLocationMutationResult;
  startSchedule: ScheduleMutationResult;
  startSensor: SensorOrError;
  stopRunningSchedule: ScheduleMutationResult;
  stopSensor: StopSensorMutationResultOrError;
  stripeSetupIntent: StripeSetupIntentMutationResult;
  submitCostInformationForMetrics: CreateOrUpdateMetricsResult;
  syncSecrets: SyncSecretsResult;
  /** Terminates a run. */
  terminatePipelineExecution: TerminateRunResult;
  /** Terminates a run. */
  terminateRun: TerminateRunResult;
  /** Terminates a set of runs given their run IDs. */
  terminateRuns: TerminateRunsResultOrError;
  /** Mark an onboarding entry as skipped, or not skipped. */
  toggleOnboardingChecklistEntry: ToggleOnboardingChecklistEntryResult;
  /** Change a deployment from HYBRID to SERVERLESS or vice versa. */
  updateDeploymentAgentType: UpdateDeploymentAgentTypeResult;
  /** Merge new settings with existing settings. */
  updateDeploymentSettings: SetDeploymentSettingsResult;
  updateLocation: UpdateLocationMutationResult;
  updateLocationFromDocument: UpdateLocationFromDocumentMutationResult;
  updateMetricCustomizations: UpdateMetricCustomizationsResult;
  /** Merge new org settings with existing settings. */
  updateOrganizationSettings: SetOrganizationSettingsResult;
  updateReportingDeploymentSettings: UpdateReportingDeploymentSettingsResult;
  updateSecret: CreateOrUpdateSecretResult;
  updateStripeCustomer: UpdateStripeCustomerMutationResult;
  updateStripeCustomerTaxIDs: UpdateStripeCustomerTaxIDsMutationResult;
  updateStripeSubscription: UpdateStripeSubscriptionMutationResult;
  useExistingRepo: CreateTemplateRepoResult;
  /** Deletes asset history from storage. */
  wipeAssets: AssetWipeMutationResult;
};


export type CloudMutationAddDynamicPartitionArgs = {
  partitionKey: Scalars['String']['input'];
  partitionsDefName: Scalars['String']['input'];
  repositorySelector: RepositorySelector;
};


export type CloudMutationAddLocationArgs = {
  location: LocationSelector;
};


export type CloudMutationAddLocationFromDocumentArgs = {
  document: Scalars['GenericScalar']['input'];
};


export type CloudMutationAddMemberToTeamArgs = {
  memberId: Scalars['Int']['input'];
  teamId: Scalars['String']['input'];
};


export type CloudMutationAddOrUpdateLocationArgs = {
  location: LocationSelector;
};


export type CloudMutationAddOrUpdateLocationFromDocumentArgs = {
  document: Scalars['GenericScalar']['input'];
};


export type CloudMutationAddUserToOrganizationArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationAnomalyDetectionInferenceArgs = {
  modelVersion: Scalars['String']['input'];
  params: Scalars['GenericScalar']['input'];
};


export type CloudMutationCancelPartitionBackfillArgs = {
  backfillId: Scalars['String']['input'];
};


export type CloudMutationCancelStripeSubscriptionArgs = {
  cancellationNote?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationCloneDbtRepoArgs = {
  gitProvider?: InputMaybe<GitProvider>;
  isPrivate: Scalars['Boolean']['input'];
  repoName: Scalars['String']['input'];
};


export type CloudMutationCreateAgentTokenArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationCreateApiTokenArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  tokenType: DagsterCloudApiTokenType;
};


export type CloudMutationCreateDeploymentArgs = {
  deploymentAgentType?: InputMaybe<DeploymentAgentType>;
  deploymentName: Scalars['String']['input'];
  inheritPermsDeploymentId?: InputMaybe<Scalars['Int']['input']>;
};


export type CloudMutationCreateOrUpdateAgentPermissionsArgs = {
  agentPermission: CreateOrUpdateCloudAgentPermissionsInput;
};


export type CloudMutationCreateOrUpdateAlertPolicyFromDocumentArgs = {
  document: Scalars['GenericScalar']['input'];
};


export type CloudMutationCreateOrUpdateBranchDeploymentArgs = {
  baseDeploymentName?: InputMaybe<Scalars['String']['input']>;
  branchData: CreateOrUpdateBranchDeploymentInput;
  commit: DeploymentCommitInput;
};


export type CloudMutationCreateOrUpdateCatalogViewArgs = {
  description: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPrivate: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  selection: CatalogViewSelectionInput;
};


export type CloudMutationCreateOrUpdateCostMultiplierArgs = {
  costMultiplier?: InputMaybe<Scalars['Float']['input']>;
  metricKey: Scalars['String']['input'];
};


export type CloudMutationCreateOrUpdateMetricsArgs = {
  metrics?: InputMaybe<Array<InputMaybe<MetricInputs>>>;
};


export type CloudMutationCreateOrUpdateTeamArgs = {
  name: Scalars['String']['input'];
};


export type CloudMutationCreateOrUpdateTeamPermissionArgs = {
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
  deploymentScope: PermissionDeploymentScope;
  grant: PermissionGrant;
  locationGrants?: InputMaybe<Array<InputMaybe<LocationScopedGrantInput>>>;
  teamId: Scalars['String']['input'];
};


export type CloudMutationCreateOrUpdateUserPermissionsArgs = {
  userPermission: CreateOrUpdateCloudUserPermissionsInput;
};


export type CloudMutationCreateSshKeyArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  publicKey: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type CloudMutationCreateSecretArgs = {
  locationNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scopes: SecretScopesInput;
  secretName: Scalars['String']['input'];
  secretValue: Scalars['String']['input'];
};


export type CloudMutationCreateTemplateRepoArgs = {
  gitProvider?: InputMaybe<GitProvider>;
  isPrivate: Scalars['Boolean']['input'];
  locationName?: InputMaybe<Scalars['String']['input']>;
  repoName: Scalars['String']['input'];
  templateUrl?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationCreateUserTokenArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
};


export type CloudMutationDeleteAlertPolicyArgs = {
  alertPolicyName: Scalars['String']['input'];
};


export type CloudMutationDeleteCatalogViewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationDeleteConcurrencyLimitArgs = {
  concurrencyKey: Scalars['String']['input'];
};


export type CloudMutationDeleteDeploymentArgs = {
  deploymentId: Scalars['Int']['input'];
};


export type CloudMutationDeleteDynamicPartitionsArgs = {
  partitionKeys: Array<Scalars['String']['input']>;
  partitionsDefName: Scalars['String']['input'];
  repositorySelector: RepositorySelector;
};


export type CloudMutationDeleteLocationArgs = {
  locationName: Scalars['String']['input'];
};


export type CloudMutationDeletePipelineRunArgs = {
  runId: Scalars['String']['input'];
};


export type CloudMutationDeleteRunArgs = {
  runId: Scalars['String']['input'];
};


export type CloudMutationDeleteSecretArgs = {
  secretId: Scalars['String']['input'];
};


export type CloudMutationDeleteTeamArgs = {
  teamId: Scalars['String']['input'];
};


export type CloudMutationDismissUserNuxChecklistEntryArgs = {
  entryKey: Scalars['String']['input'];
};


export type CloudMutationEditAgentTokenDescriptionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  tokenId: Scalars['Int']['input'];
};


export type CloudMutationEditApiTokenDescriptionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  tokenId: Scalars['ID']['input'];
  tokenType: DagsterCloudApiTokenType;
};


export type CloudMutationEditUserTokenDescriptionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  tokenId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type CloudMutationFreeConcurrencySlotsArgs = {
  runId: Scalars['String']['input'];
  stepKey?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationFreeConcurrencySlotsForRunArgs = {
  runId: Scalars['String']['input'];
};


export type CloudMutationGenerateServerlessPexUrlArgs = {
  filenames: Array<Scalars['String']['input']>;
  method: S3ClientMethod;
};


export type CloudMutationLaunchNonIsolatedRunArgs = {
  executionParams: ExecutionParams;
};


export type CloudMutationLaunchPartitionBackfillArgs = {
  backfillParams: LaunchBackfillParams;
};


export type CloudMutationLaunchPipelineExecutionArgs = {
  executionParams: ExecutionParams;
};


export type CloudMutationLaunchPipelineReexecutionArgs = {
  executionParams?: InputMaybe<ExecutionParams>;
  reexecutionParams?: InputMaybe<ReexecutionParams>;
};


export type CloudMutationLaunchRunArgs = {
  executionParams: ExecutionParams;
};


export type CloudMutationLaunchRunReexecutionArgs = {
  executionParams?: InputMaybe<ExecutionParams>;
  reexecutionParams?: InputMaybe<ReexecutionParams>;
};


export type CloudMutationLogTelemetryArgs = {
  action: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientTime: Scalars['String']['input'];
  metadata: Scalars['String']['input'];
};


export type CloudMutationMarkCliEventArgs = {
  durationSeconds: Scalars['Float']['input'];
  eventType: CliEventType;
  message?: InputMaybe<Scalars['String']['input']>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type CloudMutationMergePullRequestArgs = {
  gitProvider?: InputMaybe<GitProvider>;
  pullRequestNumber: Scalars['Int']['input'];
  repoName: Scalars['String']['input'];
};


export type CloudMutationPingLocationArgs = {
  locationName: Scalars['String']['input'];
};


export type CloudMutationReconcileAlertPoliciesFromDocumentArgs = {
  document: Scalars['GenericScalar']['input'];
};


export type CloudMutationReconcileLocationsArgs = {
  locations: Array<InputMaybe<LocationSelector>>;
};


export type CloudMutationReconcileLocationsFromDocumentArgs = {
  document: Scalars['GenericScalar']['input'];
};


export type CloudMutationReloadRepositoryLocationArgs = {
  repositoryLocationName: Scalars['String']['input'];
};


export type CloudMutationRemoveAgentPermissionsArgs = {
  agentPermission: RemoveAgentPermissionsInput;
};


export type CloudMutationRemoveMemberFromTeamArgs = {
  memberId: Scalars['Int']['input'];
  teamId: Scalars['String']['input'];
};


export type CloudMutationRemoveStripePaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};


export type CloudMutationRemoveTeamPermissionArgs = {
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
  deploymentScope: PermissionDeploymentScope;
  teamId: Scalars['String']['input'];
};


export type CloudMutationRemoveUserFromOrganizationArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationRemoveUserPermissionsArgs = {
  userPermission: RemoveUserPermissionsInput;
};


export type CloudMutationRenameTeamArgs = {
  name: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};


export type CloudMutationReportRunlessAssetEventsArgs = {
  eventParams: ReportRunlessAssetEventsParams;
};


export type CloudMutationResetScheduleArgs = {
  scheduleSelector: ScheduleSelector;
};


export type CloudMutationResetSensorArgs = {
  sensorSelector: SensorSelector;
};


export type CloudMutationRestartGitCiArgs = {
  restartBranchCI?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CloudMutationResumePartitionBackfillArgs = {
  backfillId: Scalars['String']['input'];
};


export type CloudMutationRevokeAgentTokenArgs = {
  tokenId: Scalars['Int']['input'];
};


export type CloudMutationRevokeApiTokenArgs = {
  tokenId: Scalars['ID']['input'];
  tokenType: DagsterCloudApiTokenType;
};


export type CloudMutationRevokeSshKeyArgs = {
  sshKeyId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type CloudMutationRevokeUserTokenArgs = {
  tokenId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type CloudMutationScaffoldDagsterInDbtRepoArgs = {
  codeLocationName?: InputMaybe<Scalars['String']['input']>;
  gitProvider?: InputMaybe<GitProvider>;
  repoName: Scalars['String']['input'];
};


export type CloudMutationScheduleDryRunArgs = {
  selectorData: ScheduleSelector;
  timestamp?: InputMaybe<Scalars['Float']['input']>;
};


export type CloudMutationSelectInstallationArgs = {
  accountName: Scalars['String']['input'];
};


export type CloudMutationSendSampleNotificationArgs = {
  document: Scalars['GenericScalar']['input'];
};


export type CloudMutationSensorDryRunArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  selectorData: SensorSelector;
};


export type CloudMutationSetAutoMaterializePausedArgs = {
  paused: Scalars['Boolean']['input'];
};


export type CloudMutationSetConcurrencyLimitArgs = {
  concurrencyKey: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
};


export type CloudMutationSetDeploymentSettingsArgs = {
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
  deploymentSettings: DeploymentSettingsInput;
};


export type CloudMutationSetOrganizationSettingsArgs = {
  organizationSettings?: InputMaybe<OrganizationSettingsInput>;
};


export type CloudMutationSetScimSyncEnabledArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CloudMutationSetSensorCursorArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  sensorSelector: SensorSelector;
};


export type CloudMutationShareFeedbackArgs = {
  input: ShareFeedbackInput;
};


export type CloudMutationShutdownRepositoryLocationArgs = {
  repositoryLocationName: Scalars['String']['input'];
};


export type CloudMutationStartScheduleArgs = {
  scheduleSelector: ScheduleSelector;
};


export type CloudMutationStartSensorArgs = {
  sensorSelector: SensorSelector;
};


export type CloudMutationStopRunningScheduleArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  scheduleOriginId?: InputMaybe<Scalars['String']['input']>;
  scheduleSelectorId?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationStopSensorArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  jobOriginId?: InputMaybe<Scalars['String']['input']>;
  jobSelectorId?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationSubmitCostInformationForMetricsArgs = {
  costInfo?: InputMaybe<Array<CostInformation>>;
  end: Scalars['Float']['input'];
  metricName: Scalars['String']['input'];
  start: Scalars['Float']['input'];
};


export type CloudMutationSyncSecretsArgs = {
  secrets: Array<InputMaybe<SecretInput>>;
};


export type CloudMutationTerminatePipelineExecutionArgs = {
  runId: Scalars['String']['input'];
  terminatePolicy?: InputMaybe<TerminateRunPolicy>;
};


export type CloudMutationTerminateRunArgs = {
  runId: Scalars['String']['input'];
  terminatePolicy?: InputMaybe<TerminateRunPolicy>;
};


export type CloudMutationTerminateRunsArgs = {
  runIds: Array<Scalars['String']['input']>;
  terminatePolicy?: InputMaybe<TerminateRunPolicy>;
};


export type CloudMutationToggleOnboardingChecklistEntryArgs = {
  entryKey?: InputMaybe<OnboardingChecklistEntryKey>;
  status?: InputMaybe<OnboardingChecklistStatus>;
};


export type CloudMutationUpdateDeploymentAgentTypeArgs = {
  deploymentAgentType?: InputMaybe<DeploymentAgentType>;
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
};


export type CloudMutationUpdateDeploymentSettingsArgs = {
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
  deploymentSettings?: InputMaybe<DeploymentSettingsInput>;
};


export type CloudMutationUpdateLocationArgs = {
  location: LocationSelector;
};


export type CloudMutationUpdateLocationFromDocumentArgs = {
  document: Scalars['GenericScalar']['input'];
};


export type CloudMutationUpdateMetricCustomizationsArgs = {
  customizations: Array<InsightsMetricCustomizationInput>;
};


export type CloudMutationUpdateOrganizationSettingsArgs = {
  organizationSettings?: InputMaybe<OrganizationSettingsInput>;
};


export type CloudMutationUpdateReportingDeploymentSettingsArgs = {
  metadataKeys?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type CloudMutationUpdateSecretArgs = {
  locationNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scopes: SecretScopesInput;
  secretId: Scalars['String']['input'];
  secretName: Scalars['String']['input'];
  secretValue: Scalars['String']['input'];
};


export type CloudMutationUpdateStripeCustomerArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type CloudMutationUpdateStripeCustomerTaxIDsArgs = {
  deletedIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  newIds?: InputMaybe<Array<TaxIdInput>>;
};


export type CloudMutationUpdateStripeSubscriptionArgs = {
  planType?: InputMaybe<DagsterCloudPlanType>;
};


export type CloudMutationUseExistingRepoArgs = {
  gitProvider?: InputMaybe<GitProvider>;
  repoName: Scalars['String']['input'];
};


export type CloudMutationWipeAssetsArgs = {
  assetPartitionRanges: Array<PartitionsByAssetSelector>;
};

export type CloudQuery = {
  activeAgentCategory?: Maybe<ActiveAgentCategory>;
  agentTokenOrError: AgentTokenOrError;
  agentTokensOrError: AgentTokensOrError;
  agents: Array<Agent>;
  alertConfigurations: Array<AlertConfiguration>;
  alertPolicies: Array<AlertPolicy>;
  alertPoliciesAsDocument?: Maybe<AlertPoliciesAsDocument>;
  alertPoliciesSchema?: Maybe<AlertPoliciesSchema>;
  /** Retrieve all the top level resources. */
  allTopLevelResourceDetailsOrError: ResourcesOrError;
  apiTokensOrError: ApiTokensOrError;
  /** Fetch the partitions that would be targeted by a backfill, given the root partitions targeted. */
  assetBackfillPreview: Array<AssetPartitions>;
  /** Retrieve the executions for a given asset check. */
  assetCheckExecutions: Array<AssetCheckExecution>;
  /** Retrieve the condition evaluation for an asset and partition. */
  assetConditionEvaluationForPartition?: Maybe<AssetConditionEvaluation>;
  /** Retrieve the condition evaluation records for an asset. */
  assetConditionEvaluationRecordsOrError?: Maybe<AssetConditionEvaluationRecordsOrError>;
  /** Retrieve the condition evaluation records for a given evaluation ID. */
  assetConditionEvaluationsForEvaluationId?: Maybe<AssetConditionEvaluationRecordsOrError>;
  assetDiffHistory: Array<AssetDiffHistoryEntry>;
  /** Retrieve a list of additional asset keys that must be materialized with the provided selection (due to @multi_assets with can_subset=False constraints.) */
  assetNodeAdditionalRequiredKeys: Array<AssetKey>;
  /** Retrieve a list of asset keys where two or more repos provide an asset definition. Note: Assets should not be defined in more than one repository - this query is used to present warnings and errors in the Dagster UI. */
  assetNodeDefinitionCollisions: Array<AssetNodeDefinitionCollision>;
  /** Retrieve an asset node by asset key. */
  assetNodeOrError: AssetNodeOrError;
  /** Retrieve asset nodes after applying a filter on asset group, job, and asset keys. */
  assetNodes: Array<AssetNode>;
  /** Retrieve an asset by asset key. */
  assetOrError: AssetOrError;
  /** Retrieve the latest materializations for a set of assets by asset keys. */
  assetsLatestInfo: Array<AssetLatestInfo>;
  /** Retrieve assets after applying a prefix filter, cursor, and limit. */
  assetsOrError: AssetsOrError;
  auditLog?: Maybe<AuditLogQuery>;
  /** Retrieve the auto materialization evaluation records for an asset. */
  autoMaterializeAssetEvaluationsOrError?: Maybe<AutoMaterializeAssetEvaluationRecordsOrError>;
  /** Retrieve the auto materialization evaluation records for a given evaluation ID. */
  autoMaterializeEvaluationsForEvaluationId?: Maybe<AutoMaterializeAssetEvaluationRecordsOrError>;
  /** Fetch the history of auto-materialization ticks */
  autoMaterializeTicks: Array<InstigationTick>;
  availableSlackChannels: Array<Scalars['String']['output']>;
  branchDeployments: BranchDeploymentsConnection;
  /** Returns whether the user has permission to terminate runs in the deployment */
  canBulkTerminate: Scalars['Boolean']['output'];
  /** Captured logs are the stdout/stderr logs for a given log key */
  capturedLogs: CapturedLogs;
  /** Retrieve the captured log metadata for a given log key. */
  capturedLogsMetadata: CapturedLogsMetadata;
  catalogViews: Array<CatalogView>;
  codeServerMetrics?: Maybe<CodeServerMetrics>;
  currentDeployment: DagsterCloudDeployment;
  customerInfo: CustomerInfoOrError;
  defaultViewersToCatalogMode: Scalars['Boolean']['output'];
  deploymentByName: DeploymentOrError;
  deploymentSettings?: Maybe<DeploymentSettings>;
  deploymentSettingsSchema?: Maybe<DeploymentSettingsSchema>;
  deployments: Array<DagsterCloudDeployment>;
  /** Retrieve the execution plan for a job and its run configuration. */
  executionPlanOrError: ExecutionPlanOrError;
  fullDeployments: Array<DagsterCloudDeployment>;
  getBranchDeploymentName: Scalars['String']['output'];
  githubSetup?: Maybe<GitHubSetupOrError>;
  gitlab?: Maybe<GitlabOrError>;
  /** Retrieve a graph by its location name, repository name, and graph name. */
  graphOrError: GraphOrError;
  identity: DagsterCloudIdentity;
  /** Retrieve the instance configuration for the Dagster deployment. */
  instance: Instance;
  /** Retrieve the state for a schedule or sensor by its location name, repository name, and schedule/sensor name. */
  instigationStateOrError: InstigationStateOrError;
  /** Retrieve the state for a group of instigators (schedule/sensor) by their containing repository id. */
  instigationStatesOrError: InstigationStatesOrError;
  invoiceListOrError?: Maybe<StripeInvoiceListOrError>;
  /** Retrieve whether the run configuration is valid or invalid. */
  isPipelineConfigValid: PipelineConfigValidationResult;
  localSecretsFileContents: Scalars['String']['output'];
  locationLoadHistory: Array<CodeLocationLoadHistoryEntry>;
  locationSchema?: Maybe<LocationSchema>;
  /** Retrieve location status for workspace locations. */
  locationStatusesOrError: WorkspaceLocationStatusEntriesOrError;
  locationsAsDocument?: Maybe<LocationsAsDocument>;
  /** Retrieve event logs after applying a run id filter, cursor, and limit. */
  logsForRun: EventConnectionOrError;
  materializationLevelMetricsForAsset: InsightsRunLevelMetricsOrError;
  metricTypesForAsset: MetricTypeListOrError;
  metricTypesForAssetGroup: MetricTypeListOrError;
  metricTypesForDeployment: MetricTypeListOrError;
  metricTypesForJob: MetricTypeListOrError;
  metricTypesForSpecificAsset: MetricTypeListOrError;
  metricTypesForSpecificJob: MetricTypeListOrError;
  metricsTimeRanges: ReportingTimeRanges;
  nonIsolatedRuns: NonIsolatedRuns;
  onboardingChecklistEntries?: Maybe<OnboardingChecklistEntriesOrError>;
  organization: DagsterCloudOrganization;
  organizationSettings?: Maybe<OrganizationSettings>;
  /** Retrieve a backfill by backfill id. */
  partitionBackfillOrError: PartitionBackfillOrError;
  /** Retrieve backfills after applying a status filter, cursor, and limit. */
  partitionBackfillsOrError: PartitionBackfillsOrError;
  /** Retrieve a partition set by its location name, repository name, and partition set name. */
  partitionSetOrError: PartitionSetOrError;
  /** Retrieve the partition sets for a job by its location name, repository name, and job name. */
  partitionSetsOrError: PartitionSetsOrError;
  partnerReferrals?: Maybe<PartnerReferrals>;
  paymentMethodListOrError?: Maybe<StripePaymentMethodListOrError>;
  /** Retrieve the set of permissions for the Dagster deployment. */
  permissions: Array<Permission>;
  /** Retrieve a job by its location name, repository name, and job name. */
  pipelineOrError: PipelineOrError;
  /** Retrieve a run by its run id. */
  pipelineRunOrError: RunOrError;
  /** Retrieve runs after applying a filter, cursor, and limit. */
  pipelineRunsOrError: RunsOrError;
  /** Retrieve a job snapshot by its id or location name, repository name, and job name. */
  pipelineSnapshotOrError: PipelineSnapshotOrError;
  planAllowsAddingMoreUsers: Scalars['Boolean']['output'];
  planType: DagsterCloudPlanType;
  queryableMetrics: Array<QueryableMetrics>;
  reportingDeploymentSettings: ReportingDeploymentSettings;
  reportingMetadata: ReportingDeploymentData;
  reportingMetricsByAsset: ReportingMetricsOrError;
  reportingMetricsByAssetGroup: ReportingMetricsOrError;
  reportingMetricsByDeployment: ReportingMetricsOrError;
  reportingMetricsByJob: ReportingMetricsOrError;
  /** Retrieve all the repositories. */
  repositoriesOrError: RepositoriesOrError;
  /** Retrieve a repository by its location name and repository name. */
  repositoryOrError: RepositoryOrError;
  /** Retrieve the run configuration schema for a job. */
  runConfigSchemaOrError: RunConfigSchemaOrError;
  runContainerMetrics?: Maybe<RunContainerMetrics>;
  /** Retrieve a group of runs with the matching root run id. */
  runGroupOrError: RunGroupOrError;
  /** Retrieve run IDs after applying a filter, cursor, and limit. */
  runIdsOrError: RunIdsOrError;
  runLevelMetricsForJob: InsightsRunLevelMetricsOrError;
  /** Retrieve a run by its run id. */
  runOrError: RunOrError;
  /** Retrieve the distinct tag keys from all runs. */
  runTagKeysOrError?: Maybe<RunTagKeysOrError>;
  /** Retrieve all the distinct key-value tags from all runs. */
  runTagsOrError?: Maybe<RunTagsOrError>;
  /** Retrieve runs after applying a filter, cursor, and limit. */
  runsOrError: RunsOrError;
  /** Retrieve a schedule by its location name, repository name, and schedule name. */
  scheduleOrError: ScheduleOrError;
  /** Retrieve the name of the scheduler running in the Dagster deployment. */
  scheduler: SchedulerOrError;
  /** Retrieve all the schedules. */
  schedulesOrError: SchedulesOrError;
  scimSyncEnabled: Scalars['Boolean']['output'];
  secretsOrError?: Maybe<SecretsOrError>;
  /** Retrieve a sensor by its location name, repository name, and sensor name. */
  sensorOrError: SensorOrError;
  /** Retrieve all the sensors. */
  sensorsOrError: SensorsOrError;
  serverless: Serverless;
  /** Whether or not the NUX should be shown to the user */
  shouldShowNux: Scalars['Boolean']['output'];
  sshKeysOrError: SshKeysOrError;
  teamPermissions: Array<DagsterCloudTeamPermissions>;
  /** Provides fields for testing behavior */
  test?: Maybe<TestFields>;
  /** Retrieve a top level resource by its location name, repository name, and resource name. */
  topLevelResourceDetailsOrError: ResourceDetailsOrError;
  /** Retrieve the partition keys which were true for a specific automation condition evaluation node. */
  truePartitionsForAutomationConditionEvaluationNode: Array<Scalars['String']['output']>;
  usageMetrics: UsageMetrics;
  userCountsByHighestLevel: Array<UserCount>;
  userNuxChecklistEntry?: Maybe<UserNuxChecklistEntry>;
  userTokensOrError: UserTokensOrError;
  usersOrError: DagsterCloudUsersWithScopedPermissionGrantsOrError;
  /** Retrieve all the utilized environment variables for the given repo. */
  utilizedEnvVarsOrError: EnvVarWithConsumersOrError;
  /** Retrieve the version of Dagster running in the Dagster deployment. */
  version: Scalars['String']['output'];
  workspace?: Maybe<WorkspaceQuery>;
  /** Retrieve a workspace entry by name. */
  workspaceLocationEntryOrError?: Maybe<WorkspaceLocationEntryOrError>;
  /** Retrieve the workspace and its locations. */
  workspaceOrError: WorkspaceOrError;
  workspaceSchema?: Maybe<WorkspaceSchema>;
};


export type CloudQueryAgentTokenOrErrorArgs = {
  tokenId: Scalars['Int']['input'];
};


export type CloudQueryAllTopLevelResourceDetailsOrErrorArgs = {
  repositorySelector: RepositorySelector;
};


export type CloudQueryApiTokensOrErrorArgs = {
  tokenType: DagsterCloudApiTokenType;
};


export type CloudQueryAssetBackfillPreviewArgs = {
  params: AssetBackfillPreviewParams;
};


export type CloudQueryAssetCheckExecutionsArgs = {
  assetKey: AssetKeyInput;
  checkName: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};


export type CloudQueryAssetConditionEvaluationForPartitionArgs = {
  assetKey: AssetKeyInput;
  evaluationId: Scalars['Int']['input'];
  partition: Scalars['String']['input'];
};


export type CloudQueryAssetConditionEvaluationRecordsOrErrorArgs = {
  assetKey: AssetKeyInput;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};


export type CloudQueryAssetConditionEvaluationsForEvaluationIdArgs = {
  evaluationId: Scalars['Int']['input'];
};


export type CloudQueryAssetDiffHistoryArgs = {
  assetKey: AssetKeyInput;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};


export type CloudQueryAssetNodeAdditionalRequiredKeysArgs = {
  assetKeys: Array<AssetKeyInput>;
};


export type CloudQueryAssetNodeDefinitionCollisionsArgs = {
  assetKeys: Array<AssetKeyInput>;
};


export type CloudQueryAssetNodeOrErrorArgs = {
  assetKey: AssetKeyInput;
};


export type CloudQueryAssetNodesArgs = {
  assetKeys?: InputMaybe<Array<AssetKeyInput>>;
  group?: InputMaybe<AssetGroupSelector>;
  loadMaterializations?: InputMaybe<Scalars['Boolean']['input']>;
  pipeline?: InputMaybe<PipelineSelector>;
};


export type CloudQueryAssetOrErrorArgs = {
  assetKey: AssetKeyInput;
};


export type CloudQueryAssetsLatestInfoArgs = {
  assetKeys: Array<AssetKeyInput>;
};


export type CloudQueryAssetsOrErrorArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  prefix?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type CloudQueryAutoMaterializeAssetEvaluationsOrErrorArgs = {
  assetKey: AssetKeyInput;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};


export type CloudQueryAutoMaterializeEvaluationsForEvaluationIdArgs = {
  evaluationId: Scalars['Int']['input'];
};


export type CloudQueryAutoMaterializeTicksArgs = {
  afterTimestamp?: InputMaybe<Scalars['Float']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Float']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  dayOffset?: InputMaybe<Scalars['Int']['input']>;
  dayRange?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InstigationTickStatus>>;
};


export type CloudQueryBranchDeploymentsArgs = {
  limit: Scalars['Int']['input'];
  pullRequestStatus?: InputMaybe<PullRequestStatus>;
};


export type CloudQueryCapturedLogsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  logKey: Array<Scalars['String']['input']>;
};


export type CloudQueryCapturedLogsMetadataArgs = {
  logKey: Array<Scalars['String']['input']>;
};


export type CloudQueryCodeServerMetricsArgs = {
  fromTime?: InputMaybe<Scalars['Float']['input']>;
  location: Scalars['String']['input'];
  toTime?: InputMaybe<Scalars['Float']['input']>;
};


export type CloudQueryDeploymentByNameArgs = {
  name: Scalars['String']['input'];
};


export type CloudQueryExecutionPlanOrErrorArgs = {
  mode: Scalars['String']['input'];
  pipeline: PipelineSelector;
  runConfigData?: InputMaybe<Scalars['RunConfigData']['input']>;
};


export type CloudQueryGetBranchDeploymentNameArgs = {
  branchName: Scalars['String']['input'];
  repoName: Scalars['String']['input'];
};


export type CloudQueryGraphOrErrorArgs = {
  selector?: InputMaybe<GraphSelector>;
};


export type CloudQueryInstigationStateOrErrorArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  instigationSelector: InstigationSelector;
};


export type CloudQueryInstigationStatesOrErrorArgs = {
  repositoryID: Scalars['String']['input'];
};


export type CloudQueryIsPipelineConfigValidArgs = {
  mode: Scalars['String']['input'];
  pipeline: PipelineSelector;
  runConfigData?: InputMaybe<Scalars['RunConfigData']['input']>;
};


export type CloudQueryLocationLoadHistoryArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  locationName: Scalars['String']['input'];
};


export type CloudQueryLogsForRunArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  runId: Scalars['ID']['input'];
};


export type CloudQueryMaterializationLevelMetricsForAssetArgs = {
  assetKey: QualifiedAssetKey;
  metricsSelector: InsightsRunLevelMetricsSelector;
};


export type CloudQueryMetricTypesForSpecificAssetArgs = {
  metricsFilter?: InputMaybe<AssetReportingMetricsFilter>;
  timeframeSelector: ReportingMetricsTimeframeSelector;
};


export type CloudQueryMetricTypesForSpecificJobArgs = {
  metricsFilter?: InputMaybe<JobReportingMetricsFilter>;
  timeframeSelector: ReportingMetricsTimeframeSelector;
};


export type CloudQueryPartitionBackfillOrErrorArgs = {
  backfillId: Scalars['String']['input'];
};


export type CloudQueryPartitionBackfillsOrErrorArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<BulkActionStatus>;
};


export type CloudQueryPartitionSetOrErrorArgs = {
  partitionSetName?: InputMaybe<Scalars['String']['input']>;
  repositorySelector: RepositorySelector;
};


export type CloudQueryPartitionSetsOrErrorArgs = {
  pipelineName: Scalars['String']['input'];
  repositorySelector: RepositorySelector;
};


export type CloudQueryPipelineOrErrorArgs = {
  params: PipelineSelector;
};


export type CloudQueryPipelineRunOrErrorArgs = {
  runId: Scalars['ID']['input'];
};


export type CloudQueryPipelineRunsOrErrorArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RunsFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type CloudQueryPipelineSnapshotOrErrorArgs = {
  activePipelineSelector?: InputMaybe<PipelineSelector>;
  snapshotId?: InputMaybe<Scalars['String']['input']>;
};


export type CloudQueryReportingMetricsByAssetArgs = {
  metricsFilter?: InputMaybe<AssetReportingMetricsFilter>;
  metricsSelector: ReportingMetricsSelector;
};


export type CloudQueryReportingMetricsByAssetGroupArgs = {
  metricsFilter?: InputMaybe<AssetGroupReportingMetricsFilter>;
  metricsSelector: ReportingMetricsSelector;
};


export type CloudQueryReportingMetricsByDeploymentArgs = {
  metricsFilter?: InputMaybe<DeploymentReportingMetricsFilter>;
  metricsSelector: ReportingMetricsSelector;
};


export type CloudQueryReportingMetricsByJobArgs = {
  metricsFilter?: InputMaybe<JobReportingMetricsFilter>;
  metricsSelector: ReportingMetricsSelector;
};


export type CloudQueryRepositoriesOrErrorArgs = {
  repositorySelector?: InputMaybe<RepositorySelector>;
};


export type CloudQueryRepositoryOrErrorArgs = {
  repositorySelector: RepositorySelector;
};


export type CloudQueryRunConfigSchemaOrErrorArgs = {
  mode?: InputMaybe<Scalars['String']['input']>;
  selector: PipelineSelector;
};


export type CloudQueryRunContainerMetricsArgs = {
  runId: Scalars['String']['input'];
};


export type CloudQueryRunGroupOrErrorArgs = {
  runId: Scalars['ID']['input'];
};


export type CloudQueryRunIdsOrErrorArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RunsFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type CloudQueryRunLevelMetricsForJobArgs = {
  job: QualifiedJob;
  metricsSelector: InsightsRunLevelMetricsSelector;
};


export type CloudQueryRunOrErrorArgs = {
  runId: Scalars['ID']['input'];
};


export type CloudQueryRunTagsOrErrorArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  tagKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  valuePrefix?: InputMaybe<Scalars['String']['input']>;
};


export type CloudQueryRunsOrErrorArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RunsFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type CloudQueryScheduleOrErrorArgs = {
  scheduleSelector: ScheduleSelector;
};


export type CloudQuerySchedulesOrErrorArgs = {
  repositorySelector: RepositorySelector;
  scheduleStatus?: InputMaybe<InstigationStatus>;
};


export type CloudQuerySensorOrErrorArgs = {
  sensorSelector: SensorSelector;
};


export type CloudQuerySensorsOrErrorArgs = {
  repositorySelector: RepositorySelector;
  sensorStatus?: InputMaybe<InstigationStatus>;
};


export type CloudQuerySshKeysOrErrorArgs = {
  userId: Scalars['Int']['input'];
};


export type CloudQueryTopLevelResourceDetailsOrErrorArgs = {
  resourceSelector: ResourceSelector;
};


export type CloudQueryTruePartitionsForAutomationConditionEvaluationNodeArgs = {
  assetKey: AssetKeyInput;
  evaluationId: Scalars['Int']['input'];
  nodeUniqueId?: InputMaybe<Scalars['String']['input']>;
};


export type CloudQueryUserNuxChecklistEntryArgs = {
  entryKey: Scalars['String']['input'];
};


export type CloudQueryUserTokensOrErrorArgs = {
  userId: Scalars['Int']['input'];
};


export type CloudQueryUtilizedEnvVarsOrErrorArgs = {
  repositorySelector: RepositorySelector;
};


export type CloudQueryWorkspaceLocationEntryOrErrorArgs = {
  name: Scalars['String']['input'];
};

export type CloudServerState = {
  error?: Maybe<PythonError>;
  locationName?: Maybe<Scalars['String']['output']>;
  status: CloudCodeServerStatus;
};

export type CloudSubscription = {
  /** Retrieve real-time compute logs. */
  capturedLogs: CapturedLogs;
  /** Retrieve real-time events when a location in the workspace undergoes a state change. */
  locationStateChangeEvents: LocationStateChangeSubscription;
  /** Retrieve real-time event logs after applying a filter on run id and cursor. */
  pipelineRunLogs: PipelineRunLogsSubscriptionPayload;
};


export type CloudSubscriptionCapturedLogsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  logKey: Array<Scalars['String']['input']>;
};


export type CloudSubscriptionPipelineRunLogsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  runId: Scalars['ID']['input'];
};

export enum CodeDeployStep {
  CleaningUp = 'CLEANING_UP',
  Deploying = 'DEPLOYING',
  Idle = 'IDLE',
  Initializing = 'INITIALIZING'
}

export type CodeLocation = {
  repositoryLocationName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

export type CodeLocationAssetSelectionInput = {
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
};

export type CodeLocationDiffSummary = {
  repositoryDiffs: Array<RepositoryDiffSummary>;
};

export type CodeLocationLimitError = Error & {
  message: Scalars['String']['output'];
};

export type CodeLocationLoadHistoryEntry = {
  codeLocationDataUploadTimestamp: Scalars['Float']['output'];
  codeLocationUpdateTriggerTimestamp: Scalars['Float']['output'];
  configDocument?: Maybe<Scalars['GenericScalar']['output']>;
  diffSummary?: Maybe<CodeLocationDiffSummary>;
  displayMetadata: Array<RepositoryMetadata>;
  loadStatus: DagsterCloudCodeLocationLoadStatus;
  locationName: Scalars['String']['output'];
};

export type CodeReferencesMetadataEntry = MetadataEntry & {
  codeReferences: Array<SourceLocation>;
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type CodeServerMetrics = {
  cpuLimit?: Maybe<MetricsTimeSeries>;
  cpuPercent?: Maybe<MetricsTimeSeries>;
  cpuUsage?: Maybe<MetricsTimeSeries>;
  memoryLimit?: Maybe<MetricsTimeSeries>;
  memoryPercent?: Maybe<MetricsTimeSeries>;
  memoryUsage?: Maybe<MetricsTimeSeries>;
  queuedRequests?: Maybe<MetricsTimeSeries>;
  requestUtilizationPercent?: Maybe<MetricsTimeSeries>;
  runningRequests?: Maybe<MetricsTimeSeries>;
};

export type CompositeConfigType = ConfigType & {
  description?: Maybe<Scalars['String']['output']>;
  fields: Array<ConfigTypeField>;
  isSelector: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  /**
   *
   * This is an odd and problematic field. It recursively goes down to
   * get all the types contained within a type. The case where it is horrible
   * are dictionaries and it recurses all the way down to the leaves. This means
   * that in a case where one is fetching all the types and then all the inner
   * types keys for those types, we are returning O(N^2) type keys, which
   * can cause awful performance for large schemas. When you have access
   * to *all* the types, you should instead only use the type_param_keys
   * field for closed generic types and manually navigate down the to
   * field types client-side.
   *
   * Where it is useful is when you are fetching types independently and
   * want to be able to render them, but without fetching the entire schema.
   *
   * We use this capability when rendering the sidebar.
   *
   */
  recursiveConfigTypes: Array<ConfigType>;
  /**
   *
   * This returns the keys for type parameters of any closed generic type,
   * (e.g. List, Optional). This should be used for reconstructing and
   * navigating the full schema client-side and not innerTypes.
   *
   */
  typeParamKeys: Array<Scalars['String']['output']>;
};

export type CompositeSolidDefinition = ISolidDefinition & SolidContainer & {
  assetNodes: Array<AssetNode>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inputDefinitions: Array<InputDefinition>;
  inputMappings: Array<InputMapping>;
  metadata: Array<MetadataItemDefinition>;
  modes: Array<Mode>;
  name: Scalars['String']['output'];
  outputDefinitions: Array<OutputDefinition>;
  outputMappings: Array<OutputMapping>;
  solidHandle?: Maybe<SolidHandle>;
  solidHandles: Array<SolidHandle>;
  solids: Array<Solid>;
};


export type CompositeSolidDefinitionSolidHandleArgs = {
  handleID: Scalars['String']['input'];
};


export type CompositeSolidDefinitionSolidHandlesArgs = {
  parentHandleID?: InputMaybe<Scalars['String']['input']>;
};

export type ConcurrencyKeyInfo = {
  activeRunIds: Array<Scalars['String']['output']>;
  activeSlotCount: Scalars['Int']['output'];
  assignedStepCount: Scalars['Int']['output'];
  assignedStepRunIds: Array<Scalars['String']['output']>;
  claimedSlots: Array<ClaimedConcurrencySlot>;
  concurrencyKey: Scalars['String']['output'];
  pendingStepCount: Scalars['Int']['output'];
  pendingStepRunIds: Array<Scalars['String']['output']>;
  pendingSteps: Array<PendingConcurrencyStep>;
  slotCount: Scalars['Int']['output'];
};

export type ConfigType = {
  description?: Maybe<Scalars['String']['output']>;
  isSelector: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  /**
   *
   * This is an odd and problematic field. It recursively goes down to
   * get all the types contained within a type. The case where it is horrible
   * are dictionaries and it recurses all the way down to the leaves. This means
   * that in a case where one is fetching all the types and then all the inner
   * types keys for those types, we are returning O(N^2) type keys, which
   * can cause awful performance for large schemas. When you have access
   * to *all* the types, you should instead only use the type_param_keys
   * field for closed generic types and manually navigate down the to
   * field types client-side.
   *
   * Where it is useful is when you are fetching types independently and
   * want to be able to render them, but without fetching the entire schema.
   *
   * We use this capability when rendering the sidebar.
   *
   */
  recursiveConfigTypes: Array<ConfigType>;
  /**
   *
   * This returns the keys for type parameters of any closed generic type,
   * (e.g. List, Optional). This should be used for reconstructing and
   * navigating the full schema client-side and not innerTypes.
   *
   */
  typeParamKeys: Array<Scalars['String']['output']>;
};

export type ConfigTypeField = {
  configType: ConfigType;
  configTypeKey: Scalars['String']['output'];
  defaultValueAsJson?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  isRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ConfigTypeNotFoundError = Error & {
  configTypeName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  pipeline: Pipeline;
};

export type ConfigTypeOrError = CompositeConfigType | ConfigTypeNotFoundError | EnumConfigType | PipelineNotFoundError | PythonError | RegularConfigType;

export type ConfiguredValue = {
  key: Scalars['String']['output'];
  type: ConfiguredValueType;
  value: Scalars['String']['output'];
};

export enum ConfiguredValueType {
  EnvVar = 'ENV_VAR',
  Value = 'VALUE'
}

export type ConflictingExecutionParamsError = Error & {
  message: Scalars['String']['output'];
};

export type CostInformation = {
  cost: Scalars['Float']['input'];
  opaqueId: Scalars['String']['input'];
};

export type CreateAgentTokenResult = DagsterCloudAgentToken | PythonError | UnauthorizedError;

export type CreateApiTokenResult = DagsterCloudApiToken | PythonError | UnauthorizedError;

export type CreateDeploymentResult = DagsterCloudDeployment | DeploymentLimitError | DeploymentNotFoundError | DuplicateDeploymentError | PythonError | UnauthorizedError;

export type CreateOrUpdateAlertPolicyFromDocumentMutationResult = AlertPolicy | InvalidAlertPolicyError | PythonError | UnauthorizedError;

export type CreateOrUpdateBranchDeploymentInput = {
  branchName: Scalars['String']['input'];
  branchUrl?: InputMaybe<Scalars['String']['input']>;
  pullRequestNumber?: InputMaybe<Scalars['String']['input']>;
  pullRequestStatus?: InputMaybe<PullRequestStatus>;
  pullRequestUrl?: InputMaybe<Scalars['String']['input']>;
  repoName: Scalars['String']['input'];
};

export type CreateOrUpdateCatalogViewMutationResult = CatalogView | PythonError | UnauthorizedError;

export type CreateOrUpdateCloudAgentPermissionsInput = {
  agentTokenId: Scalars['Int']['input'];
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
  deploymentScope: PermissionDeploymentScope;
  grant: PermissionGrant;
};

export type CreateOrUpdateCloudUserPermissionsInput = {
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
  deploymentScope: PermissionDeploymentScope;
  email: Scalars['String']['input'];
  grant: PermissionGrant;
  locationGrants?: InputMaybe<Array<InputMaybe<LocationScopedGrantInput>>>;
};

export type CreateOrUpdateMetricsFailed = Error & {
  message: Scalars['String']['output'];
};

export type CreateOrUpdateMetricsResult = CreateOrUpdateMetricsFailed | CreateOrUpdateMetricsSuccess | PythonError | UnauthorizedError;

export type CreateOrUpdateMetricsSuccess = {
  status: Scalars['String']['output'];
};

export type CreateOrUpdateSecretResult = CreateOrUpdateSecretSuccess | InvalidSecretInputError | PythonError | TooManySecretsError | UnauthorizedError;

export type CreateOrUpdateSecretSuccess = {
  secret: Secret;
};

export type CreateOrUpdateTeamMutationResult = CreateOrUpdateTeamSuccess | PythonError | UnauthorizedError;

export type CreateOrUpdateTeamPermissionMutationResult = CreateOrUpdateTeamPermissionSuccess | PythonError | UnauthorizedError | UserLimitError;

export type CreateOrUpdateTeamPermissionSuccess = {
  teamPermissions: DagsterCloudTeamPermissions;
};

export type CreateOrUpdateTeamSuccess = {
  team: DagsterCloudTeam;
};

export type CreateSshKeyResult = DagsterCloudInvalidPublicKeyError | DagsterCloudSshKey | PythonError | UnauthorizedError;

export type CreateTemplateRepoResult = CreateTemplateRepoSuccess | GitHubError | InvalidTemplateRepoError | PythonError | SetupRepoError | UnauthorizedError;

export type CreateTemplateRepoSuccess = {
  repoUrl: Scalars['String']['output'];
};

export type CreateUserTokenResult = DagsterCloudUserToken | PythonError | UnauthorizedError;

export type CreditLimitTarget = {
  creditLimit: Scalars['Int']['output'];
};

/** A representation of the credit metric, partitioned by job. */
export type CreditUsageByJobMetricValue = UsageMetricValue & {
  endTimestamp: Scalars['Float']['output'];
  jobName?: Maybe<Scalars['String']['output']>;
  percentTotal: Scalars['Float']['output'];
  repositoryLabel?: Maybe<Scalars['String']['output']>;
  startTimestamp: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

/** A representation of the credit metric. */
export type CreditUsageMetricValue = UsageMetricValue & {
  endTimestamp: Scalars['Float']['output'];
  startTimestamp: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type CustomerInfo = {
  id: Scalars['String']['output'];
  stripeCustomer?: Maybe<StripeCustomer>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeSubscription?: Maybe<StripeSubscription>;
};

export type CustomerInfoNotFoundError = Error & {
  message: Scalars['String']['output'];
};

export type CustomerInfoOrError = CustomerInfo | CustomerInfoNotFoundError | PythonError | UnauthorizedError;

export type CustomerPaymentMethodRequired = Error & {
  message: Scalars['String']['output'];
};

export type CustomerPaymentRequired = Error & {
  hostedInvoiceUrl: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type DaemonHealth = {
  allDaemonStatuses: Array<DaemonStatus>;
  daemonStatus: DaemonStatus;
  id: Scalars['String']['output'];
};


export type DaemonHealthDaemonStatusArgs = {
  daemonType?: InputMaybe<Scalars['String']['input']>;
};

export type DaemonStatus = {
  daemonType: Scalars['String']['output'];
  healthy?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastHeartbeatErrors: Array<PythonError>;
  lastHeartbeatTime?: Maybe<Scalars['Float']['output']>;
  required: Scalars['Boolean']['output'];
};

/**
 * The account review status of an Organization.
 *     New GA users are automatically added as "LEAD".
 *     When they convert from trial they become "CUSTOMER"
 *     Some customers with high fraud scores may be challenged with 2fa
 *     or a credit card challenge. If they fail they will enter "PENDING_REVIEW".
 *     If they fail manual review they will enter "REJECTED" and lose access
 *     to their serverless trial.
 *
 */
export enum DagsterCloudAccountReviewStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  CancelRequested = 'CANCEL_REQUESTED',
  Customer = 'CUSTOMER',
  Deactivated = 'DEACTIVATED',
  Expired = 'EXPIRED',
  Lead = 'LEAD',
  PendingReview = 'PENDING_REVIEW',
  Rejected = 'REJECTED'
}

export type DagsterCloudAgentToken = {
  createTimestamp?: Maybe<Scalars['Float']['output']>;
  createdBy: DagsterCloudUser;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  permissions?: Maybe<DagsterCloudScopedPermissionGrants>;
  revokeTimestamp?: Maybe<Scalars['Float']['output']>;
  revoked: Scalars['Boolean']['output'];
  revokedBy?: Maybe<DagsterCloudUser>;
  token: Scalars['String']['output'];
};

export type DagsterCloudAgentTokens = {
  tokens: Array<DagsterCloudAgentToken>;
};

export type DagsterCloudApiToken = {
  createTimestamp?: Maybe<Scalars['Float']['output']>;
  createdBy: DagsterCloudUser;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  revokeTimestamp?: Maybe<Scalars['Float']['output']>;
  revoked: Scalars['Boolean']['output'];
  revokedBy?: Maybe<DagsterCloudUser>;
  token: Scalars['String']['output'];
  tokenType: DagsterCloudApiTokenType;
};

/** An enumeration. */
export enum DagsterCloudApiTokenType {
  Scim = 'SCIM'
}

export type DagsterCloudApiTokens = {
  tokens: Array<DagsterCloudApiToken>;
};

/** An enumeration. */
export enum DagsterCloudCodeLocationLoadStatus {
  Error = 'ERROR',
  Loaded = 'LOADED'
}

export type DagsterCloudDeployment = {
  agentType: DeploymentAgentType;
  branchDeploymentGitMetadata?: Maybe<BranchDeploymentGitMetadata>;
  canDeleteDeployment: Scalars['Boolean']['output'];
  canEditDeploymentPermissions: Scalars['Boolean']['output'];
  canEditDeploymentSettings: Scalars['Boolean']['output'];
  canHaveBranchDeployments: Scalars['Boolean']['output'];
  commits: Array<DeploymentCommit>;
  deploymentId: Scalars['Int']['output'];
  deploymentName: Scalars['String']['output'];
  deploymentSettings: DeploymentSettings;
  deploymentStatus: DeploymentStatus;
  deploymentType: DagsterCloudDeploymentType;
  devDeploymentInfo?: Maybe<DevDeploymentInfo>;
  editableSecretLocations: EditableSecretLocations;
  isBranchDeployment: Scalars['Boolean']['output'];
  latestCommit?: Maybe<DeploymentCommit>;
  organizationId: Scalars['Int']['output'];
  organizationName: Scalars['String']['output'];
  organizationStatus: OrganizationStatus;
  parentDeployment?: Maybe<DagsterCloudDeployment>;
  workspaceEntries: Array<WorkspaceEntry>;
};


export type DagsterCloudDeploymentWorkspaceEntriesArgs = {
  agentQueues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum DagsterCloudDeploymentType {
  Branch = 'BRANCH',
  Dev = 'DEV',
  Production = 'PRODUCTION'
}

export type DagsterCloudFeatureGate = {
  key: FeatureGateKey;
  value?: Maybe<Scalars['GenericScalar']['output']>;
};

export type DagsterCloudIdentity = {
  currentDeployment: DagsterCloudDeployment;
  featureGates: Array<DagsterCloudFeatureGate>;
  permissions: Array<DagsterCloudPermission>;
  viewer?: Maybe<DagsterCloudUser>;
};

export type DagsterCloudInvalidPublicKeyError = Error & {
  message: Scalars['String']['output'];
};

export type DagsterCloudOrganization = {
  accountReview?: Maybe<OrganizationAccountReview>;
  id: Scalars['Int']['output'];
  metadata: DagsterCloudOrganizationMetadata;
  name: Scalars['String']['output'];
  publicId: Scalars['String']['output'];
  status: OrganizationStatus;
  usersOrError: DagsterCloudUserListOrError;
};

export type DagsterCloudOrganizationMetadata = {
  githubAppInstallation?: Maybe<GithubAppInstallation>;
  slackAppInstallation?: Maybe<SlackAppInstallation>;
};

export type DagsterCloudPermission = {
  disabledReason?: Maybe<Scalars['String']['output']>;
  permission: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

/**
 * The category of Plan an Organization is signed up for. Must match the
 *     plan_details tuple set in DagsterCloudPlan.
 *     New GA users are automatically entered into a STANDARD plan.
 *     ENTERPRISE plans are negotiated individually with larger customers,
 *     these have bespoke contracts.
 *     We use OPPORTUNITY to ungate enterprise features during negotiations
 *     but before a contract has been signed.
 *
 */
export enum DagsterCloudPlanType {
  Enterprise = 'ENTERPRISE',
  Opportunity = 'OPPORTUNITY',
  Partner = 'PARTNER',
  Solo = 'SOLO',
  SoloV2 = 'SOLO_V2',
  Standard = 'STANDARD',
  Team = 'TEAM',
  TeamV2 = 'TEAM_V2',
  TeamV3 = 'TEAM_V3',
  Unknown = 'UNKNOWN'
}

export type DagsterCloudSshKey = {
  createTimestamp?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  revokeTimestamp?: Maybe<Scalars['Float']['output']>;
  revoked: Scalars['Boolean']['output'];
  revokedBy?: Maybe<DagsterCloudUser>;
  user: DagsterCloudUser;
};

export type DagsterCloudSshKeyNotFoundError = Error & {
  message: Scalars['String']['output'];
};

export type DagsterCloudSshKeys = {
  keys: Array<DagsterCloudSshKey>;
};

/** If the deploymentId is null, then this represents an organization grant. */
export type DagsterCloudScopedPermissionGrant = {
  deploymentId?: Maybe<Scalars['Int']['output']>;
  deploymentScope: PermissionDeploymentScope;
  grant: PermissionGrant;
  id: Scalars['Int']['output'];
  locationGrants: Array<LocationScopedGrant>;
  organizationId: Scalars['Int']['output'];
};

export type DagsterCloudScopedPermissionGrants = {
  allBranchDeploymentsPermissionGrant?: Maybe<DagsterCloudScopedPermissionGrant>;
  deploymentPermissionGrants: Array<DagsterCloudScopedPermissionGrant>;
  organizationPermissionGrant?: Maybe<DagsterCloudScopedPermissionGrant>;
};

/**
 * The status of a stripe subscription
 *     reference: https://stripe.com/docs/api/subscriptions/object#subscription_object-status.
 *
 */
export enum DagsterCloudSubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

export type DagsterCloudTeam = {
  id: Scalars['String']['output'];
  members: Array<DagsterCloudUser>;
  name: Scalars['String']['output'];
};

export type DagsterCloudTeamPermissions = {
  allBranchDeploymentsPermissionGrant?: Maybe<DagsterCloudScopedPermissionGrant>;
  deploymentPermissionGrants: Array<DagsterCloudScopedPermissionGrant>;
  id: Scalars['String']['output'];
  organizationPermissionGrant?: Maybe<DagsterCloudScopedPermissionGrant>;
  team: DagsterCloudTeam;
};

export type DagsterCloudTokenNotFoundError = Error & {
  message: Scalars['String']['output'];
};

export type DagsterCloudUser = {
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  isScimProvisioned: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  userId: Scalars['Int']['output'];
};

export type DagsterCloudUserList = {
  users: Array<DagsterCloudUser>;
};

export type DagsterCloudUserListOrError = CantRemoveAllAdminsError | DagsterCloudUserList | PythonError | UnauthorizedError | UserLimitError | UserNotFoundError;

export type DagsterCloudUserToken = {
  createTimestamp?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  revokeTimestamp?: Maybe<Scalars['Float']['output']>;
  revoked: Scalars['Boolean']['output'];
  revokedBy?: Maybe<DagsterCloudUser>;
  token: Scalars['String']['output'];
  user: DagsterCloudUser;
};

export type DagsterCloudUserTokens = {
  tokens: Array<DagsterCloudUserToken>;
};

export type DagsterCloudUserWithScopedPermissionGrants = {
  allBranchDeploymentsPermissionGrant?: Maybe<DagsterCloudScopedPermissionGrant>;
  deploymentPermissionGrants: Array<DagsterCloudScopedPermissionGrant>;
  id: Scalars['String']['output'];
  licensedRole?: Maybe<PermissionGrant>;
  organizationPermissionGrant?: Maybe<DagsterCloudScopedPermissionGrant>;
  user?: Maybe<DagsterCloudUser>;
};

export type DagsterCloudUserWithScopedPermissionGrantsOrError = CantRemoveAllAdminsError | DagsterCloudUserWithScopedPermissionGrants | PythonError | UnauthorizedError | UserLimitError | UserNotFoundError;

export type DagsterCloudUsersWithScopedPermissionGrants = {
  userCountsByHighestLevel: Array<UserCount>;
  users: Array<DagsterCloudUserWithScopedPermissionGrants>;
};

export type DagsterCloudUsersWithScopedPermissionGrantsOrError = DagsterCloudUsersWithScopedPermissionGrants | PythonError | UnauthorizedError;

/** The types of events that may be yielded by op and job execution. */
export enum DagsterEventType {
  AlertFailure = 'ALERT_FAILURE',
  AlertStart = 'ALERT_START',
  AlertSuccess = 'ALERT_SUCCESS',
  AssetCheckEvaluation = 'ASSET_CHECK_EVALUATION',
  AssetCheckEvaluationPlanned = 'ASSET_CHECK_EVALUATION_PLANNED',
  AssetMaterialization = 'ASSET_MATERIALIZATION',
  AssetMaterializationPlanned = 'ASSET_MATERIALIZATION_PLANNED',
  AssetObservation = 'ASSET_OBSERVATION',
  AssetStoreOperation = 'ASSET_STORE_OPERATION',
  EngineEvent = 'ENGINE_EVENT',
  HandledOutput = 'HANDLED_OUTPUT',
  HookCompleted = 'HOOK_COMPLETED',
  HookErrored = 'HOOK_ERRORED',
  HookSkipped = 'HOOK_SKIPPED',
  LoadedInput = 'LOADED_INPUT',
  LogsCaptured = 'LOGS_CAPTURED',
  ObjectStoreOperation = 'OBJECT_STORE_OPERATION',
  PipelineCanceled = 'PIPELINE_CANCELED',
  PipelineCanceling = 'PIPELINE_CANCELING',
  PipelineDequeued = 'PIPELINE_DEQUEUED',
  PipelineEnqueued = 'PIPELINE_ENQUEUED',
  PipelineFailure = 'PIPELINE_FAILURE',
  PipelineStart = 'PIPELINE_START',
  PipelineStarting = 'PIPELINE_STARTING',
  PipelineSuccess = 'PIPELINE_SUCCESS',
  ResourceInitFailure = 'RESOURCE_INIT_FAILURE',
  ResourceInitStarted = 'RESOURCE_INIT_STARTED',
  ResourceInitSuccess = 'RESOURCE_INIT_SUCCESS',
  RunCanceled = 'RUN_CANCELED',
  RunCanceling = 'RUN_CANCELING',
  RunDequeued = 'RUN_DEQUEUED',
  RunEnqueued = 'RUN_ENQUEUED',
  RunFailure = 'RUN_FAILURE',
  RunStart = 'RUN_START',
  RunStarting = 'RUN_STARTING',
  RunSuccess = 'RUN_SUCCESS',
  StepExpectationResult = 'STEP_EXPECTATION_RESULT',
  StepFailure = 'STEP_FAILURE',
  StepInput = 'STEP_INPUT',
  StepOutput = 'STEP_OUTPUT',
  StepRestarted = 'STEP_RESTARTED',
  StepSkipped = 'STEP_SKIPPED',
  StepStart = 'STEP_START',
  StepSuccess = 'STEP_SUCCESS',
  StepUpForRetry = 'STEP_UP_FOR_RETRY',
  StepWorkerStarted = 'STEP_WORKER_STARTED',
  StepWorkerStarting = 'STEP_WORKER_STARTING'
}

export type DagsterLibraryVersion = {
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type DagsterRunEvent = AlertFailureEvent | AlertStartEvent | AlertSuccessEvent | AssetCheckEvaluationEvent | AssetCheckEvaluationPlannedEvent | AssetMaterializationPlannedEvent | EngineEvent | ExecutionStepFailureEvent | ExecutionStepInputEvent | ExecutionStepOutputEvent | ExecutionStepRestartEvent | ExecutionStepSkippedEvent | ExecutionStepStartEvent | ExecutionStepSuccessEvent | ExecutionStepUpForRetryEvent | HandledOutputEvent | HookCompletedEvent | HookErroredEvent | HookSkippedEvent | LoadedInputEvent | LogMessageEvent | LogsCapturedEvent | MaterializationEvent | ObjectStoreOperationEvent | ObservationEvent | ResourceInitFailureEvent | ResourceInitStartedEvent | ResourceInitSuccessEvent | RunCanceledEvent | RunCancelingEvent | RunDequeuedEvent | RunEnqueuedEvent | RunFailureEvent | RunStartEvent | RunStartingEvent | RunSuccessEvent | StepExpectationResultEvent | StepWorkerStartedEvent | StepWorkerStartingEvent;

export type DagsterType = {
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  innerTypes: Array<DagsterType>;
  inputSchemaType?: Maybe<ConfigType>;
  isBuiltin: Scalars['Boolean']['output'];
  isList: Scalars['Boolean']['output'];
  isNothing: Scalars['Boolean']['output'];
  isNullable: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  name?: Maybe<Scalars['String']['output']>;
  outputSchemaType?: Maybe<ConfigType>;
};

export type DagsterTypeNotFoundError = Error & {
  dagsterTypeName: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type DagsterTypeOrError = DagsterTypeNotFoundError | PipelineNotFoundError | PythonError | RegularDagsterType;

export type DefaultPartitionStatuses = {
  failedPartitions: Array<Scalars['String']['output']>;
  materializedPartitions: Array<Scalars['String']['output']>;
  materializingPartitions: Array<Scalars['String']['output']>;
  unmaterializedPartitions: Array<Scalars['String']['output']>;
};

export type DefinitionGroup = {
  groupName: Scalars['String']['output'];
  repositoryLocationName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

export type DefinitionTag = {
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DeleteAlertPolicyMutationResult = DeleteAlertPolicySuccess | PythonError | UnauthorizedError;

export type DeleteAlertPolicySuccess = {
  alertPolicyName: Scalars['String']['output'];
};

export type DeleteDeploymentResult = DagsterCloudDeployment | DeleteFinalDeploymentError | DeploymentNotFoundError | PythonError | UnauthorizedError;

export type DeleteDynamicPartitionsResult = DeleteDynamicPartitionsSuccess | PythonError | UnauthorizedError;

export type DeleteDynamicPartitionsSuccess = {
  partitionsDefName: Scalars['String']['output'];
};

export type DeleteFinalDeploymentError = Error & {
  message: Scalars['String']['output'];
};

export type DeleteLocationMutationResult = DeleteLocationSuccess | PythonError | UnauthorizedError;

export type DeleteLocationSuccess = {
  locationName: Scalars['String']['output'];
};

/** The output from deleting a run. */
export type DeletePipelineRunResult = DeletePipelineRunSuccess | PythonError | RunNotFoundError | UnauthorizedError;

/** Output indicating that a run was deleted. */
export type DeletePipelineRunSuccess = {
  runId: Scalars['String']['output'];
};

/** Deletes a run from storage. */
export type DeleteRunMutation = {
  Output: DeletePipelineRunResult;
};

export type DeleteSecretResult = DeleteSecretSuccess | PythonError | UnauthorizedError;

export type DeleteSecretSuccess = {
  secretId: Scalars['String']['output'];
};

export type DeleteTeamMutationResult = DeleteTeamSuccess | PythonError | UnauthorizedError;

export type DeleteTeamSuccess = {
  teamId: Scalars['String']['output'];
};

export type DeployingCode = {
  buildUrl?: Maybe<Scalars['String']['output']>;
  deploymentName?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  githubActionsBuildUrl?: Maybe<Scalars['String']['output']>;
  repoUrl?: Maybe<Scalars['String']['output']>;
  runStartTimestamp?: Maybe<Scalars['Float']['output']>;
  state: GitHubStepState;
  step: CodeDeployStep;
};

/** An enumeration. */
export enum DeploymentAgentType {
  Hybrid = 'HYBRID',
  Serverless = 'SERVERLESS'
}

export type DeploymentCommit = {
  authorAvatarUrl?: Maybe<Scalars['String']['output']>;
  authorEmail?: Maybe<Scalars['String']['output']>;
  authorName?: Maybe<Scalars['String']['output']>;
  commitHash: Scalars['String']['output'];
  commitMessage?: Maybe<Scalars['String']['output']>;
  commitUrl?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['Float']['output'];
};

export type DeploymentCommitInput = {
  authorAvatarUrl?: InputMaybe<Scalars['String']['input']>;
  authorEmail?: InputMaybe<Scalars['String']['input']>;
  authorName?: InputMaybe<Scalars['String']['input']>;
  commitHash: Scalars['String']['input'];
  commitMessage?: InputMaybe<Scalars['String']['input']>;
  commitUrl?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['Float']['input'];
};

export type DeploymentLimitError = Error & {
  message: Scalars['String']['output'];
};

export type DeploymentNotFoundError = Error & {
  message: Scalars['String']['output'];
};

export type DeploymentOrError = DagsterCloudDeployment | DeploymentNotFoundError | PythonError | UnauthorizedError;

export type DeploymentReportingMetricsFilter = {
  branchDeployments?: InputMaybe<Scalars['Boolean']['input']>;
  deploymentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type DeploymentSettings = {
  settings?: Maybe<Scalars['GenericScalar']['output']>;
};

export type DeploymentSettingsInput = {
  settings?: InputMaybe<Scalars['GenericScalar']['input']>;
};

export type DeploymentSettingsSchema = {
  /** Fetch all the named config types that are in deployment settings schema. */
  allConfigTypes: Array<ConfigType>;
  /** Fetch the root config type for deployment settings. */
  rootConfigType: ConfigType;
};

/** An enumeration. */
export enum DeploymentStatus {
  Active = 'ACTIVE',
  PendingDeletion = 'PENDING_DELETION'
}

export type DeselectInstallationResult = DeselectInstallationSuccess | GitHubError | PythonError | UnauthorizedError;

export type DeselectInstallationSuccess = {
  ok: Scalars['Boolean']['output'];
};

export type DevDeploymentInfo = {
  owner: DagsterCloudUser;
};

export type DimensionDefinitionType = {
  description: Scalars['String']['output'];
  dynamicPartitionsDefinitionName?: Maybe<Scalars['String']['output']>;
  isPrimaryDimension: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: PartitionDefinitionType;
};

export type DimensionPartitionKeys = {
  name: Scalars['String']['output'];
  partitionKeys: Array<Scalars['String']['output']>;
  type: PartitionDefinitionType;
};

export type DisplayableEvent = {
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  metadataEntries: Array<MetadataEntry>;
};

export type DryRunInstigationTick = {
  evaluationResult?: Maybe<TickEvaluation>;
  timestamp?: Maybe<Scalars['Float']['output']>;
};

export type DryRunInstigationTicks = {
  cursor: Scalars['Float']['output'];
  results: Array<DryRunInstigationTick>;
};

export type DuplicateDeploymentError = Error & {
  message: Scalars['String']['output'];
};

export type DuplicateDynamicPartitionError = Error & {
  message: Scalars['String']['output'];
  partitionName: Scalars['String']['output'];
  partitionsDefName: Scalars['String']['output'];
};

export type DynamicPartitionRequest = {
  partitionKeys?: Maybe<Array<Scalars['String']['output']>>;
  partitionsDefName: Scalars['String']['output'];
  type: DynamicPartitionsRequestType;
};

export type DynamicPartitionsRequestResult = {
  partitionKeys?: Maybe<Array<Scalars['String']['output']>>;
  partitionsDefName: Scalars['String']['output'];
  skippedPartitionKeys: Array<Scalars['String']['output']>;
  type: DynamicPartitionsRequestType;
};

export enum DynamicPartitionsRequestType {
  AddPartitions = 'ADD_PARTITIONS',
  DeletePartitions = 'DELETE_PARTITIONS'
}

export type EditApiTokenDescriptionResult = DagsterCloudApiToken | DagsterCloudTokenNotFoundError | PythonError | UnauthorizedError;

export type EditDescAgentTokenResult = DagsterCloudAgentToken | DagsterCloudTokenNotFoundError | PythonError | UnauthorizedError;

export type EditDescUserTokenResult = DagsterCloudTokenNotFoundError | DagsterCloudUserToken | PythonError | UnauthorizedError;

export type EditableSecretLocations = {
  allLocations: Scalars['Boolean']['output'];
  locationNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type EmailAlertPolicyNotification = {
  emailAddresses: Array<Scalars['String']['output']>;
};

export type EmailOwnersAlertPolicyNotification = {
  dummyValue?: Maybe<Scalars['String']['output']>;
};

export type EngineEvent = DisplayableEvent & ErrorEvent & MarkerEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<PythonError>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  markerEnd?: Maybe<Scalars['String']['output']>;
  markerStart?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type EnumConfigType = ConfigType & {
  description?: Maybe<Scalars['String']['output']>;
  givenName: Scalars['String']['output'];
  isSelector: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  /**
   *
   * This is an odd and problematic field. It recursively goes down to
   * get all the types contained within a type. The case where it is horrible
   * are dictionaries and it recurses all the way down to the leaves. This means
   * that in a case where one is fetching all the types and then all the inner
   * types keys for those types, we are returning O(N^2) type keys, which
   * can cause awful performance for large schemas. When you have access
   * to *all* the types, you should instead only use the type_param_keys
   * field for closed generic types and manually navigate down the to
   * field types client-side.
   *
   * Where it is useful is when you are fetching types independently and
   * want to be able to render them, but without fetching the entire schema.
   *
   * We use this capability when rendering the sidebar.
   *
   */
  recursiveConfigTypes: Array<ConfigType>;
  /**
   *
   * This returns the keys for type parameters of any closed generic type,
   * (e.g. List, Optional). This should be used for reconstructing and
   * navigating the full schema client-side and not innerTypes.
   *
   */
  typeParamKeys: Array<Scalars['String']['output']>;
  values: Array<EnumConfigValue>;
};

export type EnumConfigValue = {
  description?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type EnvVarConsumer = {
  name: Scalars['String']['output'];
  type: EnvVarConsumerType;
};

export enum EnvVarConsumerType {
  Resource = 'RESOURCE'
}

export type EnvVarWithConsumers = {
  envVarConsumers: Array<EnvVarConsumer>;
  envVarName: Scalars['String']['output'];
};

export type EnvVarWithConsumersList = {
  results: Array<EnvVarWithConsumers>;
};

export type EnvVarWithConsumersOrError = EnvVarWithConsumersList | PythonError;

export type Error = {
  message: Scalars['String']['output'];
};

export type ErrorChainLink = Error & {
  error: PythonError;
  isExplicitLink: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type ErrorEvent = {
  error?: Maybe<PythonError>;
};

/** An enumeration. */
export enum ErrorSource {
  FrameworkError = 'FRAMEWORK_ERROR',
  Interrupt = 'INTERRUPT',
  UnexpectedError = 'UNEXPECTED_ERROR',
  UserCodeError = 'USER_CODE_ERROR'
}

export enum EvaluationErrorReason {
  FieldsNotDefined = 'FIELDS_NOT_DEFINED',
  FieldNotDefined = 'FIELD_NOT_DEFINED',
  MissingRequiredField = 'MISSING_REQUIRED_FIELD',
  MissingRequiredFields = 'MISSING_REQUIRED_FIELDS',
  RuntimeTypeMismatch = 'RUNTIME_TYPE_MISMATCH',
  SelectorFieldError = 'SELECTOR_FIELD_ERROR'
}

export type EvaluationStack = {
  entries: Array<EvaluationStackEntry>;
};

export type EvaluationStackEntry = EvaluationStackListItemEntry | EvaluationStackMapKeyEntry | EvaluationStackMapValueEntry | EvaluationStackPathEntry;

export type EvaluationStackListItemEntry = {
  listIndex: Scalars['Int']['output'];
};

export type EvaluationStackMapKeyEntry = {
  mapKey: Scalars['GenericScalar']['output'];
};

export type EvaluationStackMapValueEntry = {
  mapKey: Scalars['GenericScalar']['output'];
};

export type EvaluationStackPathEntry = {
  fieldName: Scalars['String']['output'];
};

export type EventConnection = {
  cursor: Scalars['String']['output'];
  events: Array<DagsterRunEvent>;
  hasMore: Scalars['Boolean']['output'];
};

export type EventConnectionOrError = EventConnection | PythonError | RunNotFoundError;

export type EventTag = {
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ExecutionMetadata = {
  /**
   * The ID of the run serving as the parent within the run group.
   *         For the first re-execution, this will be the same as the `rootRunId`. For
   *         subsequent runs, the root or a previous re-execution could be the parent run.
   */
  parentRunId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The ID of the run at the root of the run group. All partial /
   *         full re-executions should use the first run as the rootRunID so they are
   *         grouped together.
   */
  rootRunId?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<ExecutionTag>>;
};

export type ExecutionParams = {
  /**
   * Defines run tags and parent / root relationships.
   *
   * Note: To
   *         'restart from failure', provide a `parentRunId` and pass the
   *         'dagster/is_resume_retry' tag. Dagster's automatic step key selection will
   *         override any stepKeys provided.
   */
  executionMetadata?: InputMaybe<ExecutionMetadata>;
  mode?: InputMaybe<Scalars['String']['input']>;
  preset?: InputMaybe<Scalars['String']['input']>;
  runConfigData?: InputMaybe<Scalars['RunConfigData']['input']>;
  /**
   * Defines the job / pipeline and solid subset that should be executed.
   *         All subsequent executions in the same run group (for example, a single-step
   *         re-execution) are scoped to the original run's selector and solid
   *         subset.
   */
  selector: JobOrPipelineSelector;
  /**
   * Defines step keys to execute within the execution plan defined
   *         by the pipeline `selector`. To execute the entire execution plan, you can omit
   *         this parameter, provide an empty array, or provide every step name.
   */
  stepKeys?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ExecutionPlan = {
  artifactsPersisted: Scalars['Boolean']['output'];
  steps: Array<ExecutionStep>;
};

export type ExecutionPlanOrError = ExecutionPlan | InvalidSubsetError | PipelineNotFoundError | PythonError | RunConfigValidationInvalid;

export type ExecutionStep = {
  inputs: Array<ExecutionStepInput>;
  key: Scalars['String']['output'];
  kind: StepKind;
  metadata: Array<MetadataItemDefinition>;
  outputs: Array<ExecutionStepOutput>;
  solidHandleID: Scalars['String']['output'];
};

export type ExecutionStepFailureEvent = ErrorEvent & MessageEvent & StepEvent & {
  error?: Maybe<PythonError>;
  errorSource?: Maybe<ErrorSource>;
  eventType?: Maybe<DagsterEventType>;
  failureMetadata?: Maybe<FailureMetadata>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ExecutionStepInput = {
  dependsOn: Array<ExecutionStep>;
  name: Scalars['String']['output'];
};

export type ExecutionStepInputEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  inputName: Scalars['String']['output'];
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
  typeCheck: TypeCheck;
};

export type ExecutionStepOutput = {
  name: Scalars['String']['output'];
};

export type ExecutionStepOutputEvent = DisplayableEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  outputName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
  typeCheck: TypeCheck;
};

export type ExecutionStepRestartEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ExecutionStepSkippedEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ExecutionStepStartEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ExecutionStepSuccessEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ExecutionStepUpForRetryEvent = ErrorEvent & MessageEvent & StepEvent & {
  error?: Maybe<PythonError>;
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  secondsToWait?: Maybe<Scalars['Int']['output']>;
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ExecutionTag = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** An enumeration. */
export enum ExistingRepoStatus {
  ExistingInstall = 'EXISTING_INSTALL',
  NotFound = 'NOT_FOUND',
  NoDagsterCloudYamlAndPyprojectToml = 'NO_DAGSTER_CLOUD_YAML_AND_PYPROJECT_TOML',
  Ok = 'OK'
}

export type ExpectationResult = DisplayableEvent & {
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  metadataEntries: Array<MetadataEntry>;
  success: Scalars['Boolean']['output'];
};

export type FailedToSetupStripe = Error & {
  message: Scalars['String']['output'];
};

export type FailureMetadata = DisplayableEvent & {
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  metadataEntries: Array<MetadataEntry>;
};

export type FeatureFlag = {
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** An enumeration. */
export enum FeatureGateKey {
  AssetHealthLiveDataKillSwitch = 'ASSET_HEALTH_LIVE_DATA_KILL_SWITCH',
  BackendSearchEnabled = 'BACKEND_SEARCH_ENABLED',
  BackfillLogStorage = 'BACKFILL_LOG_STORAGE',
  BillingAndUsageEnabled = 'BILLING_AND_USAGE_ENABLED',
  CodeSyncEnabled = 'CODE_SYNC_ENABLED',
  DbtNuxUi = 'DBT_NUX_UI',
  DevDeploymentsEnabled = 'DEV_DEPLOYMENTS_ENABLED',
  EditorAdminLimit = 'EDITOR_ADMIN_LIMIT',
  EnableAuditLogAccess = 'ENABLE_AUDIT_LOG_ACCESS',
  EnableCatalogViews = 'ENABLE_CATALOG_VIEWS',
  EnableIngestInsightsFromMetadata = 'ENABLE_INGEST_INSIGHTS_FROM_METADATA',
  EnableOrgSettingsTeamsPage = 'ENABLE_ORG_SETTINGS_TEAMS_PAGE',
  EnableOverdueAlerts = 'ENABLE_OVERDUE_ALERTS',
  EnablePagerdutyAlerts = 'ENABLE_PAGERDUTY_ALERTS',
  EnableReportingPage = 'ENABLE_REPORTING_PAGE',
  EnableScimProvisioningPage = 'ENABLE_SCIM_PROVISIONING_PAGE',
  EnableTrackingDefinitionsHistory = 'ENABLE_TRACKING_DEFINITIONS_HISTORY',
  GitlabNuxEnabled = 'GITLAB_NUX_ENABLED',
  InsightsEnableAlerts = 'INSIGHTS_ENABLE_ALERTS',
  InsightsHideGqlSubmissionPathCostMetrics = 'INSIGHTS_HIDE_GQL_SUBMISSION_PATH_COST_METRICS',
  InsightsShowBigqueryCostMetrics = 'INSIGHTS_SHOW_BIGQUERY_COST_METRICS',
  InsightsUseS3SubmissionPathCostMetrics = 'INSIGHTS_USE_S3_SUBMISSION_PATH_COST_METRICS',
  InstancePubliclyVisible = 'INSTANCE_PUBLICLY_VISIBLE',
  NativeSecretsEnabled = 'NATIVE_SECRETS_ENABLED',
  OnboardingChecklistEnabled = 'ONBOARDING_CHECKLIST_ENABLED',
  PricingMay_2023Ui = 'PRICING_MAY_2023_UI',
  ProductionDeploymentLimit = 'PRODUCTION_DEPLOYMENT_LIMIT',
  ServerlessQuickstartRepoUrl = 'SERVERLESS_QUICKSTART_REPO_URL',
  ShowAutoMaterializeSensorBanner = 'SHOW_AUTO_MATERIALIZE_SENSOR_BANNER',
  TrialServerlessUsageLimit = 'TRIAL_SERVERLESS_USAGE_LIMIT'
}

export type FieldNotDefinedConfigError = PipelineConfigValidationError & {
  fieldName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  reason: EvaluationErrorReason;
  stack: EvaluationStack;
};

export type FieldsNotDefinedConfigError = PipelineConfigValidationError & {
  fieldNames: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  reason: EvaluationErrorReason;
  stack: EvaluationStack;
};

export type FloatMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  floatValue?: Maybe<Scalars['Float']['output']>;
  label: Scalars['String']['output'];
};

export type FreshnessPolicy = {
  cronSchedule?: Maybe<Scalars['String']['output']>;
  cronScheduleTimezone?: Maybe<Scalars['String']['output']>;
  lastEvaluationTimestamp?: Maybe<Scalars['String']['output']>;
  maximumLagMinutes: Scalars['Float']['output'];
};

export type GitHubCreatingRepo = {
  errorMessage?: Maybe<Scalars['String']['output']>;
  githubUrl?: Maybe<Scalars['String']['output']>;
  isImport: Scalars['Boolean']['output'];
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  repoCreationTime?: Maybe<Scalars['Float']['output']>;
  repoName?: Maybe<Scalars['String']['output']>;
  repoUrl?: Maybe<Scalars['String']['output']>;
  state: GitHubStepState;
  step: GitHubCreationStep;
  templateUrl?: Maybe<Scalars['String']['output']>;
};

export enum GitHubCreationStep {
  GeneratingToken = 'GENERATING_TOKEN',
  Idle = 'IDLE',
  SettingUpRepo = 'SETTING_UP_REPO',
  SettingUpSecrets = 'SETTING_UP_SECRETS'
}

export type GitHubError = Error & {
  message: Scalars['String']['output'];
};

export type GitHubRepo = {
  /** Retrieve the DBT project config file paths found in the given repository. */
  dbtProjectConfigPaths: Array<Scalars['String']['output']>;
  lastUpdatedTimestamp: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type GitHubRepoDbtProjectConfigPathsArgs = {
  recursive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GitHubSetup = {
  availableGitHubAppInstallations: Array<AvailableGitHubInstallation>;
  awaitingGitHubAdminApproval: Scalars['Boolean']['output'];
  branchAgainstRepo?: Maybe<BranchAgainstRepo>;
  creatingRepo?: Maybe<GitHubCreatingRepo>;
  deployingCode?: Maybe<DeployingCode>;
  existingRepoStatus?: Maybe<ExistingRepoStatus>;
  id: Scalars['String']['output'];
  isUserGitHubAuthed: Scalars['Boolean']['output'];
};


export type GitHubSetupExistingRepoStatusArgs = {
  repoName: Scalars['String']['input'];
};

export type GitHubSetupOrError = GitHubSetup | PythonError | UnauthorizedError;

export enum GitHubStepState {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

/** An enumeration. */
export enum GitProvider {
  Github = 'GITHUB',
  Gitlab = 'GITLAB'
}

export type GithubAppInstallation = {
  accountName?: Maybe<Scalars['String']['output']>;
  appId: Scalars['String']['output'];
  dbtRepos: Array<GitHubRepo>;
  repos: Array<Scalars['String']['output']>;
  repositories: Array<GitHubRepo>;
  /** Retrieve a GitHub repository by name. */
  repository?: Maybe<GitHubRepo>;
  settingsUrl?: Maybe<Scalars['String']['output']>;
};


export type GithubAppInstallationRepositoryArgs = {
  name: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['String']['input']>;
};

export type Gitlab = {
  branchAgainstRepo?: Maybe<BranchAgainstRepo>;
  creatingRepo?: Maybe<GitHubCreatingRepo>;
  dbtProjects?: Maybe<Array<GitlabProject>>;
  deployingCode?: Maybe<DeployingCode>;
  existingRepoStatus?: Maybe<ExistingRepoStatus>;
  isUserAuthed: Scalars['Boolean']['output'];
  namespaces?: Maybe<Array<GitlabNamespace>>;
  /** Get a Gitlab project by name */
  project?: Maybe<GitlabProject>;
  projects?: Maybe<Array<GitlabProject>>;
  username?: Maybe<Scalars['String']['output']>;
};


export type GitlabExistingRepoStatusArgs = {
  repoName: Scalars['String']['input'];
};


export type GitlabProjectArgs = {
  name: Scalars['String']['input'];
};

export type GitlabNamespace = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  namespaceId: Scalars['ID']['output'];
};

export type GitlabOrError = Gitlab | PythonError | UnauthorizedError;

export type GitlabProject = {
  /** Retrieve the DBT project config file paths found in the given repository. */
  dbtProjectConfigPaths: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastUpdatedTimestamp: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  namespace: GitlabNamespace;
  url: Scalars['String']['output'];
};


export type GitlabProjectDbtProjectConfigPathsArgs = {
  recursive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Graph = SolidContainer & {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modes: Array<Mode>;
  name: Scalars['String']['output'];
  solidHandle?: Maybe<SolidHandle>;
  solidHandles: Array<SolidHandle>;
  solids: Array<Solid>;
};


export type GraphSolidHandleArgs = {
  handleID: Scalars['String']['input'];
};


export type GraphSolidHandlesArgs = {
  parentHandleID?: InputMaybe<Scalars['String']['input']>;
};

export type GraphNotFoundError = Error & {
  graphName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  repositoryLocationName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

export type GraphOrError = Graph | GraphNotFoundError | PythonError;

/**
 * This type represents the fields necessary to identify a
 *         graph
 */
export type GraphSelector = {
  graphName: Scalars['String']['input'];
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
};

export type GroupAssetSelectionInput = {
  groupName: Scalars['String']['input'];
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
};

export type HandledOutputEvent = DisplayableEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  managerKey: Scalars['String']['output'];
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  outputName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type HookCompletedEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type HookErroredEvent = ErrorEvent & MessageEvent & StepEvent & {
  error?: Maybe<PythonError>;
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type HookSkippedEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type IPipelineSnapshot = {
  dagsterTypeOrError: DagsterTypeOrError;
  dagsterTypes: Array<DagsterType>;
  description?: Maybe<Scalars['String']['output']>;
  graphName: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  modes: Array<Mode>;
  name: Scalars['String']['output'];
  parentSnapshotId?: Maybe<Scalars['String']['output']>;
  pipelineSnapshotId: Scalars['String']['output'];
  runs: Array<Run>;
  schedules: Array<Schedule>;
  sensors: Array<Sensor>;
  solidHandle?: Maybe<SolidHandle>;
  solidHandles: Array<SolidHandle>;
  solids: Array<Solid>;
  tags: Array<PipelineTag>;
};


export type IPipelineSnapshotDagsterTypeOrErrorArgs = {
  dagsterTypeName: Scalars['String']['input'];
};


export type IPipelineSnapshotRunsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type IPipelineSnapshotSolidHandleArgs = {
  handleID: Scalars['String']['input'];
};


export type IPipelineSnapshotSolidHandlesArgs = {
  parentHandleID?: InputMaybe<Scalars['String']['input']>;
};

export type ISolidDefinition = {
  assetNodes: Array<AssetNode>;
  description?: Maybe<Scalars['String']['output']>;
  inputDefinitions: Array<InputDefinition>;
  metadata: Array<MetadataItemDefinition>;
  name: Scalars['String']['output'];
  outputDefinitions: Array<OutputDefinition>;
};

export type Input = {
  definition: InputDefinition;
  dependsOn: Array<Output>;
  isDynamicCollect: Scalars['Boolean']['output'];
  solid: Solid;
};

export type InputDefinition = {
  description?: Maybe<Scalars['String']['output']>;
  metadataEntries: Array<MetadataEntry>;
  name: Scalars['String']['output'];
  type: DagsterType;
};

export type InputMapping = {
  definition: InputDefinition;
  mappedInput: Input;
};

/**
 * Possible comparison operators for an insights alert type, used to
 *     determine when to trigger an alert based on the value of the metric.
 *
 */
export enum InsightsAlertComparisonOperator {
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN'
}

export type InsightsAssetGroupThresholdTarget = {
  assetGroup: Scalars['String']['output'];
  locationName: Scalars['String']['output'];
  metricName: Scalars['String']['output'];
  operator: InsightsAlertComparisonOperator;
  repoName?: Maybe<Scalars['String']['output']>;
  selectionPeriodDays: Scalars['Int']['output'];
  threshold: Scalars['Float']['output'];
};

export type InsightsAssetThresholdTarget = {
  assetKey: AssetKey;
  metricName: Scalars['String']['output'];
  operator: InsightsAlertComparisonOperator;
  selectionPeriodDays: Scalars['Int']['output'];
  threshold: Scalars['Float']['output'];
};

export type InsightsDeploymentThresholdTarget = {
  metricName: Scalars['String']['output'];
  operator: InsightsAlertComparisonOperator;
  selectionPeriodDays: Scalars['Int']['output'];
  threshold: Scalars['Float']['output'];
};

export type InsightsEmptyRunEntry = {
  /** The run ID for the metric value. */
  runId: Scalars['String']['output'];
  /** The timestamp for the metric value. */
  timestamp: Scalars['Float']['output'];
};

export type InsightsJobThresholdTarget = {
  jobName: Scalars['String']['output'];
  locationName: Scalars['String']['output'];
  metricName: Scalars['String']['output'];
  operator: InsightsAlertComparisonOperator;
  repoName?: Maybe<Scalars['String']['output']>;
  selectionPeriodDays: Scalars['Int']['output'];
  threshold: Scalars['Float']['output'];
};

export type InsightsMetricCostMultiplier = {
  costMultiplier?: Maybe<Scalars['Float']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metricKey: Scalars['String']['output'];
};

export type InsightsMetricCustomization = {
  customMetricDescription?: Maybe<Scalars['String']['output']>;
  customMetricIcon?: Maybe<Scalars['String']['output']>;
  customMetricName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metricKey: Scalars['String']['output'];
  metricShown: Scalars['Boolean']['output'];
};

export type InsightsMetricCustomizationInput = {
  customMetricDescription?: InputMaybe<Scalars['String']['input']>;
  customMetricIcon?: InputMaybe<Scalars['String']['input']>;
  customMetricName?: InputMaybe<Scalars['String']['input']>;
  metricKey: Scalars['String']['input'];
  metricShown: Scalars['Boolean']['input'];
};

export type InsightsRunEntry = {
  /** The run ID for the metric value. */
  runId: Scalars['String']['output'];
  /** The timestamp for the metric value. */
  timestamp: Scalars['Float']['output'];
  /** The metric value for the given run. */
  value: Scalars['Float']['output'];
};

export type InsightsRunLevelMetrics = {
  /** The entity the reporting data is associated with. */
  entity: ReportingObjet;
  /** The metric values for the time period. */
  runsWithData: Array<Maybe<InsightsRunEntry>>;
  /** Runs for which no data is available for the time period. */
  runsWithoutData: Array<Maybe<InsightsEmptyRunEntry>>;
};

export type InsightsRunLevelMetricsOrError = InsightsRunLevelMetrics | PythonError | ReportingInputError | UnauthorizedError;

export type InsightsRunLevelMetricsSelector = {
  metricName: Scalars['String']['input'];
};

export type InstallationNotFoundError = Error & {
  message: Scalars['String']['output'];
};

export type Instance = {
  autoMaterializePaused: Scalars['Boolean']['output'];
  concurrencyLimit: ConcurrencyKeyInfo;
  concurrencyLimits: Array<ConcurrencyKeyInfo>;
  daemonHealth: DaemonHealth;
  executablePath: Scalars['String']['output'];
  hasInfo: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  info?: Maybe<Scalars['String']['output']>;
  maxConcurrencyLimitValue: Scalars['Int']['output'];
  minConcurrencyLimitValue: Scalars['Int']['output'];
  runLauncher?: Maybe<RunLauncher>;
  runQueueConfig?: Maybe<RunQueueConfig>;
  runQueuingSupported: Scalars['Boolean']['output'];
  supportsConcurrencyLimits: Scalars['Boolean']['output'];
  /** Whether or not the deployment is using automation policy sensors to materialize assets */
  useAutoMaterializeSensors: Scalars['Boolean']['output'];
};


export type InstanceConcurrencyLimitArgs = {
  concurrencyKey?: InputMaybe<Scalars['String']['input']>;
};

export type InstigationEvent = {
  level: LogLevel;
  message: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type InstigationEventConnection = {
  cursor: Scalars['String']['output'];
  events: Array<InstigationEvent>;
  hasMore: Scalars['Boolean']['output'];
};

/** This type represents the fields necessary to identify a schedule or sensor. */
export type InstigationSelector = {
  name: Scalars['String']['input'];
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
};

export type InstigationState = {
  hasStartPermission: Scalars['Boolean']['output'];
  hasStopPermission: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  instigationType: InstigationType;
  name: Scalars['String']['output'];
  nextTick?: Maybe<DryRunInstigationTick>;
  repositoryLocationName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
  repositoryOrigin: RepositoryOrigin;
  runningCount: Scalars['Int']['output'];
  runs: Array<Run>;
  runsCount: Scalars['Int']['output'];
  selectorId: Scalars['String']['output'];
  status: InstigationStatus;
  tick: InstigationTick;
  ticks: Array<InstigationTick>;
  typeSpecificData?: Maybe<InstigationTypeSpecificData>;
};


export type InstigationStateRunsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type InstigationStateTickArgs = {
  tickId: Scalars['BigInt']['input'];
};


export type InstigationStateTicksArgs = {
  afterTimestamp?: InputMaybe<Scalars['Float']['input']>;
  beforeTimestamp?: InputMaybe<Scalars['Float']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  dayOffset?: InputMaybe<Scalars['Int']['input']>;
  dayRange?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InstigationTickStatus>>;
};

export type InstigationStateNotFoundError = Error & {
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InstigationStateOrError = InstigationState | InstigationStateNotFoundError | PythonError;

export type InstigationStates = {
  results: Array<InstigationState>;
};

export type InstigationStatesOrError = InstigationStates | PythonError;

export enum InstigationStatus {
  Running = 'RUNNING',
  Stopped = 'STOPPED'
}

export type InstigationTick = {
  autoMaterializeAssetEvaluationId?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['String']['output']>;
  dynamicPartitionsRequestResults: Array<DynamicPartitionsRequestResult>;
  endTimestamp?: Maybe<Scalars['Float']['output']>;
  error?: Maybe<PythonError>;
  id: Scalars['ID']['output'];
  instigationType: InstigationType;
  logEvents: InstigationEventConnection;
  logKey?: Maybe<Array<Scalars['String']['output']>>;
  originRunIds: Array<Scalars['String']['output']>;
  requestedAssetKeys: Array<AssetKey>;
  requestedAssetMaterializationCount: Scalars['Int']['output'];
  requestedMaterializationsForAssets: Array<RequestedMaterializationsForAsset>;
  runIds: Array<Scalars['String']['output']>;
  runKeys: Array<Scalars['String']['output']>;
  runs: Array<Run>;
  skipReason?: Maybe<Scalars['String']['output']>;
  status: InstigationTickStatus;
  tickId: Scalars['ID']['output'];
  timestamp: Scalars['Float']['output'];
};

export enum InstigationTickStatus {
  Failure = 'FAILURE',
  Skipped = 'SKIPPED',
  Started = 'STARTED',
  Success = 'SUCCESS'
}

/** An enumeration. */
export enum InstigationType {
  AutoMaterialize = 'AUTO_MATERIALIZE',
  Schedule = 'SCHEDULE',
  Sensor = 'SENSOR'
}

export type InstigationTypeSpecificData = ScheduleData | SensorData;

export type Instigator = Schedule | Sensor;

export type IntMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  /** String representation of the int to support greater than 32 bit */
  intRepr: Scalars['String']['output'];
  /** Nullable to allow graceful degrade on > 32 bit numbers */
  intValue?: Maybe<Scalars['Int']['output']>;
  label: Scalars['String']['output'];
};

export type InvalidAlertPolicyError = Error & {
  errors: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type InvalidLocationError = Error & {
  errors: Array<Maybe<Scalars['String']['output']>>;
  message: Scalars['String']['output'];
};

export type InvalidOutputError = {
  invalidOutputName: Scalars['String']['output'];
  stepKey: Scalars['String']['output'];
};

export type InvalidPipelineRunsFilterError = Error & {
  message: Scalars['String']['output'];
};

export type InvalidSecretInputError = Error & {
  message: Scalars['String']['output'];
};

export type InvalidStepError = {
  invalidStepKey: Scalars['String']['output'];
};

export type InvalidSubsetError = Error & {
  message: Scalars['String']['output'];
  pipeline: Pipeline;
};

export type InvalidTemplateRepoError = Error & {
  message: Scalars['String']['output'];
};

export type Job = IPipelineSnapshot & SolidContainer & {
  dagsterTypeOrError: DagsterTypeOrError;
  dagsterTypes: Array<DagsterType>;
  description?: Maybe<Scalars['String']['output']>;
  graphName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAssetJob: Scalars['Boolean']['output'];
  isJob: Scalars['Boolean']['output'];
  metadataEntries: Array<MetadataEntry>;
  modes: Array<Mode>;
  name: Scalars['String']['output'];
  parentSnapshotId?: Maybe<Scalars['String']['output']>;
  pipelineSnapshotId: Scalars['String']['output'];
  presets: Array<PipelinePreset>;
  repository: Repository;
  runs: Array<Run>;
  schedules: Array<Schedule>;
  sensors: Array<Sensor>;
  solidHandle?: Maybe<SolidHandle>;
  solidHandles: Array<SolidHandle>;
  solids: Array<Solid>;
  tags: Array<PipelineTag>;
};


export type JobDagsterTypeOrErrorArgs = {
  dagsterTypeName: Scalars['String']['input'];
};


export type JobRunsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type JobSolidHandleArgs = {
  handleID: Scalars['String']['input'];
};


export type JobSolidHandlesArgs = {
  parentHandleID?: InputMaybe<Scalars['String']['input']>;
};

export type JobMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  jobName: Scalars['String']['output'];
  label: Scalars['String']['output'];
  locationName: Scalars['String']['output'];
  repositoryName?: Maybe<Scalars['String']['output']>;
};

export type JobMetricInputs = {
  metricValues?: InputMaybe<Array<InputMaybe<MetricEntryInput>>>;
};

/** This type represents the fields necessary to identify a job or pipeline */
export type JobOrPipelineSelector = {
  assetCheckSelection?: InputMaybe<Array<AssetCheckHandleInput>>;
  assetSelection?: InputMaybe<Array<AssetKeyInput>>;
  jobName?: InputMaybe<Scalars['String']['input']>;
  pipelineName?: InputMaybe<Scalars['String']['input']>;
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
  solidSelection?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type JobReportingMetricsFilter = {
  codeLocations?: InputMaybe<Array<InputMaybe<RepositoryCodeLocation>>>;
  jobs?: InputMaybe<Array<InputMaybe<QualifiedJob>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type JobWithOps = {
  jobName: Scalars['String']['output'];
  opHandleIDs: Array<Scalars['String']['output']>;
};

export type JsonMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  jsonString: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

/** Launches a set of partition backfill runs. */
export type LaunchBackfillMutation = {
  Output: LaunchBackfillResult;
};

export type LaunchBackfillParams = {
  allPartitions?: InputMaybe<Scalars['Boolean']['input']>;
  assetSelection?: InputMaybe<Array<AssetKeyInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  forceSynchronousSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  fromFailure?: InputMaybe<Scalars['Boolean']['input']>;
  partitionNames?: InputMaybe<Array<Scalars['String']['input']>>;
  partitionsByAssets?: InputMaybe<Array<InputMaybe<PartitionsByAssetSelector>>>;
  reexecutionSteps?: InputMaybe<Array<Scalars['String']['input']>>;
  selector?: InputMaybe<PartitionSetSelector>;
  tags?: InputMaybe<Array<ExecutionTag>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type LaunchBackfillResult = ConflictingExecutionParamsError | InvalidOutputError | InvalidStepError | InvalidSubsetError | LaunchBackfillSuccess | NoModeProvidedError | PartitionSetNotFoundError | PipelineNotFoundError | PresetNotFoundError | PythonError | RunConfigValidationInvalid | RunConflict | UnauthorizedError;

export type LaunchBackfillSuccess = {
  backfillId: Scalars['String']['output'];
  launchedRunIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type LaunchPipelineRunSuccess = {
  run: Run;
};

/** Launches a job run. */
export type LaunchRunMutation = {
  Output: LaunchRunResult;
};

/** Re-executes a job run. */
export type LaunchRunReexecutionMutation = {
  Output: LaunchRunReexecutionResult;
};

export type LaunchRunReexecutionResult = ConflictingExecutionParamsError | InvalidOutputError | InvalidStepError | InvalidSubsetError | LaunchRunSuccess | NoModeProvidedError | PipelineNotFoundError | PresetNotFoundError | PythonError | RunConfigValidationInvalid | RunConflict | UnauthorizedError;

export type LaunchRunResult = ConflictingExecutionParamsError | InvalidOutputError | InvalidStepError | InvalidSubsetError | LaunchRunSuccess | NoModeProvidedError | PipelineNotFoundError | PresetNotFoundError | PythonError | RunConfigValidationInvalid | RunConflict | UnauthorizedError;

export type LaunchRunSuccess = LaunchPipelineRunSuccess & {
  run: Run;
};

export type ListDagsterType = DagsterType & WrappingDagsterType & {
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  innerTypes: Array<DagsterType>;
  inputSchemaType?: Maybe<ConfigType>;
  isBuiltin: Scalars['Boolean']['output'];
  isList: Scalars['Boolean']['output'];
  isNothing: Scalars['Boolean']['output'];
  isNullable: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  name?: Maybe<Scalars['String']['output']>;
  ofType: DagsterType;
  outputSchemaType?: Maybe<ConfigType>;
};

export type LoadedInputEvent = DisplayableEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  inputName: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  managerKey: Scalars['String']['output'];
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
  upstreamOutputName?: Maybe<Scalars['String']['output']>;
  upstreamStepKey?: Maybe<Scalars['String']['output']>;
};

export type LocalFileCodeReference = {
  filePath: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
};

export type LocationSchema = {
  /** Fetch all the named config types that are in the code location config document schema. */
  allConfigTypes: Array<ConfigType>;
  /** Fetch the root config type for the code location config document. */
  rootConfigType: ConfigType;
};

export type LocationScopedGrant = {
  grant: PermissionGrant;
  locationName: Scalars['String']['output'];
};

export type LocationScopedGrantInput = {
  grant: PermissionGrant;
  locationName: Scalars['String']['input'];
};

export type LocationSelector = {
  agentQueue?: InputMaybe<Scalars['String']['input']>;
  attribute?: InputMaybe<Scalars['String']['input']>;
  commitHash?: InputMaybe<Scalars['String']['input']>;
  executablePath?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  moduleName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  packageName?: InputMaybe<Scalars['String']['input']>;
  pythonFile?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  workingDirectory?: InputMaybe<Scalars['String']['input']>;
};

export type LocationStateChangeEvent = {
  eventType: LocationStateChangeEventType;
  locationName: Scalars['String']['output'];
  message: Scalars['String']['output'];
  serverId?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum LocationStateChangeEventType {
  LocationDisconnected = 'LOCATION_DISCONNECTED',
  LocationError = 'LOCATION_ERROR',
  LocationReconnected = 'LOCATION_RECONNECTED',
  LocationUpdated = 'LOCATION_UPDATED'
}

export type LocationStateChangeSubscription = {
  event: LocationStateChangeEvent;
};

export type LocationsAsDocument = {
  document?: Maybe<Scalars['GenericScalar']['output']>;
};

export enum LogLevel {
  Critical = 'CRITICAL',
  Debug = 'DEBUG',
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export type LogMessageEvent = MessageEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

/** The output from logging telemetry. */
export type LogTelemetryMutationResult = LogTelemetrySuccess | PythonError;

/** Output indicating that telemetry was logged. */
export type LogTelemetrySuccess = {
  action: Scalars['String']['output'];
};

export type Logger = {
  configField?: Maybe<ConfigTypeField>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type LogsCapturedEvent = MessageEvent & {
  eventType?: Maybe<DagsterEventType>;
  externalStderrUrl?: Maybe<Scalars['String']['output']>;
  externalStdoutUrl?: Maybe<Scalars['String']['output']>;
  externalUrl?: Maybe<Scalars['String']['output']>;
  fileKey: Scalars['String']['output'];
  level: LogLevel;
  logKey: Scalars['String']['output'];
  message: Scalars['String']['output'];
  pid?: Maybe<Scalars['Int']['output']>;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  stepKeys?: Maybe<Array<Scalars['String']['output']>>;
  timestamp: Scalars['String']['output'];
};

export type LongRunningJobThresholdTarget = {
  thresholdSeconds: Scalars['Float']['output'];
};

export type MapConfigType = ConfigType & {
  description?: Maybe<Scalars['String']['output']>;
  isSelector: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  keyLabelName?: Maybe<Scalars['String']['output']>;
  keyType: ConfigType;
  /**
   *
   * This is an odd and problematic field. It recursively goes down to
   * get all the types contained within a type. The case where it is horrible
   * are dictionaries and it recurses all the way down to the leaves. This means
   * that in a case where one is fetching all the types and then all the inner
   * types keys for those types, we are returning O(N^2) type keys, which
   * can cause awful performance for large schemas. When you have access
   * to *all* the types, you should instead only use the type_param_keys
   * field for closed generic types and manually navigate down the to
   * field types client-side.
   *
   * Where it is useful is when you are fetching types independently and
   * want to be able to render them, but without fetching the entire schema.
   *
   * We use this capability when rendering the sidebar.
   *
   */
  recursiveConfigTypes: Array<ConfigType>;
  /**
   *
   * This returns the keys for type parameters of any closed generic type,
   * (e.g. List, Optional). This should be used for reconstructing and
   * navigating the full schema client-side and not innerTypes.
   *
   */
  typeParamKeys: Array<Scalars['String']['output']>;
  valueType: ConfigType;
};

export type MarkdownMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  mdStr: Scalars['String']['output'];
};

export type MarkerEvent = {
  markerEnd?: Maybe<Scalars['String']['output']>;
  markerStart?: Maybe<Scalars['String']['output']>;
};

export type MarshalledInput = {
  inputName: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type MarshalledOutput = {
  key: Scalars['String']['input'];
  outputName: Scalars['String']['input'];
};

export type MaterializationEvent = DisplayableEvent & MessageEvent & StepEvent & {
  assetKey?: Maybe<AssetKey>;
  assetLineage: Array<AssetLineageInfo>;
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  partition?: Maybe<Scalars['String']['output']>;
  runId: Scalars['String']['output'];
  runOrError: RunOrError;
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  stepStats: RunStepStats;
  tags: Array<EventTag>;
  timestamp: Scalars['String']['output'];
};

export type MaterializationUpstreamDataVersion = {
  assetKey: AssetKey;
  downstreamAssetKey: AssetKey;
  timestamp: Scalars['String']['output'];
};

/**
 * The primary dimension of a multipartitioned asset is the time-partitioned dimension.
 * If both dimensions of the asset are static or time-partitioned, the primary dimension is
 * the first defined dimension.
 */
export type MaterializedPartitionRangeStatuses2D = {
  primaryDimEndKey: Scalars['String']['output'];
  primaryDimEndTime?: Maybe<Scalars['Float']['output']>;
  primaryDimStartKey: Scalars['String']['output'];
  primaryDimStartTime?: Maybe<Scalars['Float']['output']>;
  secondaryDim: PartitionStatus1D;
};

export type MergePullRequestResult = GitHubError | MergePullRequestSuccess | MissingPermissionsError | PythonError | SetupRepoError | UnauthorizedError;

export type MergePullRequestSuccess = {
  pullRequestUrl: Scalars['String']['output'];
};

export type MessageEvent = {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type MetadataEntry = {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type MetadataItemDefinition = {
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetricEntryInput = {
  metricName: Scalars['String']['input'];
  metricValue: Scalars['Float']['input'];
};

export type MetricInputs = {
  assetMetricDefinitions?: InputMaybe<Array<InputMaybe<AssetMetricInputs>>>;
  codeLocationName?: InputMaybe<Scalars['String']['input']>;
  jobMetricDefinitions?: InputMaybe<Array<InputMaybe<JobMetricInputs>>>;
  repositoryName?: InputMaybe<Scalars['String']['input']>;
  runId: Scalars['String']['input'];
  stepKey?: InputMaybe<Scalars['String']['input']>;
};

export type MetricType = {
  /** The category of the metric, if available. */
  category?: Maybe<Scalars['String']['output']>;
  /** The cost per unit, if set. */
  costMultiplier?: Maybe<Scalars['Float']['output']>;
  /** The icon of the metric, if available. */
  customIcon?: Maybe<Scalars['String']['output']>;
  /** The default, non-user-customized description of the metric. */
  defaultDescription?: Maybe<Scalars['String']['output']>;
  /** The default, non-user-customized display name of the metric. */
  defaultDisplayName: Scalars['String']['output'];
  /** The description of the metric, if available. */
  description?: Maybe<Scalars['String']['output']>;
  /** The display name of the metric. */
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** The internal name of the metric. */
  metricName: Scalars['String']['output'];
  /** Whether the metric is not yet available. */
  pending?: Maybe<Scalars['Boolean']['output']>;
  /** The priority of the metric. */
  priority?: Maybe<Scalars['Int']['output']>;
  /** The unit type of the metric, if available. */
  unitType?: Maybe<ReportingUnitType>;
  /** Whether the metric is visible on the sidebar. */
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export type MetricTypeList = {
  id: Scalars['String']['output'];
  /** A list of available metric types. */
  metricTypes: Array<Maybe<MetricType>>;
};

export type MetricTypeListOrError = MetricTypeList | PythonError | UnauthorizedError;

export type MetricsDataPoint = {
  timestamp: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type MetricsTimeSeries = {
  endTimestamp: Scalars['Float']['output'];
  metricName: Scalars['String']['output'];
  points: Array<MetricsDataPoint>;
  startTimestamp: Scalars['Float']['output'];
  tags: Array<Scalars['String']['output']>;
};

export type MicrosoftTeamsAlertPolicyNotification = {
  webhookUrl: Scalars['String']['output'];
};

export type MissingFieldConfigError = PipelineConfigValidationError & {
  field: ConfigTypeField;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  reason: EvaluationErrorReason;
  stack: EvaluationStack;
};

export type MissingFieldsConfigError = PipelineConfigValidationError & {
  fields: Array<ConfigTypeField>;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  reason: EvaluationErrorReason;
  stack: EvaluationStack;
};

export type MissingPermissionsError = Error & {
  message: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type MissingRunIdErrorEvent = {
  invalidRunId: Scalars['String']['output'];
};

export type Mode = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  loggers: Array<Logger>;
  name: Scalars['String']['output'];
  resources: Array<Resource>;
};

export type ModeNotFoundError = Error & {
  message: Scalars['String']['output'];
  mode: Scalars['String']['output'];
};

export type ModifyAgentTokenResult = DagsterCloudAgentToken | PythonError | UnauthorizedError;

export type MultiPartitionStatuses = {
  primaryDimensionName: Scalars['String']['output'];
  ranges: Array<MaterializedPartitionRangeStatuses2D>;
};

export type NestedResourceEntry = {
  name: Scalars['String']['output'];
  resource?: Maybe<ResourceDetails>;
  type: NestedResourceType;
};

/** An enumeration. */
export enum NestedResourceType {
  Anonymous = 'ANONYMOUS',
  TopLevel = 'TOP_LEVEL'
}

export type NoModeProvidedError = Error & {
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
};

/** An invocation of a solid within a repo. */
export type NodeInvocationSite = {
  pipeline: Pipeline;
  solidHandle: SolidHandle;
};

export type NonIsolatedRuns = {
  enableNonIsolatedRunToggle: Scalars['Boolean']['output'];
  nonIsolatedRunToggleMessage: Scalars['String']['output'];
  showNonIsolatedRunToggle: Scalars['Boolean']['output'];
};

export type NotebookMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type NullMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
};

export type NullableConfigType = ConfigType & WrappingConfigType & {
  description?: Maybe<Scalars['String']['output']>;
  isSelector: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  ofType: ConfigType;
  /**
   *
   * This is an odd and problematic field. It recursively goes down to
   * get all the types contained within a type. The case where it is horrible
   * are dictionaries and it recurses all the way down to the leaves. This means
   * that in a case where one is fetching all the types and then all the inner
   * types keys for those types, we are returning O(N^2) type keys, which
   * can cause awful performance for large schemas. When you have access
   * to *all* the types, you should instead only use the type_param_keys
   * field for closed generic types and manually navigate down the to
   * field types client-side.
   *
   * Where it is useful is when you are fetching types independently and
   * want to be able to render them, but without fetching the entire schema.
   *
   * We use this capability when rendering the sidebar.
   *
   */
  recursiveConfigTypes: Array<ConfigType>;
  /**
   *
   * This returns the keys for type parameters of any closed generic type,
   * (e.g. List, Optional). This should be used for reconstructing and
   * navigating the full schema client-side and not innerTypes.
   *
   */
  typeParamKeys: Array<Scalars['String']['output']>;
};

export type NullableDagsterType = DagsterType & WrappingDagsterType & {
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  innerTypes: Array<DagsterType>;
  inputSchemaType?: Maybe<ConfigType>;
  isBuiltin: Scalars['Boolean']['output'];
  isList: Scalars['Boolean']['output'];
  isNothing: Scalars['Boolean']['output'];
  isNullable: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  name?: Maybe<Scalars['String']['output']>;
  ofType: DagsterType;
  outputSchemaType?: Maybe<ConfigType>;
};

export type ObjectStoreOperationEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  operationResult: ObjectStoreOperationResult;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ObjectStoreOperationResult = DisplayableEvent & {
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  metadataEntries: Array<MetadataEntry>;
  op: ObjectStoreOperationType;
};

export enum ObjectStoreOperationType {
  CpObject = 'CP_OBJECT',
  GetObject = 'GET_OBJECT',
  RmObject = 'RM_OBJECT',
  SetObject = 'SET_OBJECT'
}

export type ObservationEvent = DisplayableEvent & MessageEvent & StepEvent & {
  assetKey?: Maybe<AssetKey>;
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  partition?: Maybe<Scalars['String']['output']>;
  runId: Scalars['String']['output'];
  runOrError: RunOrError;
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  stepStats: RunStepStats;
  tags: Array<EventTag>;
  timestamp: Scalars['String']['output'];
};

export type OnboardingChecklistEntries = {
  entries: Array<OnboardingChecklistEntry>;
};

export type OnboardingChecklistEntriesOrError = OnboardingChecklistEntries | PythonError | UnauthorizedError;

export type OnboardingChecklistEntry = {
  entryKey: OnboardingChecklistEntryKey;
  metadata?: Maybe<Scalars['GenericScalar']['output']>;
  status: OnboardingChecklistStatus;
  updateTimestamp?: Maybe<Scalars['Float']['output']>;
};

/** An enumeration. */
export enum OnboardingChecklistEntryKey {
  ChoseAgentType = 'CHOSE_AGENT_TYPE',
  CreatedBranchDeployment = 'CREATED_BRANCH_DEPLOYMENT',
  FinishedSetup = 'FINISHED_SETUP',
  InvitedTeam = 'INVITED_TEAM',
  InvoiceCreated = 'INVOICE_CREATED',
  InvoicePaid = 'INVOICE_PAID',
  LaunchedAgent = 'LAUNCHED_AGENT',
  LaunchedRun = 'LAUNCHED_RUN',
  LoadedCode = 'LOADED_CODE',
  LoadedCustomCode = 'LOADED_CUSTOM_CODE',
  PaymentMethodAdded = 'PAYMENT_METHOD_ADDED',
  PlanSelected = 'PLAN_SELECTED',
  ScheduledDemoCall = 'SCHEDULED_DEMO_CALL'
}

/**
 * Represents the status of an onboarding checklist item, whether it is
 *     incomplete, complete, or skipped.
 *
 *     Entries which are absent from storage are considered to be incomplete.
 *
 */
export enum OnboardingChecklistStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  Skipped = 'SKIPPED'
}

export type OrganizationAccountReview = {
  status: DagsterCloudAccountReviewStatus;
};

export type OrganizationSettings = {
  settings?: Maybe<Scalars['GenericScalar']['output']>;
};

export type OrganizationSettingsInput = {
  settings?: InputMaybe<Scalars['GenericScalar']['input']>;
};

/** An enumeration. */
export enum OrganizationStatus {
  Active = 'ACTIVE',
  PendingDeletion = 'PENDING_DELETION',
  ReadOnly = 'READ_ONLY',
  Suspended = 'SUSPENDED'
}

export type Output = {
  definition: OutputDefinition;
  dependedBy: Array<Input>;
  solid: Solid;
};

export type OutputDefinition = {
  description?: Maybe<Scalars['String']['output']>;
  isDynamic?: Maybe<Scalars['Boolean']['output']>;
  metadataEntries: Array<MetadataEntry>;
  name: Scalars['String']['output'];
  type: DagsterType;
};

export type OutputMapping = {
  definition: OutputDefinition;
  mappedOutput: Output;
};

export type OwnerAssetSelectionInput = {
  ownerName: Scalars['String']['input'];
};

export type PagerdutyAlertPolicyNotification = {
  integrationKey: Scalars['String']['output'];
};

export type ParentMaterializedRuleEvaluationData = {
  updatedAssetKeys?: Maybe<Array<AssetKey>>;
  willUpdateAssetKeys?: Maybe<Array<AssetKey>>;
};

export type Partition = {
  mode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partitionSetName: Scalars['String']['output'];
  runConfigOrError: PartitionRunConfigOrError;
  runs: Array<Run>;
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<RunStatus>;
  tagsOrError: PartitionTagsOrError;
};


export type PartitionRunsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RunsFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PartitionBackfill = {
  assetBackfillData?: Maybe<AssetBackfillData>;
  assetSelection?: Maybe<Array<AssetKey>>;
  cancelableRuns: Array<Run>;
  description?: Maybe<Scalars['String']['output']>;
  endTimestamp?: Maybe<Scalars['Float']['output']>;
  error?: Maybe<PythonError>;
  fromFailure: Scalars['Boolean']['output'];
  hasCancelPermission: Scalars['Boolean']['output'];
  hasResumePermission: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isAssetBackfill: Scalars['Boolean']['output'];
  isValidSerialization: Scalars['Boolean']['output'];
  logEvents: InstigationEventConnection;
  numCancelable: Scalars['Int']['output'];
  numPartitions?: Maybe<Scalars['Int']['output']>;
  partitionNames?: Maybe<Array<Scalars['String']['output']>>;
  partitionSet?: Maybe<PartitionSet>;
  partitionSetName?: Maybe<Scalars['String']['output']>;
  partitionStatusCounts: Array<PartitionStatusCounts>;
  partitionStatuses?: Maybe<PartitionStatuses>;
  partitionsTargetedForAssetKey?: Maybe<AssetBackfillTargetPartitions>;
  reexecutionSteps?: Maybe<Array<Scalars['String']['output']>>;
  runs: Array<Run>;
  status: BulkActionStatus;
  tags: Array<PipelineTag>;
  timestamp: Scalars['Float']['output'];
  title?: Maybe<Scalars['String']['output']>;
  unfinishedRuns: Array<Run>;
  user?: Maybe<Scalars['String']['output']>;
};


export type PartitionBackfillCancelableRunsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type PartitionBackfillLogEventsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};


export type PartitionBackfillPartitionsTargetedForAssetKeyArgs = {
  assetKey?: InputMaybe<AssetKeyInput>;
};


export type PartitionBackfillRunsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type PartitionBackfillUnfinishedRunsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PartitionBackfillOrError = BackfillNotFoundError | PartitionBackfill | PythonError;

export type PartitionBackfills = {
  results: Array<PartitionBackfill>;
};

export type PartitionBackfillsOrError = PartitionBackfills | PythonError;

export type PartitionDefinition = {
  description: Scalars['String']['output'];
  dimensionTypes: Array<DimensionDefinitionType>;
  name?: Maybe<Scalars['String']['output']>;
  type: PartitionDefinitionType;
};

export enum PartitionDefinitionType {
  Dynamic = 'DYNAMIC',
  Multipartitioned = 'MULTIPARTITIONED',
  Static = 'STATIC',
  TimeWindow = 'TIME_WINDOW'
}

export type PartitionKeyRange = {
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export type PartitionKeys = {
  partitionKeys: Array<Scalars['String']['output']>;
};

export type PartitionKeysOrError = PartitionKeys | PartitionSubsetDeserializationError;

export type PartitionMapping = {
  className: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type PartitionRange = {
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

/** This type represents a partition range selection with start and end. */
export type PartitionRangeSelector = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
};

/** An enumeration. */
export enum PartitionRangeStatus {
  Failed = 'FAILED',
  Materialized = 'MATERIALIZED',
  Materializing = 'MATERIALIZING'
}

export type PartitionRun = {
  id: Scalars['String']['output'];
  partitionName: Scalars['String']['output'];
  run?: Maybe<Run>;
};

export type PartitionRunConfig = {
  yaml: Scalars['String']['output'];
};

export type PartitionRunConfigOrError = PartitionRunConfig | PythonError;

export type PartitionSet = {
  backfills: Array<PartitionBackfill>;
  id: Scalars['ID']['output'];
  mode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partition?: Maybe<Partition>;
  partitionRuns: Array<PartitionRun>;
  partitionStatusesOrError: PartitionStatusesOrError;
  partitionsOrError: PartitionsOrError;
  pipelineName: Scalars['String']['output'];
  repositoryOrigin: RepositoryOrigin;
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
};


export type PartitionSetBackfillsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type PartitionSetPartitionArgs = {
  partitionName: Scalars['String']['input'];
};


export type PartitionSetPartitionsOrErrorArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PartitionSetNotFoundError = Error & {
  message: Scalars['String']['output'];
  partitionSetName: Scalars['String']['output'];
};

export type PartitionSetOrError = PartitionSet | PartitionSetNotFoundError | PythonError;

/**
 * This type represents the fields necessary to identify a
 *         pipeline or pipeline subset.
 */
export type PartitionSetSelector = {
  partitionSetName: Scalars['String']['input'];
  repositorySelector: RepositorySelector;
};

export type PartitionSets = {
  results: Array<PartitionSet>;
};

export type PartitionSetsOrError = PartitionSets | PipelineNotFoundError | PythonError;

export type PartitionStats = {
  numFailed: Scalars['Int']['output'];
  numMaterialized: Scalars['Int']['output'];
  numMaterializing: Scalars['Int']['output'];
  numPartitions: Scalars['Int']['output'];
};

export type PartitionStatus = {
  id: Scalars['String']['output'];
  partitionName: Scalars['String']['output'];
  runDuration?: Maybe<Scalars['Float']['output']>;
  runId?: Maybe<Scalars['String']['output']>;
  runStatus?: Maybe<RunStatus>;
};

export type PartitionStatus1D = DefaultPartitionStatuses | TimePartitionStatuses;

export type PartitionStatusCounts = {
  count: Scalars['Int']['output'];
  runStatus: RunStatus;
};

export type PartitionStatuses = {
  results: Array<PartitionStatus>;
};

export type PartitionStatusesOrError = PartitionStatuses | PythonError;

export type PartitionSubsetDeserializationError = Error & {
  message: Scalars['String']['output'];
};

export type PartitionTags = {
  results: Array<PipelineTag>;
};

export type PartitionTagsOrError = PartitionTags | PythonError;

export type PartitionedAssetConditionEvaluationNode = {
  childUniqueIds: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endTimestamp?: Maybe<Scalars['Float']['output']>;
  numCandidates?: Maybe<Scalars['Int']['output']>;
  numTrue: Scalars['Int']['output'];
  startTimestamp?: Maybe<Scalars['Float']['output']>;
  uniqueId: Scalars['String']['output'];
};

export type Partitions = {
  results: Array<Partition>;
};

/** This type represents a partitions selection for an asset. */
export type PartitionsByAssetSelector = {
  assetKey: AssetKeyInput;
  partitions?: InputMaybe<PartitionsSelector>;
};

export type PartitionsOrError = Partitions | PythonError;

/** This type represents a partitions selection. */
export type PartitionsSelector = {
  range: PartitionRangeSelector;
};

export type PartnerReferralEntry = {
  createTimestamp: Scalars['Float']['output'];
  referred: Scalars['String']['output'];
};

export type PartnerReferrals = {
  referrals: Array<PartnerReferralEntry>;
};

export type PathMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type PendingConcurrencyStep = {
  assignedTimestamp?: Maybe<Scalars['Float']['output']>;
  enqueuedTimestamp: Scalars['Float']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
  runId: Scalars['String']['output'];
  stepKey: Scalars['String']['output'];
};

export type Permission = {
  disabledReason?: Maybe<Scalars['String']['output']>;
  permission: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum PermissionDeploymentScope {
  AllBranchDeployments = 'ALL_BRANCH_DEPLOYMENTS',
  Deployment = 'DEPLOYMENT',
  Organization = 'ORGANIZATION'
}

/** An enumeration. */
export enum PermissionGrant {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Editor = 'EDITOR',
  Launcher = 'LAUNCHER',
  Viewer = 'VIEWER'
}

export type PingLocationMutationResult = PingLocationSuccess | PythonError;

export type PingLocationSuccess = {
  locationName: Scalars['String']['output'];
};

export type Pipeline = IPipelineSnapshot & SolidContainer & {
  dagsterTypeOrError: DagsterTypeOrError;
  dagsterTypes: Array<DagsterType>;
  description?: Maybe<Scalars['String']['output']>;
  graphName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAssetJob: Scalars['Boolean']['output'];
  isJob: Scalars['Boolean']['output'];
  metadataEntries: Array<MetadataEntry>;
  modes: Array<Mode>;
  name: Scalars['String']['output'];
  parentSnapshotId?: Maybe<Scalars['String']['output']>;
  pipelineSnapshotId: Scalars['String']['output'];
  presets: Array<PipelinePreset>;
  repository: Repository;
  runs: Array<Run>;
  schedules: Array<Schedule>;
  sensors: Array<Sensor>;
  solidHandle?: Maybe<SolidHandle>;
  solidHandles: Array<SolidHandle>;
  solids: Array<Solid>;
  tags: Array<PipelineTag>;
};


export type PipelineDagsterTypeOrErrorArgs = {
  dagsterTypeName: Scalars['String']['input'];
};


export type PipelineRunsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type PipelineSolidHandleArgs = {
  handleID: Scalars['String']['input'];
};


export type PipelineSolidHandlesArgs = {
  parentHandleID?: InputMaybe<Scalars['String']['input']>;
};

export type PipelineConfigValidationError = {
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  reason: EvaluationErrorReason;
  stack: EvaluationStack;
};

export type PipelineConfigValidationInvalid = {
  errors: Array<PipelineConfigValidationError>;
  pipelineName: Scalars['String']['output'];
};

export type PipelineConfigValidationResult = InvalidSubsetError | PipelineConfigValidationValid | PipelineNotFoundError | PythonError | RunConfigValidationInvalid;

export type PipelineConfigValidationValid = {
  pipelineName: Scalars['String']['output'];
};

export type PipelineNotFoundError = Error & {
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  repositoryLocationName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

export type PipelineOrError = InvalidSubsetError | Pipeline | PipelineNotFoundError | PythonError;

export type PipelinePreset = {
  mode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  runConfigYaml: Scalars['String']['output'];
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
  tags: Array<PipelineTag>;
};

/**
 * This interface supports the case where we can look up a pipeline successfully in the
 * repository available to the DagsterInstance/graphql context, as well as the case where we know
 * that a pipeline exists/existed thanks to materialized data such as logs and run metadata, but
 * where we can't look the concrete pipeline up.
 */
export type PipelineReference = {
  name: Scalars['String']['output'];
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
};

export type PipelineRun = {
  assets: Array<Asset>;
  canTerminate: Scalars['Boolean']['output'];
  /**
   *
   *         Captured logs are the stdout/stderr logs for a given file key within the run
   *
   */
  capturedLogs: CapturedLogs;
  eventConnection: EventConnection;
  executionPlan?: Maybe<ExecutionPlan>;
  id: Scalars['ID']['output'];
  jobName: Scalars['String']['output'];
  mode: Scalars['String']['output'];
  parentRunId?: Maybe<Scalars['String']['output']>;
  pipeline: PipelineReference;
  pipelineName: Scalars['String']['output'];
  pipelineSnapshotId?: Maybe<Scalars['String']['output']>;
  repositoryOrigin?: Maybe<RepositoryOrigin>;
  rootRunId?: Maybe<Scalars['String']['output']>;
  runConfig: Scalars['RunConfigData']['output'];
  runConfigYaml: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
  stats: RunStatsSnapshotOrError;
  status: RunStatus;
  stepKeysToExecute?: Maybe<Array<Scalars['String']['output']>>;
  stepStats: Array<RunStepStats>;
  tags: Array<PipelineTag>;
};


export type PipelineRunCapturedLogsArgs = {
  fileKey: Scalars['String']['input'];
};


export type PipelineRunEventConnectionArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type PipelineRunConflict = {
  message: Scalars['String']['output'];
};

export type PipelineRunLogsSubscriptionFailure = {
  message: Scalars['String']['output'];
  missingRunId?: Maybe<Scalars['String']['output']>;
};

export type PipelineRunLogsSubscriptionPayload = PipelineRunLogsSubscriptionFailure | PipelineRunLogsSubscriptionSuccess;

export type PipelineRunLogsSubscriptionSuccess = {
  cursor: Scalars['String']['output'];
  hasMorePastEvents: Scalars['Boolean']['output'];
  messages: Array<DagsterRunEvent>;
  run: Run;
};

export type PipelineRunMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  runId: Scalars['String']['output'];
};

export type PipelineRunNotFoundError = {
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
};

export type PipelineRunStatsSnapshot = {
  endTime?: Maybe<Scalars['Float']['output']>;
  enqueuedTime?: Maybe<Scalars['Float']['output']>;
  expectations: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  launchTime?: Maybe<Scalars['Float']['output']>;
  materializations: Scalars['Int']['output'];
  runId: Scalars['String']['output'];
  startTime?: Maybe<Scalars['Float']['output']>;
  stepsFailed: Scalars['Int']['output'];
  stepsSucceeded: Scalars['Int']['output'];
};

export type PipelineRunStepStats = {
  endTime?: Maybe<Scalars['Float']['output']>;
  expectationResults: Array<ExpectationResult>;
  materializations: Array<MaterializationEvent>;
  runId: Scalars['String']['output'];
  startTime?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<StepEventStatus>;
  stepKey: Scalars['String']['output'];
};

export type PipelineRuns = {
  count?: Maybe<Scalars['Int']['output']>;
  results: Array<Run>;
};

/**
 * This type represents the fields necessary to identify a
 *         pipeline or pipeline subset.
 */
export type PipelineSelector = {
  assetCheckSelection?: InputMaybe<Array<AssetCheckHandleInput>>;
  assetSelection?: InputMaybe<Array<AssetKeyInput>>;
  pipelineName: Scalars['String']['input'];
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
  solidSelection?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PipelineSnapshot = IPipelineSnapshot & PipelineReference & SolidContainer & {
  dagsterTypeOrError: DagsterTypeOrError;
  dagsterTypes: Array<DagsterType>;
  description?: Maybe<Scalars['String']['output']>;
  graphName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metadataEntries: Array<MetadataEntry>;
  modes: Array<Mode>;
  name: Scalars['String']['output'];
  parentSnapshotId?: Maybe<Scalars['String']['output']>;
  pipelineSnapshotId: Scalars['String']['output'];
  runs: Array<Run>;
  schedules: Array<Schedule>;
  sensors: Array<Sensor>;
  solidHandle?: Maybe<SolidHandle>;
  solidHandles: Array<SolidHandle>;
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
  solids: Array<Solid>;
  tags: Array<PipelineTag>;
};


export type PipelineSnapshotDagsterTypeOrErrorArgs = {
  dagsterTypeName: Scalars['String']['input'];
};


export type PipelineSnapshotRunsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type PipelineSnapshotSolidHandleArgs = {
  handleID: Scalars['String']['input'];
};


export type PipelineSnapshotSolidHandlesArgs = {
  parentHandleID?: InputMaybe<Scalars['String']['input']>;
};

export type PipelineSnapshotNotFoundError = Error & {
  message: Scalars['String']['output'];
  snapshotId: Scalars['String']['output'];
};

export type PipelineSnapshotOrError = PipelineNotFoundError | PipelineSnapshot | PipelineSnapshotNotFoundError | PythonError;

export type PipelineTag = {
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/**
 * A run tag and the free-form values that have been associated
 *         with it so far.
 */
export type PipelineTagAndValues = {
  key: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type PresetNotFoundError = Error & {
  message: Scalars['String']['output'];
  preset: Scalars['String']['output'];
};

export type PullRequestData = {
  baseBranch: Scalars['String']['output'];
  branchName: Scalars['String']['output'];
  pullRequestNumber: Scalars['Int']['output'];
  pullRequestStatus?: Maybe<PullRequestStatus>;
  pullRequestUrl: Scalars['String']['output'];
};

/** An enumeration. */
export enum PullRequestStatus {
  Closed = 'CLOSED',
  Merged = 'MERGED',
  Open = 'OPEN'
}

export type PythonArtifactMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  module: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PythonError = Error & {
  cause?: Maybe<PythonError>;
  causes: Array<PythonError>;
  className?: Maybe<Scalars['String']['output']>;
  errorChain: Array<ErrorChainLink>;
  message: Scalars['String']['output'];
  stack: Array<Scalars['String']['output']>;
};

export type QualifiedAssetGroup = {
  assetGroupName?: InputMaybe<Scalars['String']['input']>;
  codeLocationName?: InputMaybe<Scalars['String']['input']>;
  repositoryName?: InputMaybe<Scalars['String']['input']>;
};

export type QualifiedAssetKey = {
  assetKey: AssetKeyInput;
};

export type QualifiedJob = {
  codeLocationName?: InputMaybe<Scalars['String']['input']>;
  jobName?: InputMaybe<Scalars['String']['input']>;
  repositoryName?: InputMaybe<Scalars['String']['input']>;
};

export enum QueryableMetrics {
  AllStepDuration = 'ALL_STEP_DURATION',
  Credits = 'CREDITS',
  ServerlessRunDuration = 'SERVERLESS_RUN_DURATION'
}

export type ReconcileAlertPoliciesResult = InvalidAlertPolicyError | PythonError | ReconcileAlertPoliciesSuccess | UnauthorizedError;

export type ReconcileAlertPoliciesSuccess = {
  alertPolicies: Array<Maybe<AlertPolicy>>;
};

export type ReconcileLocationsFromDocumentMutationResult = InvalidLocationError | PythonError | ReconcileLocationsSuccess | UnauthorizedError;

export type ReconcileLocationsMutationResult = InvalidLocationError | PythonError | ReconcileLocationsSuccess | UnauthorizedError;

export type ReconcileLocationsSuccess = {
  locations: Array<Maybe<WorkspaceEntry>>;
};

export type ReexecutionParams = {
  parentRunId: Scalars['String']['input'];
  strategy: ReexecutionStrategy;
};

export enum ReexecutionStrategy {
  AllSteps = 'ALL_STEPS',
  FromFailure = 'FROM_FAILURE'
}

/** Regular is an odd name in this context. It really means Scalar or Any. */
export type RegularConfigType = ConfigType & {
  description?: Maybe<Scalars['String']['output']>;
  givenName: Scalars['String']['output'];
  isSelector: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  /**
   *
   * This is an odd and problematic field. It recursively goes down to
   * get all the types contained within a type. The case where it is horrible
   * are dictionaries and it recurses all the way down to the leaves. This means
   * that in a case where one is fetching all the types and then all the inner
   * types keys for those types, we are returning O(N^2) type keys, which
   * can cause awful performance for large schemas. When you have access
   * to *all* the types, you should instead only use the type_param_keys
   * field for closed generic types and manually navigate down the to
   * field types client-side.
   *
   * Where it is useful is when you are fetching types independently and
   * want to be able to render them, but without fetching the entire schema.
   *
   * We use this capability when rendering the sidebar.
   *
   */
  recursiveConfigTypes: Array<ConfigType>;
  /**
   *
   * This returns the keys for type parameters of any closed generic type,
   * (e.g. List, Optional). This should be used for reconstructing and
   * navigating the full schema client-side and not innerTypes.
   *
   */
  typeParamKeys: Array<Scalars['String']['output']>;
};

export type RegularDagsterType = DagsterType & {
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  innerTypes: Array<DagsterType>;
  inputSchemaType?: Maybe<ConfigType>;
  isBuiltin: Scalars['Boolean']['output'];
  isList: Scalars['Boolean']['output'];
  isNothing: Scalars['Boolean']['output'];
  isNullable: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  name?: Maybe<Scalars['String']['output']>;
  outputSchemaType?: Maybe<ConfigType>;
};

export type ReloadNotSupported = Error & {
  message: Scalars['String']['output'];
};

/** Reloads a code location server. */
export type ReloadRepositoryLocationMutation = {
  Output: ReloadRepositoryLocationMutationResult;
};

/** The output from reloading a code location server. */
export type ReloadRepositoryLocationMutationResult = PythonError | ReloadNotSupported | RepositoryLocationNotFound | UnauthorizedError | WorkspaceLocationEntry;

/** Reloads the workspace and its code location servers. */
export type ReloadWorkspaceMutation = {
  Output: ReloadWorkspaceMutationResult;
};

/** The output from reloading the workspace. */
export type ReloadWorkspaceMutationResult = PythonError | UnauthorizedError | Workspace;

export type RemoveAgentPermissionsInput = {
  agentTokenId: Scalars['Int']['input'];
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
  deploymentScope: PermissionDeploymentScope;
};

export type RemoveMemberFromTeamMutationResult = PythonError | RemoveMemberFromTeamSuccess | UnauthorizedError;

export type RemoveMemberFromTeamSuccess = {
  team: DagsterCloudTeam;
};

export type RemoveStripePaymentFailedError = Error & {
  message: Scalars['String']['output'];
};

export type RemoveStripePaymentMethodMutationResult = PythonError | RemoveStripePaymentFailedError | RemoveStripePaymentMethodSucceeded | UnauthorizedError | UnknownStripePaymentMethodError;

export type RemoveStripePaymentMethodSucceeded = {
  status: Scalars['String']['output'];
};

export type RemoveTeamPermissionMutationResult = CantRemoveAllAdminsError | PythonError | RemoveTeamPermissionSuccess | UnauthorizedError;

export type RemoveTeamPermissionSuccess = {
  teamPermissions: DagsterCloudTeamPermissions;
};

export type RemoveUserFromOrganizationMutationResult = CantRemoveAllAdminsError | PythonError | RemoveUserFromOrganizationSuccess | UnauthorizedError;

export type RemoveUserFromOrganizationSuccess = {
  email: Scalars['String']['output'];
};

export type RemoveUserPermissionsInput = {
  deploymentId?: InputMaybe<Scalars['Int']['input']>;
  deploymentScope: PermissionDeploymentScope;
  email: Scalars['String']['input'];
};

export type RenameTeamResult = DagsterCloudTeam | PythonError | UnauthorizedError;

export type ReportRunlessAssetEventsParams = {
  assetKey: AssetKeyInput;
  description?: InputMaybe<Scalars['String']['input']>;
  eventType: AssetEventType;
  partitionKeys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** The output from reporting runless events. */
export type ReportRunlessAssetEventsResult = PythonError | ReportRunlessAssetEventsSuccess | UnauthorizedError;

/** Output indicating that runless asset events were reported. */
export type ReportRunlessAssetEventsSuccess = {
  assetKey: AssetKey;
};

export type ReportingAggregateValueChange = {
  /** The change in the aggregate metric value from the previous time period. */
  change: Scalars['Float']['output'];
  /** Whether this object is newly available in the current time period. */
  isNewlyAvailable: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum ReportingAggregationFunction {
  Average = 'AVERAGE',
  P75 = 'P75',
  P90 = 'P90',
  P95 = 'P95',
  P99 = 'P99',
  Sum = 'SUM'
}

export type ReportingAsset = {
  assetGroup?: Maybe<Scalars['String']['output']>;
  assetKey: AssetKey;
  codeLocationName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

export type ReportingAssetGroup = {
  codeLocationName: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

export type ReportingDeploymentData = {
  availableMetadataKeys?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  downsamplingRate?: Maybe<Scalars['Float']['output']>;
  latestDataTimestamp?: Maybe<Scalars['Float']['output']>;
};

export type ReportingDeploymentSettings = {
  costMultipliers: Array<InsightsMetricCostMultiplier>;
  customizations: Array<InsightsMetricCustomization>;
  metadataKeys: Array<Scalars['String']['output']>;
};

export type ReportingEntry = {
  /** The aggregate metric value for the time period. */
  aggregateValue: Scalars['Float']['output'];
  /** The change in the aggregate metric value from the previous time period. */
  aggregateValueChange: ReportingAggregateValueChange;
  /** The entity the reporting data is associated with. */
  entity: ReportingObjet;
  /** The metric values for the time period. Guaranteed to be the same length as the timestamps list. */
  values: Array<Maybe<Scalars['Float']['output']>>;
};

export type ReportingInputError = Error & {
  message: Scalars['String']['output'];
};

export type ReportingJob = {
  codeLocationName: Scalars['String']['output'];
  jobName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

export type ReportingMetrics = {
  /** The metric values for the time period. */
  metrics: Array<Maybe<ReportingEntry>>;
  /** The timestamps for the metric values. Guaranteed to be the same length as the values list for each metrics entry. */
  timestamps: Array<Scalars['Float']['output']>;
};

/** An enumeration. */
export enum ReportingMetricsGranularity {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type ReportingMetricsOrError = PythonError | ReportingInputError | ReportingMetrics | UnauthorizedError;

export type ReportingMetricsSelector = {
  after: Scalars['Float']['input'];
  aggregationFunction?: InputMaybe<ReportingAggregationFunction>;
  before: Scalars['Float']['input'];
  granularity: ReportingMetricsGranularity;
  metricName: Scalars['String']['input'];
  sortDirection?: InputMaybe<Array<InputMaybe<ReportingSortDirection>>>;
  sortTarget?: InputMaybe<Array<InputMaybe<ReportingSortTarget>>>;
};

export type ReportingMetricsTimeframeSelector = {
  after?: InputMaybe<Scalars['Float']['input']>;
  before?: InputMaybe<Scalars['Float']['input']>;
};

export type ReportingObjet = DagsterCloudDeployment | ReportingAsset | ReportingAssetGroup | ReportingJob;

/** An enumeration. */
export enum ReportingSortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

/** An enumeration. */
export enum ReportingSortTarget {
  AggregationValue = 'AGGREGATION_VALUE',
  CodeLocationName = 'CODE_LOCATION_NAME',
  Name = 'NAME',
  PctChange = 'PCT_CHANGE'
}

/** An enumeration. */
export enum ReportingTimeRange {
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  Last120Days = 'Last120Days',
  ThisMonth = 'ThisMonth',
  ThisWeek = 'ThisWeek'
}

export type ReportingTimeRanges = {
  timeRanges: Array<ReportingTimeRange>;
};

/** An enumeration. */
export enum ReportingUnitType {
  Float = 'FLOAT',
  Integer = 'INTEGER',
  TimeMs = 'TIME_MS'
}

export type RepositoriesOrError = PythonError | RepositoryConnection | RepositoryNotFoundError;

export type Repository = {
  allTopLevelResourceDetails: Array<ResourceDetails>;
  assetGroups: Array<AssetGroup>;
  assetNodes: Array<AssetNode>;
  displayMetadata: Array<RepositoryMetadata>;
  id: Scalars['ID']['output'];
  jobs: Array<Job>;
  location: RepositoryLocation;
  name: Scalars['String']['output'];
  origin: RepositoryOrigin;
  partitionSets: Array<PartitionSet>;
  pipelines: Array<Pipeline>;
  schedules: Array<Schedule>;
  sensors: Array<Sensor>;
  usedSolid?: Maybe<UsedSolid>;
  usedSolids: Array<UsedSolid>;
};


export type RepositorySensorsArgs = {
  sensorType?: InputMaybe<SensorType>;
};


export type RepositoryUsedSolidArgs = {
  name: Scalars['String']['input'];
};

export type RepositoryCodeLocation = {
  codeLocationName?: InputMaybe<Scalars['String']['input']>;
  repositoryName?: InputMaybe<Scalars['String']['input']>;
};

export type RepositoryConnection = {
  nodes: Array<Repository>;
};

export type RepositoryDiffSummary = {
  addedAssets: Array<AssetKey>;
  modifiedAssets: Array<AssetKey>;
  removedAssets: Array<AssetKey>;
  repositoryName: Scalars['String']['output'];
};

export type RepositoryLocation = {
  dagsterLibraryVersions?: Maybe<Array<DagsterLibraryVersion>>;
  environmentPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReloadSupported: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  repositories: Array<Repository>;
  serverId?: Maybe<Scalars['String']['output']>;
};

export enum RepositoryLocationLoadStatus {
  Loaded = 'LOADED',
  Loading = 'LOADING'
}

export type RepositoryLocationNotFound = Error & {
  message: Scalars['String']['output'];
};

export type RepositoryLocationOrLoadError = PythonError | RepositoryLocation;

export type RepositoryMetadata = {
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type RepositoryNotFoundError = Error & {
  message: Scalars['String']['output'];
  repositoryLocationName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

export type RepositoryOrError = PythonError | Repository | RepositoryNotFoundError;

export type RepositoryOrigin = {
  id: Scalars['String']['output'];
  repositoryLocationMetadata: Array<RepositoryMetadata>;
  repositoryLocationName: Scalars['String']['output'];
  repositoryName: Scalars['String']['output'];
};

/** This type represents the fields necessary to identify a repository. */
export type RepositorySelector = {
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
};

export type RequestedMaterializationsForAsset = {
  assetKey: AssetKey;
  partitionKeys: Array<Scalars['String']['output']>;
};

export type ResetLoadedCodeSetupStageSuccess = {
  orgId: Scalars['Int']['output'];
};

/** Reset a schedule to its status defined in code, otherwise disable it from launching runs for a job. */
export type ResetScheduleMutation = {
  Output: ScheduleMutationResult;
};

/** Reset a sensor to its status defined in code, otherwise disable it from launching runs for a job. */
export type ResetSensorMutation = {
  Output: SensorOrError;
};

export type Resource = {
  configField?: Maybe<ConfigTypeField>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ResourceDetails = {
  assetKeysUsing: Array<AssetKey>;
  /** Snapshots of all the fields for the given resource */
  configFields: Array<ConfigTypeField>;
  /** List of K/V pairs of user-configured values for each of the top-level fields on the resource */
  configuredValues: Array<ConfiguredValue>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isTopLevel: Scalars['Boolean']['output'];
  jobsOpsUsing: Array<JobWithOps>;
  name: Scalars['String']['output'];
  /** List of nested resources for the given resource */
  nestedResources: Array<NestedResourceEntry>;
  /** List of parent resources for the given resource */
  parentResources: Array<NestedResourceEntry>;
  resourceType: Scalars['String']['output'];
  schedulesUsing: Array<Scalars['String']['output']>;
  sensorsUsing: Array<Scalars['String']['output']>;
};

export type ResourceDetailsList = {
  results: Array<ResourceDetails>;
};

export type ResourceDetailsOrError = PythonError | ResourceDetails | ResourceNotFoundError;

export type ResourceInitFailureEvent = DisplayableEvent & ErrorEvent & MarkerEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  error?: Maybe<PythonError>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  markerEnd?: Maybe<Scalars['String']['output']>;
  markerStart?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ResourceInitStartedEvent = DisplayableEvent & MarkerEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  markerEnd?: Maybe<Scalars['String']['output']>;
  markerStart?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ResourceInitSuccessEvent = DisplayableEvent & MarkerEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  markerEnd?: Maybe<Scalars['String']['output']>;
  markerStart?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type ResourceNotFoundError = Error & {
  message: Scalars['String']['output'];
  resourceName: Scalars['String']['output'];
};

export type ResourceRequirement = {
  resourceKey: Scalars['String']['output'];
};

/** This type represents the fields necessary to identify a top-level resource. */
export type ResourceSelector = {
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
  resourceName: Scalars['String']['input'];
};

export type ResourcesOrError = PythonError | RepositoryNotFoundError | ResourceDetailsList;

export type RestartGitCiError = Error & {
  message: Scalars['String']['output'];
};

export type RestartGitCiSuccess = {
  ok: Scalars['Boolean']['output'];
};

export type RestartGitHubActionsRunMutationResult = GitHubError | PythonError | RestartGitCiError | RestartGitCiSuccess | UnauthorizedError;

export type ResumeBackfillResult = PythonError | ResumeBackfillSuccess | UnauthorizedError;

export type ResumeBackfillSuccess = {
  backfillId: Scalars['String']['output'];
};

export type RevokeAgentTokenResult = DagsterCloudAgentToken | DagsterCloudTokenNotFoundError | PythonError | UnauthorizedError;

export type RevokeApiTokenResult = DagsterCloudApiToken | DagsterCloudTokenNotFoundError | PythonError | UnauthorizedError;

export type RevokeSshKeyResult = DagsterCloudSshKey | DagsterCloudSshKeyNotFoundError | PythonError | UnauthorizedError;

export type RevokeUserTokenResult = DagsterCloudTokenNotFoundError | DagsterCloudUserToken | PythonError | UnauthorizedError;

export type Run = PipelineRun & {
  assetCheckSelection?: Maybe<Array<AssetCheckhandle>>;
  assetMaterializations: Array<MaterializationEvent>;
  assetSelection?: Maybe<Array<AssetKey>>;
  assets: Array<Asset>;
  canTerminate: Scalars['Boolean']['output'];
  /**
   *
   *         Captured logs are the stdout/stderr logs for a given file key within the run
   *
   */
  capturedLogs: CapturedLogs;
  creationTime: Scalars['Float']['output'];
  endTime?: Maybe<Scalars['Float']['output']>;
  eventConnection: EventConnection;
  executionPlan?: Maybe<ExecutionPlan>;
  hasConcurrencyKeySlots: Scalars['Boolean']['output'];
  hasDeletePermission: Scalars['Boolean']['output'];
  hasReExecutePermission: Scalars['Boolean']['output'];
  hasTerminatePermission: Scalars['Boolean']['output'];
  hasUnconstrainedRootNodes: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  jobName: Scalars['String']['output'];
  mode: Scalars['String']['output'];
  parentPipelineSnapshotId?: Maybe<Scalars['String']['output']>;
  parentRunId?: Maybe<Scalars['String']['output']>;
  pipeline: PipelineReference;
  pipelineName: Scalars['String']['output'];
  pipelineSnapshotId?: Maybe<Scalars['String']['output']>;
  repositoryOrigin?: Maybe<RepositoryOrigin>;
  resolvedOpSelection?: Maybe<Array<Scalars['String']['output']>>;
  rootConcurrencyKeys?: Maybe<Array<Scalars['String']['output']>>;
  rootRunId?: Maybe<Scalars['String']['output']>;
  runConfig: Scalars['RunConfigData']['output'];
  runConfigYaml: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
  startTime?: Maybe<Scalars['Float']['output']>;
  stats: RunStatsSnapshotOrError;
  status: RunStatus;
  stepKeysToExecute?: Maybe<Array<Scalars['String']['output']>>;
  stepStats: Array<RunStepStats>;
  tags: Array<PipelineTag>;
  updateTime?: Maybe<Scalars['Float']['output']>;
};


export type RunCapturedLogsArgs = {
  fileKey: Scalars['String']['input'];
};


export type RunEventConnectionArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type RunCanceledEvent = ErrorEvent & MessageEvent & RunEvent & {
  error?: Maybe<PythonError>;
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type RunCancelingEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

/**
 * The run config schema represents the all the config type
 *         information given a certain execution selection and mode of execution of that
 *         selection. All config interactions (e.g. checking config validity, fetching
 *         all config types, fetching in a particular config type) should be done
 *         through this type
 */
export type RunConfigSchema = {
  /**
   * Fetch all the named config types that are in the schema. Useful
   *         for things like a type browser UI, or for fetching all the types are in the
   *         scope of a document so that the index can be built for the autocompleting editor.
   *
   */
  allConfigTypes: Array<ConfigType>;
  /**
   * Parse a particular run config result. The return value
   *         either indicates that the validation succeeded by returning
   *         `PipelineConfigValidationValid` or that there are configuration errors
   *         by returning `RunConfigValidationInvalid' which containers a list errors
   *         so that can be rendered for the user
   */
  isRunConfigValid: PipelineConfigValidationResult;
  /**
   * Fetch the root environment type. Concretely this is the type that
   *         is in scope at the root of configuration document for a particular execution selection.
   *         It is the type that is in scope initially with a blank config editor.
   */
  rootConfigType: ConfigType;
  /**
   * The default configuration for this run in yaml. This is
   *         so that the client does not have to parse JSON client side and assemble
   *         it into a single yaml document.
   */
  rootDefaultYaml: Scalars['String']['output'];
};


/**
 * The run config schema represents the all the config type
 *         information given a certain execution selection and mode of execution of that
 *         selection. All config interactions (e.g. checking config validity, fetching
 *         all config types, fetching in a particular config type) should be done
 *         through this type
 */
export type RunConfigSchemaIsRunConfigValidArgs = {
  runConfigData?: InputMaybe<Scalars['RunConfigData']['input']>;
};

export type RunConfigSchemaOrError = InvalidSubsetError | ModeNotFoundError | PipelineNotFoundError | PythonError | RunConfigSchema;

export type RunConfigValidationInvalid = PipelineConfigValidationInvalid & {
  errors: Array<PipelineConfigValidationError>;
  pipelineName: Scalars['String']['output'];
};

export type RunConflict = Error & PipelineRunConflict & {
  message: Scalars['String']['output'];
};

export type RunContainerMetrics = {
  cpuCfsPeriodUs?: Maybe<MetricsTimeSeries>;
  cpuCfsQuotaUs?: Maybe<MetricsTimeSeries>;
  cpuLimitMs?: Maybe<MetricsTimeSeries>;
  cpuPercent?: Maybe<MetricsTimeSeries>;
  cpuUsageMs?: Maybe<MetricsTimeSeries>;
  memoryLimit?: Maybe<MetricsTimeSeries>;
  memoryPercent?: Maybe<MetricsTimeSeries>;
  memoryUsage?: Maybe<MetricsTimeSeries>;
};

export type RunDequeuedEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type RunEnqueuedEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type RunEvent = {
  pipelineName: Scalars['String']['output'];
};

export type RunFailureEvent = ErrorEvent & MessageEvent & RunEvent & {
  error?: Maybe<PythonError>;
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type RunGroup = {
  rootRunId: Scalars['String']['output'];
  runs?: Maybe<Array<Maybe<Run>>>;
};

export type RunGroupNotFoundError = Error & {
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
};

export type RunGroupOrError = PythonError | RunGroup | RunGroupNotFoundError;

export type RunGroups = {
  results: Array<RunGroup>;
};

export type RunIds = {
  results: Array<Scalars['String']['output']>;
};

export type RunIdsOrError = InvalidPipelineRunsFilterError | PythonError | RunIds;

export type RunLauncher = {
  name: Scalars['String']['output'];
};

export type RunMarker = {
  endTime?: Maybe<Scalars['Float']['output']>;
  startTime?: Maybe<Scalars['Float']['output']>;
};

export type RunNotFoundError = Error & PipelineRunNotFoundError & {
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
};

export type RunOrError = PythonError | Run | RunNotFoundError;

export type RunQueueConfig = {
  isOpConcurrencyAware?: Maybe<Scalars['Boolean']['output']>;
  maxConcurrentRuns: Scalars['Int']['output'];
  tagConcurrencyLimitsYaml?: Maybe<Scalars['String']['output']>;
};

export type RunRequest = {
  assetSelection?: Maybe<Array<AssetKey>>;
  jobName?: Maybe<Scalars['String']['output']>;
  runConfigYaml: Scalars['String']['output'];
  runKey?: Maybe<Scalars['String']['output']>;
  tags: Array<PipelineTag>;
};

export type RunStartEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type RunStartingEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type RunStatsSnapshot = PipelineRunStatsSnapshot & {
  endTime?: Maybe<Scalars['Float']['output']>;
  enqueuedTime?: Maybe<Scalars['Float']['output']>;
  expectations: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  launchTime?: Maybe<Scalars['Float']['output']>;
  materializations: Scalars['Int']['output'];
  runId: Scalars['String']['output'];
  startTime?: Maybe<Scalars['Float']['output']>;
  stepsFailed: Scalars['Int']['output'];
  stepsSucceeded: Scalars['Int']['output'];
};

export type RunStatsSnapshotOrError = PythonError | RunStatsSnapshot;

/** The status of run execution. */
export enum RunStatus {
  /** Runs that have been canceled before completion. */
  Canceled = 'CANCELED',
  /** Runs that are in-progress and pending to be canceled. */
  Canceling = 'CANCELING',
  /** Runs that have failed to complete. */
  Failure = 'FAILURE',
  /** Runs that are managed outside of the Dagster control plane. */
  Managed = 'MANAGED',
  /** Runs that have been created, but not yet submitted for launch. */
  NotStarted = 'NOT_STARTED',
  /** Runs waiting to be launched by the Dagster Daemon. */
  Queued = 'QUEUED',
  /** Runs that have been launched and execution has started. */
  Started = 'STARTED',
  /** Runs that have been launched, but execution has not yet started. */
  Starting = 'STARTING',
  /** Runs that have successfully completed. */
  Success = 'SUCCESS'
}

export type RunStepStats = PipelineRunStepStats & {
  attempts: Array<RunMarker>;
  endTime?: Maybe<Scalars['Float']['output']>;
  expectationResults: Array<ExpectationResult>;
  markers: Array<RunMarker>;
  materializations: Array<MaterializationEvent>;
  runId: Scalars['String']['output'];
  startTime?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<StepEventStatus>;
  stepKey: Scalars['String']['output'];
};

export type RunSuccessEvent = MessageEvent & RunEvent & {
  eventType?: Maybe<DagsterEventType>;
  level: LogLevel;
  message: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type RunTagKeys = {
  keys: Array<Scalars['String']['output']>;
};

export type RunTagKeysOrError = PythonError | RunTagKeys;

export type RunTags = {
  tags: Array<PipelineTagAndValues>;
};

export type RunTagsOrError = PythonError | RunTags;

export type RunWorkerState = {
  message?: Maybe<Scalars['String']['output']>;
  run?: Maybe<Run>;
  runId: Scalars['String']['output'];
  status: WorkerStatus;
};

export type Runs = PipelineRuns & {
  count?: Maybe<Scalars['Int']['output']>;
  results: Array<Run>;
};

/** This type represents a filter on Dagster runs. */
export type RunsFilter = {
  createdAfter?: InputMaybe<Scalars['Float']['input']>;
  createdBefore?: InputMaybe<Scalars['Float']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  pipelineName?: InputMaybe<Scalars['String']['input']>;
  runIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  snapshotId?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<RunStatus>>;
  tags?: InputMaybe<Array<ExecutionTag>>;
  updatedAfter?: InputMaybe<Scalars['Float']['input']>;
  updatedBefore?: InputMaybe<Scalars['Float']['input']>;
};

export type RunsOrError = InvalidPipelineRunsFilterError | PythonError | Runs;

export type RuntimeMismatchConfigError = PipelineConfigValidationError & {
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  reason: EvaluationErrorReason;
  stack: EvaluationStack;
  valueRep?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum S3ClientMethod {
  Get = 'GET',
  Put = 'PUT'
}

export type SshKeysOrError = DagsterCloudSshKeys | PythonError | UnauthorizedError;

export type SandboxConnectionInfo = {
  hostname: Scalars['String']['output'];
  port: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type SandboxProxyInfo = {
  authToken: Scalars['String']['output'];
  hostname: Scalars['String']['output'];
  maxPort?: Maybe<Scalars['Int']['output']>;
  minPort?: Maybe<Scalars['Int']['output']>;
  port: Scalars['Int']['output'];
  sshPort: Scalars['Int']['output'];
};

export type ScaffoldDagsterInDbtRepoSuccess = {
  branchName: Scalars['String']['output'];
  pullRequestUrl: Scalars['String']['output'];
};

export type ScaffoldDagsterInPullRequestResult = GitHubError | MissingPermissionsError | PythonError | ScaffoldDagsterInDbtRepoSuccess | SetupRepoError | UnauthorizedError;

export type ScalarUnionConfigType = ConfigType & {
  description?: Maybe<Scalars['String']['output']>;
  isSelector: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  nonScalarType: ConfigType;
  nonScalarTypeKey: Scalars['String']['output'];
  /**
   *
   * This is an odd and problematic field. It recursively goes down to
   * get all the types contained within a type. The case where it is horrible
   * are dictionaries and it recurses all the way down to the leaves. This means
   * that in a case where one is fetching all the types and then all the inner
   * types keys for those types, we are returning O(N^2) type keys, which
   * can cause awful performance for large schemas. When you have access
   * to *all* the types, you should instead only use the type_param_keys
   * field for closed generic types and manually navigate down the to
   * field types client-side.
   *
   * Where it is useful is when you are fetching types independently and
   * want to be able to render them, but without fetching the entire schema.
   *
   * We use this capability when rendering the sidebar.
   *
   */
  recursiveConfigTypes: Array<ConfigType>;
  scalarType: ConfigType;
  scalarTypeKey: Scalars['String']['output'];
  /**
   *
   * This returns the keys for type parameters of any closed generic type,
   * (e.g. List, Optional). This should be used for reconstructing and
   * navigating the full schema client-side and not innerTypes.
   *
   */
  typeParamKeys: Array<Scalars['String']['output']>;
};

export type Schedule = {
  assetSelection?: Maybe<AssetSelection>;
  canReset: Scalars['Boolean']['output'];
  cronSchedule: Scalars['String']['output'];
  defaultStatus: InstigationStatus;
  description?: Maybe<Scalars['String']['output']>;
  executionTimezone?: Maybe<Scalars['String']['output']>;
  futureTick: DryRunInstigationTick;
  futureTicks: DryRunInstigationTicks;
  id: Scalars['ID']['output'];
  mode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partitionSet?: Maybe<PartitionSet>;
  pipelineName: Scalars['String']['output'];
  potentialTickTimestamps: Array<Scalars['Float']['output']>;
  scheduleState: InstigationState;
  solidSelection?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};


export type ScheduleFutureTickArgs = {
  tickTimestamp: Scalars['Int']['input'];
};


export type ScheduleFutureTicksArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  until?: InputMaybe<Scalars['Float']['input']>;
};


export type SchedulePotentialTickTimestampsArgs = {
  lowerLimit?: InputMaybe<Scalars['Int']['input']>;
  startTimestamp?: InputMaybe<Scalars['Float']['input']>;
  upperLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduleData = {
  cronSchedule: Scalars['String']['output'];
  startTimestamp?: Maybe<Scalars['Float']['output']>;
};

export type ScheduleDryRunResult = DryRunInstigationTick | PythonError | ScheduleNotFoundError;

export type ScheduleMutationResult = PythonError | ScheduleStateResult | UnauthorizedError;

export type ScheduleNotFoundError = Error & {
  message: Scalars['String']['output'];
  scheduleName: Scalars['String']['output'];
};

export type ScheduleOrError = PythonError | Schedule | ScheduleNotFoundError;

/** This type represents the fields necessary to identify a schedule. */
export type ScheduleSelector = {
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
  scheduleName: Scalars['String']['input'];
};

export type ScheduleStateResult = {
  scheduleState: InstigationState;
};

export enum ScheduleStatus {
  Ended = 'ENDED',
  Running = 'RUNNING',
  Stopped = 'STOPPED'
}

export type ScheduleTick = {
  status: InstigationTickStatus;
  tickId: Scalars['String']['output'];
  tickSpecificData?: Maybe<ScheduleTickSpecificData>;
  timestamp: Scalars['Float']['output'];
};

export type ScheduleTickFailureData = {
  error: PythonError;
};

export type ScheduleTickSpecificData = ScheduleTickFailureData | ScheduleTickSuccessData;

export type ScheduleTickSuccessData = {
  run?: Maybe<Run>;
};

export type Scheduler = {
  schedulerClass?: Maybe<Scalars['String']['output']>;
};

export type SchedulerNotDefinedError = Error & {
  message: Scalars['String']['output'];
};

export type SchedulerOrError = PythonError | Scheduler | SchedulerNotDefinedError;

export type Schedules = {
  results: Array<Schedule>;
};

export type SchedulesOrError = PythonError | RepositoryNotFoundError | Schedules;

export type Secret = {
  allBranchDeploymentsScope: Scalars['Boolean']['output'];
  canEditSecret: Scalars['Boolean']['output'];
  canViewSecretValue: Scalars['Boolean']['output'];
  fullDeploymentScope: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  localDeploymentScope: Scalars['Boolean']['output'];
  locationNames: Array<Scalars['String']['output']>;
  secretName: Scalars['String']['output'];
  secretValue: Scalars['String']['output'];
  specificBranchDeploymentScope?: Maybe<Scalars['String']['output']>;
  updateTimestamp?: Maybe<Scalars['Float']['output']>;
  updatedBy?: Maybe<DagsterCloudUser>;
};

export type SecretInput = {
  locationNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scopes: SecretScopesInput;
  secretName: Scalars['String']['input'];
  secretValue: Scalars['String']['input'];
};

export type SecretScopesInput = {
  allBranchDeploymentsScope?: InputMaybe<Scalars['Boolean']['input']>;
  fullDeploymentScope?: InputMaybe<Scalars['Boolean']['input']>;
  localDeploymentScope?: InputMaybe<Scalars['Boolean']['input']>;
  specificBranchDeploymentScope?: InputMaybe<Scalars['String']['input']>;
};

export type Secrets = {
  secrets: Array<Secret>;
};

export type SecretsOrError = PythonError | Secrets | UnauthorizedError;

export type SelectInstallationResult = GitHubError | GithubAppInstallation | InstallationNotFoundError | PythonError | UnauthorizedError;

export type SelectorTypeConfigError = PipelineConfigValidationError & {
  incomingFields: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  path: Array<Scalars['String']['output']>;
  reason: EvaluationErrorReason;
  stack: EvaluationStack;
};

export type SendSampleNotificationFailure = {
  message: Scalars['String']['output'];
};

export type SendSampleNotificationMutationResult = InvalidAlertPolicyError | PythonError | SendSampleNotificationFailure | SendSampleNotificationSuccess | UnauthorizedError;

export type SendSampleNotificationSuccess = {
  message: Scalars['String']['output'];
};

export type Sensor = {
  assetSelection?: Maybe<AssetSelection>;
  canReset: Scalars['Boolean']['output'];
  defaultStatus: InstigationStatus;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobOriginId: Scalars['String']['output'];
  metadata: SensorMetadata;
  minIntervalSeconds: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nextTick?: Maybe<DryRunInstigationTick>;
  sensorState: InstigationState;
  sensorType: SensorType;
  targets?: Maybe<Array<Target>>;
};

export type SensorData = {
  lastCursor?: Maybe<Scalars['String']['output']>;
  lastRunKey?: Maybe<Scalars['String']['output']>;
  lastTickTimestamp?: Maybe<Scalars['Float']['output']>;
};

export type SensorDryRunResult = DryRunInstigationTick | PythonError | SensorNotFoundError;

export type SensorMetadata = {
  assetKeys?: Maybe<Array<AssetKey>>;
};

export type SensorNotFoundError = Error & {
  message: Scalars['String']['output'];
  sensorName: Scalars['String']['output'];
};

export type SensorOrError = PythonError | Sensor | SensorNotFoundError | UnauthorizedError;

/** This type represents the fields necessary to identify a sensor. */
export type SensorSelector = {
  repositoryLocationName: Scalars['String']['input'];
  repositoryName: Scalars['String']['input'];
  sensorName: Scalars['String']['input'];
};

/** An enumeration. */
export enum SensorType {
  Asset = 'ASSET',
  Automation = 'AUTOMATION',
  AutoMaterialize = 'AUTO_MATERIALIZE',
  FreshnessPolicy = 'FRESHNESS_POLICY',
  MultiAsset = 'MULTI_ASSET',
  RunStatus = 'RUN_STATUS',
  Standard = 'STANDARD',
  Unknown = 'UNKNOWN'
}

export type Sensors = {
  results: Array<Sensor>;
};

export type SensorsOrError = PythonError | RepositoryNotFoundError | Sensors;

export type Serverless = {
  awsAccessKeyId: Scalars['String']['output'];
  awsAuthToken?: Maybe<Scalars['String']['output']>;
  awsRegion: Scalars['String']['output'];
  awsSecretAccessKey: Scalars['String']['output'];
  canAccessLogs: Scalars['Boolean']['output'];
  canEnable: Scalars['Boolean']['output'];
  error?: Maybe<PythonError>;
  id: Scalars['String']['output'];
  isWaitlisted: Scalars['Boolean']['output'];
  logStreamEvents?: Maybe<ServerlessLogEventsConnection>;
  logStreams?: Maybe<ServerlessLogStreamsConnection>;
  registryAllowCustomBase?: Maybe<Scalars['Boolean']['output']>;
  registryUrl?: Maybe<Scalars['String']['output']>;
  requiresPaymentCheck: Scalars['Boolean']['output'];
  stackCompleteResourceCount?: Maybe<Scalars['Int']['output']>;
  stackRetryCount?: Maybe<Scalars['Int']['output']>;
  stackTotalResourceCount?: Maybe<Scalars['Int']['output']>;
  status: ServerlessAgentStatus;
};


export type ServerlessLogStreamEventsArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
  streamID: Scalars['ID']['input'];
};


export type ServerlessLogStreamsArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
};

/** An enumeration. */
export enum ServerlessAgentStatus {
  Active = 'ACTIVE',
  DeleteError = 'DELETE_ERROR',
  Deleting = 'DELETING',
  Inactive = 'INACTIVE',
  Provisioning = 'PROVISIONING',
  ProvisionError = 'PROVISION_ERROR',
  ProvisionRetryRequested = 'PROVISION_RETRY_REQUESTED',
  UpdateError = 'UPDATE_ERROR',
  UpdateRetryRequested = 'UPDATE_RETRY_REQUESTED',
  Updating = 'UPDATING'
}

export type ServerlessLogEvent = {
  message: Scalars['String']['output'];
  timestamp: Scalars['Float']['output'];
};

export type ServerlessLogEventsConnection = {
  cursor?: Maybe<Scalars['ID']['output']>;
  events: Array<ServerlessLogEvent>;
  hasMore: Scalars['Boolean']['output'];
};

export type ServerlessLogStream = {
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastEventTime: Scalars['Float']['output'];
};

export type ServerlessLogStreamsConnection = {
  cursor?: Maybe<Scalars['ID']['output']>;
  hasMore: Scalars['Boolean']['output'];
  streams: Array<ServerlessLogStream>;
};

export type ServerlessPexUploadUrl = {
  url?: Maybe<Scalars['String']['output']>;
};

export type SetDeploymentSettingsResult = DeleteFinalDeploymentError | DeploymentNotFoundError | DeploymentSettings | DuplicateDeploymentError | PythonError | UnauthorizedError;

export type SetOrganizationSettingsResult = OrganizationSettings | PythonError | UnauthorizedError;

export type SetScimSyncEnabledResult = PythonError | SetScimSyncEnabledSuccess | UnauthorizedError;

export type SetScimSyncEnabledSuccess = {
  enabled: Scalars['Boolean']['output'];
};

/** Set a cursor for a sensor to track state across evaluations. */
export type SetSensorCursorMutation = {
  Output: SensorOrError;
};

export type SetupRepoError = Error & {
  message: Scalars['String']['output'];
};

export type ShareFeedbackInput = {
  description: Scalars['String']['input'];
  path: Scalars['String']['input'];
  priority: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ShareFeedbackMutationResult = PythonError | ShareFeedbackSubmissionError | ShareFeedbackSuccess;

export type ShareFeedbackSubmissionError = Error & {
  message: Scalars['String']['output'];
};

export type ShareFeedbackSuccess = {
  success: Scalars['Boolean']['output'];
};

/** Shuts down a code location server. */
export type ShutdownRepositoryLocationMutation = {
  Output: ShutdownRepositoryLocationMutationResult;
};

/** The output from shutting down a code location server. */
export type ShutdownRepositoryLocationMutationResult = PythonError | RepositoryLocationNotFound | ShutdownRepositoryLocationSuccess | UnauthorizedError;

/** Output indicating that a code location server was shut down. */
export type ShutdownRepositoryLocationSuccess = {
  repositoryLocationName: Scalars['String']['output'];
};

export type SlackAlertPolicyNotification = {
  slackChannelName: Scalars['String']['output'];
  slackWorkspaceName: Scalars['String']['output'];
};

export type SlackAppInstallation = {
  teamId: Scalars['String']['output'];
  teamName: Scalars['String']['output'];
};

export type Solid = {
  definition: ISolidDefinition;
  inputs: Array<Input>;
  isDynamicMapped: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  outputs: Array<Output>;
};

export type SolidContainer = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modes: Array<Mode>;
  name: Scalars['String']['output'];
  solidHandle?: Maybe<SolidHandle>;
  solidHandles: Array<SolidHandle>;
  solids: Array<Solid>;
};


export type SolidContainerSolidHandleArgs = {
  handleID: Scalars['String']['input'];
};


export type SolidContainerSolidHandlesArgs = {
  parentHandleID?: InputMaybe<Scalars['String']['input']>;
};

export type SolidDefinition = ISolidDefinition & {
  assetNodes: Array<AssetNode>;
  configField?: Maybe<ConfigTypeField>;
  description?: Maybe<Scalars['String']['output']>;
  inputDefinitions: Array<InputDefinition>;
  metadata: Array<MetadataItemDefinition>;
  name: Scalars['String']['output'];
  outputDefinitions: Array<OutputDefinition>;
  requiredResources: Array<ResourceRequirement>;
};

export type SolidHandle = {
  handleID: Scalars['String']['output'];
  parent?: Maybe<SolidHandle>;
  solid: Solid;
  stepStats?: Maybe<SolidStepStatsOrError>;
};


export type SolidHandleStepStatsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type SolidStepStatsConnection = {
  nodes: Array<RunStepStats>;
};

export type SolidStepStatsOrError = SolidStepStatsConnection | SolidStepStatusUnavailableError;

export type SolidStepStatusUnavailableError = Error & {
  message: Scalars['String']['output'];
};

export type SourceLocation = LocalFileCodeReference | UrlCodeReference;

export type SpecificPartitionAssetConditionEvaluationNode = {
  childUniqueIds: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  status: AssetConditionEvaluationStatus;
  uniqueId: Scalars['String']['output'];
};

export type StaleCause = {
  category: StaleCauseCategory;
  dependency?: Maybe<AssetKey>;
  dependencyPartitionKey?: Maybe<Scalars['String']['output']>;
  key: AssetKey;
  partitionKey?: Maybe<Scalars['String']['output']>;
  reason: Scalars['String']['output'];
};

/** An enumeration. */
export enum StaleCauseCategory {
  Code = 'CODE',
  Data = 'DATA',
  Dependencies = 'DEPENDENCIES'
}

/** An enumeration. */
export enum StaleStatus {
  Fresh = 'FRESH',
  Missing = 'MISSING',
  Stale = 'STALE'
}

/** Enable a schedule to launch runs for a job at a fixed interval. */
export type StartScheduleMutation = {
  Output: ScheduleMutationResult;
};

/** A representation of the step duration metric, partitioned by job. */
export type StepDurationByJobUsageMetricValue = UsageMetricValue & {
  endTimestamp: Scalars['Float']['output'];
  jobName?: Maybe<Scalars['String']['output']>;
  percentTotal: Scalars['Float']['output'];
  repositoryLabel?: Maybe<Scalars['String']['output']>;
  startTimestamp: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

/** A representation of the step duration metric. */
export type StepDurationUsageMetricValue = UsageMetricValue & {
  endTimestamp: Scalars['Float']['output'];
  startTimestamp: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

/** The Dagster Cloud organization's step duration usage, in minutes. */
export type StepDurationUsageMetrics = {
  metrics: Array<UsageMetricValue>;
  timeGranularity: TimeGranularity;
  unit: Scalars['String']['output'];
};

export type StepEvent = {
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
};

export enum StepEventStatus {
  Failure = 'FAILURE',
  InProgress = 'IN_PROGRESS',
  Skipped = 'SKIPPED',
  Success = 'SUCCESS'
}

export type StepExecution = {
  marshalledInputs?: InputMaybe<Array<MarshalledInput>>;
  marshalledOutputs?: InputMaybe<Array<MarshalledOutput>>;
  stepKey: Scalars['String']['input'];
};

export type StepExpectationResultEvent = MessageEvent & StepEvent & {
  eventType?: Maybe<DagsterEventType>;
  expectationResult: ExpectationResult;
  level: LogLevel;
  message: Scalars['String']['output'];
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export enum StepKind {
  /** This is a user-defined computation step */
  Compute = 'COMPUTE',
  /** This is a collect step that is not yet resolved */
  UnresolvedCollect = 'UNRESOLVED_COLLECT',
  /** This is a mapped step that has not yet been resolved */
  UnresolvedMapped = 'UNRESOLVED_MAPPED'
}

export type StepOutputHandle = {
  outputName: Scalars['String']['input'];
  stepKey: Scalars['String']['input'];
};

export type StepWorkerStartedEvent = DisplayableEvent & MarkerEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  markerEnd?: Maybe<Scalars['String']['output']>;
  markerStart?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

export type StepWorkerStartingEvent = DisplayableEvent & MarkerEvent & MessageEvent & StepEvent & {
  description?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<DagsterEventType>;
  label?: Maybe<Scalars['String']['output']>;
  level: LogLevel;
  markerEnd?: Maybe<Scalars['String']['output']>;
  markerStart?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  metadataEntries: Array<MetadataEntry>;
  runId: Scalars['String']['output'];
  solidHandleID?: Maybe<Scalars['String']['output']>;
  stepKey?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
};

/** Disable a schedule from launching runs for a job. */
export type StopRunningScheduleMutation = {
  Output: ScheduleMutationResult;
};

/** Disable a sensor from launching runs for a job. */
export type StopSensorMutation = {
  Output: StopSensorMutationResultOrError;
};

export type StopSensorMutationResult = {
  instigationState?: Maybe<InstigationState>;
};

export type StopSensorMutationResultOrError = PythonError | StopSensorMutationResult | UnauthorizedError;

export type StripeCustomer = {
  createdDate: Scalars['Float']['output'];
  email?: Maybe<Scalars['String']['output']>;
  frozenTime?: Maybe<Scalars['Float']['output']>;
  needsTaxID: Scalars['Boolean']['output'];
  taxIDExempt: Scalars['Boolean']['output'];
  taxIDs: Array<TaxId>;
};

export type StripeInvoice = {
  created: Scalars['Float']['output'];
  discount?: Maybe<StripeInvoiceDiscount>;
  dueDate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  items: Array<StripeInvoiceItem>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  periodEnd: Scalars['Float']['output'];
  periodStart: Scalars['Float']['output'];
  status: StripeInvoiceStatus;
  total: Scalars['Float']['output'];
};

export type StripeInvoiceDiscount = {
  amount: Scalars['Int']['output'];
  coupon: Scalars['String']['output'];
};

export type StripeInvoiceItem = {
  description: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type StripeInvoiceList = {
  invoices: Array<StripeInvoice>;
};

export type StripeInvoiceListOrError = PythonError | StripeInvoiceList | UnauthorizedError | UnknownStripeInvoiceError;

export enum StripeInvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

export type StripePaymentMethod = {
  cardBrand: Scalars['String']['output'];
  cardExpMonth: Scalars['String']['output'];
  cardExpYear: Scalars['String']['output'];
  cardLast4: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type StripePaymentMethodList = {
  paymentMethods: Array<StripePaymentMethod>;
};

export type StripePaymentMethodListOrError = PythonError | StripePaymentMethodList | UnauthorizedError;

export type StripeSetupIntent = {
  clientSecret: Scalars['String']['output'];
};

export type StripeSetupIntentMutationResult = FailedToSetupStripe | PythonError | StripeSetupIntent | UnauthorizedError;

export type StripeSubscription = {
  billingPeriodEnd: Scalars['Int']['output'];
  billingPeriodStart: Scalars['Int']['output'];
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  creditLimit: Scalars['Int']['output'];
  creditUsage: Scalars['Int']['output'];
  deploymentCount: Scalars['Int']['output'];
  deploymentLimit: Scalars['Int']['output'];
  id?: Maybe<Scalars['String']['output']>;
  numFreeCredits: Scalars['Int']['output'];
  planType?: Maybe<DagsterCloudPlanType>;
  seats: Scalars['Int']['output'];
  serverlessComputeMinutesUsage: Scalars['Int']['output'];
  status: DagsterCloudSubscriptionStatus;
  stepDurationUsage: Scalars['Int']['output'];
  trialEndDate?: Maybe<Scalars['Float']['output']>;
  trialServerlessStepDurationLimit: Scalars['Int']['output'];
  trialServerlessStepDurationUsage: Scalars['Int']['output'];
  trialStartDate?: Maybe<Scalars['Float']['output']>;
};

export type SyncSecretsResult = InvalidSecretInputError | PythonError | SyncSecretsSuccess | TooManySecretsError | UnauthorizedError;

export type SyncSecretsSuccess = {
  removedSecrets?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  secrets?: Maybe<Array<Maybe<Secret>>>;
};

export type Table = {
  records: Array<Scalars['String']['output']>;
  schema: TableSchema;
};

export type TableColumn = {
  constraints: TableColumnConstraints;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type TableColumnConstraints = {
  nullable: Scalars['Boolean']['output'];
  other: Array<Scalars['String']['output']>;
  unique: Scalars['Boolean']['output'];
};

export type TableColumnDep = {
  assetKey: AssetKey;
  columnName: Scalars['String']['output'];
};

export type TableColumnLineageEntry = {
  columnDeps: Array<TableColumnDep>;
  columnName: Scalars['String']['output'];
};

export type TableColumnLineageMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  lineage: Array<TableColumnLineageEntry>;
};

export type TableConstraints = {
  other: Array<Scalars['String']['output']>;
};

export type TableMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  table: Table;
};

export type TableSchema = {
  columns: Array<TableColumn>;
  constraints?: Maybe<TableConstraints>;
};

export type TableSchemaMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  schema: TableSchema;
};

export type TagAssetSelectionInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TagInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Target = {
  mode: Scalars['String']['output'];
  pipelineName: Scalars['String']['output'];
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
};

export type TaxId = {
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TaxIdInput = {
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type TeamAssetOwner = {
  team: Scalars['String']['output'];
};

/** Interface indicating that a run failed to terminate. */
export type TerminatePipelineExecutionFailure = {
  message: Scalars['String']['output'];
  run: Run;
};

/** Interface indicating that a run was terminated. */
export type TerminatePipelineExecutionSuccess = {
  run: Run;
};

/** Output indicating that a run failed to terminate. */
export type TerminateRunFailure = TerminatePipelineExecutionFailure & {
  message: Scalars['String']['output'];
  run: Run;
};

/** Terminates a run. */
export type TerminateRunMutation = {
  Output: TerminateRunResult;
};

/** The type of termination policy to use for a run. */
export enum TerminateRunPolicy {
  MarkAsCanceledImmediately = 'MARK_AS_CANCELED_IMMEDIATELY',
  SafeTerminate = 'SAFE_TERMINATE'
}

/** The output from a run termination. */
export type TerminateRunResult = PythonError | RunNotFoundError | TerminateRunFailure | TerminateRunSuccess | UnauthorizedError;

/** Output indicating that a run was terminated. */
export type TerminateRunSuccess = TerminatePipelineExecutionSuccess & {
  run: Run;
};

/** Indicates the runs that successfully terminated and those that failed to terminate. */
export type TerminateRunsResult = {
  terminateRunResults: Array<TerminateRunResult>;
};

/** The output from runs termination. */
export type TerminateRunsResultOrError = PythonError | TerminateRunsResult;

export type TestFields = {
  alwaysException?: Maybe<Scalars['String']['output']>;
  asyncString?: Maybe<Scalars['String']['output']>;
};

export type TextMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type TextRuleEvaluationData = {
  text?: Maybe<Scalars['String']['output']>;
};

export type TickEvaluation = {
  cursor?: Maybe<Scalars['String']['output']>;
  dynamicPartitionsRequests?: Maybe<Array<DynamicPartitionRequest>>;
  error?: Maybe<PythonError>;
  runRequests?: Maybe<Array<RunRequest>>;
  skipReason?: Maybe<Scalars['String']['output']>;
};

/** The smallest difference between two metric values. */
export enum TimeGranularity {
  Day = 'DAY',
  Month = 'MONTH'
}

export type TimePartitionRangeStatus = {
  endKey: Scalars['String']['output'];
  endTime: Scalars['Float']['output'];
  startKey: Scalars['String']['output'];
  startTime: Scalars['Float']['output'];
  status: PartitionRangeStatus;
};

export type TimePartitionStatuses = {
  ranges: Array<TimePartitionRangeStatus>;
};

export type TimestampMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  timestamp: Scalars['Float']['output'];
};

export type TimestampedError = {
  error: PythonError;
  timestamp?: Maybe<Scalars['Float']['output']>;
};

export type TooManySecretsError = Error & {
  message: Scalars['String']['output'];
};

export type TypeCheck = DisplayableEvent & {
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  metadataEntries: Array<MetadataEntry>;
  success: Scalars['Boolean']['output'];
};

export type UnauthorizedError = Error & {
  message: Scalars['String']['output'];
};

export type UnknownPipeline = PipelineReference & {
  name: Scalars['String']['output'];
  solidSelection?: Maybe<Array<Scalars['String']['output']>>;
};

export type UnknownStripeInvoiceError = Error & {
  message: Scalars['String']['output'];
};

export type UnknownStripePaymentMethodError = Error & {
  message: Scalars['String']['output'];
};

export type UnpartitionedAssetConditionEvaluationNode = {
  childUniqueIds: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  endTimestamp?: Maybe<Scalars['Float']['output']>;
  metadataEntries: Array<MetadataEntry>;
  startTimestamp?: Maybe<Scalars['Float']['output']>;
  status: AssetConditionEvaluationStatus;
  uniqueId: Scalars['String']['output'];
};

export type UnpartitionedAssetStatus = {
  assetKey: AssetKey;
  failed: Scalars['Boolean']['output'];
  inProgress: Scalars['Boolean']['output'];
  materialized: Scalars['Boolean']['output'];
};

export type UnsupportedOperationError = Error & {
  message: Scalars['String']['output'];
};

export type UpdateCustomerTaxIdError = Error & {
  message: Scalars['String']['output'];
};

export type UpdateCustomerTaxIdSucceeded = {
  status: Scalars['String']['output'];
};

export type UpdateDeploymentAgentTypeResult = DagsterCloudDeployment | DeploymentNotFoundError | PythonError | UnauthorizedError;

export type UpdateLocationFromDocumentMutationResult = InvalidLocationError | PythonError | UnauthorizedError | WorkspaceEntry;

export type UpdateLocationMutationResult = PythonError | UnauthorizedError | WorkspaceEntry;

export type UpdateMetricCustomizationsFailureError = {
  message: Scalars['String']['output'];
};

export type UpdateMetricCustomizationsResult = PythonError | UnauthorizedError | UpdateMetricCustomizationsFailureError | UpdateMetricCustomizationsSuccess;

export type UpdateMetricCustomizationsSuccess = {
  costMultipliers: Array<InsightsMetricCostMultiplier>;
  customizations: Array<InsightsMetricCustomization>;
  status: Scalars['String']['output'];
};

export type UpdateReportingDeploymentSettingsResult = PythonError | UnauthorizedError | UpdateReportingDeploymentSettingsSuccess;

export type UpdateReportingDeploymentSettingsSuccess = {
  status: Scalars['String']['output'];
};

export type UpdateStripeCustomerMutationResult = PythonError | StripeCustomer | UnauthorizedError;

export type UpdateStripeCustomerTaxIDsMutationResult = PythonError | UnauthorizedError | UpdateCustomerTaxIdError | UpdateCustomerTaxIdSucceeded;

export type UpdateStripeSubscriptionFailed = Error & {
  message: Scalars['String']['output'];
};

export type UpdateStripeSubscriptionMutationResult = CustomerPaymentMethodRequired | CustomerPaymentRequired | PythonError | StripeSubscription | UnauthorizedError | UpdateStripeSubscriptionFailed;

export type UrlCodeReference = {
  label?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type UrlMetadataEntry = MetadataEntry & {
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** A representation of the usage of a particular metric. */
export type UsageMetricValue = {
  endTimestamp: Scalars['Float']['output'];
  startTimestamp: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

/** The Dagster Cloud organization's usage metrics. */
export type UsageMetrics = {
  dagsterCredits: StepDurationUsageMetrics;
  dagsterCreditsByJob: StepDurationUsageMetrics;
  serverlessComputeMinutes: StepDurationUsageMetrics;
  serverlessComputeMinutesByJob: StepDurationUsageMetrics;
  stepDuration: StepDurationUsageMetrics;
  stepDurationByJob: StepDurationUsageMetrics;
};


/** The Dagster Cloud organization's usage metrics. */
export type UsageMetricsDagsterCreditsArgs = {
  timeGranularity: TimeGranularity;
};


/** The Dagster Cloud organization's usage metrics. */
export type UsageMetricsDagsterCreditsByJobArgs = {
  startTimestamp: Scalars['Float']['input'];
};


/** The Dagster Cloud organization's usage metrics. */
export type UsageMetricsServerlessComputeMinutesArgs = {
  timeGranularity: TimeGranularity;
};


/** The Dagster Cloud organization's usage metrics. */
export type UsageMetricsServerlessComputeMinutesByJobArgs = {
  startTimestamp: Scalars['Float']['input'];
};


/** The Dagster Cloud organization's usage metrics. */
export type UsageMetricsStepDurationArgs = {
  timeGranularity: TimeGranularity;
};


/** The Dagster Cloud organization's usage metrics. */
export type UsageMetricsStepDurationByJobArgs = {
  startTimestamp: Scalars['Float']['input'];
};

/** A solid definition and its invocations within the repo. */
export type UsedSolid = {
  definition: ISolidDefinition;
  invocations: Array<NodeInvocationSite>;
};

export type UserAssetOwner = {
  email: Scalars['String']['output'];
};

export type UserCount = {
  count: Scalars['Int']['output'];
  userType: PermissionGrant;
};

export type UserLimitError = Error & {
  message: Scalars['String']['output'];
};

/**
 * This error should only be raised after authorization to view the user is checked to
 * not leak info about the users.
 */
export type UserNotFoundError = Error & {
  message: Scalars['String']['output'];
};

export type UserNuxChecklistEntry = {
  dismissed: Scalars['Boolean']['output'];
  entryKey: Scalars['String']['output'];
  metadata?: Maybe<Scalars['GenericScalar']['output']>;
  updateTimestamp?: Maybe<Scalars['Float']['output']>;
};

export type UserTokensOrError = DagsterCloudUserTokens | PythonError | UnauthorizedError;

export type WaitingOnKeysRuleEvaluationData = {
  waitingOnAssetKeys?: Maybe<Array<AssetKey>>;
};

/** An enumeration. */
export enum WorkerStatus {
  Failed = 'FAILED',
  NotFound = 'NOT_FOUND',
  Running = 'RUNNING',
  Success = 'SUCCESS',
  Unknown = 'UNKNOWN'
}

export type Workspace = {
  id: Scalars['String']['output'];
  locationEntries: Array<WorkspaceLocationEntry>;
};

export type WorkspaceEntry = {
  canEditCodeLocationPermissions: Scalars['Boolean']['output'];
  connectionInfo?: Maybe<SandboxConnectionInfo>;
  dataUpdatedTimestamp?: Maybe<Scalars['Float']['output']>;
  hasOutdatedData: Scalars['Boolean']['output'];
  locationName: Scalars['String']['output'];
  metadataTimestamp?: Maybe<Scalars['Float']['output']>;
  sandboxProxyInfo?: Maybe<SandboxProxyInfo>;
  sandboxSavedTimestamp?: Maybe<Scalars['Float']['output']>;
  serializedDeploymentMetadata: Scalars['String']['output'];
};

export type WorkspaceLocationEntry = {
  displayMetadata: Array<RepositoryMetadata>;
  featureFlags: Array<FeatureFlag>;
  id: Scalars['ID']['output'];
  loadStatus: RepositoryLocationLoadStatus;
  locationOrLoadError?: Maybe<RepositoryLocationOrLoadError>;
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  updatedTimestamp: Scalars['Float']['output'];
};

export type WorkspaceLocationEntryOrError = PythonError | WorkspaceLocationEntry;

export type WorkspaceLocationStatusEntries = {
  entries: Array<WorkspaceLocationStatusEntry>;
};

export type WorkspaceLocationStatusEntriesOrError = PythonError | WorkspaceLocationStatusEntries;

export type WorkspaceLocationStatusEntry = {
  id: Scalars['ID']['output'];
  loadStatus: RepositoryLocationLoadStatus;
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  updateTimestamp: Scalars['Float']['output'];
};

export type WorkspaceOrError = PythonError | Workspace;

export type WorkspaceQuery = {
  workspaceEntries: Array<WorkspaceEntry>;
};

export type WorkspaceSchema = {
  /** Fetch all the named config types that are in the workspace config document schema. */
  allConfigTypes: Array<ConfigType>;
  /** Fetch the root config type for the workspace config document. */
  rootConfigType: ConfigType;
};

export type WrappingConfigType = {
  ofType: ConfigType;
};

export type WrappingDagsterType = {
  ofType: DagsterType;
};

export type ToggleOnboardingChecklistEntryResult = OnboardingChecklistEntry | PythonError | UnauthorizedError;

export type GetRunQueryVariables = Exact<{
  runId: Scalars['ID']['input'];
}>;


export type GetRunQuery = { runOrError: { __typename: 'PythonError', message: string } | { __typename: 'Run', id: string, runId: string, status: RunStatus, runConfig: any, pipeline: { name: string } | { name: string }, tags: Array<{ key: string, value: string }>, stats: { startTime?: number | null, endTime?: number | null, stepsSucceeded: number, stepsFailed: number } | {} } | { __typename: 'RunNotFoundError', message: string } };

export type GetRunsQueryVariables = Exact<{
  updatedAfter?: InputMaybe<Scalars['Float']['input']>;
}>;


export type GetRunsQuery = { runsOrError: { __typename: 'InvalidPipelineRunsFilterError', message: string } | { __typename: 'PythonError', message: string } | { __typename: 'Runs', results: Array<{ id: string, runId: string, status: RunStatus, runConfig: any, pipeline: { name: string } | { name: string }, tags: Array<{ key: string, value: string }>, stats: { startTime?: number | null, endTime?: number | null, stepsSucceeded: number, stepsFailed: number } | {} }> } };

export type LaunchPipelineExecutionMutationVariables = Exact<{
  pipelineName: Scalars['String']['input'];
  assetName: Scalars['String']['input'];
  partitionKey: Scalars['String']['input'];
  runConfig?: InputMaybe<Scalars['RunConfigData']['input']>;
}>;


export type LaunchPipelineExecutionMutation = { launchPipelineExecution: { __typename: 'ConflictingExecutionParamsError', message: string } | { __typename: 'InvalidOutputError' } | { __typename: 'InvalidStepError' } | { __typename: 'InvalidSubsetError', message: string } | { __typename: 'LaunchRunSuccess', run: { id: string, runId: string, status: RunStatus, pipeline: { name: string } | { name: string }, tags: Array<{ key: string, value: string }> } } | { __typename: 'NoModeProvidedError', message: string } | { __typename: 'PipelineNotFoundError', message: string } | { __typename: 'PresetNotFoundError', message: string } | { __typename: 'PythonError', message: string } | { __typename: 'RunConfigValidationInvalid', pipelineName: string, errors: Array<{ message: string } | { message: string } | { message: string } | { message: string } | { message: string } | { message: string }> } | { __typename: 'RunConflict', message: string } | { __typename: 'UnauthorizedError', message: string } };

export type RunFragment = { id: string, runId: string, status: RunStatus, runConfig: any, pipeline: { name: string } | { name: string }, tags: Array<{ key: string, value: string }>, stats: { startTime?: number | null, endTime?: number | null, stepsSucceeded: number, stepsFailed: number } | {} };
