import { UIFile } from '~/types'
import { compareRows } from '~/utils'
import { Action } from '../actions'

export function files(state: UIFile[] = [], action: Action): UIFile[] {
	switch (action.type) {
		case 'GET_FILES_OK':
			return action.payload.toSorted(compareRows())

		case 'FILE_ADDED':
			return [...state, action.payload as UIFile].toSorted(compareRows())

		case 'FILE_UPDATED':
			return state.map(f => (f.name === action.meta ? { ...f, ...action.payload } : f))

		case 'FILE_DELETED':
			return state.filter(f => f.name !== action.meta)
	}
	return state
}
