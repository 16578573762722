import { FC, Fragment, PropsWithChildren } from 'react'

export interface TagSetProps extends PropsWithChildren {
	label: string
	row: number
}

export const TagSet: FC<TagSetProps> = ({ label, row, children }) => (
	<Fragment>
		<div
			className="col-span-full h-full min-h-[3rem] rounded bg-white dark:bg-white/30"
			style={{ gridRow: row }}
			data-testid="tag-set"
		/>
		<span className="ml-4 self-center font-bold" style={{ gridRow: row, gridColumn: 1 }}>
			{label}
		</span>
		<ul
			className="flex flex-wrap items-center gap-2 p-2"
			style={{ gridRow: row, gridColumn: 2 }}
			data-testid="tags"
		>
			{children}
		</ul>
	</Fragment>
)
