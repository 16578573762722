import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '~/components/ui/toast'
import { TOAST_REMOVE_DELAY } from '~/utils'
import { useSelector } from '~/utils/hooks'

export interface ToasterProps {
	posistion?: 'left' | 'right'
}

export function Toaster(props: ToasterProps) {
	const { posistion = 'right' } = props
	const toasts = useSelector(state => state.toast.items)

	return (
		<ToastProvider swipeDirection={posistion}>
			{toasts.map(
				({
					id,
					title,
					description,
					action,
					variant,
					duration = variant === 'destructive' ? TOAST_REMOVE_DELAY * 2 : TOAST_REMOVE_DELAY,
					...props
				}) => (
					<Toast key={id} variant={variant} duration={duration} {...props} direction={posistion}>
						<div className="grid gap-1">
							{title && <ToastTitle>{title}</ToastTitle>}
							{description && <ToastDescription>{description}</ToastDescription>}
						</div>
						{action}
						<ToastClose />
					</Toast>
				)
			)}
			<ToastViewport className={posistion === 'left' ? 'left-0' : ''} />
		</ToastProvider>
	)
}
