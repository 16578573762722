import { captureException } from '@sentry/react'
import { StorageError } from '@supabase/storage-js'
import { AuthError, FunctionsError, PostgrestError, User, createClient } from '@supabase/supabase-js'
import clsx from 'clsx'
import { snakeCase, startCase } from 'lodash-es'
import { Except } from 'type-fest'
import { ZodInvalidTypeIssue } from 'zod'
import {
	CompanyDimension,
	ContactDimension,
	Database,
	Dimension,
	DimensionKey,
	Nullable,
	OrganizationUser,
	PartialNull,
	Project,
	ProspectingType,
	Resource,
	Role,
	SegmentTag,
	TablesInsert,
	TagSet,
	TypeID,
	TypeIDPrefix,
	UIEnrichedResource,
	UIOrganization,
	UIResource,
	UISegment
} from '~/types'
import { ResourcesState, TagConfigState, Toast } from '~/types/ui'
import { ALL_DIMENSIONS, AUTH_SESSION_KEY, COMPANY_DIMENSIONS, CONTACT_DIMENSIONS } from './constants'

export const supabase = createClient<Database>(
	import.meta.env.VITE_SUPABASE_API_URL,
	import.meta.env.VITE_SUPABASE_ANON_KEY,
	{
		auth: { storageKey: AUTH_SESSION_KEY }
	}
)

export function tagSetColor(
	id: TagSet['tag_type_id'] | 'orange' | 'yellow' | 'green' | 'blue' | 'purple' | null,
	mode?: 'button' | 'inactive' | 'border' | 'text'
) {
	switch (id) {
		case 'blue':
		case 'tt_01hhcq79j9ejcavfqqksw4ctk9':
			return clsx({
				'bg-blue': !mode,
				'text-blue': mode === 'text',
				'border-blue': mode === 'border',
				'bg-blue hover:bg-blue-hover': mode === 'button',
				'bg-blue bg-blue-hover hover:bg-blue': mode === 'inactive'
			})

		case 'green':
		case 'tt_01hhcq79j9farv9j0m33drza77':
			return clsx({
				'bg-green': !mode,
				'text-green': mode === 'text',
				'border-green': mode === 'border',
				'bg-green hover:opacity-40': mode === 'button',
				'bg-green opacity-40 hover:opacity-100': mode === 'inactive'
			})

		case 'yellow':
		case 'tt_01hhcq79j9eaz9qx0mncr0b8t8':
			return clsx({
				'bg-yellow': !mode,
				'text-yellow': mode === 'text',
				'border-yellow': mode === 'border',
				'bg-yellow hover:opacity-40': mode === 'button',
				'bg-yellow opacity-40 hover:opacity-100': mode === 'inactive'
			})

		case 'purple':
		case 'tt_01hhcq79j9fs3bytz751x96xmz':
			return clsx({
				'bg-purple': !mode,
				'text-purple': mode === 'text',
				'border-purple': mode === 'border',
				'bg-purple hover:opacity-40': mode === 'button',
				'bg-purple opacity-40 hover:opacity-100': mode === 'inactive'
			})

		case 'orange':
			return clsx({
				'bg-orange': !mode,
				'text-orange': mode === 'text',
				'border-orange': mode === 'border',
				'bg-orange hover:opacity-40': mode === 'button',
				'bg-orange opacity-40 hover:opacity-100': mode === 'inactive'
			})

		case null:
			return clsx({
				'bg-[#7A7A7A]': !mode,
				'text-[#7A7A7A]': mode === 'text',
				'border-[#7A7A7A]': mode === 'border',
				'bg-[#7A7A7A] hover:opacity-40': mode === 'button',
				'bg-[#7A7A7A] opacity-40 hover:opacity-100': mode === 'inactive'
			})
	}
	return null
}

export function getSegmentName(segment: UISegment, segmentTags: SegmentTag[], config: TagConfigState) {
	if (segment.label) return segment.label
	const sets = config.sets.map(set => {
		const tags = config.tags.filter(tag => tag.tag_set_id === set.id)
		return {
			...set,
			tags,
			activeTags: segmentTags.filter(st => st.segment_id === segment.id && tags.some(tag => tag.id === st.tag_id))
		}
	})

	return sets
		.flatMap(set => {
			const activeTags = set.tags.filter(
				t => segmentTags.some(st => st.tag_id === t.id) && set.activeTags.some(at => at.tag_id === t.id)
			)

			// Use set label if more than one tag in the set, otherwise use tag label
			return activeTags.length > 1 ? set.label : activeTags.map(t => t.label)
		})
		.join(':')
}

type ApiError = Error | ZodInvalidTypeIssue
/** Response type from supabase-functions
 * @todo Figure out if this can be generated
 */
interface ApiErrorResponse {
	error?: ApiError
	errors?: ApiError[]
}

export async function toToastError(
	error: PostgrestError | FunctionsError | AuthError | StorageError
): Promise<Except<Toast, 'id'>> {
	let description: string | undefined
	if (typeof error === 'string') {
		description = error
	} else if (!error) {
		description = 'Unknown error'
	} else if ('context' in error && error.context instanceof Response) {
		const contentType = error.context.headers.get('content-type')
		if (contentType?.includes('application/json')) {
			const json = (await error.context.json()) as ApiErrorResponse
			const err = json.errors?.[0] ?? json.error ?? error
			description = ['path' in err ? startCase(err.path.join('')) : null, err.message].filter(Boolean).join(' ')
		} else if (contentType?.includes('text/plain')) {
			description = await error.context.text()
		}
	}
	if (!description) description = error?.message ?? 'Unknown error'
	captureException(error) // Maybe not the best place to do this, but it's the most convenient way to get the actual error object
	return {
		variant: 'destructive',
		title: 'Error',
		description
	}
}

export function isTypeId<K extends TypeIDPrefix>(str: Nullable<string>, idType?: K): str is TypeID<K> {
	if (!str) return false
	const [type, id] = str.split('_')
	if (!id || id.length !== 26) return false
	if (idType) return type === idType
	return true
}

export function isMemberOf(company: UIOrganization, user: User | OrganizationUser, role?: Role) {
	const userId = 'user_id' in user ? user.user_id : user.id
	return company.org_users.some(cu => cu.user_id === userId && cu.role === role)
}

export type ComparableRow = Pick<Resource, 'created_at' | 'updated_at'>

export interface CompareRowsOptions {
	type: keyof ComparableRow
	order: 'asc' | 'desc'
}

/**
 * Standard function for sorting rows based on common properties all supabase tables have
 * @param options
 * @returns
 */
export function compareRows(options: Partial<CompareRowsOptions> = {}) {
	const order = options.order || 'desc'
	const type = options.type || 'created_at'

	return (a: PartialNull<ComparableRow>, b: PartialNull<ComparableRow>): number => {
		const aTime = new Date(a[type] ?? a.created_at ?? 0).getTime()
		const bTime = new Date(b[type] ?? b.created_at ?? 0).getTime()
		return order === 'asc' ? aTime - bTime : bTime - aTime
	}
}

export function toFilePath(project: Nullable<Project>, filename: string) {
	return `${project?.org_id}/${project?.id}/${filename}`
}

export const enrichResource =
	(state: ResourcesState) =>
	(res: UIResource): UIEnrichedResource => {
		const file = state.files.find(f => f.id === res.file_id) ?? null
		const text = state.texts.find(t => t.id === res.text_id) ?? null
		return { ...res, file, text }
	}

export function getLabel(res: UIEnrichedResource): string {
	return res.label || res.file?.filename || res.text?.value || ''
}

export function isSegmentEqual(
	a: TablesInsert<'resource_segment' | 'prospecting_company_segment'>,
	b: TablesInsert<'resource_segment' | 'prospecting_company_segment'>
) {
	if ('resource_id' in a && 'resource_id' in b)
		return a.resource_id === b.resource_id && a.segment_id === b.segment_id
	if ('company_id' in a && 'company_id' in b) return a.company_id === b.company_id && a.segment_id === b.segment_id
	return false
}
export function isDimension(key: string, type: 'contact'): key is ContactDimension
export function isDimension(key: string, type: 'company'): key is CompanyDimension
export function isDimension(key: string, type: 'all'): key is DimensionKey
// eslint-disable-next-line @typescript-eslint/unified-signatures -- Removing this line breaks typing in getDimensionKey
export function isDimension(key: string, type: ProspectingType | 'all'): key is DimensionKey
export function isDimension(key: string, type: ProspectingType | 'all' = 'all'): key is DimensionKey {
	const searchKey = snakeCase(key) as Dimension
	switch (type) {
		case 'all':
			return ALL_DIMENSIONS.map(d => d.replace(/(company|contact)_/g, '')).includes(searchKey)
		case 'company':
			return COMPANY_DIMENSIONS.map(d => d.replace('company_', '')).includes(searchKey)
		case 'contact':
			return CONTACT_DIMENSIONS.map(d => d.replace('contact_', '')).includes(searchKey)
	}
}

export function getDimensionKey(_key: string, type: 'contact'): ContactDimension | null
export function getDimensionKey(_key: string, type: 'company'): CompanyDimension | null
export function getDimensionKey(_key: string, type: 'all'): DimensionKey | null
export function getDimensionKey(_key: string, type: ProspectingType | 'all' = 'all'): DimensionKey | null {
	const key = _key.toLocaleLowerCase()
	return isDimension(key, type) ? key : null
}
