import { Column } from '@tanstack/react-table'
import { startCase, uniqBy } from 'lodash-es'
import * as React from 'react'
import { RxCheck, RxPlusCircled } from 'react-icons/rx'
import { isPresent } from 'ts-extras'
import {
	Badge,
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Separator
} from '~/components/ui'
import { cn } from '~/utils'

export interface FaceatedFilterOption {
	label: string
	value: string
	icon?: React.ComponentType<{ className?: string }>
}

export interface DataTableFacetedFilterProps<TData, TValue> {
	column: Column<TData, TValue>
	title?: string
}

export function DataTableFacetedFilter<TData, TValue>({ column, title }: DataTableFacetedFilterProps<TData, TValue>) {
	let facets: Map<string, number> | undefined
	try {
		facets = column.getFacetedUniqueValues() || new Map()
	} catch {
		return null
	}
	const selectedValues = new Set(column.getFilterValue() as string[])
	const options = uniqBy(
		Array.from(facets.keys())
			.sort()
			.flatMap<FaceatedFilterOption>(option =>
				Array.isArray(option)
					? option.filter(isPresent).map(o => ({ label: o, value: o }))
					: typeof option === 'string'
						? { label: option.includes('/') ? option : option, value: option } // Don't normalize pathnames
						: { label: option, value: option }
			),
		option => option.value
	).filter(option => isPresent(option.value) && option.value !== '')

	const isToggle = options.length > 0 && options.every(option => typeof option.value === 'boolean')

	return isToggle ? (
		<Button
			onClick={_ => column?.setFilterValue(column.getFilterValue() ? undefined : [true])}
			variant={isPresent(column.getFilterValue()) ? 'default' : 'outline'}
			size="sm"
			className="h-8 border-dashed"
		>
			<RxPlusCircled className="mr-2 h-4 w-4" />
			{title ?? column?.id?.includes('/') ? column?.id : startCase(column?.id)}
		</Button>
	) : (
		<Popover>
			<PopoverTrigger asChild>
				<Button variant="outline" size="sm" className="h-8 border-dashed">
					<RxPlusCircled className="mr-2 h-4 w-4" />
					{title ?? column?.id?.includes('/') ? column?.id : startCase(column?.id)}
					{selectedValues?.size > 0 && (
						<>
							<Separator orientation="vertical" className="mx-2 h-4" />
							<Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
								{selectedValues.size}
							</Badge>
							<div className="hidden space-x-1 lg:flex">
								{selectedValues.size > 2 ? (
									<Badge variant="secondary" className="rounded-sm px-1 font-normal">
										{selectedValues.size} selected
									</Badge>
								) : (
									options
										.filter(option => selectedValues.has(option.value))
										.map(option => (
											<Badge
												variant="secondary"
												key={option.value}
												className="rounded-sm px-1 font-normal"
											>
												{option.label}
											</Badge>
										))
								)}
							</div>
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto max-w-screen-2xs p-0" align="start">
				<Command>
					<CommandInput placeholder={title} />
					<CommandList>
						<CommandEmpty>No results found.</CommandEmpty>
						<CommandGroup>
							{options.map(option => {
								const isSelected = selectedValues.has(option.value)
								return (
									<CommandItem
										key={option.value}
										onSelect={() => {
											if (isSelected) {
												selectedValues.delete(option.value)
											} else {
												selectedValues.add(option.value)
											}
											const filterValues = Array.from(selectedValues)
											column?.setFilterValue(filterValues.length ? filterValues : undefined)
										}}
									>
										<div
											className={cn(
												'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
												isSelected
													? 'bg-primary text-primary-foreground'
													: 'opacity-50 [&_svg]:invisible'
											)}
										>
											<RxCheck className={cn('h-4 w-4')} />
										</div>
										{option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
										<span>{option.label}</span>
										{facets?.get(option.value) && (
											<span className="font-mono ml-auto flex h-4 w-4 items-center justify-center text-xs">
												{facets.get(option.value)}
											</span>
										)}
									</CommandItem>
								)
							})}
						</CommandGroup>
						{selectedValues.size > 0 && (
							<>
								<CommandSeparator />
								<CommandGroup>
									<CommandItem
										onSelect={() => column?.setFilterValue(undefined)}
										className="justify-center text-center"
									>
										Clear filters
									</CommandItem>
								</CommandGroup>
							</>
						)}
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}
