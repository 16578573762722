import { FileSpreadsheet, Globe } from 'lucide-react'
import { FC } from 'react'
import { LiaEdit, LiaQuestionSolid, LiaRobotSolid } from 'react-icons/lia'
import { EnrichmentType } from '~/types'

export interface EnrichmentTypeIconProps {
	type: Pick<EnrichmentType, 'id' | 'name'>
}

export const EnrichmentTypeIcon: FC<EnrichmentTypeIconProps> = ({ type }) => {
	switch (type.name) {
		case 'csv':
			return <FileSpreadsheet />
		case 'gpt':
			return <LiaRobotSolid />
		case 'domain':
			return <Globe />
		case 'proff':
			return <img className="h-6 p-0.5" src="/proff.ico" alt="Proff" />
		case 'manual':
			return <LiaEdit className="h-6 w-6" />

		default:
			return <LiaQuestionSolid className="h-6 w-6" />
	}
}
