import { zodResolver } from '@hookform/resolvers/zod'
import { DialogProps } from '@radix-ui/react-dialog'
import { startCase } from 'lodash-es'
import { FC, ReactElement, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
	Button,
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input
} from '~/components/ui'
import { Actions } from '~/redux'
import { Nullable, Project, ProspectingList } from '~/types'
import { supabase, toToastError, useDispatch, useSelector } from '~/utils'

const FormSchema = z.object({
	name: z.string().min(1, 'You must enter a name')
})

export interface ProjectCreatorProps {
	currentProject: Nullable<Project>
	onCreate?: (list: ProspectingList) => void

	children?: ReactElement
	open: DialogProps['open']
	onOpenChange?: DialogProps['onOpenChange']
}
export const ListDialog: FC<ProjectCreatorProps> = ({ currentProject, onCreate, children, ...props }) => {
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)
	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: { name: '' },
		mode: 'onBlur'
	})

	useEffect(() => {
		form.setValue('name', '')
	}, [form, props.open])

	return (
		<Dialog {...props}>
			{children && <DialogTrigger asChild>{children}</DialogTrigger>}
			<DialogContent>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(async data => {
							const prospectList = await supabase
								.from('prospecting_list')
								.insert({
									label: data.name,
									project_id: currentProject!.id,
									creator_id: user!.info!.id,
									org_id: currentProject!.org_id
								})
								.select('*')
								.single()
							if (prospectList.error) {
								form.setError('root', prospectList.error)
								// TODO: Remove toast when root error works
								dispatch(Actions.addToast(await toToastError(prospectList.error)))
							} else {
								onCreate?.(prospectList.data)
								props.onOpenChange?.(false)
							}
						}, console.error)}
						className="space-y-4"
					>
						<DialogHeader>
							<DialogTitle>Create list</DialogTitle>
							<DialogDescription>Create a new list to manage companies to prospect.</DialogDescription>
						</DialogHeader>
						<FormField
							control={form.control}
							name="name"
							render={({ field }) => (
								<FormItem>
									<FormLabel>{startCase(field.name)}</FormLabel>
									<FormControl>
										<Input placeholder="Energy Companies in DACH" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<DialogFooter>
							<DialogClose asChild>
								<Button type="button" variant="outline">
									Cancel
								</Button>
							</DialogClose>
							<Button type="submit">Continue</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	)
}
