import { FC } from 'react'
import { Link } from 'react-router-dom'
import {
	Avatar,
	AvatarFallback,
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	DropdownMenuTrigger
} from '~/components/ui'
import { getUserInitials, setItem, supabase, useSelector } from '~/utils'

export const UserNav: FC = () => {
	const user = useSelector(state => state.user)
	if (!user) return null
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="relative h-8 w-8 rounded-full" data-testid="avatar-button">
					<Avatar className="h-8 w-8">
						<AvatarFallback>{user.info ? getUserInitials(user.info) : '?'}</AvatarFallback>
					</Avatar>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end" forceMount>
				<DropdownMenuLabel className="font-normal">
					<div className="flex flex-col space-y-1">
						<p className="text-sm font-medium leading-none">{user.info?.user_metadata.name}</p>
						<p className="text-xs leading-none text-muted-foreground">{user.info?.email}</p>
					</div>
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup className="md:hidden">
					<DropdownMenuItem asChild>
						<Link to="/templates">
							Templates
							<DropdownMenuShortcut>⇧⌘T</DropdownMenuShortcut>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem asChild>
						<Link to="/configuration">
							Profile Configuration
							<DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
						</Link>
					</DropdownMenuItem>
				</DropdownMenuGroup>
				<DropdownMenuSeparator className="md:hidden" />
				<DropdownMenuGroup>
					<DropdownMenuItem asChild>
						<Link to="/settings/profile">
							Settings
							<DropdownMenuShortcut>⇧⌘S</DropdownMenuShortcut>
						</Link>
					</DropdownMenuItem>
				</DropdownMenuGroup>
				<DropdownMenuSeparator />
				<DropdownMenuItem
					onClick={async _ => {
						await supabase.auth.signOut()
						setItem('selectedProjectId', null)
						location.replace('/')
					}}
				>
					Log out
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
