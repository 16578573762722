import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { isPresent } from 'ts-extras'

export function setItem<T = any>(key: string, value: T) {
	if (typeof localStorage === 'undefined' || !isPresent(localStorage)) return null
	if (isPresent(value)) localStorage.setItem(key, JSON.stringify(value))
	else localStorage.removeItem(key)
}

export function getItem<T>(key: string): T | null {
	if (typeof localStorage === 'undefined' || !isPresent(localStorage)) return null
	const item = localStorage.getItem(key)
	if (!item) return null
	try {
		return JSON.parse(item)
	} catch {
		return item as T
	}
}

export function useStorageState<S = undefined>(cacheKey: string, initialState?: S): [S, Dispatch<SetStateAction<S>>] {
	const storage = getItem<S>(cacheKey)
	const [state, setState] = useState(storage ?? initialState)
	useEffect(() => {
		setItem(cacheKey, state)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state])
	useEffect(() => {
		// Reload possible previous state if cache key changes
		const existing = getItem<S>(cacheKey)
		if (!existing || existing === state) return
		setState(existing)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cacheKey])
	return [state as S, setState as Dispatch<SetStateAction<S>>]
}
