import {
	ColumnFiltersState,
	SortingState,
	TableOptions,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table'
import clsx from 'clsx'
import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/components/ui'
import { cn } from '~/utils'
import { DataTableToolbar, DataTableToolbarProps } from './data-table-toolbar'

export interface DataTableProps<TData, TValue> extends Partial<TableOptions<TData>> {
	tableClassName?: string

	/** If true, truncate long text / content in rows */
	truncate?: boolean
	className?: string
	searchColumn?: DataTableToolbarProps<TData, TValue>['searchColumn']
	defaultVibility?: VisibilityState
	/** Specificy actions that will be performed on selected data */
	actions?: DataTableToolbarProps<TData, TValue>['actions']
	showToggleColumns?: DataTableToolbarProps<TData, TValue>['showToggleColumns']
}

export function DataTable<TData, TValue>({
	columns = [],
	data = [],
	className,
	tableClassName,
	truncate,
	searchColumn,
	defaultVibility = {},
	actions,
	state,
	showToggleColumns,
	...props
}: DataTableProps<TData, TValue>) {
	const [rowSelection, setRowSelection] = useState({})
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultVibility)
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const [sorting, setSorting] = useState<SortingState>([])

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			columnVisibility,
			rowSelection,
			columnFilters,
			...state
		},
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		...props
	})

	return (
		<>
			<DataTableToolbar
				table={table}
				searchColumn={searchColumn}
				columns={columns}
				actions={actions}
				showToggleColumns={showToggleColumns}
			/>
			<div className={cn('min-h-0 rounded-md border bg-white dark:bg-stone-900', className)}>
				<Table className={tableClassName}>
					<TableHeader
						className={clsx('bg-white dark:bg-stone-900', { 'sticky top-0 z-20': props.enableRowPinning })}
					>
						{table.getHeaderGroups().map(headerGroup => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<TableHead key={header.id} colSpan={header.colSpan}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
									</TableHead>
								))}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map(row => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && 'selected'}
									aria-disabled={(row.original as any).disabled ?? false}
								>
									{row.getVisibleCells().map(cell => {
										if (truncate)
											return (
												<TableCell
													key={cell.id}
													className="group max-w-screen-xs truncate hover:overflow-visible"
												>
													<div className="relative inline group-hover:z-10 group-hover:inline-block group-hover:backdrop-blur-3xl">
														{flexRender(cell.column.columnDef.cell, cell.getContext())}
													</div>
												</TableCell>
											)
										const value = cell.getValue()?.toString()
										return (
											<TableCell key={cell.id}>
												{!value ? (
													flexRender(cell.column.columnDef.cell, cell.getContext())
												) : value.length > 100 ? (
													<div style={{ minWidth: `${value.length / 8}ch` }}>
														{flexRender(cell.column.columnDef.cell, cell.getContext())}
													</div>
												) : value.length > 50 ? (
													<div className="max-h-20">
														{flexRender(cell.column.columnDef.cell, cell.getContext())}
													</div>
												) : (
													<div className="w-[max-content]">
														{flexRender(cell.column.columnDef.cell, cell.getContext())}
													</div>
												)}
											</TableCell>
										)
									})}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns.length} className="h-24 text-center">
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
		</>
	)
}
