import { captureException } from '@sentry/react'
import type { Store } from 'redux'
import type { SagaMiddleware } from 'redux-saga'
import { call, cancel, delay, fork, take } from 'typed-redux-saga'
import { __DEV__ } from '~/utils'
import { fileSagas } from './files'
import { prospectingSagas } from './prospecting'
import { resourceSagas } from './resources'
import { runSagas } from './runs'
import { watchRealtimeDBEvents } from './sagaUtils'
import { segmentSagas } from './segments'
import { tagSetSagas } from './tagSets'
import { templateSagas } from './templates'
import { userSagas } from './user'

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR'

const sagas = [
	fileSagas,
	segmentSagas,
	prospectingSagas,
	runSagas,
	tagSetSagas,
	templateSagas,
	resourceSagas,
	userSagas,
	watchRealtimeDBEvents
]

function makeRestartable(saga: any) {
	return function* () {
		while (true) {
			try {
				yield* call(saga)
				break
			} catch (e) {
				captureException(e)
				console.error(`Saga '${saga.name}' failed.`, e)
			}
			yield* delay(1000)
		}
	}
}

// TODO: Add proper typing
function createAbortableSaga(saga: any) {
	if (__DEV__) {
		return function* main() {
			const sagaTask = yield* fork(saga)

			yield* take(CANCEL_SAGAS_HMR)
			yield* cancel(sagaTask)
		}
	} else {
		return saga
	}
}

const SagaManager = {
	startSagas(sagaMiddleware: SagaMiddleware<any>) {
		// eslint-disable-next-line github/array-foreach
		sagas
			.map(makeRestartable)
			.map(createAbortableSaga)
			.forEach(saga => sagaMiddleware.run(saga))
	},

	cancelSagas(store: Store<any>) {
		store.dispatch({
			type: CANCEL_SAGAS_HMR
		})
	}
}

export default SagaManager
