import { ColumnDef, TableOptions } from '@tanstack/react-table'
import { Paperclip } from 'lucide-react'
import { FC, useMemo } from 'react'
import { twJoin } from 'tailwind-merge'
import { isPresent } from 'ts-extras'
import { DataTable } from '~/components/table'
import { DataTableColumnHeader } from '~/components/table/data-table-column-header'
import { Badge, Checkbox, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui'
import { UIResource } from '~/types'
import { depluralize, enrichResource, getLabel, getSegmentName, tagSetColor, useSelector } from '~/utils'

export interface ResourceTableProps extends Partial<TableOptions<UIResource>> {
	className?: string
	data: UIResource[]
}

export const ResourceTable: FC<ResourceTableProps> = props => {
	const resourceCategories = useSelector(state => state.resourceCategories).toSorted((a, b) => a.order - b.order)
	const resourceState = useSelector(state => state.resources)
	const files = useSelector(state => state.files)
	const config = useSelector(state => state.config)
	const tags = config.tags
	const segments = useSelector(state => state.segments)
	const segmentsTags = useSelector(state => state.segmentTags)
	const resourceSegments = useSelector(state => state.resourceSegments)

	const columns = useMemo(() => {
		const data: Array<ColumnDef<UIResource>> = [
			{
				id: 'select',
				header: ({ table }) => (
					<Checkbox
						checked={table.getIsAllPageRowsSelected()}
						onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
						aria-label="Select all"
						className="translate-y-[2px]"
					/>
				),
				cell: ({ row }) => (
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={value => row.toggleSelected(!!value)}
						aria-label="Select row"
						className="translate-y-[2px]"
					/>
				),
				enableSorting: false,
				enableColumnFilter: false,
				enableHiding: false
			},
			{
				id: 'type',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				accessorFn: row => depluralize(resourceCategories.find(at => at.id === row.category_id)?.label),
				filterFn: (row, id, value) => value.includes(row.getValue(id)),
				enableColumnFilter: false
			},
			{
				id: 'label',
				accessorFn: row => getLabel(enrichResource(resourceState)(row)),
				header: ({ column }) => <DataTableColumnHeader title="Attribute" column={column} />,
				cell: ({ cell, row }) => {
					const resourceFile = enrichResource(resourceState)(row.original).file
					const attachment = files.find(f => f.id === resourceFile?.id || f.name === resourceFile?.filename)
					return (
						<span>
							{cell.getValue<string>()}{' '}
							{attachment && (
								<TooltipProvider delayDuration={0}>
									<Tooltip>
										<TooltipTrigger className="align-middle">
											<Paperclip className="h-em" />
										</TooltipTrigger>
										<TooltipContent>{attachment.name}</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							)}
						</span>
					)
				},
				enableColumnFilter: false
			},
			{
				id: 'description',
				accessorFn: row => row.description,
				header: ({ column }) => <DataTableColumnHeader title="Description" column={column} />,
				enableColumnFilter: false
			},
			{
				id: 'segment',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				cell: ({ row: { original: row } }) => (
					<div className="flex flex-wrap gap-2">
						{segments
							.filter(segment =>
								resourceSegments.some(s => s.segment_id === segment.id && s.resource_id === row.id)
							)
							.map(seg => (
								<Badge
									key={seg.id}
									variant="secondary"
									className={twJoin(
										'text-center leading-none dark:text-gray-900',
										tagSetColor('orange', 'button')
									)}
								>
									{getSegmentName(seg, segmentsTags, config)}
								</Badge>
							))}
					</div>
				),
				accessorFn: _ => {
					const rowTags = segments.map(seg => getSegmentName(seg, segmentsTags, config))
					if (rowTags.length === 0) return null
					return rowTags
				},
				filterFn: (row, _, values: string[]) =>
					segments
						.filter(segment =>
							resourceSegments.some(s => s.segment_id === segment.id && s.resource_id === row.id)
						)
						.map(seg => getSegmentName(seg, segmentsTags, config))
						.some(segment => values.includes(segment))
			},
			{
				id: 'tag',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				cell: ({ row: { original: row } }) => (
					<div className="flex flex-wrap gap-2">
						{resourceState.tags
							.filter(tag => tag.resource_id === row.id)
							.map(tag => tags.find(t => t.id === tag.tag_id))
							.filter(isPresent)
							.map(tag => (
								<Badge
									key={tag.id}
									variant="secondary"
									className={twJoin(
										'dark:text-gray-900',
										tagSetColor(
											tag.type ??
												config.sets.find(t => t.id === tag.tag_set_id)?.tag_type_id ??
												null,
											'button'
										)
									)}
								>
									{tag.label}
								</Badge>
							))}
					</div>
				),
				accessorFn: row => {
					const rowTags = resourceState.tags
						.filter(tag => tag.resource_id === row.id)
						.map(tag => tags.find(t => t.id === tag.tag_id))
						.filter(isPresent)
						.map(tag => tag.label)

					if (rowTags.length === 0) return null

					return rowTags
				},
				filterFn: (row, _, values: string[]) =>
					row.getValue<string[]>(_)?.some(tag => values.includes(tag)) ?? false
			},
			{
				id: 'enabled',
				accessorKey: 'enabled',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				cell: ({ cell }) => (cell.getValue<boolean>() ? 'No' : 'Yes'),
				enableColumnFilter: false
			},
			{
				id: 'created_at',
				accessorKey: 'created_at',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				cell: ({ cell }) => new Date(cell.getValue<string>()).toLocaleString(),
				enableColumnFilter: false
			},

			{
				id: 'updated_at',
				accessorKey: 'updated_at',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				cell: ({ cell }) =>
					cell.getValue<string>() ? new Date(cell.getValue<string>()).toLocaleString() : null,
				enableColumnFilter: false
			},
			{
				id: 'hasFile',
				accessorFn: row => !!row.file_id,
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				filterFn: (row, id, values: boolean[]) => values?.includes(row.getValue(id)) ?? false
			}
		]
		return data
	}, [resourceCategories, resourceState, files, segments, resourceSegments, segmentsTags, config, tags])

	return (
		<DataTable
			getRowId={e => e.id}
			searchColumn="label"
			defaultVibility={{ created_at: false, updated_at: false, enabled: false, hasFile: false }}
			columns={columns}
			{...props}
		/>
	)
}
