import type { Template as UITemplate } from '~/types'
import { Action, MetaAction } from '../actions'

const initialState = null

function item(state: UITemplate, action: MetaAction): UITemplate {
	if (state.id !== action.meta) return state
	switch (action.type) {
		case 'UPDATE_TEMPLATE_OK':
		case 'UPDATE_TEMPLATE':
			return { ...state, ...action.payload }
	}
	return state
}

export function templates(state: UITemplate[] | null = initialState, action: Action): UITemplate[] | null {
	switch (action.type) {
		case 'GET_TEMPLATES_SUCCESS':
			return action.payload

		case 'INSERT_TEMPLATE_OK':
			return [...(state ?? []).filter(t => t.id !== action.payload.id), action.payload]

		case 'UPDATE_TEMPLATE_OK':
		case 'UPDATE_TEMPLATE':
			return state?.map(t => item(t, action)) ?? null
	}
	return state
}
