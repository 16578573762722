import type { Enrichment, EnrichmentType } from '~/types'
import { PROFF_COMPANY_ENRICHMENT_ID } from '~/utils'
import { Action } from '../actions'

export function enrichments(state: Enrichment[] = [], action: Action): Enrichment[] {
	switch (action.type) {
		case 'GET_ENRICHMENTS_OK':
			return action.payload

		case 'INSERT_ENRICHMENT_OK':
			if (action.payload.type_id === PROFF_COMPANY_ENRICHMENT_ID) return state
			return [...state, action.payload]

		case 'UPDATE_ENRICHMENT_OK':
			return state.map(rc => (rc.id === action.payload.id ? action.payload : rc))

		case 'DELETE_ENRICHMENT_OK':
			return state.filter(resourceCategory => resourceCategory.id !== action.payload)
	}
	return state
}

export function enrichmentTypes(state: EnrichmentType[] = [], action: Action): EnrichmentType[] {
	switch (action.type) {
		case 'GET_ENRICHMENT_TYPES_OK':
			return action.payload

		case 'INSERT_ENRICHMENT_TYPE_OK':
			return [...state, action.payload]

		case 'UPDATE_ENRICHMENT_TYPE_OK':
			return state.map(rc => (rc.id === action.payload.id ? action.payload : rc))

		case 'DELETE_ENRICHMENT_TYPE_OK':
			return state.filter(resourceCategory => resourceCategory.id !== action.payload)
	}
	return state
}
