/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { CustomCellRendererProps } from 'ag-grid-react'
import { isObject } from 'lodash-es'
import { ArrowRightCircle } from 'lucide-react'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Except } from 'type-fest'
import { AutoTable, AutoTableProps } from '~/components/table/AutoTable'
import { Actions } from '~/redux'
import { Enrichment, ProspectingContact, ProspectingList } from '~/types'
import {
	CONTACT_DIMENSIONS,
	MANUAL_CONTACT_ENRICHMENT_ID,
	MANUAL_CONTACT_WEIGHT_ID,
	compareRows,
	getDimensionEntry,
	getDimensionKey,
	getItem,
	isDimension,
	supabase,
	toToastError,
	useCurrentProject,
	useDispatch,
	useMemoSelector,
	useStorageState
} from '~/utils'
import { ProspectingSearchParams } from './Prospecting'
import { deleteRows } from './listUtils'

export const excludedColums = ['created_by', 'updated_at', 'created_at', 'org_id'] as const

const isContactDimension = (key: string) => isDimension(key, 'contact')

export interface ContactTableProps extends Except<ProspectingSearchParams, 'type'>, AutoTableProps<any> {
	list: ProspectingList
	contacts: ProspectingContact[]
	enrichments: Enrichment[]
}

export const ContactTable: FC<ContactTableProps> = ({ tab, list, contacts, enrichments, ...props }) => {
	const dispatch = useDispatch()
	const project = useCurrentProject()

	const types = useMemoSelector(
		state => state.enrichments.types,
		t =>
			t
				.filter(et => !['manual', 'proff'].includes(et.name) && et.type === 'contact')
				.sort(compareRows({ order: 'asc' }))
	)
	const selectedType = types.find(et => et.name === tab) // TODO: Retrieve enrichment based on latest of each type

	const columnVisiblityKey = `col-contact-${tab}`
	const [columnVisibility, setColumnVisibility] = useStorageState<Record<string, boolean>>(columnVisiblityKey)
	useEffect(() => {
		const enrichmentsByType = tab === 'all' ? enrichments : enrichments.filter(e => e.type_id === selectedType?.id)
		const contactEnrichments = enrichmentsByType.filter(e => types.map(t => t.id).includes(e.type_id))
		const dimensionKeys = Object.fromEntries(
			CONTACT_DIMENSIONS.map(t => [t.replace('contact_', ''), tab === 'all'])
		)
		const set: Record<string, boolean> = {
			id: false,
			first_name: true,
			last_name: true,
			company: true,
			...dimensionKeys,
			roles: tab === 'all'
		}

		for (const row of contactEnrichments) {
			for (const key in row) {
				if (!Object.hasOwn(row, key) || key in set) continue
				set[key] = contactEnrichments.some(e => {
					if (!isObject(e.data)) return false
					if (key in e.data) return true

					return false
				})
			}
		}

		// Only override custom column visibility if it has not been set before
		if (tab === 'custom' && getItem(columnVisiblityKey)) {
			for (const key in set) {
				if (!Object.hasOwn(set, key)) continue
				set[key] = false
			}
			Object.assign(set, getItem(columnVisiblityKey))
		}
		setColumnVisibility(set)
	}, [columnVisiblityKey, contacts, enrichments, list.id, selectedType?.id, setColumnVisibility, tab, types])

	const onCellEdit = useCallback<NonNullable<AutoTableProps<ProspectingContact>['onCellValueChanged']>>(
		async ({ value, colDef, node }) => {
			if (!colDef.colId) return
			const key = getDimensionKey(colDef.colId, 'contact')
			if (!key) return

			if (!key) return
			const enr = await supabase
				.from('enrichment')
				.insert({
					list_id: list.id,
					data: { [key]: value },
					type_id: MANUAL_CONTACT_ENRICHMENT_ID,
					contact_id: node.data?.id
				})
				.select()
				.single()
			if (enr.error) return dispatch(Actions.addToast(await toToastError(enr.error)))

			const dim = await supabase
				.from(`dimension_contact_${key}`)
				.insert(getDimensionEntry(enr.data.id, MANUAL_CONTACT_WEIGHT_ID)(value as string))
			if (dim.error) {
				dispatch(Actions.addToast(await toToastError(dim.error)))
				await supabase.from('enrichment').delete().eq('id', enr.data.id)
			} else if (node.data) {
				dispatch({
					type: 'UPDATE_PROSPECTING_CONTACT_OK',
					meta: node.data.id,
					payload: { ...node.data, [key]: value }
				})
			} else {
				console.error(`ctx.row.data is missing for ${key}`)
			}
		},
		[dispatch, list.id]
	)

	const actions = useMemo(
		() =>
			({
				deleteContacts: async rows => {
					const errors = await deleteRows(rows)
					for (const error of errors) {
						dispatch(Actions.addToast(await toToastError(error)))
					}
				}
			}) satisfies AutoTableProps<(typeof contacts)[0]>['actions'],
		[dispatch]
	)

	if (!project) return null
	return (
		<AutoTable
			className="max-h-full w-full"
			excludedColumns={excludedColums}
			data={contacts}
			editableColumns={isContactDimension}
			onCellValueChanged={onCellEdit}
			showToggleColumns={tab === 'custom'}
			actions={actions}
			{...props}
			columnVisibility={columnVisibility}
			onColumnVisibilityChange={setColumnVisibility}
			extraColumns={[
				{
					colId: 'actions',
					maxWidth: 60,
					sortable: false,
					resizable: false,
					pinned: 'right',

					cellRenderer: ({ data }: CustomCellRendererProps<ProspectingContact>) => (
						<div className="flex h-full items-center justify-center">
							<Link to={data!.id}>
								<ArrowRightCircle className="h-4 w-4" />
							</Link>
						</div>
					)
				}
			]}
		/>
	)
}
