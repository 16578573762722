import type { ResourceSegment } from '~/types'
import { isSegmentEqual } from '~/utils'
import { Action, MetaAction } from '../actions'

export const initialState: ResourceSegment[] = []

function items(state: ResourceSegment, action: MetaAction): ResourceSegment {
	if (typeof action.meta === 'string' || !('segment_id' in action.meta)) return state
	if (!isSegmentEqual(state, action.meta)) return state
	switch (action.type) {
		case 'UPDATE_RESOURCE_SEGMENT_OK':
			return { ...state, ...action.payload }
	}
	return state
}

export function resourceSegments(state: ResourceSegment[] = initialState, action: Action): ResourceSegment[] {
	switch (action.type) {
		case 'GET_RESOURCE_SEGMENTS_OK':
			return action.payload

		case 'INSERT_RESOURCE_SEGMENT_OK':
			return [...state, action.payload]

		case 'UPDATE_RESOURCE_SEGMENT_OK':
			return state.map(as => items(as, action))

		case 'DELETE_RESOURCE_SEGMENT_OK':
			return state.filter(as => !isSegmentEqual(as, action.meta))
	}
	return state
}
