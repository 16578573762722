import { clamp } from 'lodash-es'
import { FC, PropsWithChildren } from 'react'
import { HTMLElementProps } from '~/types'
import { cn, extractTextNodes, tagSetColor } from '~/utils'

export interface TagProps extends PropsWithChildren {
	className?: HTMLElementProps<'div'>['className']
	onClick?: HTMLElementProps<'button'>['onClick']
	disabled?: HTMLElementProps<'button'>['disabled']
	type?: Parameters<typeof tagSetColor>[0]
	inactive?: boolean
}

export const Tag: FC<TagProps> = ({ className, onClick, type = null, children, inactive, ...props }) => {
	const color = tagSetColor(type, onClick ? (inactive ? 'inactive' : 'button') : undefined)
	const classNames = cn(
		'flex items-center whitespace-nowrap gap-1 justify-center',
		'rounded-full px-4 py-1 text-center text-sm transition-colors transition-opacity',
		color,
		className
	)
	const label = extractTextNodes(children)
	return onClick ? (
		<button type="button" className={classNames} onClick={onClick} {...props} aria-selected={inactive === false}>
			{label || children}
		</button>
	) : (
		<li
			className={classNames}
			{...props}
			style={label && label.length > 16 ? { fontSize: clamp(26 - label.length / 2, 8, 14) } : {}}
		>
			{label || children}
		</li>
	)
}
