import { REALTIME_SUBSCRIBE_STATES, RealtimePostgresChangesPayload } from '@supabase/supabase-js'
import { END, eventChannel } from 'redux-saga'
import { call, put, select, take } from 'typed-redux-saga'
import { GetPrimaryKey, Nullable, TableNames, Tables } from '~/types'
import { DBEventOperation } from '~/types/realtime'
import { State } from '~/types/state'
import { getItem, setItem, sleep, supabase } from '~/utils'

export function* getCurrentProject() {
	let user
	let timeout = 2000
	while (true) {
		user = yield* select((state: State) => state.user)
		if (user?.companies.length) break
		yield* call(sleep, 20)
		timeout -= 5
		if (timeout < 0) break
	}
	const urlID = location.pathname.split('/')[1]
	if (urlID && urlID.startsWith('pr_')) {
		const project = user?.projects.find(p => p.id === urlID)
		if (project) {
			setItem('selectedProjectId', project.id)
			return project
		}
	}
	return user?.projects.find(p => p.id === getItem('selectedProjectId')) ?? user?.projects[0] ?? null
}

export function* getCurrentCompany() {
	const project = yield* call(getCurrentProject)
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
	const companies = yield* select((state: State) => state.user?.companies!)
	const company = companies.find(c => c.id === project?.org_id) ?? companies[0]
	return company
}

/**
 * Subscribe to realtime changes in the database.
 * @param channelName The name of the channel to subscribe to.
 * If no table names are provided, all tables will be subscribed to.
 */
export function subscribe<T extends TableNames>(channelName: string, tableNames?: T[]) {
	return eventChannel<RealtimePostgresChangesPayload<Tables<T>> | `${REALTIME_SUBSCRIBE_STATES}` | Error>(emitter => {
		const channel = supabase.channel(channelName)
		for (const tableName of tableNames ?? [undefined]) {
			channel.on<Tables<T>>('postgres_changes', { event: '*', schema: 'public', table: tableName }, data => {
				emitter(data)
				if (data.errors) console.warn(data.errors)
			})
		}
		channel.subscribe((status, error) => {
			if (error) {
				emitter(error)
				emitter(END)
			} else {
				emitter(status)
			}
		})

		return () => channel.unsubscribe()
	})
}

function getPrimaryKey<T extends TableNames>(
	event: Pick<RealtimePostgresChangesPayload<Tables<T>>, 'eventType' | 'new' | 'old'>
): GetPrimaryKey<Tables<T>> | null {
	const obj = Object.keys(event.new).length > 0 ? event.new : event.old
	if (!obj) return null
	if ('id' in obj) return obj.id as any
	return Object.fromEntries(Object.entries(obj).filter(([k]) => k.endsWith('_id'))) as any
}

export function* watchRealtimeDBEvents() {
	let retryCount = 1
	while (true) {
		const channel = yield* call(subscribe, 'db')
		try {
			while (true) {
				// take(END) will cause the saga to terminate by jumping to the finally block
				const event = yield* take(channel)

				if (event === 'SUBSCRIBED') {
					console.info('Realtime connection established')
					retryCount = 1
					continue
				} else if (typeof event === 'string') {
					console.warn(event, new Error(event))
					continue
				} else if (event instanceof Error) {
					console.error(event)
					continue
				}
				const table = event.table as TableNames
				const reduxAction = dbAction(event.eventType, table, event.new ?? event.old, getPrimaryKey(event))
				yield put(reduxAction)
			}
		} catch (e) {
			console.error(e)
		}
		yield* call(sleep, Math.pow(2, retryCount++) * 500)
	}
}

export function dbAction<T extends TableNames>(
	eventType: DBEventOperation,
	table: T,
	payload: Partial<Tables<T>>,
	meta?: Nullable<GetPrimaryKey<Tables<T>>>
) {
	return {
		type: `${eventType}_${table.toUpperCase()}_OK`,
		payload,
		meta: meta ?? getPrimaryKey({ eventType, new: payload, old: {} }) ?? undefined
	}
}
