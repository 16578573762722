import { FC } from 'react'
import { Outlet, SubmitOptions, useSubmit } from 'react-router-dom'
import { Grid } from '~/components/Grid'
import { supabase, useCurrentCompany, useSelector } from '~/utils'

export async function templatesLoader() {
	return supabase.from('template').select('id, label, org_id, parameters').order('created_at')
}

export const Templates: FC = () => {
	const company = useCurrentCompany()
	const templates = useSelector(s => s.templates)?.filter(t => t.org_id === company?.id)
	const submit = useSubmit()

	return (
		<Grid.Container className="max-md:h-auto">
			<Grid.SideMenu
				className="h-full min-h-[10rem]"
				title="Templates"
				onSubmit={e => {
					const data = new FormData(e.currentTarget)
					if (!company) return
					data.append('org_id', company.id)
					submit(data, { method: e.currentTarget.method as SubmitOptions['method'] })
					e.preventDefault()
				}}
			>
				{templates?.map((template, i) => (
					<Grid.SideMenuLink key={template.id} to={`/templates/${template.id}`}>
						{template.label ?? `Template ${i + 1}`}
					</Grid.SideMenuLink>
				))}
			</Grid.SideMenu>
			<Outlet />
		</Grid.Container>
	)
}
