import { formatDistance } from 'date-fns'
import { capitalize, groupBy } from 'lodash-es'
import { FC, Fragment, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Grid } from '~/components/Grid'
import { cn, compareRows, isValidDate, useCurrentProject, useMemoSelector } from '~/utils'

export const Canvas: FC = () => {
	const { canvasId } = useParams()
	const [now, setNow] = useState(new Date())
	const currentProject = useCurrentProject()

	const canvases = useMemoSelector(
		s => s.canvas,
		c =>
			c
				.filter(cv => cv.project_id === currentProject?.id)
				.toSorted(compareRows({ type: 'updated_at', order: 'desc' })),
		[currentProject?.id]
	)

	const canvasGroups = groupBy(canvases, cv => {
		const cvDate = cv.updated_at ?? cv.created_at
		const utcDate = isValidDate(new Date(`${cvDate}Z`)) ? new Date(`${cvDate}Z`) : new Date(cvDate)
		return formatDistance(utcDate, now, { addSuffix: true })
	})

	useEffect(() => {
		const int = setInterval(() => setNow(new Date()), 60000)
		return () => clearInterval(int)
	}, [])

	return (
		<Grid.Container
			className={cn('grid-rows-[1fr,auto,auto,auto,auto] bg-stone-50 dark:bg-stone-900', {
				'max-md:h-auto': canvasId
			})}
		>
			<Grid.SideMenu className="col-start-1 row-start-1 h-full xl:row-span-full" title="Conversations">
				{Object.entries(canvasGroups).map(([elapsed, canvasGroup], i) => (
					<Fragment key={elapsed}>
						<h4 className={cn('ml-1 text-xs leading-none opacity-60', { '!mt-5': i !== 0 })}>
							{capitalize(elapsed)}
						</h4>
						{canvasGroup?.map(canvas => {
							const task = canvas.tasks?.[0]
							return (
								<Grid.SideMenuLink key={canvas.id} to={canvas.id.toString()}>
									{[
										task?.templates?.label ?? 'Chat',
										canvas.label ??
											task?.label ??
											canvases
												.toSorted(compareRows({ type: 'created_at', order: 'asc' }))
												.findIndex(cv => cv.id === canvas.id) + 1
									]
										.filter(Boolean)
										.join(': ')}
								</Grid.SideMenuLink>
							)
						})}
					</Fragment>
				))}
			</Grid.SideMenu>
			<Outlet />
		</Grid.Container>
	)
}
