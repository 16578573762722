import { CustomCellRendererProps } from 'ag-grid-react'
import clsx from 'clsx'
import { HiPencil } from 'react-icons/hi2'
import { isPresent } from 'ts-extras'
import { enrichmentName } from '~/routes/Prospecting/listUtils'
import { Dimension, ProspectingType } from '~/types'
import { compareRows, supabase, useQuery, useSelector } from '~/utils'
import { EnrichmentTypeIcon } from '../EnrichmentTypeIcon'
import { Popover, PopoverContent, PopoverTrigger } from '../ui'

export interface EditableCellProps<TData> extends CustomCellRendererProps<TData> {
	onEdit?: (ctx: { key: string; row: CustomCellRendererProps<TData>['node']; value: string }) => void
}

export function EditableCell<TData>(props: EditableCellProps<TData>): JSX.Element {
	return (
		<div className={clsx('group flex h-full w-full cursor-pointer ')}>
			<Popover>
				<PopoverTrigger asChild>
					<div className="w-full overflow-hidden text-ellipsis">{props.valueFormatted}</div>
				</PopoverTrigger>
				<PopoverContent className="w-full min-w-[320px]">
					<DimensionList
						id={props.node.id as string}
						dimension={props.column?.getColId()?.toLowerCase() as Dimension}
					/>{' '}
				</PopoverContent>
			</Popover>
			<div className="hidden group-hover:block">
				<button
					className="flex h-full w-6 cursor-pointer items-center justify-end"
					onClick={_ => {
						if (!props.column) return
						props.api.startEditingCell({
							rowIndex: props.node.rowIndex!,
							colKey: props.column.getColId()!
						})
					}}
				>
					<HiPencil />
				</button>
			</div>
		</div>
	)
}

export interface DimensionListProps<TDim extends Dimension> {
	id: string
	dimension: TDim
}

export function DimensionList<TDim extends Dimension>({ id, ...props }: DimensionListProps<TDim>) {
	const dimension = props.dimension as 'revenue' // Cast as widest type in union
	const type: ProspectingType = (id.startsWith('plc_') || id.startsWith('pco_') ? 'company' : 'contact') as 'company' // Cast as revenue only exists for companies
	const listCompanies = useSelector(state => state.prospecting.listCompanies)
	const query = useQuery([id], async _ => {
		const q = supabase
			.from(`enrichment`)
			.select(`id, enrichment_type(id,name), dimension_${type}_${dimension}(*, weight(value))`)
		return type === 'company'
			? q.eq('company_id', id.startsWith('plc_') ? listCompanies.find(c => c.id === id)?.company_id ?? '' : id)
			: q.eq('contact_id', id)
	})
	if (query.error || query.data?.error) {
		const error = query.error || query.data?.error
		return <div className="text-red-500">{error?.message}</div>
	}
	const dimensions = query.data?.data
		?.filter(d => d[`dimension_${type}_${dimension}`])
		.flatMap(d => ({
			...d[`dimension_${type}_${dimension}`],
			et: d.enrichment_type
		}))
		.filter(isPresent)
		.sort(compareRows())

	return query.isLoading ? (
		<div className="text-gray-500">Loading...</div>
	) : !dimensions || dimensions.length === 0 ? (
		<div className="text-gray-500">
			No {dimension} data found for {type}.
		</div>
	) : (
		<table className="w-full text-center">
			<thead>
				<tr>
					<th aria-label="Icon" />
					{dimensions?.[0] && 'max' in dimensions[0] ? (
						<>
							<th>Min</th>
							<th>Max</th>
						</>
					) : (
						<th>Value</th>
					)}
					<th>Confidence</th>
					<th>Source</th>
					<th>Date</th>
				</tr>
			</thead>
			<tbody>
				{dimensions.map(d => (
					<tr key={d.id} className="border-t border-gray-200">
						<td>
							<EnrichmentTypeIcon type={d.et!} />
						</td>
						<td className="px-2">{d.value}</td>
						{'max' in d && <td className="px-2">{d.max}</td>}
						<td className="px-2">{Math.round(d.weight!.value * 100)}%</td>
						<td className="px-2">{enrichmentName(d.et!)}</td>
						<td className="px-2">{new Date(d.updated_at ?? d.created_at ?? 0).toLocaleDateString()}</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}
