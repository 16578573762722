import { FunctionsResponse } from '@supabase/functions-js'
import { call, put, takeLatest } from 'typed-redux-saga'
import { Enrichment, ProspectingCompany, ProspectingContact } from '~/types'
import { PROFF_COMPANY_ENRICHMENT_ID, compareRows, supabase, toToastError } from '~/utils'
import { Action, ActionTypes, Actions } from '../actions'

export function* prospectingSagas() {
	yield* takeLatest<ActionTypes, any>('ORGANIZATIONS_LOADED', loadEnrichmentTypes)
	yield* takeLatest<ActionTypes, any>('ORGANIZATIONS_LOADED', loadCompanySegments)
	yield* takeLatest<ActionTypes, any>('PROJECT_SELECT', loadProspectingLists)
	yield* takeLatest<ActionTypes, any>('GET_PROSPECTING_LISTS_OK', loadProspectingListCompanies)
	yield* takeLatest<ActionTypes, any>('GET_PROSPECTING_LIST_COMPANIES_OK', loadProspectingCompanies)
	yield* takeLatest<ActionTypes, any>('PROJECT_SELECT', loadEnrichments)
	yield* takeLatest<ActionTypes, any>('PROJECT_SELECT', loadProspectingContacts)
	yield* takeLatest<ActionTypes, any>('PROJECT_SELECT', loadProspectingRoles)
}

function* loadProspectingLists(action: Action<'PROJECT_SELECT'>) {
	const projectID = action.meta
	if (!projectID) return
	const res = yield* call(async () => supabase.from('prospecting_list').select('*').eq('project_id', projectID))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getProspectingListsOk(res.data))
	}
}

function* loadProspectingListCompanies(action: Action<'GET_PROSPECTING_LISTS_OK'>) {
	const listIds = action.payload.map(l => l.id)
	const res = yield* call(async () => supabase.from('prospecting_list_company').select('*').in('list_id', listIds))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getProspectingListCompaniesOk(res.data))
	}
}

function* loadProspectingCompanies(action: Action<'GET_PROSPECTING_LIST_COMPANIES_OK'>) {
	const companyIds = action.payload.map(c => c.company_id)
	const companies = yield* call(async () =>
		supabase.functions.invoke<FunctionsResponse<ProspectingCompany[]>>('companies', {
			body: { companyIds }
		})
	)
	if (companies.error || companies.data?.error) {
		const toastError = yield* call(toToastError, companies.error || companies.data?.error)
		if (toastError.description?.toString().includes('timeout')) return
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getProspectingCompaniesOk(companies.data?.data as ProspectingCompany[]))
	}
}

function* loadEnrichmentTypes(_action: Action<'ORGANIZATIONS_LOADED'>) {
	const res = yield* call(async () => supabase.from('enrichment_type').select('*'))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getEnrichmentTypesOk(res.data.sort(compareRows({ order: 'asc', type: 'updated_at' }))))
	}
}

function* loadCompanySegments(_action: Action<'ORGANIZATIONS_LOADED'>) {
	const res = yield* call(async () => supabase.from('prospecting_company_segment').select('*'))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getCompanySegmentsOk(res.data))
	}
}

function* loadEnrichments(action: Action<'PROJECT_SELECT'>) {
	const projectID = action.meta
	if (!projectID) return
	const lists = yield* call(async () => supabase.from('prospecting_list').select('*').eq('project_id', projectID))
	if (lists.error) {
		const toastError = yield* call(toToastError, lists.error)
		yield* put(Actions.addToast(toastError))
		return
	}
	const latestEnrichments = yield* call(async () =>
		supabase
			.from('enrichment')
			.select('*')
			.neq('type_id', PROFF_COMPANY_ENRICHMENT_ID)
			.in(
				'list_id',
				lists.data.map(l => l.id)
			)
			.order('created_at', { ascending: false })
	)
	if (latestEnrichments.error) {
		const toastError = yield* call(toToastError, latestEnrichments.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getEnrichmentsOk(latestEnrichments.data as Enrichment[]))
	}
}

export function* loadProspectingContacts(action: Action<'PROJECT_SELECT'>) {
	const projectID = action.meta
	if (!projectID) return
	const res = yield* call(async () => supabase.from('contact_dimensions').select('*'))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getProspectingContactsOk(res.data as ProspectingContact[]))
	}
}

export function* loadProspectingRoles(action: Action<'PROJECT_SELECT'>) {
	const projectID = action.meta
	if (!projectID) return
	const res = yield* call(async () => supabase.from('prospecting_role').select('*'))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getProspectingRolesOk(res.data))
	}
}
