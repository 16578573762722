/* eslint-disable github/array-foreach */
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { Table } from '@tanstack/react-table'
import { startCase } from 'lodash-es'
import { RxMixerHorizontal } from 'react-icons/rx'
import {
	Button,
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator
} from '~/components/ui'

interface DataTableViewOptionsProps<TData> {
	table: Table<TData>
}

export function DataTableViewOptions<TData>({ table }: DataTableViewOptionsProps<TData>) {
	const togglableColumns = table.getAllColumns().filter(column => !['select', 'name'].includes(column.id))
	const isAllVisible = togglableColumns.every(column => column.getIsVisible())
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="outline" size="sm" className="ml-auto hidden h-8 lg:flex">
					<RxMixerHorizontal className="mr-2 h-4 w-4" />
					Columns
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-[150px]">
				<DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuCheckboxItem
					checked={isAllVisible}
					onCheckedChange={value => togglableColumns.forEach(column => column.toggleVisibility(value))}
				>
					{isAllVisible ? 'Uns' : 'S'}elect All
				</DropdownMenuCheckboxItem>
				<DropdownMenuSeparator />
				{table
					.getAllColumns()
					.filter(column => typeof column.accessorFn !== 'undefined' && column.getCanHide())
					.map(column => (
						<DropdownMenuCheckboxItem
							key={column.id}
							className="capitalize"
							checked={column.getIsVisible()}
							onCheckedChange={value => column.toggleVisibility(!!value)}
						>
							{startCase(column.id)}
						</DropdownMenuCheckboxItem>
					))}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
