import { Toast } from '~/types/ui'
import { Action } from '../actions'

export const TOAST_LIMIT = 8

let count = 0

function genId() {
	count = (count + 1) % Number.MAX_SAFE_INTEGER
	return count.toString()
}

interface State {
	items: Toast[]
}

export const initialState: State = {
	items: []
}

export function toast(state: State = initialState, action: Action): State {
	switch (action.type) {
		case 'ADD_TOAST':
			// TODO: Remove this temp workaround once a better one exists https://github.com/supabase/auth-js/issues/620
			if ([action.payload.description, action.payload.title].some(s => s?.toString().includes('JWT expired')))
				return state

			return {
				...state,
				items: [{ ...action.payload, id: genId() }, ...state.items].slice(0, TOAST_LIMIT)
			}

		case 'UPDATE_TOAST':
			return {
				...state,
				items: state.items.map(t => (t.id === action.payload.id ? { ...t, ...action.payload } : t))
			}

		case 'DISMISS_TOAST': {
			const { meta: toastId } = action

			return {
				...state,
				items: state.items.map(t =>
					t.id === toastId || toastId === undefined
						? {
								...t,
								open: false
							}
						: t
				)
			}
		}
		case 'REMOVE_TOAST':
			if (action.meta === undefined) {
				return {
					...state,
					items: []
				}
			}
			return {
				...state,
				items: state.items.filter(t => t.id !== action.meta)
			}
	}
	return state
}
