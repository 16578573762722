import { FC } from 'react'
import { NavLink, NavLinkProps, useParams } from 'react-router-dom'
import { twJoin } from 'tailwind-merge'
import { HTMLElementProps } from '~/types'
import { cn, useCurrentProject } from '~/utils'
import { ProjectSwitcher } from './ProjectSwitcher'
import { UserNav } from './UserNav'

export const NavItem: FC<NavLinkProps> = props => (
	<NavLink
		{...props}
		onClick={e => (props['aria-disabled'] ? e.preventDefault() : props.onClick)}
		className={twJoin(
			'text-sm font-medium transition-colors hover:text-primary',
			'inactive:text-muted-foreground',
			'aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
			props.className as string
		)}
		title={props['aria-disabled'] ? 'Please select a project first' : undefined}
	/>
)

export const Navbar: FC<HTMLElementProps<'div'>> = ({ className, ...props }) => {
	const { project: projectId } = useParams()
	const currentProject = useCurrentProject()
	const project = projectId || currentProject?.id

	return (
		<div className="border-b">
			<div className={cn('flex h-16 items-center gap-6', className)} {...props}>
				<ProjectSwitcher />
				<nav className="flex items-center space-x-4 lg:space-x-6">
					<NavItem to={project || ''} end>
						Knowledge
					</NavItem>
					<NavItem to={`/${project}/prospecting`} aria-disabled={!project}>
						Prospecting
					</NavItem>
					<NavItem to={`/${project}/segments`} aria-disabled={!project}>
						Segments
					</NavItem>
					<NavItem to={`/${project}/canvas`} aria-disabled={!project}>
						Chats
					</NavItem>
				</nav>
				<div className="ml-auto flex items-center space-x-4">
					<NavItem to="/templates" className="max-md:hidden" aria-disabled={!project}>
						Templates
					</NavItem>
					<NavItem to="/configuration" className="max-md:hidden" aria-disabled={!project}>
						Profile Configuration
					</NavItem>
					<UserNav />
				</div>
			</div>
		</div>
	)
}
