import { User } from '@supabase/supabase-js'
import { isObject, mapValues, omit, transform } from 'lodash-es'
import React, { ReactNode } from 'react'
import { isPresent } from 'ts-extras'
import type { Primitive, Simplify, UnionToIntersection } from 'type-fest'
import type { Nullable, Tag, UISegment } from '~/types'

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export type DeepReplaceValue<T extends Record<string, any>, TOld extends Primitive, TNew extends Primitive> = {
	[K in keyof T]: Exclude<T[K], null | undefined> extends Record<string, any>
		? Simplify<DeepReplaceValue<T[K], TOld, TNew>>
		: T[K] extends TOld
			? TNew
			: TOld extends T[K]
				? Exclude<T[K], TOld> | TNew
				: T[K]
}

/**
 * Recursively replaces values in a record matching oldValue with newValue.
 * @param obj
 * @param oldValue
 * @param newValue
 * @returns
 */
export function deepReplaceValues<T extends Record<string, any>, TOld extends Primitive, TNew extends Primitive>(
	obj: Nullable<T>,
	oldValue: TOld,
	newValue: TNew
): Simplify<DeepReplaceValue<T, TOld, TNew>> {
	return mapValues(obj, value =>
		isObject(value) ? deepReplaceValues(value, oldValue, newValue) : value === oldValue ? newValue : value
	) as DeepReplaceValue<T, TOld, TNew>
}

export function isText(val: any): val is number | string {
	return typeof val === 'number' || typeof val === 'string'
}

export function isSegment(type: Tag | UISegment): type is UISegment {
	return 'project_id' in type
}

/**
 * Flatten an object with nested objects
 * @param obj Object to be flattened
 * @param prefix Optionally prefix object keys with a string
 * @returns
 */
export function normalize(
	obj: Record<string, any>,
	prefix: string | ((key: string) => string) = ''
): Record<string, Primitive> {
	return transform(
		obj,
		(result, value, key) =>
			Object.assign(
				result,
				typeof value !== 'object'
					? { [typeof prefix === 'string' ? prefix + key : prefix(key)]: value }
					: normalize(value, prefix)
			),
		{} as Record<string, Primitive>
	)
}

/** Like lodash´s {@link omit}, but only accepts keys that present in the object */
export function except<T extends object, K extends keyof T>(obj: Nullable<T>, ...paths: K[]): Omit<T, K> {
	if (!obj) return {} as Omit<T, K>
	return omit(obj, paths)
}

/**
 * Like {@link Array.includes}, but array only accepts values that are valid values for @param value
 * @param array
 * @param value
 * @returns
 */
export function includes<T extends string, K extends T[]>(array: K, value: T): boolean {
	return array.includes(value)
}

export function getUserInitials(user: User): string {
	return getInitials(
		[user.user_metadata.firstName, user.user_metadata.lastName].filter(Boolean).join(' ') || user.email || 'U'
	)
}

export function getInitials(name: string): string {
	name = name.toLocaleUpperCase()
	const [first, last] = name.split(/[\s-_]+/)
	return first && last ? `${first[0]}${last[0]}` : `${name[0]}${name[1]}`
}

export function pluralize(word: string): string {
	return word.endsWith('y') ? `${word.slice(0, -1)}ies` : `${word}s`
}

export function depluralize(str: Nullable<string>) {
	return str?.replace(/ies$/g, 'y').replace(/s$/g, '')
}

export function extractTextNodes(node: ReactNode) {
	return React.Children.map(node, child => (typeof child === 'string' ? child : null))
		?.filter(isPresent)
		.join('')
}

export function floorDate(date: Date | string, unit: 'd' | 'h' | 'm' | 's' = 'd'): Date {
	const newDate = new Date(date)
	newDate.setMilliseconds(0)
	if (unit === 's') return newDate
	newDate.setSeconds(0)
	if (unit === 'm') return newDate
	newDate.setMinutes(0)
	if (unit === 'h') return newDate
	newDate.setUTCHours(0)
	return newDate
}

export function formatFileSize(bytes: number): string {
	if (!bytes) return '0 Bytes'
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

	const i = Math.floor(Math.log(bytes) / Math.log(1024))
	return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`
}

export function parseRange(range: string | number): [number, number | null] | null {
	if (typeof range === 'number') return [range, null]
	if (!isPresent(range.match(/^[0-9.,-\s]+$/)?.[0])) return null
	range = range.replaceAll(',', '.').replaceAll(' ', '')
	const dashes = range.split('-')
	switch (dashes.length - 1) {
		case 1:
			if (!range.startsWith('-')) {
				return dashes.map(v => Number.parseFloat(v)) as [number, number | null]
			} else {
				// Intentional fallthrough
			}
		case 0: {
			const num = Number.parseFloat(range)
			if (isNaN(num)) return null
			return [Number.parseFloat(range), null]
		}

		case 2:
			if (range.startsWith('-')) {
				return [-Number.parseFloat(dashes[1]!), Number.parseFloat(dashes[2]!)]
			} else {
				// Intentional fallthrough
			}

		// Range contains negative numbers
		default: {
			const matches = range.match(/(-?[\d.]+)/g)
			if (!matches) return null
			const start = Number.parseFloat(matches[0])
			const end = Number.parseFloat(matches[1]!)
			return [start, end]
		}
	}
}

/**
 * Similar to Object.assign, except that it does not overwrite existing keys
 */
export function combine<T extends Array<Record<string, any>>>(...args: T): UnionToIntersection<T[number]> {
	const result: Record<string, any> = {}
	for (const obj of args) {
		for (const key in obj) {
			if (key in result) continue
			result[key] = obj[key]
		}
	}
	return result as UnionToIntersection<T[number]>
}

export function isValidDate(date: Date): boolean {
	return !isNaN(date as unknown as number)
}
