import { isObject } from 'lodash-es'
import { ArrowLeftIcon, Building2Icon, CheckCircle2Icon, Edit2Icon, Loader2Icon, UserIcon } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Grid } from '~/components/Grid'
import { Editor } from '~/components/textEditor2'
import { Button, Card, CardContent, CardHeader, CardTitle } from '~/components/ui'
import { Actions } from '~/redux'
import { Enrichment, EnrichmentType, Nullable, ProspectingContact, ProspectingList } from '~/types'
import { RunStatus } from '~/types/dagster.generated'
import {
	E_GPT_ID,
	compareRows,
	findEnrichmentRun,
	isRunActive,
	supabase,
	toToastError,
	useDispatch,
	useMemoSelector,
	useSearch,
	useSelector
} from '~/utils'

const EMAIL_GPT_IDS: Array<EnrichmentType['id']> = ['ent_01j0n44tteez7vrj3cmxrkfynb', E_GPT_ID]

const EMAIL_ID = 'te_01hhcq79jffyfbs5ekcn5vps0k'

export const Sequence: FC = () => {
	const dispatch = useDispatch()
	const { contactId, listId } = useParams<{ contactId: ProspectingContact['id']; listId: ProspectingList['id'] }>()
	const [{ revision }] = useSearch<{ revision: Enrichment['id'] }>()
	const [editing, setEditing] = useState(false)

	useEffect(() => {
		setEditing(false)
	}, [contactId, listId])

	const contacts = useSelector(s => s.prospecting.contacts)
	const index = contacts.findIndex(c => c.id === contactId)
	const contact = contacts[index]
	const previousContact = index > 0 ? contacts[index - 1] : null
	const nextContact = contacts[index + 1]
	const role = useMemoSelector(
		state => state.prospecting.roles,
		roles => roles.find(r => r.contact_id === contactId),
		[contactId]
	)
	const company = useMemoSelector(
		state => state.prospecting.companies,
		companies => companies.find(c => c.id === role?.company_id),
		[role?.company_id]
	)
	const emails = useMemoSelector(
		state => state.enrichments.items,
		es =>
			es
				.filter(enr => enr.contact_id === contactId && enr.company_id === role?.company_id)
				.filter(enr => EMAIL_GPT_IDS.includes(enr.type_id) && isObject(enr.data) && EMAIL_ID in enr.data)
				.sort(compareRows({ order: 'desc', type: 'created_at' })),
		[contactId]
	)

	const email = emails.find(e => e.id === revision) ?? emails[0]

	const enrichmentType = useMemoSelector(
		s => s.enrichments.types,
		ets => ets.find(et => EMAIL_GPT_IDS.includes(et.id))
	)

	const run = useMemoSelector(
		s => s.enrichments.runs,
		runs => findEnrichmentRun({ runs, templateId: EMAIL_ID, listId, contactId, enrichmentType }),
		[listId, contactId, enrichmentType]
	)
	const isRunning = run ? isRunActive(run) : false

	const [renderEditor, setRender] = useState(true)
	useEffect(() => {
		// Force remount of editor when switching pages
		setRender(false)
		setTimeout(() => setRender(true), 0)
	}, [index, revision, emails.length])

	if (!contact) return null
	return (
		<Grid.Container className="content-start sm:grid-rows-[auto,auto,1fr]">
			<Grid.SideMenu
				className="col-start-1 sm:row-start-1"
				onSubmit={false}
				title={
					<span className="-ml-2 flex items-center gap-2">
						<UserIcon className="h-4 w-4" />
						<span>
							{contact.first_name} {contact.last_name}
						</span>
					</span>
				}
			>
				<div>{role?.role}</div>
			</Grid.SideMenu>
			<Link to="..?type=contact" className="absolute left-6 top-5 z-10" relative="path">
				<ArrowLeftIcon className="h-6 w-6" />
			</Link>
			<Grid.SideMenu
				className="col-start-1 sm:row-start-2"
				onSubmit={false}
				title={
					<span className="-ml-2 flex items-center gap-2">
						<Building2Icon className="h-4 w-4" />
						<span>{company?.name}</span>
					</span>
				}
			/>
			{emails.length > 0 && (
				<Grid.SideMenu className="col-start-1 sm:row-start-3" onSubmit={false} title="Revisions">
					{emails.map(e => (
						<Grid.SideMenuLink
							key={e.id}
							to={`?revision=${e.id}`}
							className={email?.id === e.id ? '' : 'active:bg-transparent'}
						>
							<code className="text-sm">{e.id.slice(-8)}</code>
						</Grid.SideMenuLink>
					))}
				</Grid.SideMenu>
			)}
			<div className="flex h-full flex-col justify-between sm:col-start-2 sm:row-start-1 sm:row-end-4">
				<Card>
					<CardHeader>
						<CardTitle className="flex items-baseline justify-between">
							<span className="leading-10">Email</span>
							{email && (
								<Button
									variant="ghost"
									size="icon"
									aria-label="Edit Email"
									onClick={async _ => {
										setEditing(s => !s)
									}}
								>
									{editing ? (
										<CheckCircle2Icon className="h-4 w-4" />
									) : (
										<Edit2Icon className="h-4 w-4" />
									)}
								</Button>
							)}
						</CardTitle>
					</CardHeader>
					<CardContent>
						{renderEditor && (
							<Editor
								editable={editing}
								content={
									email ? extractEmail(email) : 'No email found. Press Generate to generate one.'
								}
								onBlur={async e => {
									if (!email) return
									const res = await supabase
										.from('enrichment')
										.update({ data: { [EMAIL_ID]: e.editor.getHTML() } })
										.eq('id', email.id)
									if (res.error) {
										dispatch(Actions.addToast(await toToastError(res.error)))
									}
								}}
							/>
						)}
					</CardContent>
				</Card>
				<div className="flex items-baseline justify-end gap-2">
					<span className="text-sm text-muted-foreground">
						{index + 1} / {contacts.length}
					</span>
					<Button variant="ghost" disabled={!previousContact}>
						<Link to={`../${previousContact?.id}`} relative="path">
							Previous
						</Link>
					</Button>
					<Button
						className="gap-1"
						variant={run && run.status === RunStatus.Failure ? 'destructive' : 'warning'}
						disabled={isRunning}
						onClick={_ => {
							dispatch(
								Actions.runEnrichment({
									listId: listId!,
									type: 'contact',
									templateId: EMAIL_ID,
									parameters: {},
									selected: [contactId!]
								})
							)
						}}
					>
						{isRunning && <Loader2Icon className="h-4 w-4 animate-spin" />}
						{run && run.status === RunStatus.Failure ? (
							<span>{run.status}</span>
						) : emails.length === 0 ? (
							<span>Generate</span>
						) : (
							<span>Regenerate</span>
						)}
					</Button>
					<Button
						variant="default"
						onClick={async () => {
							await navigator.clipboard.writeText(extractEmail(email))
							dispatch(Actions.addToast({ title: 'Email copied to clipboard' }))
						}}
					>
						Copy to clipboard
					</Button>
					{nextContact ? (
						<Button variant="secondary" asChild>
							<Link to={`../${nextContact.id}`} relative="path">
								Next
							</Link>
						</Button>
					) : (
						<Button variant="default">Finish</Button>
					)}
				</div>
			</div>
		</Grid.Container>
	)
}

function extractEmail(email: Nullable<Enrichment>) {
	const enrichResult = (email?.data as any)?.[EMAIL_ID]
	return isObject(enrichResult) && 'Write Messaging' in enrichResult
		? (enrichResult['Write Messaging'] as string)
		: typeof enrichResult === 'string'
			? enrichResult
			: ''
}
