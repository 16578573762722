import { FC } from 'react'
import { ModeToggle } from '~/components/ui/mode-toggle'
import { VERSION } from '~/utils'

export const About: FC = () => (
	<div className="space-y-4">
		<p>
			App Version: <code className="rounded bg-gray-100 p-2 dark:bg-gray-900">{VERSION}</code>
		</p>
		<div className="flex items-center gap-4">
			<strong>Theme</strong>
			<ModeToggle />
		</div>
	</div>
)
