import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { HTMLElementProps } from '~/types'
import { cn } from '~/utils'

export const TextArea = forwardRef<HTMLTextAreaElement, HTMLElementProps<'textarea'>>((props, ref) => {
	const textRef = useRef<HTMLTextAreaElement>(null)

	useImperativeHandle(ref, () => textRef.current as HTMLTextAreaElement)

	function resize() {
		if (textRef.current) {
			textRef.current.style.height = 'auto' // Reset scrollHeight to match current content
			textRef.current.style.height = `${textRef.current.scrollHeight}px`
		}
	}

	useEffect(() => {
		resize()
		// eslint-disable-next-line github/prefer-observers -- TODO: Replace with ResizeObserver
		window.addEventListener('resize', resize)
		return () => window.removeEventListener('resize', resize)
	}, [])

	return (
		<textarea
			{...props}
			className={cn('w-full resize-none rounded px-6 py-6 text-center', props.className)}
			onChange={e => {
				props.onChange?.(e)
				resize()
			}}
			ref={textRef}
		/>
	)
})
TextArea.displayName = 'TextArea'
