import { PlusSquareIcon } from 'lucide-react'
import { FC, useState } from 'react'
import { HiCheckCircle, HiPencil } from 'react-icons/hi2'
import {
	Button,
	Dialog,
	Separator,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow
} from '~/components/ui'
import { Actions } from '~/redux'
import { Project } from '~/types'
import { supabase, toToastError, useCurrentProject, useDispatch, useSelector } from '~/utils'
import { ProjectCreator } from '../layout/navbar/ProjectCreator'
import { DeleteConfirm } from './DeleteConfirm'

export const Projects: FC = () => {
	const dispatch = useDispatch()
	const [dialogOpen, setDialogOpen] = useState<'invite' | 'create' | null>(null)
	const currentProject = useCurrentProject()
	const currentUser = useSelector(state => state.user)
	const [editing, setEditing] = useState<Project['id'] | null>(null)

	if (!currentUser) return null

	return (
		<div className="space-y-6">
			<Dialog open={dialogOpen === 'create'} onOpenChange={open => setDialogOpen(open ? 'create' : null)}>
				<div className="space-y-2">
					<h2 className="text-lg font-medium">Projects</h2>
					<p className="text-sm text-muted-foreground">Edit and create projects</p>
					<Button variant="secondary" onClick={_ => setDialogOpen('create')}>
						<PlusSquareIcon className="mr-2" /> Create Project
					</Button>
				</div>
				<ProjectCreator currentProject={currentProject} onClose={() => setDialogOpen(null)} />
			</Dialog>
			<Separator />
			{currentUser.companies?.map(company => (
				<div key={company.id} className="space-y-2">
					<header className="flex items-center justify-between">
						<h3 className="flex-auto font-medium">{company.name}</h3>
					</header>
					<div className="rounded-md border">
						<Table>
							<TableHeader>
								<TableRow key="header">
									{currentUser.projects.filter(pj => pj.org_id === company.id).length > 0 ? (
										<>
											<TableHead key="name">Project Name</TableHead>
											<TableHead key="status">Status</TableHead>
											<TableHead key="actions" className="w-0" />
										</>
									) : (
										<TableHead key="name">No Projects</TableHead>
									)}
								</TableRow>
							</TableHeader>
							<TableBody>
								{currentUser.projects
									.filter(pj => pj.org_id === company.id)
									.map(project => (
										<TableRow key={project.id} data-testid="project-row">
											<TableCell key="name" data-testid="project-row-name">
												{editing === project.id ? (
													<input
														name="name"
														className="rounded border border-black"
														defaultValue={project.name}
														onBlur={async e => {
															const res = await supabase
																.from('project')
																.update({ name: e.target.value.trim() })
																.eq('id', project.id)
															if (res.error) {
																const error = await toToastError(res.error)
																dispatch(Actions.addToast(error))
															}
														}}
													/>
												) : (
													project.name
												)}
											</TableCell>
											<TableCell key="status">Active</TableCell>
											<TableCell key="actions" className="flex items-center">
												<Button
													size="icon"
													variant="ghost"
													aria-label={editing ? 'Save changes' : 'Edit project'}
													onClick={_ =>
														setEditing(editing === project.id ? null : project.id)
													}
												>
													{editing === project.id ? (
														<HiCheckCircle className="text-xl" />
													) : (
														<HiPencil />
													)}
												</Button>
												<DeleteConfirm
													password={project.name}
													onSubmit={async () => {
														const res = await supabase
															.from('project')
															.delete()
															.eq('id', project.id)
														if (res.error) {
															const toastError = await toToastError(res.error)
															dispatch(Actions.addToast(toastError))
														}
													}}
													disabled={
														company.org_users.find(u => u.user_id === currentUser.info?.id)
															?.role !== 'admin'
													}
												>
													<p className="text-base">
														Are you sure you want to delete Project {project.name}? All its
														resources, chats, and segments will be deleted.
													</p>
												</DeleteConfirm>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</div>
				</div>
			))}
		</div>
	)
}
