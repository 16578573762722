import { Session, User } from '@supabase/supabase-js'
import { Project, UIOrganization } from '~/types'
import { Action, FilterActions, MetaAction } from '../actions'

export interface UserState {
	info: User | null
	session: Session
	companies: UIOrganization[]
	projects: Project[]
	loaded: boolean
}

function project(state: Project, action: FilterActions<MetaAction, 'PROJECT'>): Project {
	if (state.id !== action.meta) return state
	switch (action.type) {
		case 'UPDATE_PROJECT_OK':
			return {
				...state,
				...action.payload
			}
	}
	return state
}

function company(state: UIOrganization, action: MetaAction): UIOrganization {
	const companyId =
		typeof action.meta === 'string' ? action.meta : 'org_id' in action.meta ? action.meta.org_id : null

	if (state.id !== companyId) return state
	switch (action.type) {
		case 'UPDATE_ORGANIZATION_OK':
			return {
				...state,
				...action.payload
			}

		case 'USER_INVITE_OK':
			return {
				...state,
				org_users: [...state.org_users, action.payload]
			}

		case 'DELETE_ORGANIZATION_USER_OK':
			return {
				...state,
				org_users: state.org_users.filter(u => u.user_id !== action.meta.user_id)
			}
	}
	return state
}

export function user(state: UserState | null = null, action: Action): UserState | null {
	if (action.type === 'USER_LOGGED_IN')
		return {
			...state,
			info: action.meta ?? action.payload.user,
			session: action.payload,
			projects: [],
			companies: [],
			loaded: false
		}
	else if (state)
		switch (action.type) {
			case 'ORGANIZATIONS_LOADED': {
				if (!state || !action.payload) return null
				return {
					...state,
					companies: action.payload,
					projects: action.payload.flatMap(c => c.projects),
					loaded: true
				}
			}

			case 'USER_UPDATED':
				if (!state.info) return state
				return {
					...state,
					info: {
						...state.info,
						...action.payload
					}
				}

			case 'ORG_CREATED':
				return {
					...state,
					companies: [{ ...action.payload }, ...state.companies]
				}

			case 'DELETE_ORGANIZATION_OK':
			case 'ORG_DELETED':
				return {
					...state,
					companies: state.companies.filter(c => c.id !== action.meta)
				}

			case 'USER_INVITE_OK':
			case 'UPDATE_ORGANIZATION_OK':
			case 'DELETE_ORGANIZATION_USER_OK':
				return {
					...state,
					companies: state.companies.map(c => company(c, action))
				}

			case 'INSERT_PROJECT_OK':
				return {
					...state,
					projects: [...state.projects, action.payload]
				}

			case 'UPDATE_PROJECT_OK':
				return {
					...state,
					projects: state.projects.map(p => project(p, action))
				}

			case 'DELETE_PROJECT_OK':
				return {
					...state,
					projects: state.projects.filter(p => p.id !== action.meta)
				}
		}
	return state
}
