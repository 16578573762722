import { objectEntries } from 'ts-extras'
import { Dimension, Permutations, ProspectingType, Role } from '~/types'

export const __DEV__ = import.meta.env.DEV

export const VERSION = import.meta.env.VITE_PUBLIC_VERSION
export const ROLES: Permutations<Role> = ['user', 'admin']

const SUPABASE_API_URL = `${import.meta.env.VITE_SUPABASE_API_URL}`

export const AUTH_SESSION_KEY = `${SUPABASE_API_URL.split('/').pop()}-supabase-auth-session`

export const TOAST_LIMIT = 4
export const TOAST_REMOVE_DELAY = 5000

export const GLOBAL_COMPANY = 'co_01hkwefgw0f2jtyr2trf27r8aa'

export const SUPABASE_STORAGE_URL = `${SUPABASE_API_URL}/storage/v1/upload/resumable`
export const SUPABASE_BUCKET_NAME = 'documents'
export const LISTS_BUCKET_NAME = 'prospecting'

export const BOOKMARKS_CATEGORY = import.meta.env.VITE_BOOKMARKS_CATEGORY_ID
export const FILES_CATEGORY = import.meta.env.VITE_FILES_CATEGORY_ID
export const COMPANY_CATEGORY = import.meta.env.VITE_COMPANY_CATEGORY_ID
export const LISTS_CATEGORY = import.meta.env.VITE_LISTS_CATEGORY_ID

/** Enrichment GPT type ID */
export const E_GPT_ID = 'ent_01hsgat8wcem9bkyv2afzj6dkn'

export const PROFF_COMPANY_ENRICHMENT_ID = 'ent_01hs3w53ffe4htj79wnvrp69dm'
export const MANUAL_COMPANY_ENRICHMENT_ID = 'ent_01hw691t8zfrft1jn9482yh00n'
export const MANUAL_CONTACT_ENRICHMENT_ID = 'ent_01hxs711y9fn9sy842918hbbm3'
export const CSV_COMPANY_ENRIGHTMENT_ID = 'ent_01hrydr3hcf548sybk0943c799'
export const CSV_CONTACT_ENRIGHTMENT_ID = 'ent_01hxs6zsq8fxbtpbyb1v5j9tqv'

/** Weight ID for uploaded csv enrichment, for all dimensions */
export const CSV_COMPANY_WEIGHT_ID = 'wgt_01hwqed08seszajdx37zyxmqb4'
export const CSV_CONTACT_WEIGHT_ID = 'wgt_01hxs74qxtf5abt2x4gmg9y94t'
/* Weight ID for manual enrichment, for all dimensions */
export const MANUAL_COMPANY_WEIGHT_ID = 'wgt_01hwqed08seanvv331jarkwtqr'
export const MANUAL_CONTACT_WEIGHT_ID = 'wgt_01hxs74qxtfg4tnsy8bfn9mez5'

export const DIMENSION_TYPES = ['company', 'contact'] satisfies Permutations<ProspectingType>

export const ALL_DIMENSIONS = [
	'company_name',
	'company_phone',
	'company_about',
	'company_url',
	'company_revenue',
	'company_headcount',
	'company_profit',
	'company_total_assets',

	'contact_phone',
	'contact_email'
] satisfies Dimension[]

export const COMPANY_DIMENSIONS = ALL_DIMENSIONS.filter(d => d.startsWith('company_')) as Array<
	Extract<Dimension, `company_${string}`>
>
export const CONTACT_DIMENSIONS = ALL_DIMENSIONS.filter(d => d.startsWith('contact_')) as Array<
	Extract<Dimension, `contact_${string}`>
>

// eslint-disable-next-line no-shadow -- TODO: This rule is broken for typescript, use @typescript-eslint/no-shadow instead
export enum WorkflowType {
	Researcher = 'researcher',
	Writer = 'writer',
	Qualifier = 'qualifier',
	Other = 'other'
}

export const WORKFLOW_TYPES = objectEntries(WorkflowType).map(([, value]) => value)
