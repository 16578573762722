import type { SegmentTag } from '~/types'
import { Action } from '../actions'

export const initialState: SegmentTag[] = []

export function segmentTags(state: SegmentTag[] = initialState, action: Action): SegmentTag[] {
	switch (action.type) {
		case 'GET_SEGMENTS_OK':
			return action.payload.flatMap(s => ('tags' in s ? s.tags : []) as SegmentTag[])

		case 'INSERT_SEGMENT_TAG_OK':
			return [...state, action.payload]

		case 'DELETE_SEGMENT_TAG_OK':
			return state.filter(t => t.id !== action.meta)
	}
	return state
}
