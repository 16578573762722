import { PlusSquareIcon } from 'lucide-react'
import { FC, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { ProjectCreator } from '~/components/layout'
import { Button, Dialog } from '~/components/ui'
import { useCurrentProject } from '~/utils'

export const Project: FC = () => {
	const [dialogOpen, toggleDialog] = useState(false)
	const project = useCurrentProject()
	return project ? (
		<Outlet />
	) : (
		<div className="mt-4 space-y-4 text-center">
			<h2 className="text-xl">No project selected</h2>
			<h3 className="opacity-50">Select a project from the project selector in the top left.</h3>
			<Dialog open={dialogOpen} onOpenChange={toggleDialog}>
				<Button variant="secondary" onClick={_ => toggleDialog(true)}>
					<PlusSquareIcon className="mr-2" /> Create a project
				</Button>
				<ProjectCreator currentProject={null} onClose={() => toggleDialog(false)} />
			</Dialog>
		</div>
	)
}
