import { ComponentPropsWithoutRef, FC, useEffect, useState } from 'react'
import { LiaCaretDownSolid, LiaCheckCircle, LiaPlusCircleSolid } from 'react-icons/lia'
import { Link, generatePath, useMatches } from 'react-router-dom'
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
	Button,
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
	Dialog,
	DialogTrigger,
	Popover,
	PopoverContent,
	PopoverTrigger
} from '~/components/ui'
import { Actions } from '~/redux'
import { cn, getInitials, useCurrentProject, useDispatch, usePathPattern, useSelector } from '~/utils'
import { ProjectCreator } from './ProjectCreator'

export type ProjectSwitcher = ComponentPropsWithoutRef<typeof PopoverTrigger>

export const ProjectSwitcher: FC<ProjectSwitcher> = ({ className }) => {
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)
	const [open, setOpen] = useState(false)
	const [showDialog, setShowDialog] = useState(false)
	const selectedProject = useCurrentProject()
	const loaded = useSelector(state => state.user?.loaded)

	useEffect(() => {
		if (selectedProject?.id && loaded) {
			dispatch(Actions.getResources(selectedProject.id))
		}
	}, [loaded, dispatch, selectedProject?.id])

	const onProjectUrl = useMatches().some(match => match.params.projectId)
	const pattern = usePathPattern(['projectId'])

	return (
		<Dialog open={showDialog} onOpenChange={setShowDialog}>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={open}
						aria-label="Select a team"
						className={cn('justify-between sm:w-[200px]', className)}
					>
						{selectedProject && (
							<Avatar className="mr-2 h-5 w-5">
								<AvatarImage
									src={`https://avatar.vercel.sh/${selectedProject.id.slice(-6)}`}
									alt={selectedProject.name}
								/>
								<AvatarFallback>{getInitials(selectedProject.name)}</AvatarFallback>
							</Avatar>
						)}
						<span className="max-sm:hidden">{selectedProject?.name ?? 'Please select a project'}</span>
						<LiaCaretDownSolid className="ml-auto h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-[200px] p-0" data-testid="project-switcher-popover">
					<Command>
						<CommandList>
							<CommandInput placeholder="Search project..." />
							<CommandEmpty>No project found.</CommandEmpty>
							{user?.companies.map(company => {
								const projects = user.projects.filter(pj => pj.org_id === company.id)
								return (
									<CommandGroup key={company.id} heading={company.name}>
										{projects.length > 0 ? (
											projects.map(project => (
												<Link
													key={project.id}
													to={
														onProjectUrl
															? generatePath(pattern, { projectId: project.id })
															: `/${project.id}`
													}
												>
													<CommandItem
														onSelect={() => setOpen(false)}
														className="cursor-pointer text-sm"
													>
														<Avatar className="mr-2 h-5 w-5">
															<AvatarImage
																src={`https://avatar.vercel.sh/${project.id.slice(-6)}`}
																alt={project.name}
															/>
															<AvatarFallback>{getInitials(project.name)}</AvatarFallback>
														</Avatar>
														{project.name}
														<LiaCheckCircle
															className={cn(
																'ml-auto h-4 w-4',
																selectedProject?.id === project.id
																	? 'opacity-100'
																	: 'opacity-0'
															)}
														/>
													</CommandItem>
												</Link>
											))
										) : (
											<CommandItem className="-mt-2 text-xs opacity-50" disabled>
												No projects.
											</CommandItem>
										)}
									</CommandGroup>
								)
							})}
						</CommandList>
						<CommandSeparator />
						<CommandList>
							<CommandGroup>
								<DialogTrigger asChild>
									<CommandItem
										onSelect={() => {
											setOpen(false)
											setShowDialog(true)
										}}
									>
										<LiaPlusCircleSolid className="mr-2 h-5 w-5" />
										Create Project
									</CommandItem>
								</DialogTrigger>
							</CommandGroup>
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
			<ProjectCreator currentProject={selectedProject} onClose={() => setShowDialog(false)} />
		</Dialog>
	)
}
