import { combineReducers } from 'redux'
import { canvas } from './canvas'
import { companySegments } from './companySegments'
import { config } from './config'
import { enrichmentTypes, enrichments } from './enrichments'
import { files } from './files'
import { prospectingCompanies, prospectingListCompanies } from './prospectingCompanies'
import { prospectingContacts, prospectingRoles } from './prospectingContacts'
import { prospectingLists } from './prospectingLists'
import { resourceCategories } from './resourceCategories'
import { resourceSegments } from './resourceSegments'
import { resources } from './resources'
import { runs } from './runs'
import { segmentTags } from './segmentTags'
import { segments } from './segments'
import { templates } from './templates'
import { toast } from './toast'
import { user } from './user'

export const rootReducer = () =>
	combineReducers({
		canvas,
		config,
		files,
		prospecting: combineReducers({
			companies: prospectingCompanies,
			contacts: prospectingContacts,
			roles: prospectingRoles,
			lists: prospectingLists,
			listCompanies: prospectingListCompanies,
			segments: companySegments
		}),
		enrichments: combineReducers({
			runs,
			items: enrichments,
			types: enrichmentTypes
		}),
		resources,
		resourceCategories,
		resourceSegments,
		segments,
		segmentTags,
		templates,
		toast,
		user
	})
