import type { UIResource } from '~/types'
import { ResourcesState } from '~/types/ui'
import { Action, MetaAction } from '../actions'

let ID_COUNTER = 0

export const initialState: ResourcesState = {
	loading: true,
	items: [],
	tags: [],
	sets: [],
	files: [],
	texts: []
}

function item(state: UIResource, action: MetaAction): UIResource {
	if (state.id !== action.meta) return state
	switch (action.type) {
		case 'UPDATE_RESOURCE':
		case 'UPDATE_RESOURCE_OK':
			return { ...state, ...action.payload }

		case 'EDIT_RESOURCE':
			return { ...state, editing: action.payload }
	}
	return state
}

export function resources(state: ResourcesState = initialState, action: Action): ResourcesState {
	switch (action.type) {
		case 'UPDATE_RESOURCE':
		case 'UPDATE_RESOURCE_OK':
		case 'EDIT_RESOURCE':
			return {
				...state,
				items: state.items.map(i => item(i, action))
			}

		case 'DELETE_RESOURCE_OK':
			return { ...state, items: state.items.filter(i => i.id !== action.meta) }

		case 'GET_RESOURCES':
			return { ...state, items: [], sets: [], tags: [], loading: true }

		case 'GET_RESOURCES_OK':
			return {
				...state,
				loading: false,
				items: action.payload.map(rs => ({ ...rs, editing: false }))
			}

		case 'INSERT_RESOURCE_OK':
			return { ...state, items: [{ ...action.payload, editing: false }, ...state.items] }

		case 'CREATE_RESOURCE_SET':
			return {
				...state,
				sets: [...state.sets, { ...action.payload, id: ID_COUNTER++ }]
			}

		case 'CREATE_RESOURCE_SETS':
			return {
				...state,
				sets: [...state.sets, ...action.payload.map(s => ({ ...s, id: ID_COUNTER++ }))]
			}

		case 'GET_RESOURCE_TAGS_OK':
			return { ...state, tags: action.payload }

		case 'INSERT_RESOURCE_TAG_OK':
			return { ...state, tags: [...state.tags, action.payload] }

		case 'UPDATE_RESOURCE_TAG_OK':
			return { ...state, tags: state.tags.map(t => (t.id === action.meta ? action.payload : t)) }

		case 'DELETE_RESOURCE_TAG_OK':
			return { ...state, tags: state.tags.filter(t => t.id !== action.meta) }

		case 'GET_RESOURCE_FILES_OK':
			return { ...state, files: action.payload }

		case 'INSERT_RESOURCE_FILE_OK':
			return { ...state, files: [...state.files, action.payload] }

		case 'UPDATE_RESOURCE_FILE_OK':
			return {
				...state,
				files: state.files.map(file => (file.id === action.meta ? { ...file, ...action.payload } : file))
			}

		case 'DELETE_RESOURCE_FILE_OK':
			return { ...state, files: state.files.filter(i => i.id !== action.meta) }

		case 'GET_RESOURCE_TEXTS_OK':
			return { ...state, texts: action.payload }

		case 'INSERT_RESOURCE_TEXT_OK':
			return { ...state, texts: [...state.texts, action.payload] }

		case 'UPDATE_RESOURCE_TEXT_OK':
			return {
				...state,
				texts: state.texts.map(text => (text.id === action.meta ? { ...text, ...action.payload } : text))
			}

		case 'DELETE_RESOURCE_TEXT_OK':
			return { ...state, texts: state.texts.filter(i => i.id !== action.meta) }
	}
	return state
}
