import * as Sentry from '@sentry/react'
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { LiaGithub, LiaGoogle, LiaSpinnerSolid } from 'react-icons/lia'
import {
	Button,
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
	Input,
	Label
} from '~/components/ui'
import { Actions } from '~/redux'
import { supabase, useDispatch, useSearch, useStorageState } from '~/utils'

export type AuthMode = 'login' | 'signup' | 'reset'

export const Auth = () => {
	const dispatch = useDispatch()
	const [message, setMessage] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const [_mode, _setMode] = useStorageState<AuthMode>('authMode', 'signup')
	const [{ auth: mode }, setParams] = useSearch({ auth: _mode })

	const setMode = useCallback(
		(m: AuthMode) => {
			setParams({ auth: m })
			if (m !== 'reset') _setMode(m)
		},
		[_setMode, setParams]
	)

	useEffect(() => setMessage(null), [mode])

	async function handleSubmit(formData: FormData) {
		setIsLoading(true)
		const email = formData.get('email') as string
		const password = formData.get('password') as string
		let res
		switch (mode) {
			case 'signup':
				res = await supabase.auth.signUp({ email, password, options: { emailRedirectTo: location.origin } })
				break
			case 'login':
				res = await supabase.auth.signInWithPassword({ email, password })
				break
			case 'reset':
				res = await supabase.auth.resetPasswordForEmail(email, {
					redirectTo: `${location.origin}/settings/password?fromEmail=true`
				})
				res = { error: res.error, data: null } // Having data be {} breaks branch type checking
				break
		}

		setIsLoading(false)
		if (res.error) {
			setMessage(res.error.message)
			return
		} else if (res.data?.session) {
			Sentry.setUser({
				id: res.data.session.user.id,
				email: res.data.session.user.email
			})
			setMessage(null)
			setParams({ auth: null })
			dispatch(Actions.userLoggedIn(res.data.session, res.data.user ?? res.data.session.user))
		} else {
			setMessage('Password reset email sent. Please check your email.')
		}
	}

	return (
		<form
			className="mx-auto w-full max-w-screen-xs"
			onSubmit={e => {
				e.preventDefault()
				handleSubmit(new FormData(e.currentTarget))
			}}
		>
			{mode === 'reset' ? (
				<Card className="h-full w-full">
					<CardHeader className="space-y-1">
						<CardTitle>Reset password</CardTitle>
					</CardHeader>
					<CardContent className="grid gap-4">
						<div className="grid gap-2">
							<Label htmlFor="email">Email</Label>
							<Input
								id="email"
								type="email"
								autoComplete="username"
								placeholder="m@example.com"
								required
							/>
						</div>
					</CardContent>
					<CardFooter>
						<Button className="w-full" type="submit">
							{isLoading && <LiaSpinnerSolid className="mr-2 h-4 w-4 animate-spin" />}
							Send Reset Email
						</Button>
					</CardFooter>
					<div className="-mt-2 space-y-2 px-6 pb-6">
						{message && <ErrorMessage>{message}</ErrorMessage>}
						<button type="button" className="text-blue-500 hover:underline" onClick={_ => setMode('login')}>
							Go back to sign in
						</button>
					</div>
				</Card>
			) : (
				<Card className="h-full w-full">
					<CardHeader className="space-y-1">
						<CardTitle>
							{mode === 'signup' ? 'Create an account for' : 'Sign in to'}
							<span> Relay</span>
						</CardTitle>
						<CardDescription>
							Enter your email below to {mode === 'signup' ? 'create your account' : 'log in'}
						</CardDescription>
					</CardHeader>
					<CardContent className="grid gap-4">
						<div className="grid grid-cols-2 gap-x-6 gap-y-2">
							<p className="col-span-full text-center text-sm italic text-muted-foreground">
								Coming soon!
							</p>
							<Button variant="outline" disabled>
								<LiaGithub className="mr-2 h-4 w-4" />
								Github
							</Button>
							<Button variant="outline" disabled>
								<LiaGoogle className="mr-2 h-4 w-4" />
								Google
							</Button>
						</div>
						<div className="relative">
							<div className="absolute inset-0 flex items-center">
								<span className="w-full border-t" />
							</div>
							<div className="relative flex justify-center text-xs uppercase">
								<span className="bg-background px-2 text-muted-foreground">Or continue with</span>
							</div>
						</div>
						<div className="grid gap-2">
							<Label htmlFor="email">Email</Label>
							<Input
								id="email"
								type="email"
								autoComplete="username"
								placeholder="m@example.com"
								required
							/>
						</div>
						<div className="grid gap-2">
							<Label htmlFor="password">Password</Label>
							<Input
								id="password"
								type="password"
								autoComplete={mode === 'login' ? 'current-password' : 'new-password'}
								minLength={8}
								required
							/>
							{mode === 'login' && (
								<div className="text-right text-sm">
									<button
										type="button"
										className="text-blue-500 hover:underline"
										onClick={_ => setMode('reset')}
									>
										Forgot password?
									</button>
								</div>
							)}
						</div>
						{mode === 'signup' && (
							<div className="grid gap-2">
								<Label htmlFor="confirm-password">Confirm Password</Label>
								<Input
									id="confirm-password"
									type="password"
									autoComplete="new-password"
									required
									onChange={e => {
										const field = e.currentTarget
										if (field.value != field.form?.password.value) {
											field.setCustomValidity('Passwords do not match.')
										} else {
											field.setCustomValidity('')
										}
									}}
								/>
							</div>
						)}
					</CardContent>
					<CardFooter>
						<Button className="w-full" type="submit">
							{isLoading && <LiaSpinnerSolid className="mr-2 h-4 w-4 animate-spin" />}
							{mode === 'signup' ? 'Create account' : 'Sign in'}
						</Button>
					</CardFooter>
					<div className="-mt-2 space-y-2 px-6 pb-6">
						{message && <ErrorMessage>{message}</ErrorMessage>}
						<span>Already have an account? </span>
						<button
							type="button"
							className="text-blue-500 hover:underline"
							onClick={_ => setMode(mode === 'signup' ? 'login' : 'signup')}
						>
							{mode === 'signup' ? 'Sign in' : 'Create account'}
						</button>
					</div>
				</Card>
			)}
		</form>
	)
}

const ErrorMessage: FC<PropsWithChildren> = ({ children }) => (
	<p className="text-center font-semibold text-red-500" data-testid="auth-error-message">
		{children}
	</p>
)
