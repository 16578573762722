/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom'
import { Project as ProjectType, UICanvas } from '~/types'
import { App } from './components/layout/App'
import { About, Organizations, PasswordForm, ProfileForm, Projects } from './components/settings'
import './globals.css'
import { Actions } from './redux'
import { reduxStore as store } from './redux/configureStore'
import { Canvas } from './routes/Canvas'
import { TagConfig } from './routes/Configuration'
import { Project } from './routes/Project'
import { Lists, Prospecting, Sequence } from './routes/Prospecting'
import { Resources } from './routes/Resources'
import { Segments } from './routes/Segments'
import { Templates } from './routes/Templates'
import { UserSettings } from './routes/UserSettings'
import { getItem, isTypeId, supabase, toToastError } from './utils'
import { handleFulfilled, handleRejected } from './utils/router'

const queryClient = new QueryClient()

store.dispatch(Actions.loadUser())

const items = [
	{
		title: 'Profile',
		href: '/settings/profile',
		component: <ProfileForm />
	},
	{
		title: 'Password',
		href: '/settings/password',
		component: <PasswordForm />
	},
	{
		title: 'Organizations',
		href: '/settings/organizations',
		component: <Organizations />
	},
	{
		title: 'Projects',
		href: '/settings/projects',
		component: <Projects />
	},
	{
		title: 'About',
		href: '/settings/about',
		component: <About />,
		hidden: import.meta.env.PROD
	}
]

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route id="root" path="" element={<App />}>
			<Route
				path=":projectId?"
				shouldRevalidate={args => args.nextParams.projectId !== args.currentParams.projectId}
				loader={async ({ params }) => {
					const session = await supabase.auth.getSession()
					const selectedProject = getItem<ProjectType['id']>('selectedProjectId')
					if (params.projectId && isTypeId(params.projectId, 'pr') && session.data.session)
						store.dispatch(Actions.selectProject(params.projectId))
					else if (selectedProject) return redirect(`/${selectedProject}`)
					return null
				}}
				element={<Project />}
			>
				<Route path="" element={<Resources />} />
				<Route path="prospecting" element={<Lists />}>
					<Route path=":listId" element={<Prospecting />} />
				</Route>
				<Route path="prospecting/:listId/:contactId" element={<Sequence />} />
				<Route path="segments" element={<Segments />} />
				<Route
					path="canvas"
					id="canvas"
					element={<Canvas />}
					loader={async () => {
						const canvases = await supabase
							.from('canvas')
							.select('*, tasks:task(*, templates:template(label))')
						if (canvases.error) {
							const toastError = await toToastError(canvases.error)
							store.dispatch(Actions.addToast(toastError))
						} else {
							store.dispatch(Actions.getCanvasOk(canvases.data as UICanvas[]))
						}
						return null
					}}
					action={async ({ params }) => {
						if (!isTypeId(params.projectId, 'pr')) return
						const canvas = await supabase
							.from('canvas')
							.insert({ project_id: params.projectId })
							.select()
							.single()
						if (canvas.data) return redirect(`/${params.projectId}/canvas/${canvas.data.id}`)
						else {
							const toastError = await toToastError(canvas.error)
							store.dispatch(Actions.addToast(toastError))
						}
					}}
				>
					<Route path=":canvasId" lazy={() => import('./routes/Task')} />
				</Route>
			</Route>
			<Route path="configuration" element={<TagConfig />} />
			<Route
				path="templates"
				element={<Templates />}
				loader={() => store.dispatch(Actions.getTemplates())}
				action={async ({ request }) => {
					const data = await request.formData()
					const org_id = data.get('org_id') as string
					if (!isTypeId(org_id, 'co')) return
					return supabase.from('template').insert({ org_id }).single().then(handleFulfilled, handleRejected)
				}}
			>
				<Route path=":templateId" lazy={() => import('./routes/Template')} />
			</Route>
			<Route path="/settings" element={<UserSettings navItems={items} />}>
				{items.map(item => (
					<Route key={item.href} path={item.href} element={item.component} />
				))}
				<Route path="*" element={<div>404</div>} />
			</Route>

			<Route path="*" element={<div>404</div>} />
		</Route>
	)
)

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<Provider store={store}>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
		</QueryClientProvider>
	</Provider>
)
