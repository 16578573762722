import { zodResolver } from '@hookform/resolvers/zod'
import { startCase } from 'lodash-es'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { objectKeys } from 'ts-extras'
import * as z from 'zod'
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input } from '~/components/ui'
import { Actions } from '~/redux'
import { supabase, toToastError } from '~/utils'

const passwordFormSchema = z.object({
	password: z.string({ required_error: 'Please enter a new password.' }).min(8)
})

type PasswordFormValues = z.infer<typeof passwordFormSchema>

export const PasswordForm: FC = () => {
	const dispatch = useDispatch()
	const form = useForm<PasswordFormValues>({
		resolver: zodResolver(passwordFormSchema),
		defaultValues: {},
		mode: 'onChange'
	})

	async function onSubmit({ password }: PasswordFormValues) {
		const res = await supabase.auth.updateUser({ password })
		if (res.error) dispatch(Actions.addToast(await toToastError(res.error)))
		else dispatch(Actions.addToast({ title: 'Password Updated' }))
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit, console.error)} className="space-y-4">
				{objectKeys(passwordFormSchema.shape).map(key => (
					<FormField
						key={key}
						control={form.control}
						name={key}
						render={({ field }) => (
							<FormItem>
								<FormLabel>{startCase(field.name)}</FormLabel>
								<FormControl>
									<Input {...field} {...getFormProps(field.name)} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				))}

				<Button type="submit" className="!mt-8">
					Update password
				</Button>
			</form>
		</Form>
	)
}

function getFormProps(key: keyof PasswordFormValues): { placeholder: string; autoComplete: AutoFill } {
	switch (key) {
		case 'password':
			return { placeholder: '************', autoComplete: 'new-password' }
	}
}
