/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { isObject } from 'lodash-es'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Except } from 'type-fest'
import { AutoTable, AutoTableProps } from '~/components/table/AutoTable'
import { Actions } from '~/redux'
import { Enrichment, ProspectingCompany, ProspectingList, ProspectingListCompany } from '~/types'
import {
	COMPANY_DIMENSIONS,
	MANUAL_COMPANY_ENRICHMENT_ID,
	MANUAL_COMPANY_WEIGHT_ID,
	compareRows,
	getDimensionEntry,
	getDimensionKey,
	getItem,
	isDimension,
	supabase,
	toToastError,
	useCurrentProject,
	useDispatch,
	useMemoSelector,
	useSelector,
	useStorageState
} from '~/utils'
import { ProspectingSearchParams } from './Prospecting'
import { SegmentDialog } from './SegmentDialog'
import { deleteRows, mapEnrichment, toggleRows } from './listUtils'

export const excludedColumns = ['list_id', 'org_id', 'created_by', 'disabled', 'company_id', 'company_name']

const isCompanyDimension = (key: string) => isDimension(key, 'company')

export interface CompanyTableProps extends Except<ProspectingSearchParams, 'type'>, AutoTableProps<any> {
	list: ProspectingList
	companies: Array<ProspectingListCompany & Record<string, any>>
	enrichments: Enrichment[]
}

export const CompanyTable: FC<CompanyTableProps> = ({ tab, list, companies, enrichments, ...props }) => {
	const dispatch = useDispatch()
	const project = useCurrentProject()
	const prospectingCompanies = useSelector(state => state.prospecting.companies)

	const [companiesToBeSegmented, setSegmentCompanies] = useState<Array<ProspectingCompany['id']>>([])

	const types = useMemoSelector(
		state => state.enrichments.types,
		state =>
			state
				.filter(et => et.type === 'company')
				.filter(et => !['manual', 'proff'].includes(et.name))
				.sort(compareRows({ order: 'asc' }))
	)
	const selectedType = types.find(et => et.name === tab) // TODO: Retrieve enrichment based on latest of each type

	const columnVisiblityKey = `col-company'-${tab}`
	const [columnVisibility, setColumnVisibility] = useStorageState<Record<string, boolean>>(columnVisiblityKey)
	useEffect(() => {
		const set: Record<string, boolean> = {}
		// Only override custom column visibility if it has not been set before
		if (tab === 'custom' && getItem(columnVisiblityKey)) {
			for (const key in set) {
				if (!Object.hasOwn(set, key)) continue
				set[key] = false
			}
			Object.assign(set, getItem(columnVisiblityKey))
		} else {
			for (const row of enrichments) {
				const enrichment = mapEnrichment(row)
				if (!isObject(enrichment)) continue
				for (const key in enrichment) {
					set[key] = tab === 'all' || row.type_id === selectedType?.id
				}
			}
			for (const _key of COMPANY_DIMENSIONS) {
				const key = _key.replace('company_', '')
				if (key in set) continue
				set[key] = tab === 'all'
			}
			const defaults = {
				id: false,
				name: true,
				country: ['all', 'csv'].includes(tab),
				org_number: ['all', 'csv'].includes(tab),
				list_id: false,
				org_id: false,
				created_at: false,
				updated_at: false
			}
			for (const key in defaults) {
				if (!Object.hasOwn(defaults, key)) continue
				set[key] = defaults[key as keyof typeof defaults]
			}
		}
		setColumnVisibility(set)
	}, [columnVisiblityKey, companies, enrichments, selectedType?.id, setColumnVisibility, tab])

	const onCellEdit = useCallback<NonNullable<AutoTableProps<(typeof companies)[0]>['onCellValueChanged']>>(
		async ({ value, colDef, node }) => {
			if (!colDef.colId) return
			const key = getDimensionKey(colDef.colId, 'company')
			if (!key) return

			const enr = await supabase
				.from('enrichment')
				.insert({
					list_id: list.id,
					data: { [key]: value },
					type_id: MANUAL_COMPANY_ENRICHMENT_ID,
					company_id: node.data?.company_id as string
				})
				.select()
				.single()
			if (enr.error) return dispatch(Actions.addToast(await toToastError(enr.error)))

			const dim = await supabase
				.from(`dimension_company_${key}`)
				.insert(getDimensionEntry(enr.data.id, MANUAL_COMPANY_WEIGHT_ID)(value))
			if (dim.error) {
				dispatch(Actions.addToast(await toToastError(dim.error)))
				await supabase.from('enrichment').delete().eq('id', enr.data.id)
			} else {
				const company = prospectingCompanies.find(c => c.id === node.data?.company_id)
				if (!company) return
				dispatch({
					type: 'UPDATE_PROSPECTING_COMPANY_OK',
					meta: company.id,
					payload: { ...company, [key]: value }
				})
			}
		},
		[dispatch, list.id, prospectingCompanies]
	)

	const actions = useMemo(
		() =>
			({
				enable: rows => toggleRows(rows, dispatch, true),
				disable: rows => toggleRows(rows, dispatch, false),
				deleteCompany: async rows => {
					const errors = await deleteRows(rows)
					for (const error of errors) {
						dispatch(Actions.addToast(await toToastError(error)))
					}
				},
				editSegments: rows => setSegmentCompanies(rows.map(row => row.company_id))
			}) satisfies AutoTableProps<(typeof companies)[0]>['actions'],
		[dispatch]
	)

	if (!project) return null
	return (
		<>
			<SegmentDialog onOpenChange={_ => setSegmentCompanies([])} selectedCompanies={companiesToBeSegmented} />
			<AutoTable
				className="max-h-full w-full"
				rowClassRules={{
					disabled: row => row.data.disabled
				}}
				data={companies}
				{...props}
				actions={actions}
				excludedColumns={excludedColumns}
				editableColumns={isCompanyDimension}
				onCellValueChanged={onCellEdit}
				showToggleColumns={tab === 'custom'}
				onColumnVisibilityChange={setColumnVisibility}
				columnVisibility={columnVisibility}
			/>
		</>
	)
}
