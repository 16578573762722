import { camelCase } from 'lodash-es'
import { FC } from 'react'
import { HiOutlineViewGrid, HiOutlineViewList } from 'react-icons/hi'
import { LiaPlusCircleSolid } from 'react-icons/lia'
import { FileTable } from '~/components/FileTable'
import { Upload } from '~/components/Upload'
import { FileCard, Resource, ResourceTable } from '~/components/resource'
import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from '~/components/ui'
import { Actions } from '~/redux'
import {
	COMPANY_CATEGORY,
	FILES_CATEGORY,
	LISTS_CATEGORY,
	SUPABASE_BUCKET_NAME,
	depluralize,
	supabase,
	toFilePath,
	toToastError,
	useCurrentProject,
	useDispatch,
	useSearch,
	useSelector
} from '~/utils'

interface SearchParams {
	view: 'grid' | 'list'
	type?: string
}

export const Resources: FC = () => {
	const dispatch = useDispatch()
	const categories = useSelector(state => state.resourceCategories)
		.filter(rc => ![COMPANY_CATEGORY, LISTS_CATEGORY].includes(rc.id))
		.toSorted((a, b) => a.order - b.order)
	const resources = useSelector(state => state.resources.items)
	const resourceFiles = useSelector(state => state.resources.items)
	const [params, setParams] = useSearch<SearchParams>({ view: 'grid' })
	const project = useCurrentProject()
	const files = useSelector(state => state.files)
		.filter(f => f.bucket_id === SUPABASE_BUCKET_NAME)
		.filter(file => file.project_id === project?.id)

	if (!project || !categories.length) return null
	return (
		<Tabs
			value={params.type || camelCase(categories[0]?.label)}
			defaultValue={camelCase(categories[0]?.label)}
			onValueChange={type => setParams({ type })}
		>
			<div className="float-right flex justify-between space-x-4">
				<TabsList>
					{categories.map(resourceCategory => (
						<TabsTrigger key={resourceCategory.id} value={camelCase(resourceCategory.label)}>
							{resourceCategory.label}
						</TabsTrigger>
					))}
				</TabsList>
				<Tabs
					value={params.view ?? undefined}
					onValueChange={view => setParams({ view: view as SearchParams['view'] })}
				>
					<TabsList>
						<TabsTrigger className="text-xl" value="grid" aria-label="Grid">
							<HiOutlineViewGrid />
						</TabsTrigger>
						<TabsTrigger className="text-xl" value="list" aria-label="List">
							<HiOutlineViewList />
						</TabsTrigger>
					</TabsList>
				</Tabs>
			</div>

			{categories.map(category => (
				<TabsContent key={category.id} value={camelCase(category.label)} className="mt-0 space-y-4">
					{category.id === FILES_CATEGORY ? (
						<Upload
							bucketName={SUPABASE_BUCKET_NAME}
							prefix={toFilePath(project, '')}
							fileAdded={file => {
								dispatch(
									Actions.fileAdded({
										id: file.id,
										bucket_id: file.meta.bucketName as string,
										name: file.name,
										created_at: new Date().toISOString(),
										last_accessed_at: new Date().toISOString(),
										updated_at: new Date().toISOString(),
										project_id: project.id,
										metadata: {
											eTag: '',
											contentLength: file.size,
											size: file.size,
											mimetype: file.type || '',
											lastModified:
												'lastModified' in file.data
													? new Date(file.data.lastModified).toISOString()
													: ''
										},
										progress: file.progress
									})
								)
							}}
							uploadProgress={(file, progress) =>
								file && dispatch(Actions.fileUpdated({ progress }, file.name))
							}
						/>
					) : (
						<Button
							className="rounded bg-green text-black hover:bg-green-300"
							onClick={async () => {
								if (!category) return
								setParams({ view: 'grid' })
								const rt = await supabase
									.from('resource_text')
									.insert({
										value: `A new ${depluralize(category.label)}`,
										project_id: project.id
									})
									.select()
									.single()
								if (rt.error) return dispatch(Actions.addToast(await toToastError(rt.error)))

								const res = await supabase.from('resource').insert({
									category_id: category.id,
									project_id: project.id,
									text_id: rt.data.id
								})
								if (res.error) return dispatch(Actions.addToast(await toToastError(res.error)))
							}}
						>
							<LiaPlusCircleSolid className="mr-1 h-6 w-6" /> Create {depluralize(category.label)} Aspect
						</Button>
					)}
					{params.view === 'grid' ? (
						<div className="grid grid-cols-[repeat(auto-fit,100%)] items-start justify-start gap-4 xs:grid-cols-[repeat(auto-fit,minmax(22rem,1fr))]">
							{files
								.filter(file => file.bucket_id === SUPABASE_BUCKET_NAME)
								.filter(
									// Only show files without a resource
									file => !resourceFiles.some(rf => rf.id === file.id || rf.label === file.name)
								)
								.map(file => (
									<FileCard key={file.name} file={file} />
								))}
							{resources
								.filter(resource => resource.category_id === category.id)
								.map(resource => (
									<Resource key={resource.id} resource={resource} />
								))}
						</div>
					) : category.id === FILES_CATEGORY ? (
						<FileTable files={files} />
					) : (
						<ResourceTable data={resources.filter(resource => resource.category_id === category.id)} />
					)}
				</TabsContent>
			))}
		</Tabs>
	)
}
