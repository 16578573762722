import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware, { EffectMiddleware } from 'redux-saga'
import { rootReducer } from './reducers'
import SagaManager from './sagas'

// Create dummy which will be replaced in configureStore
const actionInterceptor: EffectMiddleware = next => effect => next(effect)
const effectMiddleware = (next: any) => actionInterceptor(next)

const sagaMiddleware = createSagaMiddleware({ effectMiddlewares: [effectMiddleware] })

const middlewares = [sagaMiddleware]

const devtools = typeof window !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null
const composeEnhancers: typeof compose = devtools ?? compose

export function configureStore() {
	const store = createStore(rootReducer(), {}, composeEnhancers(applyMiddleware(...middlewares)))

	// run sagas
	SagaManager.startSagas(sagaMiddleware)

	return store
}

export const reduxStore = configureStore()
