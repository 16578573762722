import type { UICanvas } from '~/types'
import { Action, MetaAction } from '../actions'

export const initialState: UICanvas[] = []

function tasks(state: UICanvas['tasks'] = [], action: MetaAction) {
	switch (action.type) {
		case 'INSERT_TASK_OK':
			return [...state, action.payload]

		case 'DELETE_TASK_OK':
			return state.filter(t => t.id !== action.meta)

		case 'UPDATE_TASK_OK':
			return state.map(task => (task.id === action.meta ? { ...task, ...action.payload } : task))
	}
	return state
}

function item(state: UICanvas, action: MetaAction): UICanvas {
	switch (action.type) {
		case 'INSERT_TASK_OK':
		case 'DELETE_TASK_OK':
		case 'UPDATE_TASK_OK':
			return {
				...state,
				tasks: tasks(state.tasks, action) as UICanvas['tasks']
			}
	}

	if (state.id !== action.meta) return state
	switch (action.type) {
		case 'UPDATE_CANVAS':
		case 'UPDATE_CANVAS_OK':
			return {
				...state,
				...(action.payload as UICanvas)
			}
	}
	return state
}

export function canvas(state: UICanvas[] = initialState, action: Action): UICanvas[] {
	switch (action.type) {
		case 'GET_CANVAS_OK':
			return action.payload

		case 'UPDATE_CANVAS':
		case 'UPDATE_CANVAS_OK':
		case 'INSERT_TASK_OK':
		case 'DELETE_TASK_OK':
		case 'UPDATE_TASK_OK':
			return state.map(cv => item(cv, action))
	}
	return state
}
