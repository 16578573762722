import { Segment, SegmentTag, TablesInsert, UISegment } from '~/types'
import { makeActionCreator, makeMetaActionCreator } from './actionCreator'

export default {
	getSegments: makeMetaActionCreator<Segment['project_id']>()('GET_SEGMENTS'),
	getSegmentsOk: makeActionCreator<UISegment[]>()('GET_SEGMENTS_OK'),

	editSegment: makeActionCreator<boolean, Segment['id']>()('EDIT_SEGMENT'),
	updateSegment: makeActionCreator<Partial<Segment>, Segment['id']>()('UPDATE_SEGMENT'),

	deleteSegment: makeMetaActionCreator<Segment['id']>()('DELETE_SEGMENT'),

	createSegment: makeActionCreator<TablesInsert<'segment'>>()('CREATE_SEGMENT'),

	getSegmentTagsOk: makeActionCreator<SegmentTag[]>()('GET_SEGMENT_TAGS_OK')
}
