import { PlusCircleIcon } from 'lucide-react'
import { FC, FunctionComponent } from 'react'
import { HiTrash } from 'react-icons/hi2'
import { LiaToggleOffSolid, LiaToggleOnSolid } from 'react-icons/lia'
import { TagEditor } from '~/components/layout/TagEditor'
import { Button } from '~/components/ui'
import { Actions } from '~/redux'
import { TagSet, TagType } from '~/types'
import { cn, supabase, tagSetColor, toToastError, useCurrentProject, useDispatch, useSelector } from '~/utils'

export const TagConfig: FunctionComponent = () => {
	const dispatch = useDispatch()
	const config = useSelector(state => state.config)
	return (
		<div className="space-y-10">
			{config.types
				.toSorted((a, b) => a.order - b.order)
				.map(type => (
					<div className="space-y-5" key={type.id} data-testid={`tag-set-${type.label.toLocaleLowerCase()}`}>
						<h2 className="text-2xl font-bold">{type.label} Profile</h2>
						{config.sets
							.filter(g => g.tag_type_id === type.id)
							.toSorted((a, b) => a.order - b.order)
							.map(set => (
								<div key={set.id} className="space-y-3" data-testid="tag-set">
									<div className="flex flex-wrap items-center justify-end">
										<h3 className="flex-auto">
											{set.editing ? (
												<input
													className="border-b border-current"
													placeholder="Enter set name"
													onBlur={e =>
														dispatch(
															Actions.updateTagSet({ label: e.target.value }, set.id)
														)
													}
													defaultValue={set.label}
												/>
											) : (
												set.label
											)}
										</h3>
										<Button
											className="mr-auto text-[#575757]"
											variant="ghost"
											onClick={async _ => {
												await supabase.from('tag_set').delete().eq('id', set.id)
												dispatch(Actions.deleteTagSet(set.id))
											}}
										>
											<HiTrash className="mr-2 h-4 w-4" /> Delete
										</Button>
										<Button
											className="place-self-end text-[#575757]"
											variant="ghost"
											onClick={async _ => {
												await supabase
													.from('tag_set')
													.update({ disabled: !set.disabled })
													.eq('id', set.id)
												dispatch(Actions.disableTagSet(!set.disabled, set.id))
											}}
										>
											{set.disabled ? (
												<>
													<LiaToggleOffSolid className="mr-2 h-4 w-4" />
													<span>Enable</span>
												</>
											) : (
												<>
													<LiaToggleOnSolid className="mr-2 h-4 w-4" />
													<span>Disable</span>
												</>
											)}
										</Button>
									</div>
									<div className="flex gap-4">
										<TagEditor className="flex-auto" set={set} />
										<TagConfigAdd set={set} />
									</div>
								</div>
							))}
						{config.sets.filter(g => g.tag_type_id === type.id).length === 0 && (
							<TagConfigAdd type={type} />
						)}
					</div>
				))}
		</div>
	)
}

type TagConfigAddProps = { set: TagSet } | { type: TagType }

export const TagConfigAdd: FC<TagConfigAddProps> = props => {
	const dispatch = useDispatch()
	const project = useCurrentProject()
	const companies = useSelector(state => state.user?.companies)
	const companyId = project?.org_id ?? companies?.[0]?.id
	const sets = useSelector(state => state.config.sets)
	if (!companyId) return null
	const type = 'set' in props ? props.set.tag_type_id : props.type.id
	return (
		<button
			className={cn('flex items-center rounded-lg p-4 transition-colors', tagSetColor(type, 'button'))}
			onClick={async _ => {
				const order = 'set' in props ? props.set.order + 1 : 1
				const updatedSets = sets
					.filter(g => g.tag_type_id === type)
					.filter(g => g.order >= order)
					.map(g => ({ ...g, order: g.order + 1 }))
				const { data, error } = await supabase
					.from('tag_set')
					.insert({ label: '', tag_type_id: type, org_id: companyId, order })
					.select()
					.single()
				for (const set of updatedSets) {
					dispatch(Actions.updateTagSet({ order: set.order }, set.id))
				}
				if (error) {
					const toastError = await toToastError(error)
					dispatch(Actions.addToast(toastError))
				} else if (data) dispatch(Actions.createTagSet({ ...data, editing: true }))
			}}
		>
			<PlusCircleIcon />
		</button>
	)
}
