import { FunctionComponent, useState } from 'react'
import { HiCheckCircle, HiPencil } from 'react-icons/hi2'
import { LiaPlusCircleSolid, LiaTrashAlt } from 'react-icons/lia'
import { Tag } from '~/components/resource'
import { Button } from '~/components/ui'
import { Actions } from '~/redux'
import { HTMLElementProps, UITagSet } from '~/types'
import { cn, supabase, tagSetColor, toToastError, useDispatch, useSelector } from '~/utils'

export interface TagEditorProps extends HTMLElementProps<'div'> {
	set: UITagSet
}

export const TagEditor: FunctionComponent<TagEditorProps> = ({ set, className, ...props }) => {
	const dispatch = useDispatch()
	const config = useSelector(state => state.config)
	const [creating, setCreating] = useState(false)
	const [value, setValue] = useState('')
	return (
		<div
			className={cn(
				'flex flex-wrap items-center gap-2 rounded-lg border bg-white p-4 dark:bg-stone-900',
				tagSetColor(set.tag_type_id, 'border'),
				className
			)}
			{...props}
		>
			{config.tags
				.filter(t => t.tag_set_id === set.id)
				.map(tag => (
					<Tag
						key={tag.id}
						type={set.tag_type_id}
						onClick={
							!set.editing
								? undefined
								: async _ => {
										await supabase.from('tag').delete().eq('id', tag.id)
										dispatch(Actions.deleteTag(tag.id))
									}
						}
					>
						{tag.label}
						{set.editing && <LiaTrashAlt className="-mr-2 h-5 w-5" />}
					</Tag>
				))}
			{set.editing && !creating && (
				<Button
					className={cn('h-8 w-8 rounded-full border-0', tagSetColor(set.tag_type_id, 'button'))}
					variant="outline"
					size="icon"
					onClick={_ => setCreating(true)}
					aria-label="Add"
				>
					<LiaPlusCircleSolid className="h-5 w-5" />
				</Button>
			)}
			{creating && (
				<form
					onSubmit={e => {
						if (!value) return
						supabase
							.from('tag')
							.insert({ label: value, tag_set_id: set.id })
							.select()
							.single()
							.then(async res => {
								if (res.error) {
									const toastError = await toToastError(res.error)
									dispatch(Actions.addToast(toastError))
								} else if (res.data) {
									setCreating(false)
									setValue('')
								}
							})
						e.preventDefault()
						return false
					}}
				>
					<Tag type={set.tag_type_id}>
						<input
							autoFocus
							className="bg-white/60"
							value={value}
							onChange={e => setValue(e.target.value)}
							required
						/>
						<button type="submit" className="hover:opacity-60" aria-label="Add">
							<LiaPlusCircleSolid className="-mr-2 h-5 w-5" />
						</button>
					</Tag>
				</form>
			)}
			<Button
				className={cn('h-8 w-8 rounded-full border-0', tagSetColor(set.tag_type_id, 'button'))}
				variant="outline"
				size="icon"
				aria-label={set.editing ? 'Confirm' : 'Edit'}
				onClick={_ => {
					dispatch(Actions.editTagSet(!set.editing, set.id))
					setCreating(false)
				}}
			>
				{set.editing ? <HiCheckCircle className="h-5 w-5" /> : <HiPencil className="h-4 w-4" />}
			</Button>
		</div>
	)
}
