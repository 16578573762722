import type { UISegment } from '~/types'
import { compareRows } from '~/utils'
import { Action, MetaAction } from '../actions'

export const initialState: UISegment[] = []

function items(state: UISegment, action: MetaAction): UISegment {
	if (state.id !== action.meta) return state
	switch (action.type) {
		case 'UPDATE_SEGMENT_OK':
		case 'UPDATE_SEGMENT':
			return { ...state, ...action.payload }

		case 'EDIT_SEGMENT':
			return { ...state, editing: action.payload }
	}
	return state
}

export function segments(state: UISegment[] = initialState, action: Action): UISegment[] {
	switch (action.type) {
		case 'GET_SEGMENTS_OK':
			return action.payload.map(s => ({ ...s, editing: false })).toSorted(compareRows())

		case 'INSERT_SEGMENT_TAG_OK':
		case 'DELETE_SEGMENT_TAG_OK':
		case 'EDIT_SEGMENT':
		case 'UPDATE_SEGMENT_OK':
		case 'UPDATE_SEGMENT':
			return state.map(s => items(s, action))

		case 'DELETE_SEGMENT_OK':
			return state.filter(s => s.id !== action.meta)

		case 'INSERT_SEGMENT_OK':
			return [...state, { ...action.payload, editing: true }].toSorted(compareRows())
	}
	return state
}
