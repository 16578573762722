import { zodResolver } from '@hookform/resolvers/zod'
import { startCase } from 'lodash-es'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import {
	Button,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input
} from '~/components/ui'
import { Actions } from '~/redux'
import { supabase, useDispatch, useSelector } from '~/utils'

const createCompanySchema = z.object({
	name: z.string({
		required_error: 'Please enter a company name.'
	})
})

type InviteFormValues = z.infer<typeof createCompanySchema>

export interface InviteDialogProps {
	onClose: () => void
}
export const CreateCompanyDialog: FC<InviteDialogProps> = ({ onClose }) => {
	const user = useSelector(state => state.user)
	const dispatch = useDispatch()
	const form = useForm<InviteFormValues>({
		resolver: zodResolver(createCompanySchema),
		defaultValues: { name: '' },
		mode: 'onBlur'
	})

	const [error, setError] = useState<string | null>(null)

	async function onSubmit(data: InviteFormValues) {
		if (!user?.info) return
		const newCompany = await supabase.rpc('create_organization', { name: data.name })
		if (newCompany.error) return setError(newCompany.error.message)
		dispatch(Actions.getCompanies())
		onClose()
	}

	return (
		<DialogContent>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit, console.error)} className="space-y-8">
					<DialogHeader>
						<DialogTitle>Create a new organization</DialogTitle>
					</DialogHeader>
					<FormField
						control={form.control}
						name="name"
						render={({ field }) => (
							<FormItem>
								<FormLabel>{startCase(field.name)}</FormLabel>
								<FormControl>
									<Input placeholder="Acme Inc." {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<DialogFooter>
						<Button type="button" variant="outline" onClick={onClose}>
							Cancel
						</Button>
						<Button type="submit">Continue</Button>
					</DialogFooter>
					{error && <p className="text-center text-red-500">{error}</p>}
				</form>
			</Form>
		</DialogContent>
	)
}
