import type { ProspectingContact, ProspectingRole } from '~/types'
import { Action } from '../actions'

export function prospectingRoles(state: ProspectingRole[] = [], action: Action): ProspectingRole[] {
	switch (action.type) {
		case 'GET_PROSPECTING_ROLES_OK':
			return action.payload

		case 'INSERT_PROSPECTING_ROLE_OK':
			return [...state, action.payload]

		case 'UPDATE_PROSPECTING_ROLE_OK':
			return state.map(pl => (pl.id === action.meta ? action.payload : pl))

		case 'DELETE_PROSPECTING_ROLE_OK':
			return state.filter(pl => pl.id !== action.meta)
	}
	return state
}

export function prospectingContacts(state: ProspectingContact[] = [], action: Action): ProspectingContact[] {
	switch (action.type) {
		case 'GET_PROSPECTING_CONTACTS_OK':
			return action.payload

		case 'INSERT_PROSPECTING_CONTACT_OK':
			return [...state, action.payload as ProspectingContact]

		case 'UPDATE_PROSPECTING_CONTACT_OK':
			return state.map(pl => (pl.id === action.meta ? (action.payload as ProspectingContact) : pl))

		case 'DELETE_PROSPECTING_CONTACT_OK':
			return state.filter(pl => pl.id !== action.meta)
	}
	return state
}
