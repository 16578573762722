import { FC } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { buttonVariants } from '~/components/ui'
import { cn } from '~/utils'

export interface SidebarItem {
	href: string
	title: string
	hidden?: boolean
}

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
	items: SidebarItem[]
}

export const SidebarNav: FC<SidebarNavProps> = ({ className, items, children, ...props }) => {
	const { pathname } = useLocation()
	return (
		<nav className={cn('flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1', className)} {...props}>
			{items.map(item =>
				item.hidden && !pathname.includes(item.href) ? null : (
					<NavLink
						key={item.href}
						to={item.href}
						className={cn(
							buttonVariants({ variant: 'ghost' }),
							'justify-start hover:bg-transparent hover:underline active:bg-muted active:hover:bg-muted'
						)}
					>
						{item.title}
					</NavLink>
				)
			)}
			{children}
		</nav>
	)
}
