import { ColumnDef, TableOptions } from '@tanstack/react-table'
import { FC, useMemo } from 'react'
import { isPresent } from 'ts-extras'
import { DataTable } from '~/components/table'
import { DataTableColumnHeader } from '~/components/table/data-table-column-header'
import { Checkbox } from '~/components/ui'
import { UIFile } from '~/types'
import { formatFileSize } from '~/utils'

export interface FileTableProps extends Partial<TableOptions<UIFile>> {
	className?: string
	files: UIFile[]
	actions?: Record<string, (selectedRows: Record<string, boolean>) => void>
}

export const FileTable: FC<FileTableProps> = ({ files, ...props }) => {
	const columns = useMemo(() => {
		const data: Array<ColumnDef<UIFile>> = [
			{
				id: 'select',
				header: ({ table }) => (
					<Checkbox
						checked={table.getIsAllPageRowsSelected()}
						onCheckedChange={value => table.toggleAllPageRowsSelected(!!value)}
						aria-label="Select all"
						className="translate-y-[2px]"
					/>
				),
				cell: ({ row }) => (
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={value => row.toggleSelected(!!value)}
						aria-label="Select row"
						className="translate-y-[2px]"
					/>
				),
				enableSorting: false,
				enableColumnFilter: false,
				enableHiding: false
			},
			{
				id: 'type',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				accessorFn: row => row.metadata.mimetype
			},
			{
				id: 'name',
				accessorKey: 'name',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				enableColumnFilter: false
			},
			{
				id: 'progress',
				accessorFn: row => {
					const progress = row.progress
						? Math.floor((row.progress.bytesUploaded * 100) / row.progress.bytesTotal)
						: null
					return progress
				},
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				cell: ({ cell, row }) => {
					const progress = cell.getValue<number | null>()
					return (
						<div>
							{isPresent(progress)
								? `${progress}%`
								: row.original.id.startsWith('uppy-')
									? 'Not started'
									: 'Uploaded'}
						</div>
					)
				},
				enableColumnFilter: false
			},
			{
				id: 'size',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				accessorFn: row => row.metadata.size,
				cell: ({ cell }) => formatFileSize(cell.getValue<number>()),
				enableColumnFilter: false
			},
			{
				id: 'last_modified',
				accessorFn: row => new Date(row.metadata.lastModified).toLocaleString(),
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				enableColumnFilter: false
			},
			{
				id: 'created_at',
				accessorKey: 'created_at',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				cell: ({ cell }) => new Date(cell.getValue<string>()).toLocaleString(),
				enableColumnFilter: false
			},

			{
				id: 'updated_at',
				accessorKey: 'updated_at',
				header: ({ column }) => <DataTableColumnHeader column={column} />,
				cell: ({ cell }) =>
					cell.getValue<string>() ? new Date(cell.getValue<string>()).toLocaleString() : null,
				enableColumnFilter: false
			}
		]
		return data
	}, [])

	return (
		<DataTable
			getRowId={e => e.name}
			data={files}
			searchColumn="name"
			defaultVibility={{ created_at: false, updated_at: false, uploaded_by: false }}
			columns={columns}
			{...props}
		/>
	)
}
