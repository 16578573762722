import { SetOptional } from 'type-fest'
import { Tag, TagSet, TagType, UITagSet } from '~/types'
import { makeActionCreator, makeMetaActionCreator } from './actionCreator'

export default {
	getTagSet: makeActionCreator<TagSet>()('GET_TAG_SET'),
	getTagConfig: makeActionCreator()('GET_TAG_CONFIG'),
	getTagConfigOk: makeActionCreator<{
		tagSets: TagSet[]
		tagSetTypes: TagType[]
		tags: Tag[]
	}>()('GET_TAG_CONFIG_OK'),

	createTagSet: makeActionCreator<SetOptional<UITagSet, 'editing'>>()('CREATE_TAG_SET'),
	editTagSet: makeActionCreator<boolean, TagSet['id']>()('EDIT_TAG_SET'),
	disableTagSet: makeActionCreator<boolean, TagSet['id']>()('DISABLE_TAG_SET'),
	updateTagSet: makeActionCreator<Partial<TagSet>, TagSet['id']>()('UPDATE_TAG_SET'),
	deleteTagSet: makeMetaActionCreator<TagSet['id']>()('DELETE_TAG_SET'),

	deleteTag: makeMetaActionCreator<Tag['id']>()('DELETE_TAG')
}
