import { call, put, takeLatest } from 'typed-redux-saga'
import { supabase, toToastError } from '~/utils'
import { Action, ActionTypes, Actions } from '../actions'

export function* segmentSagas() {
	yield* takeLatest<ActionTypes, any>('GET_SEGMENTS', getSegments)
	yield* takeLatest<ActionTypes, any>('GET_RESOURCES', getSegments)
	yield* takeLatest<ActionTypes, any>('CREATE_SEGMENT', createSegment)
	yield* takeLatest<ActionTypes, any>('DELETE_SEGMENT', deleteSegment)
}

function* getSegments(action: Action<'GET_RESOURCES'> | Action<'GET_SEGMENTS'>) {
	const res = yield* call(async () =>
		supabase.from('segment').select('*, tags:segment_tag(*)').eq('project_id', action.meta)
	)
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getSegmentsOk(res.data.map(s => ({ ...s, editing: false }))))
	}
}

function* createSegment(action: Action<'CREATE_SEGMENT'>) {
	const res = yield* call(async () => supabase.from('segment').insert(action.payload).select().single())
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	}
}

function* deleteSegment(action: Action<'DELETE_SEGMENT'>) {
	const res = yield* call(async () => supabase.from('segment').delete().eq('id', action.meta))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	}
}
