/* eslint-disable jsx-a11y/label-has-associated-control */
import Uppy, { UppyEventMap } from '@uppy/core'
import Tus from '@uppy/tus'
import { kebabCase } from 'lodash-es'
import { FC, useState } from 'react'
import { LiaUploadSolid } from 'react-icons/lia'
import { objectEntries } from 'ts-extras'
import { CamelCase, KebabCase } from 'type-fest'
import { Actions } from '~/redux'
import { SUPABASE_STORAGE_URL, supabase, toToastError, useDispatch, useQuery, useSelector } from '~/utils'
import { Button } from './ui'

export interface UploadedFile {
	bucketName?: string
	objectName?: string
	contentType?: string
	cacheControl?: string
}

export type UploadEventProps = {
	[K in CamelCase<keyof UppyEventMap<UploadedFile>>]?: KebabCase<K> extends keyof UppyEventMap<UploadedFile>
		? UppyEventMap<UploadedFile>[KebabCase<K>]
		: never
}

export interface UploadProps extends UploadEventProps {
	className?: string
	label?: string
	bucketName: string
	prefix?: string
	verifyFile?: (file: File) => Promise<boolean> | boolean
}

export const Upload: FC<UploadProps> = ({
	className,
	label = 'Choose Files',
	verifyFile,
	fileAdded,
	prefix = '',
	bucketName,
	...listeners
}) => {
	const mimeTypes = useQuery([bucketName], async () =>
		supabase.functions.invoke<string[]>('storage', { body: { bucket_id: bucketName } })
	)
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)
	const [uppy] = useState(() => {
		const builder = new Uppy()
			.use(Tus, {
				// From https://supabase.com/docs/guides/storage/uploads/resumable-uploads
				endpoint: SUPABASE_STORAGE_URL,
				headers: {
					authorization: `Bearer ${user?.session.access_token}`,
					apikey: import.meta.env.VITE_SUPABASE_ANON_KEY
				},
				/** Important if you want to allow re-uploading the same file
				 * https://github.com/tus/tus-js-client/blob/main/docs/api.md#removefingerprintonsuccess
				 * */
				removeFingerprintOnSuccess: true,
				retryDelays: [100, 300, 500, 1000],
				chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
				allowedMetaFields: ['bucketName', 'objectName', 'contentType', 'cacheControl'] satisfies Array<
					keyof UploadedFile
				>
			})
			.on('file-added', file => {
				const supabaseMetadata: UploadedFile = {
					bucketName,
					objectName: `${prefix}${file.name}`,
					contentType: file.type
				}

				file.meta = {
					...file.meta,
					...supabaseMetadata
				}
				fileAdded?.(file)
				builder
					.upload()
					.then(res => {
						if (res.successful.length) {
							dispatch(
								Actions.addToast({
									title: 'Files uploaded',
									description: `Successfully uploaded ${res.successful.map(f => f.name).join()}`
								})
							)
						}
						if (res.failed) {
							for (const failure of res.failed) {
								const simpleMessage = failure.error.match(/response text: (.*?),/)
								dispatch(
									Actions.addToast({
										variant: 'destructive',
										title: 'Error uploading file',
										description: simpleMessage?.[1] ?? failure.error
									})
								)
							}
						}
					})
					.catch(async err => {
						console.error(err)
						const error = await toToastError(err)
						dispatch(Actions.addToast(error))
					})
			})
		return objectEntries(listeners).reduce(
			(a, [event, handler]) => (handler ? a.on(kebabCase(event) as KebabCase<typeof event>, handler) : a),
			builder
		)
	})

	async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
		const file = e.target.files?.[0]
		if (file) {
			const ok = verifyFile ? await verifyFile(file) : true
			if (ok) uppy.addFile({ name: file.name, type: file.type, data: file })

			// supabase.storage.from(bucketName).upload(`${prefix}${file.name}`, file, { upsert: true })
		}
		e.target.form?.reset()
	}

	return (
		<Button asChild className={className} variant="outline">
			<label className="cursor-pointer">
				<LiaUploadSolid className="mr-1 h-5 w-5" />
				{label}
				<form className="pointer-events-none absolute h-0 w-0 opacity-0">
					<input
						type="file"
						disabled={mimeTypes.isLoading}
						accept={mimeTypes.data?.data?.join(',') ?? ''}
						onChange={handleUpload}
					/>
				</form>
			</label>
		</Button>
	)
}
