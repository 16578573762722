import { TNode } from '@udecode/plate-common'
import { plateToMarkdown } from 'slate-mark'
import { call, put, takeLatest } from 'typed-redux-saga'
import { Template } from '~/types'
import { GLOBAL_COMPANY, sleep, supabase, toToastError } from '~/utils'
import { Action, ActionTypes, Actions } from '../actions'
import { getCurrentCompany } from './sagaUtils'

export function* templateSagas() {
	yield* takeLatest<ActionTypes, any>('ORGANIZATIONS_LOADED', getTemplates)
	yield* takeLatest<ActionTypes, any>('GET_TEMPLATES', getTemplates)
	yield* takeLatest<ActionTypes, any>('UPDATE_TEMPLATE', updateTemplate)
}

function* getTemplates(_action: Action<'GET_TEMPLATES'>) {
	const company = yield* call(getCurrentCompany)
	if (!company) {
		console.warn('Cannot load templates: No company selected')
		return
	}

	const res = yield* call(async () =>
		supabase.from('template').select('*').in('org_id', [company.id, GLOBAL_COMPANY]).order('created_at')
	)
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getTemplatesSuccess(res.data as Template[]))
	}
}

function removeCodeLineNodes(nodes: TNode[]) {
	return nodes.flatMap(node => (node.type === 'code_line' ? node.children : node))
}

function* updateTemplate(action: Action<'UPDATE_TEMPLATE'>) {
	yield* call(sleep, 1000)
	const payload = action.payload
	if (payload.raw) {
		const raw = payload.raw as TNode[]
		const plateContent = raw.map(node =>
			node.type === 'code_block' ? { ...node, children: removeCodeLineNodes(node.children as TNode[]) } : node
		)
		payload.text = plateToMarkdown(plateContent)
	}
	const res = yield* call(async () => supabase.from('template').update(action.payload).eq('id', action.meta))
	if (res.error) {
		const toastError = yield* call(toToastError, res.error)
		yield* put(Actions.addToast(toastError))
	}
}
