import { QueryFunction, QueryKey, UseQueryOptions, useQuery as useReactQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash-es'
import { useMemo } from 'react'
import { TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux'
import { useMatches, useParams, useSearchParams } from 'react-router-dom'
import { Except } from 'type-fest'
import { Action } from '~/redux'
import { Project, UIResource, UIResourceTagSet } from '~/types'
import type { State } from '../types/state'
import { getItem } from './storage'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => (action: Action) => void
export const useDispatch: DispatchFunc = useAppDispatch
export const useSelector: TypedUseSelectorHook<State> = useAppSelector

export function useMemoSelector<T, R>(
	selector: (s: State) => T,
	mapper: (value: T) => R,
	deps: readonly any[] = []
): R {
	const state = useSelector(selector)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const result = useMemo(() => mapper(state), [state, ...deps])
	return result
}

export function useQuery<TQueryFnData = unknown>(
	key: QueryKey,
	fn: QueryFunction<TQueryFnData, unknown[]>,
	options: Except<UseQueryOptions, 'queryFn' | 'queryKey'> = {}
) {
	return useReactQuery<TQueryFnData>({
		...options,
		queryKey: Array.isArray(key) ? key : [key],
		queryFn: fn
	} as any)
}

export function useCurrentProject() {
	const params = useParams()
	const projectId = params.projectId || getItem<Project['id']>('selectedProjectId')
	const user = useSelector(state => state.user)
	return user?.projects?.find(p => p.id === projectId)
}

export function useCurrentCompany() {
	const project = useCurrentProject()
	const companies = useSelector(state => state.user?.companies)
	return companies?.find(c => c.id === project?.org_id) ?? null
}

export function useResourceTagSets(resource: UIResource): UIResourceTagSet[] {
	const config = useSelector(state => state.config)
	const resourceSegments = useSelector(state => state.resourceSegments).filter(as => as.resource_id === resource.id)
	const existingSets = useSelector(state => state.resources.sets).filter(set => set.resource_id === resource.id)
	const segmentTags = useSelector(state => state.segmentTags)
		.filter(seg => resourceSegments.some(s => s.segment_id === seg.segment_id))
		.map(tag => ({ ...tag, tag_set_id: config.tags.find(t => t.id === tag.tag_id)?.tag_set_id }))

	let i = 0
	const segmentSets = config.sets
		.filter(set => segmentTags.some(tag => tag.tag_set_id === set.id))
		.map<UIResourceTagSet>(set => ({
			id: i++,
			resource_id: resource.id,
			editing: false,
			tag_set: set
		}))

	const setType = (set: UIResourceTagSet) =>
		config.types.find(type => type.id === set.tag_set.tag_type_id)?.order ?? 0
	return uniqBy([...segmentSets, ...existingSets], set => set.tag_set.id).sort((a, b) => {
		const order = setType(a) - setType(b)
		return order !== 0 ? order : a.tag_set.order - b.tag_set.order
	})
}

export function useSearch<T extends Record<string, any>>(initialData: T = {} as T) {
	const [_search, _setSearch] = useSearchParams(initialData)
	return useMemo(
		() =>
			[
				Object.fromEntries(_search.entries()) as T,
				(params: { [P in keyof T]?: T[P] | null }) =>
					_setSearch(
						prev => {
							const next = new URLSearchParams(prev)
							for (const [key, value] of Object.entries(params)) {
								if (value === null) next.delete(key)
								else next.set(key, value as string)
							}
							return next
						},
						{ replace: true }
					)
			] as const,
		[_search, _setSearch]
	)
}

/**
 * @TODO Hopefully implented in the future by react-router
 * @see https://github.com/remix-run/react-router/issues/8785
 * @returns the path pattern of the current route, for use in {@link generatePath}
 */
export function usePathPattern(include: string[] = []) {
	const matches = useMatches()
	const match = matches[matches.length - 1]

	if (!match) return '/'
	return Object.entries(match.params).reduce((pattern, [key, value = '']) => {
		if (include.length > 0 && !include.includes(key)) return pattern.replace(`/${value}`, '')
		return pattern.replace(value, `:${key}`)
	}, match.pathname)
}
