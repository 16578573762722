import {
	CompanySegment,
	Dagster,
	Enrichment,
	EnrichmentType,
	ProspectingCompany,
	ProspectingContact,
	ProspectingList,
	ProspectingListCompany,
	ProspectingRole,
	ProspectingType
} from '~/types'
import { makeActionCreator } from './actionCreator'

interface RunEnrichmentPayload {
	listId: string
	templateId: string
	parameters?: Record<string, string>
	type: ProspectingType
	selected?: Array<ProspectingListCompany['id'] | ProspectingCompany['id'] | ProspectingContact['id']>
}

export default {
	getProspectingListsOk: makeActionCreator<ProspectingList[]>()('GET_PROSPECTING_LISTS_OK'),
	getProspectingCompaniesOk: makeActionCreator<ProspectingCompany[]>()('GET_PROSPECTING_COMPANY_OK'),
	getProspectingListCompaniesOk: makeActionCreator<ProspectingListCompany[]>()('GET_PROSPECTING_LIST_COMPANIES_OK'),
	getProspectingContactsOk: makeActionCreator<ProspectingContact[]>()('GET_PROSPECTING_CONTACTS_OK'),
	getProspectingRolesOk: makeActionCreator<ProspectingRole[]>()('GET_PROSPECTING_ROLES_OK'),

	getEnrichmentsOk: makeActionCreator<Enrichment[]>()('GET_ENRICHMENTS_OK'),
	getEnrichmentTypesOk: makeActionCreator<EnrichmentType[]>()('GET_ENRICHMENT_TYPES_OK'),

	runEnrichment: makeActionCreator<RunEnrichmentPayload>()('RUN_ENRICHMENT'),
	getRunsOk: makeActionCreator<Dagster.RunFragment[]>()('GET_RUNS_OK'),
	runLaunchedOk: makeActionCreator<Dagster.RunFragment>()('RUN_LAUNCHED_OK'),
	getCompanySegmentsOk: makeActionCreator<CompanySegment[]>()('GET_COMPANY_SEGMENTS_OK')
}
