import { zodResolver } from '@hookform/resolvers/zod'
import { startCase } from 'lodash-es'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import {
	Button,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
	Input,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '~/components/ui'
import { Organization, UIOrganizationUser } from '~/types'
import { ROLES, supabase } from '~/utils'

const inviteFormSchema = z.object({
	email: z
		.string({
			required_error: 'Please enter your email address.'
		})
		.email('Please enter a valid email address.'),
	role: z.enum(ROLES, { required_error: 'Please select a role.' })
})

type InviteFormValues = z.infer<typeof inviteFormSchema>

export interface InviteDialogProps {
	company: Organization | null
	onClose: () => void
	onInvited?: (user: UIOrganizationUser) => void
}
export const InviteDialog: FC<InviteDialogProps> = ({ company, onClose, onInvited }) => {
	const form = useForm<InviteFormValues>({
		resolver: zodResolver(inviteFormSchema),
		defaultValues: { email: '' },
		mode: 'onBlur'
	})

	const [error, setError] = useState<string | null>(null)

	async function onSubmit(data: InviteFormValues) {
		const res = await supabase.functions.invoke<UIOrganizationUser>('invites', {
			body: { ...data, company: company?.id }
		})
		if (res.error) {
			if (res.error.context instanceof Error) return setError(res.error.message)
			const body = await res.error.context.json()
			setError(body.errors[0].message)
		} else if (res.data) {
			onInvited?.(res.data)
		}
	}

	return (
		<DialogContent>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
					<DialogHeader>
						<DialogTitle>Add a team member to {company?.name}</DialogTitle>
						<DialogDescription>
							Add a member to this organization. If a user does not exist, an invite will be sent instead.
						</DialogDescription>
					</DialogHeader>
					<div>
						<div className="space-y-4 py-2 pb-4">
							<FormField
								control={form.control}
								name="email"
								render={({ field }) => (
									<FormItem>
										<FormLabel>{startCase(field.name)}</FormLabel>
										<FormControl>
											<Input placeholder="mail@example.com" {...field} />
										</FormControl>
										<FormMessage data-testid="form-error-message" />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="role"
								render={({ field }) => (
									<FormItem>
										<FormLabel>{startCase(field.name)}</FormLabel>
										<Select onValueChange={field.onChange} defaultValue={field.value}>
											<FormControl>
												<SelectTrigger>
													<SelectValue placeholder="Select a Role" />
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												{ROLES.map(role => (
													<SelectItem key={role} value={role}>
														{startCase(role)}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
					</div>
					<DialogFooter>
						<Button variant="outline" onClick={onClose}>
							Cancel
						</Button>
						<Button type="submit">Continue</Button>
					</DialogFooter>
					{error && (
						<p className="text-center text-red-500" data-testid="form-error-message">
							{error}
						</p>
					)}
				</form>
			</Form>
		</DialogContent>
	)
}
