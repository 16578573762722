// src/Tiptap.tsx
import { EditorProvider, EditorProviderProps, FloatingMenu } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { FC } from 'react'
import { Markdown } from 'tiptap-markdown'

import { cn } from '~/utils'
import { BubbleMenu } from './BubbleMenu'
import './tiptap.css'

// define your extension array
const extensions: EditorProviderProps['extensions'] = [
	StarterKit,
	Markdown.configure({
		html: true, // Allow HTML input/output
		tightLists: true, // No <p> inside <li> in markdown output
		tightListClass: 'tight', // Add class to <ul> allowing you to remove <p> margins when tight
		bulletListMarker: '-', // <li> prefix in markdown output
		linkify: true, // Create links from "https://..." text
		breaks: false, // New lines (\n) in markdown input are converted to <br>
		transformPastedText: true, // Allow to paste markdown text in the editor
		transformCopiedText: true // Copied text is transformed to markdown
	})
]

export const Editor: FC<EditorProviderProps> = ({ ...props }) => (
	<div className={cn('prose max-w-none border', props.editable !== false ? 'border-gray-300' : 'border-transparent')}>
		<EditorProvider extensions={extensions} {...props}>
			<FloatingMenu editor={null}>This is the floating menu</FloatingMenu>
			<BubbleMenu />
		</EditorProvider>
	</div>
)
