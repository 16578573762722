import { FC, useState } from 'react'
import { HiCheckCircle, HiDocumentDuplicate, HiTrash } from 'react-icons/hi2'
import { LiaEditSolid, LiaToggleOffSolid, LiaToggleOnSolid } from 'react-icons/lia'
import { TextArea } from '~/components/form'
import { Button } from '~/components/ui'
import { Actions } from '~/redux'
import { UIResource } from '~/types'
import {
	SUPABASE_BUCKET_NAME,
	depluralize,
	enrichResource,
	getLabel,
	supabase,
	toFilePath,
	useCurrentProject,
	useDispatch,
	useSelector
} from '~/utils'
import { DownloadButton } from './DownloadButton'
import { ResourceTagSets } from './ResourceTagSets'
import { TagGroups } from './TagGroups'

export interface ResourceProps {
	resource: UIResource
}

export const Resource: FC<ResourceProps> = props => {
	const dispatch = useDispatch()
	const project = useCurrentProject()
	const types = useSelector(state => state.resourceCategories)
	const resourcesState = useSelector(state => state.resources)
	const resource = enrichResource(resourcesState)(props.resource)
	const [label, setLabel] = useState(getLabel(resource))
	const [description, setDescription] = useState(resource.description)

	return (
		<div className="h-min w-full space-y-2.5 rounded bg-gray-200 p-4 dark:bg-zinc-900" data-testid="aspect-card">
			<h2 className="text-center text-lg">
				{depluralize(types.find(t => t.id === resource.category_id)?.label)}
			</h2>
			<TextArea
				className="bg-white dark:bg-white/30"
				name="label"
				disabled={!resource.editing}
				value={label}
				onChange={e => setLabel(e.target.value)}
				onBlur={async _ => {
					if (label === resource.label) return
					if (resource.text_id)
						await supabase.from('resource_text').update({ value: label }).eq('id', resource.text_id)
					else await supabase.from('resource').update({ label }).eq('id', resource.id)
				}}
				placeholder="Enter value proposition here"
			/>
			{resource.editing ? (
				<TextArea
					className="h-12 border border-foreground/10 bg-background/60 p-3 text-sm italic shadow-inner"
					name="label"
					value={description ?? ''}
					onChange={e => setDescription(e.target.value)}
					onBlur={async _ => {
						if (description === resource.description) return
						await supabase.from('resource').update({ description }).eq('id', resource.id)
					}}
					placeholder="Description"
				/>
			) : description ? (
				<p className="!-mt-0 text-center text-sm italic">{description}</p>
			) : null}
			<div className="grid grid-flow-row grid-cols-[auto,1fr] gap-2.5">
				<TagGroups row={1} resource={resource} />
				<ResourceTagSets row={3} resource={resource} />
			</div>
			<div className="flex justify-end space-x-2">
				{resource.editing ? (
					<Button
						className="mr-auto"
						variant={resource.editing ? 'secondary' : 'ghost'}
						onClick={async _ =>
							resource.file
								? supabase.storage
										.from(SUPABASE_BUCKET_NAME)
										.remove([toFilePath(project, resource.file.filename)])
								: resource.text_id
									? supabase.from('resource_text').delete().eq('id', resource.text_id)
									: supabase.from('resource').delete().eq('id', resource.id)
						}
					>
						<HiTrash className="mr-2 h-4 w-4" /> Delete
					</Button>
				) : resource.file ? (
					<DownloadButton resourceFile={resource.file} />
				) : (
					<Button
						className="mr-auto"
						variant={resource.editing ? 'secondary' : 'ghost'}
						onClick={_ => dispatch(Actions.duplicateResource(resource))}
					>
						<HiDocumentDuplicate className="mr-2 h-4 w-4" /> Duplicate
					</Button>
				)}
				<Button
					className="place-self-end"
					variant={resource.editing ? 'secondary' : 'ghost'}
					onClick={async _ =>
						supabase.from('resource').update({ disabled: !resource.disabled }).eq('id', resource.id)
					}
				>
					{resource.disabled ? (
						<>
							<LiaToggleOffSolid className="mr-2 h-4 w-4" />
							<span>Enable</span>
						</>
					) : (
						<>
							<LiaToggleOnSolid className="mr-2 h-4 w-4" />
							<span>Disable</span>
						</>
					)}
				</Button>
				{resource.editing ? (
					<Button variant="secondary" onClick={_ => dispatch(Actions.editResource(false, resource.id))}>
						<HiCheckCircle className="mr-2 h-4 w-4" /> Done
					</Button>
				) : (
					<Button variant="ghost" onClick={_ => dispatch(Actions.editResource(true, resource.id))}>
						<LiaEditSolid className="mr-2 h-4 w-4" /> Edit
					</Button>
				)}
			</div>
		</div>
	)
}
