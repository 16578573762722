import { isPresent } from 'ts-extras'
import { call, put, takeLatest } from 'typed-redux-saga'
import { UIFile } from '~/types'
import { LISTS_BUCKET_NAME, SUPABASE_BUCKET_NAME, supabase, toToastError } from '~/utils'
import { Action, ActionTypes, Actions } from '../actions'
import { getCurrentProject } from './sagaUtils'

export function* fileSagas() {
	yield* takeLatest<ActionTypes, any>('PROJECT_SELECT', loadFiles)
	yield* takeLatest<ActionTypes, any>('DELETE_RESOURCE_FILE_OK', loadFiles) // Refresh files after resource file deletion
	yield* takeLatest<ActionTypes, any>('PROJECT_SELECT', loadResourceFiles)
}

function* loadFiles(_action: Action<'PROJECT_SELECT'>) {
	const user = yield* call(() => supabase.auth.getUser())
	if (!user.data) return
	const project = yield* call(getCurrentProject)
	if (!project) {
		yield* put(Actions.addToast({ title: 'Project not found when attemption to load files' }))
		return
	}
	const [uploads, lists] = yield* call(async () =>
		Promise.all([
			supabase.storage.from(SUPABASE_BUCKET_NAME).list(`${project.org_id}/${project.id}`),
			supabase.storage.from(LISTS_BUCKET_NAME).list(`${project.org_id}`)
		])
	)
	const error = uploads.error || lists.error
	if (error) {
		const toastError = yield* call(toToastError, error)
		yield* put(Actions.addToast(toastError))
	} else {
		const files = [
			...uploads.data.map(f => ({ ...f, project_id: project.id, bucket_id: SUPABASE_BUCKET_NAME })),
			...lists.data.map(f => ({ ...f, bucket_id: LISTS_BUCKET_NAME }))
		].filter(f => isPresent(f.id) && f.name !== '.emptyFolderPlaceholder' /* Exclude folders */)

		yield* put(Actions.getFilesOk(files as UIFile[]))
	}
}

function* loadResourceFiles(_action: Action<'PROJECT_SELECT'>) {
	const project = yield* call(getCurrentProject)
	if (!project) {
		yield* put(Actions.addToast({ title: 'Project not found when attemption to load resourceFiles' }))
		return
	}
	const resourceFiles = yield* call(async () => supabase.from('resource_file').select('*'))
	if (resourceFiles.error) {
		const toastError = yield* call(toToastError, resourceFiles.error)
		yield* put(Actions.addToast(toastError))
	} else {
		yield* put(Actions.getResourceFilesOk(resourceFiles.data))
	}
}
