import { ColumnDef, RowSelectionState, Table } from '@tanstack/react-table'
import { startCase } from 'lodash-es'
import { HiEllipsisVertical } from 'react-icons/hi2'
import { RxCross2 } from 'react-icons/rx'
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Input
} from '~/components/ui'
import { DataTableFacetedFilter } from './data-table-faceted-filter'
import { DataTableViewOptions } from './data-table-view-options'

export interface DataTableToolbarProps<TData, TValue> {
	searchColumn?: keyof TData
	table: Table<TData>
	columns: Array<ColumnDef<TData, TValue>>
	actions?: Record<string, (selectedRows: RowSelectionState) => void>
	showToggleColumns?: boolean
}

export function DataTableToolbar<TData, TValue>({
	table,
	searchColumn,
	actions,
	showToggleColumns = true
}: DataTableToolbarProps<TData, TValue>) {
	const isFiltered = table.getState().columnFilters.length > 0
	const selected = table.getState().rowSelection
	const attributeColumn =
		searchColumn && table.getAllColumns().length > 1 ? table.getColumn(searchColumn.toString()) : null
	return (
		<div className="flex items-start justify-between">
			<div className="flex flex-1 flex-wrap items-center gap-2">
				{actions && Object.keys(actions).length > 0 && (
					<DropdownMenu>
						<DropdownMenuTrigger asChild disabled={Object.keys(selected).length === 0}>
							<Button variant="outline" size="sm" className="h-8 space-x-1">
								<HiEllipsisVertical className="h-4 w-4" />
								<span>Actions</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="start" className="left-4 min-w-[150px]">
							{Object.entries(actions).map(([label, action]) => (
								<DropdownMenuItem key={label} onClick={() => action(selected)}>
									{startCase(label)}
								</DropdownMenuItem>
							))}
						</DropdownMenuContent>
					</DropdownMenu>
				)}
				{attributeColumn ? (
					<Input
						placeholder="Filter..."
						value={(attributeColumn?.getFilterValue() as string) ?? ''}
						onChange={event => attributeColumn?.setFilterValue(event.target.value)}
						className="h-8 w-[150px] lg:w-[250px]"
					/>
				) : (
					<div />
				)}
				{table
					.getAllColumns()
					.filter(col => col.getCanFilter())
					.map(column => (
						<DataTableFacetedFilter key={column.id} column={column} />
					))}
				{isFiltered && (
					<Button variant="ghost" onClick={() => table.resetColumnFilters()} className="h-8 px-2 lg:px-3">
						Reset
						<RxCross2 className="ml-2 h-4 w-4" />
					</Button>
				)}
			</div>
			{showToggleColumns && <DataTableViewOptions table={table} />}
		</div>
	)
}
