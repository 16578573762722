import { capitalize, startCase } from 'lodash-es'
import isObject from 'lodash-es/isObject'
import { isPresent } from 'ts-extras'
import { z } from 'zod'
import { Dimension, Enrichment, TablesInsert, Weight } from '~/types'
import { parseRange } from './helpers'

export function countryToEmoji(countryCode: string) {
	const offset = 127397
	const A = 65
	const Z = 90

	const f = countryCode.codePointAt(0) as number
	const s = countryCode.codePointAt(1) as number

	if (countryCode.length !== 2 || f > Z || f < A || s > Z || s < A) return '🏳️'

	return String.fromCodePoint(f + offset) + String.fromCodePoint(s + offset)
}

export function toFullCountryName(countryCode: string) {
	if (!countryCode) return ''
	try {
		const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
		return regionNames.of(countryCode)
	} catch {
		return capitalize(countryCode)
	}
}

export const AddressSchema = z.object({
	zipCode: z.string(),
	postPlace: z.string(),
	addressLine: z.string(),
	boxAddressLine: z.string().nullable()
})

export function displayAddress(address: z.infer<typeof AddressSchema>) {
	return `${address.addressLine}, ${address.zipCode} ${address.postPlace}`
}

const numberFormat = new Intl.NumberFormat()

/**
 * @deprecated Should no longer be needed with AG-Grid.
 * Use custom formatters for specific types like revenue instead of this catch-all formatter
 */
export function displayValue<T extends Record<string, unknown>>(key: string, row: T): string | boolean | number | null {
	const value = row[key]
	if (Array.isArray(value)) {
		if (value.length > 0 && isObject(value[0])) return ''
		if (value.every(v => !isPresent(v))) return '-'
		if (value.length === 2 && typeof value[0] === 'number')
			return value[1] ? value.map(numberFormat.format).join(' - ') : numberFormat.format(value[0])
		return value.join(', ')
	}
	if (isObject(value)) {
		if (Object.keys(value).length === 0) return ''
		if ('name' in value) return value.name as string
		return JSON.stringify(value)
	}
	if (typeof value === 'string') {
		if (value === 'FALSE') return false
		if (value === 'TRUE') return true
		return value.replaceAll('\\n', '\n')
	}
	if (typeof value === 'number') return numberFormat.format(value)
	if (typeof value === 'boolean') return value
	return value as string
}

export function getColumnKeys<T extends Record<string, any>>(data: T[], excludedColumns: ReadonlyArray<keyof T>) {
	const keys = new Set<string>()
	for (const row of data) {
		for (const key in row) {
			keys.add(key)
		}
	}
	for (const key of excludedColumns ?? []) {
		keys.delete(key as string)
	}
	return Array.from(keys)
}

export function downloadCSV<T extends Record<string, string>>(
	data: T[],
	filename: string,
	includedColumns?: Array<keyof T>
) {
	// Convert the data to a CSV string
	const csvRows = []
	const headers = getColumnKeys(data, []).filter(key =>
		includedColumns?.length ? includedColumns.includes(key) : true
	)
	csvRows.push(headers.join(';'))

	for (const row of data) {
		const values = headers.map(header => displayValue(header, row))
		csvRows.push(values.join(';'))
	}

	const csvString = csvRows.join('\n')

	// Create a Blob with the CSV string
	const blob = new Blob([csvString], { type: 'text/csv' })

	// Create a download link and trigger the download
	const link = document.createElement('a')
	link.href = URL.createObjectURL(blob)
	link.download = `${filename}.csv`
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

export function getColumnStats(key: string, data: Array<Record<string, unknown>>) {
	const commonValueCount = new Map<string, number>()
	let totalCount = 0

	for (const row of data ?? []) {
		if (!row[key]) continue
		const value = row[key] ? JSON.stringify(row[key]) : 'null'
		commonValueCount.set(value, (commonValueCount.get(value) ?? 0) + 1)
		totalCount++
	}

	return {
		key: startCase(key),
		uniqueValues: commonValueCount.size,
		totalValues: totalCount,
		uniquePercentage: totalCount ? commonValueCount.size / totalCount : 0,
		values: Object.fromEntries(commonValueCount.entries()) // TODO: Remove
	}
}

export function getDimensionEntry(enrichmentId: Enrichment['id'], weightId: Weight['id']) {
	function insertValue(value: number): TablesInsert<`dimension_company_${'headcount'}`>
	function insertValue(
		value: string | number
	): TablesInsert<`dimension_${'company_name' | 'company_url' | 'contact_email' | 'contact_phone'}`>
	function insertValue(
		value: string | number,
		currency: string
	): TablesInsert<`dimension_company_${'revenue' | 'profit' | 'total_assets' | 'revenue'}`>
	function insertValue(value: string | number, currency?: string) {
		const [min, max] = parseRange(value) ?? [value, null]
		const ret = {
			enrichment_id: enrichmentId,
			value: min ?? max,
			max: max ?? undefined,
			weight_id: weightId,
			currency: currency ?? undefined
		}
		if (!isPresent(ret.currency)) delete ret.currency
		if (!isPresent(ret.max)) delete ret.max
		return ret as TablesInsert<`dimension_${Dimension}`>
	}
	return insertValue
}
